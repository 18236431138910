// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-46 {
  align-items: flex-end;
  background-color: #5d7395;
  display: flex;
  height: 77px;
  justify-content: flex-end;
  left: 1512px;
  min-width: 280px;
  padding: 12px 30px;
  position: absolute;
  top: 1174px;
}

.next {
  height: 48px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  width: 212px;
}

.group-46.group-46-1 {
  left: 1591px;
  top: 1230px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Admin/CPP/Group46/Group46.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":[".group-46 {\n  align-items: flex-end;\n  background-color: #5d7395;\n  display: flex;\n  height: 77px;\n  justify-content: flex-end;\n  left: 1512px;\n  min-width: 280px;\n  padding: 12px 30px;\n  position: absolute;\n  top: 1174px;\n}\n\n.next {\n  height: 48px;\n  letter-spacing: 0;\n  line-height: 30px;\n  text-align: center;\n  width: 212px;\n}\n\n.group-46.group-46-1 {\n  left: 1591px;\n  top: 1230px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
