import * as React from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CustomerProfileHeaderSection from "./CustomerProfileHeaderSection";
import CustomerProfileSection from "./CustomerProfileSection";
import UpdateCustomerPasswordModal from "./UpdateCustomerPasswordModal";
import {
  isValidName,
  isValidEmail,
  isValidAddress,
  isValidPostcode,
  isValidPhone,
  isValidPassword,
} from "../../../utils/regexUtils";
import {
  SET_CUSTOMER_BILLING_DETAILS,
  SET_IS_LOADING,
  SET_PROFILE_UPDATE_STATUS,
} from "../../../constants/actionTypes";
import { updateCustomerDetailsByAdmin } from "../../../serviceCalls/updateCustomerDetailsByAdmin";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import Alert from "@material-ui/lab/Alert";
import { useEffect } from "react";
import CustomerProfileDiscountSection from "./CustomerProfileDiscountSection";
import NavigationBar from "../../common/NavigationBar";

const CustomerProfile = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const {
    config,
    isLoading,
    profileUpdateStatus,
    customerList,
    productList,
    cppList,
    updateCppMessage,
    cppStatus,
    tacredituser,
    updateSendCreditFormMessage,
    approvedCreditLimitMsg,
    savedTACreditMsg,
    taForm,
  } = applicationState;
  const customerId = history?.location?.pathname?.split("/")?.[2];
  let customerDetails = {};
  const [profileDetails, setProfileDetails] = React.useState({});

  useEffect(() => {
    customerDetails = customerList?.find((item) => item?.id === customerId);
    customerDetails = customerDetails ? customerDetails : {};
    let profileDetailsUpdate = {
      ...customerDetails,
      tradeofbuisness: customerDetails.tradeofbuisness
        ? customerDetails.tradeofbuisness
        : "Mobile Practitioners",
    };
    setProfileDetails(profileDetailsUpdate);
  }, [customerId, customerList]);

  const [profileDetailsError, setProfileDetailsError] = React.useState({
    firstNameError: "",
    lastNameError: "",
    addressLineOneError: "",
    addressLineTwoError: "",
    cityError: "",
    postCodeError: "",
    phoneNoError: "",
    tradeOfBusinessError: "",
  });
  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  const validateName = (e) => {
    const { id, value } = e?.target;
    if (id === "fname") {
      setProfileDetailsError({
        ...profileDetailsError,
        firstNameError: isValidName(value),
      });
    } else {
      setProfileDetailsError({
        ...profileDetailsError,
        lastNameError: isValidName(value),
      });
    }
  };

  const onNameChange = (e) => {
    if (e.target.id === "fname") {
      setProfileDetails({ ...profileDetails, firstname: e.target.value });
      setProfileDetailsError({ ...profileDetailsError, firstNameError: "" });
    } else {
      setProfileDetails({ ...profileDetails, lastname: e.target.value });
      setProfileDetailsError({ ...profileDetailsError, lastNameError: "" });
    }
    setEmptyCredentialsError("");
  };

  const validateAddress = (e) => {
    const { value } = e?.target;
    setProfileDetailsError({
      ...profileDetailsError,
      addressError: isValidAddress(value),
    });
  };

  const onAddressChange = (e) => {

    if(e.target.id === 'addressLineOne') {
      setProfileDetails({ ...profileDetails, address: {...profileDetails.address, addressLineOne: e.target.value }});
      setProfileDetailsError({ ...profileDetailsError, addressLineOneError: "" });
    }
    else if(e.target.id === 'addressLineTwo') {
      setProfileDetails({ ...profileDetails, address: {...profileDetails.address, addressLineTwo: e.target.value }});
      setProfileDetailsError({ ...profileDetailsError, addressLineTwoError: "" });
    }
    else if(e.target.id === 'city') {
      setProfileDetails({ ...profileDetails, address: {...profileDetails.address, city: e.target.value }});
      setProfileDetailsError({ ...profileDetailsError, cityError: "" });
    }
    setEmptyCredentialsError("");
  };

  const validatePostcode = (e) => {
    const { value } = e?.target;
    setProfileDetailsError({
      ...profileDetailsError,
      postcodeError: isValidPostcode(value),
    });
  };

  const onPostcodeChange = (e) => {
    setProfileDetails({ ...profileDetails, address: {...profileDetails.address, postcode: e.target.value }});
    setProfileDetailsError({ ...profileDetailsError, postCodeError: "" });
    setEmptyCredentialsError("");
  };

  const validatePhone = (e) => {
    const { value } = e?.target;
    setProfileDetailsError({
      ...profileDetailsError,
      phoneNoError: isValidPhone(value),
    });
  };

  const onPhoneChange = (e) => {
    setProfileDetails({ ...profileDetails, mobileno: e.target.value });
    setProfileDetailsError({ ...profileDetailsError, phoneNoError: "" });
    setEmptyCredentialsError("");
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const validatePassword = (e) => {
    const { value } = e?.target;
    setPasswordError(isValidPassword(value));
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setEmptyCredentialsError("");
  };

  const updatePassword = (e) => {
    if (passwordError) {
      e.preventDefault();
    } else if (password === "") {
      e.preventDefault();
      setPasswordError(isValidPassword(e.target.value));
    } else {
      const customerDetails = {
        ...profileDetails,
        password: password,
      };
      props.dispatch({ type: SET_IS_LOADING, payload: true });
      updateCustomerDetailsByAdmin({
        dispatch,
        customerDetails,
        config,
        history,
        flag: true,
      });
      setShowModal(false);
    }
  };

  const validateSubmit = (e) => {
    if (
      profileDetailsError.firstNameError ||
      profileDetailsError.lastNameError ||
      profileDetailsError.emailError ||
      profileDetailsError.phoneNoError ||
      profileDetailsError.addressLineOneError ||
      profileDetailsError.cityError ||
      profileDetailsError.addressLineTwoError ||
      profileDetailsError.postcodeError
    ) {
      setEmptyCredentialsError(
        (profileDetailsError.firstNameError ?? '') + (profileDetailsError.lastNameError ?? '') + (profileDetailsError.emailError ?? '') +
        (profileDetailsError.addressLineOneError ?? '') + (profileDetailsError.cityError ?? '') + (profileDetailsError.addressLineTwoError ?? '') +
        (profileDetailsError.phoneNoError ?? '') + (profileDetailsError.postcodeError ?? '')
      );
      e.preventDefault();
    } else if (
      profileDetails.firstName === "" ||
      profileDetails.lastName === "" ||
      profileDetails.email === "" ||
      profileDetails.address?.addressLineOne === "" ||
      profileDetails.address?.addressLineTwo === "" ||
      profileDetails.address?.city === "" ||
      profileDetails.phoneNo === "" ||
      profileDetails.postCode === ""
    ) {
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try?"
      );
      e.preventDefault();
    } else {
      props.dispatch({ type: SET_IS_LOADING, payload: true });
      dispatch({
        type: SET_CUSTOMER_BILLING_DETAILS,
        payload: profileDetails,
      });
      const customerDetails = {
        ...profileDetails,
      };
      updateCustomerDetailsByAdmin({
        dispatch,
        customerDetails,
        config,
        history,
        flag: false,
      });
    }
  };

  const showUpdatePassowrdModal = (showModalValue) => {
    setShowModal(showModalValue);
  };

  const closeAlert = () => {
    dispatch({
      type: SET_PROFILE_UPDATE_STATUS,
      payload: "",
    });
  };

  return (
    <>
      <div className="admin">
        {isLoading && (
          <div className="d-flex justify-content-center loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <div id="myprofile">
          <div>
            <AdminHeaderMenu dispatch={dispatch} />
          </div>
          <div id="profile">
            {profileUpdateStatus && (
              <Alert className="mb-4" severity="success" onClose={closeAlert}>
                {profileUpdateStatus}
              </Alert>
            )}
            <div className="container-fluid">
              <div>
                {" "}
                <NavigationBar />
              </div>
              <CustomerProfileHeaderSection
                showUpdatePassowrdModal={showUpdatePassowrdModal}
                profileDetails={profileDetails}
              />

              <CustomerProfileSection
                validateName={validateName}
                onNameChange={onNameChange}
                validateAddress={validateAddress}
                onAddressChange={onAddressChange}
                validatePostcode={validatePostcode}
                onPostcodeChange={onPostcodeChange}
                validatePhone={validatePhone}
                onPhoneChange={onPhoneChange}
                setProfileDetails={setProfileDetails}
                validateSubmit={validateSubmit}
                profileDetails={profileDetails}
                profileDetailsError={profileDetailsError}
                emptyCredentialsError={emptyCredentialsError}
                authToken={config.authToken}
              />
              <UpdateCustomerPasswordModal
                title="Please enter new password:"
                onClose={() => showUpdatePassowrdModal(false)}
                show={showModal}
                updatePassword={updatePassword}
              >
                <div className="form-floating mb-3">
                  <input
                    id="floatingPassword"
                    className="form-control"
                    type={passwordShown ? "text" : "password"}
                    onChange={(e) => onPasswordChange(e)}
                    onBlur={(e) => validatePassword(e)}
                    value={password}
                    placeholder="password"
                    required
                  />
                  <i
                    className={`fa ${
                      passwordShown ? `fa-eye-slash` : `fa-eye`
                    } sign-up-icon`}
                    onClick={togglePasswordVisiblity}
                  ></i>
                </div>
                {passwordError ? (
                  <span className="error">{passwordError}</span>
                ) : (
                  <React.Fragment />
                )}
              </UpdateCustomerPasswordModal>
              <br />
              <CustomerProfileDiscountSection
                productList={productList}
                authToken={config.authToken}
                profile={profileDetails}
                dispatch={dispatch}
                adminEmail={config.email}
                cppList={cppList}
                updateCppMessage={updateCppMessage}
                cppStatus={cppStatus}
                isAdmin={config?.userType === "USER" ? false : true}
                tacredituser={tacredituser}
                updateSendCreditFormMessage={updateSendCreditFormMessage}
                approvedCreditLimitMsg={approvedCreditLimitMsg}
                savedTACreditMsg={savedTACreditMsg}
                taForm={taForm}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;
