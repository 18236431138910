import * as React from "react";
import { useHistory } from "react-router-dom";
import { CLEAR_ADD_ITEM_FOR_ORDER, EDIT_PRODUCT_QUANTITY } from "../../../constants/actionTypes";

import { getProductsApiCall } from "../../../serviceCalls/getProductsApiCall";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { Spinner } from "react-bootstrap";

const CustomerWalkinPaymentSuccess = (props) => {
  const { applicationState, dispatch } = props;
  const history = useHistory();

  React.useEffect(() => {
    const { config } = applicationState;
    
    getProductsApiCall({
      dispatch: dispatch,
      history: history,
      signInResponse: {
        authToken: config.authToken,
        userType: config.userType
      },
      email: null,
      moveToNextPage: false
    })
   
  }, [applicationState.config.authToken]);

  const onContinueShopping = () => {
    if(applicationState.config?.userType === "ADMIN")
      history.push("/walk_in_order")
    else
      history.push("/producttypes")
  }
  return (
    <div className="admin">
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div id="checkout">
        <div className="container-fluid">
          <div className="card px-0 pb-0 mb-3">
            <div className="row">
              <div className="col-md-12 mx-0">
                <form id="msform">
                  {/* <CheckoutProgressBar /> */}
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
                      <fieldset>
                        <div className="form-card">
                          <div>
                            <h2 className="fs-title text-center">Success!</h2>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                              <img
                                src="https://img.icons8.com/color/96/000000/ok--v2.png"
                                className="fit-image"
                              />
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-7 col-sm-12 px-0 text-center">
                              <h5>Order Placed Sucessfully</h5>
                            </div>
                          </div>
                          <div className="text-center mrt-20">
                            <button
                              className="btn btn-secondary"
                              type="submit"
                              onClick={() => onContinueShopping()}
                            >
                              Continue Shopping
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerWalkinPaymentSuccess;
