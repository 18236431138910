import React from "react";
import { useHistory } from "react-router-dom";
import HeaderMenu from "../common/HeaderMenu";
import product from '../../assert/customer-design/product.png';
import minus from '../../assert/customer-design/minus-2.svg';
import plus from '../../assert/customer-design/plus-2.svg';
import { EDIT_PRODUCT_QUANTITY } from "../../constants/actionTypes";
import { customUpdateCartDetails } from "../../serviceCalls/customUpdateCartDetails";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const ProductDetails = (props) => {

    const { applicationState, dispatch } = props;
    const { cartDetails, productDetailData } = applicationState;
    const [quantity, setQuantity] = React.useState(10);

    const history = useHistory();

    const [isLocalCartEmpty, setIsLocalCartEmpty] = React.useState(
        !(productDetailData && productDetailData.quantity > 0)
    );

    React.useEffect(() => {
        let qty = cartDetails.filter((item) => item.productid === productDetailData.productid ? productDetailData.quantity : 0);
        setQuantity(qty[0]?.quantity ?? 0);
    }, []);


    const handleQuantiyChanges = (val, prod) => {

        const { productList } = applicationState;
        const productlistArray = [...productList];

        // Find and update the product quantity
        const updatedProductList = productlistArray.map((product) => {
            if (product.productid === prod.productid) {
            return {
                ...product,
                quantity: val
            };
            } else {
            return product;
            }
        });

        const tempArray = [];

        updatedProductList.forEach((product) => {
            if (product.quantity && product.quantity !== 0) {
            tempArray.push(product);
            }
        });

        tempArray.length === 0
            ? setIsLocalCartEmpty(true)
            : setIsLocalCartEmpty(false);

        dispatch({
            type: EDIT_PRODUCT_QUANTITY,
            payload: updatedProductList,
            cartDetails: tempArray,
        });
        window.sessionStorage.setItem("cart", JSON.stringify(tempArray));

        setQuantity(val);

    };

    const onInputChange = (qty, prod) => {
        if (qty &&
            !isNaN(qty) &&
            (Number(qty) <= Number(productDetailData?.warehouse?.["Online-Warehouse"]))
        ) {
            setQuantity(Number(qty));
        }
        else if (qty &&
            !isNaN(qty) &&
            (Number(qty) > Number(productDetailData?.warehouse?.["Online-Warehouse"]))) {
            setQuantity(qty);
        }
        else {
            setQuantity(0);
        }
        handleQuantiyChanges(qty, prod);
    };
    const onIncrement = (qty, prod) => {
        const incrementQuantity = Number(qty) + 1;

        if (parseInt(incrementQuantity) > productDetailData.warehouse["Online-Warehouse"]) {
            return toast(`Product quantity can not be more than ${productDetailData.warehouse["Online-Warehouse"]}`)
        }
        handleQuantiyChanges(incrementQuantity, prod);
    };

    const onDecrement = (quantity, prod) => {
        const decrementQuantity = Number(quantity) <= 0 ? 0 : Number(quantity) - 1;
        if (parseInt(decrementQuantity) < 0) {
            return toast(`Product quantity can not be less than 0`)
        }
        handleQuantiyChanges(decrementQuantity, prod);
    };

    const onInputBlur = (quantity, prod) => {
        if (quantity && !isNaN(quantity)) {
            const qty = quantity;
            if (parseInt(qty) > productDetailData.warehouse["Online-Warehouse"]) {
                setQuantity(0);
                return toast(`Product quantity can not be more than ${productDetailData.warehouse["Online-Warehouse"]}`)
            }
            if (parseInt(qty) < 0) {
                setQuantity(0);
                return toast(`Product quantity can not be less than 0`)
            }

            handleQuantiyChanges(qty, prod);
        }
    };

    const onProceed = (e) => {
        const {cartDetails, shippingCost, config} = applicationState;
        const itemList = cartDetails.map((item) => [
            item.productid,
            item.quantity,
        ]);
        let productidcartmap = Object.fromEntries(itemList);
        let subTotalValue = 0;
        let vatAmount = 0;
        const totalArray = cartDetails.map(
            (prod) => prod.salepriceperunit * prod.quantity
        );
        const vatArray = cartDetails.map((prod) => prod.vat);
        const reducer = (previousValue, currentValue) =>
            previousValue + currentValue;
        subTotalValue = totalArray.reduce(reducer);
        vatAmount = vatArray.reduce(reducer);

        const customerCartArray = {
            ordershippingcost: Number(shippingCost),
            productidcartmap,
            subtotal: Number(subTotalValue),
            totalvat: Number(vatAmount),
            userId: applicationState?.shippingAddressDetails?.email || "",
        };
        customUpdateCartDetails({
            dispatch,
            customerCartArray,
            history,
            authToken: config.authToken,
        });

    };

    return (
        <div id="producttype">
            <ToastContainer />
            <div>
                <HeaderMenu dispatch={dispatch} cartCount={cartDetails ? cartDetails.length : 0} />
            </div>
            <div class="template_product">
                <div class="container">
                    <div class="product">
                        <div class="productImage">
                            <div class="img_d">
                                {productDetailData?.productImageUrl ?
                                <img src={productDetailData?.productImageUrl} alt="img-1"/>
                                : <img src={product} alt="img-1"/>
                                }
                            </div>
                        </div>
                        <div class="productInfo">
                            <h2 class="product_title">{productDetailData.brand}</h2>
                            <div class="short_desc">
                                {productDetailData.productdesc}
                            </div>
                            <div class="priceQtyD">
                                <div class="product_price">
                                    £ {productDetailData.salepriceperunit.toFixed(2)}
                                </div>
                                <div class="pro__quantity quantity">
                                    <button className="pro__quantity-minus qty_minus"
                                        onClick={(event) => onDecrement(quantity, productDetailData)}
                                        disabled={quantity <= 0}
                                    >
                                        <img src={minus} alt="minus" />
                                    </button>
                                    <input type="text"
                                        value={quantity}
                                        class="pro__quantity-field qty_input"
                                        onChange={(val) => onInputChange(val.target.value, productDetailData)}
                                        onBlur={(val) => onInputBlur(val.target.value, productDetailData)}
                                        disabled={productDetailData?.warehouse?.["Online-Warehouse"] === 0}
                                    />
                                    <button className="pro__quantity-plus qty_plus"
                                        onClick={(val) => onIncrement(quantity, productDetailData)}
                                        disabled={quantity === Number(productDetailData?.warehouse?.["Online-Warehouse"])}
                                    >
                                        <img src={plus} alt="plus" />
                                    </button>
                                </div>
                            </div>
                            <div class="addNBuyBtns">
                                <button
                                    class="btn indigo-btn addToCart"
                                    onClick={(e) => onProceed(e)}
                                    disabled={quantity <= 0}
                                >Add To Cart</button>
                                <button
                                    className="btn pink-btn"
                                    type="submit"
                                    name="btn-checkout"
                                    id="btn-checkout"
                                    onClick={(e) => onProceed(e)}
                                    disabled={quantity <= 0}
                                >Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ProductDetails;
