import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_ERROR, SET_IS_LOADING } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { getCouponApiCall } from "./getCouponApiCall";

export const updateCoupon = async ({
  dispatch,
  requestBodyForEdit,
  config,
}) => {
  const authToken = localStorage.getItem("sessionKey");
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    {
      "x-auth-token": authToken,
      "Content-Type": "application/json",
      Cookie: "JSESSIONID=0219AB5B01F985FD5218404C0C1CBE0F",
    },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/couponlines/editcoupon`;
  await axios
    .post(apiUrl, requestBodyForEdit, apiRequestHeader)
    .then((apiResponse) => {
      console.log("edit coupon ", apiResponse);

      if (apiResponse?.status === 200) {
        getCouponApiCall({ dispatch: dispatch, config: config });
      }
    })
    .catch((error) => {
      console.log("edit coupon error ", error);
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
};
