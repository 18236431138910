// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: -webkit-fill-available;
  text-align: center;
}

.navBarHeading {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;

  /* line-height: 28.13px; */
}

.navBarHeadingMenu {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 12px;
  cursor: pointer;
  /* line-height: 16.41px; */
}
`, "",{"version":3,"sources":["webpack://./src/components/css/homepage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;;EAEf,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".content {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  height: -webkit-fill-available;\n  text-align: center;\n}\n\n.navBarHeading {\n  font-size: 24px;\n  font-weight: 600;\n  color: #ffffff;\n  cursor: pointer;\n\n  /* line-height: 28.13px; */\n}\n\n.navBarHeadingMenu {\n  font-size: 14px;\n  font-weight: 600;\n  color: #ffffff;\n  margin-top: 12px;\n  cursor: pointer;\n  /* line-height: 16.41px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
