// QrModal.js
import React from "react";
import Modal from "react-modal";

const QrModal = ({ isOpen, qrcode, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          width: "50%",
          height: "auto",
          margin: "auto",
          border: "none",
          borderRadius: "8px",
          padding: "20px",
        },
      }}
    >
      <div>
        <img src={qrcode} alt="QR Code" style={{ width: "100%",marginTop:"15%" }} />
      </div>
    </Modal>
  );
};

export default QrModal;
