import * as React from "react";
import moment from "moment";

const Enum = {
  NEXT_DAY: "Next day delivery",
  NEXT_DAY_PM: "Next day by 12pm",
  NEXT_DAY_AM: "Next day by 10.30am",
  SATURDAY_DAY: "Saturday Delivery",
  CLICK_AND_COLLECT: "Click and Collect",
};

const OrderDetailsTab = ({
  orderInfo,
  showEmailPopUp,
  showStatus,
  disableButtons,
}) => {
  const orderShippingInfo = (cost) => {
    switch (cost.toString()) {
      case "0":
        return `${Enum.CLICK_AND_COLLECT} £${cost}`;
      case "0.00":
        return `${Enum.CLICK_AND_COLLECT} £${cost}`;
      case "9.95":
        return `${Enum.NEXT_DAY} £${cost}`;
      case "15.95":
        return `${Enum.NEXT_DAY_PM} £${cost}`;
      case "19.95":
        return `${Enum.NEXT_DAY_AM} £${cost}`;
      case "14.00":
        return `${Enum.SATURDAY_DAY} £${cost}`;
      default:
        return `£${cost}`;
    }
  };

  return (
    <>
      <div className="row pt-5 form-card p-3">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order Id -</strong>
          </label>{" "}
          {orderInfo?.id || "N/A"}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order Date -</strong>
          </label>{" "}
          {moment(orderInfo?.orderDate || "N/A").format(
            "DD-MM-YYYY  h:mm:ss a"
          )}
        </div>
      </div>
      <div className="row form-card p-3">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order No -</strong>
          </label>{" "}
          {orderInfo?.orderNum || "N/A"}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order Amount -</strong>
          </label>{" "}
          £ {orderInfo?.amount || "N/A"}
        </div>
      </div>
      <div className="row form-card p-3">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order Payment Method -</strong>
          </label>{" "}
          {orderInfo?.orderpaymentmethod || "N/A"}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order Shipping cost -</strong>
          </label>{" "}
          {orderShippingInfo(
            orderInfo?.shippingcost ||
              "Click and Collect " + orderInfo?.shippingcost
          )}
        </div>
      </div>
      <div className="row form-card p-3">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Products Warehouse -</strong>
          </label>{" "}
          {orderInfo?.warehouse || "N/A"}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Promocode Discount -</strong>
          </label>{" "}
          {orderInfo?.promocodeapplied ? "Yes" : "No"}
        </div>
      </div>
      <div className="row form-card p-3">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Promocode Applied -</strong>
          </label>{" "}
          {orderInfo?.promocode || "N/A"}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label>
            <strong>Order Type -</strong>
          </label>{" "}
          {orderInfo?.ordertype === "STORE"
            ? `${orderInfo?.ordertype} ${orderInfo?.shippingtype.replace(
                "Click and Collect :",
                "- "
              )}`
            : orderInfo?.ordertype}
        </div>
      </div>
      {showStatus && (
        <div className="row form-card p-3">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label>
              <strong>Status -</strong>
            </label>{" "}
            {orderInfo?.currentstage || "N/A"}
          </div>
        </div>
      )}
    </>
  );
};
export default OrderDetailsTab;
