import axios from "axios";
import { DELETE_COUPON } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { getCouponApiCall } from "./getCouponApiCall";

export const deleteCoupon = async ({ dispatch, couponId, config }) => {
    // console.log('delete coupon id ', couponId);
    // return
  try {
    const authToken = localStorage.getItem("sessionKey");
    // console.log('authToken ', authToken);
    
    const apiRequestHeader = makeApiRequestHeader(
       "DELETE",
      {
        "x-auth-token": authToken,
        "Content-Type": "application/json",
        Cookie: "JSESSIONID=0219AB5B01F985FD5218404C0C1CBE0F",
      },
      null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/couponlines/deletebyid?id=${couponId}`;
    const response = await axios.delete(apiUrl, apiRequestHeader);
    dispatch({
      type: DELETE_COUPON,
      payload: couponId,
    });
    getCouponApiCall({ dispatch: dispatch, config: config });
    return response;
  } catch (e) {
    console.log('error ', e);
    return logError(e, "deleteCoupon");
  }
};
