import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_IS_LOADING, SET_ERROR, TA_CREDIT_APPROVAL_SUCCESSED } from "../constants/actionTypes";
import { fetchCreditUserApiCall } from "../serviceCalls/fetchCreditUserApiCall";

import envConfig from "../envConfigurations";

export const approveCreditLimitApiCall = async ({
    dispatch,
    authToken,
    approveremail,
    status,
    email
}) => {
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/approveTACredituser`;
    await axios
        .post(apiUrl, {approveremail: approveremail, status: status, email:email}, apiRequestHeader)
        .then(() => {
            dispatch({
                type: TA_CREDIT_APPROVAL_SUCCESSED, message: "Trade Account Credit Approved"
            })
            dispatch({
                type: SET_IS_LOADING, isLoading: false
            })
            fetchCreditUserApiCall({
                dispatch: dispatch,
                authToken: authToken,
                email: email
            })
        })
        .catch(() => {
            dispatch({
                type: SET_ERROR,
            });
        });
};
