import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import Tooltip from '@material-ui/core/Tooltip';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { ProductQuantityEditor } from './ProductQuantityEditor';
import { ScanProductButtonRenderer } from './ScanProductButtonRenderer';
import { verifyOrderItem } from '../../../serviceCalls/scanApis';
import { downloadDpdLabel } from "../../../serviceCalls/downloadDpdLabel";


const ProductDetailsTab = ({ refetchOrderData, orderInfo, showEmailPopUp, dispatch, history, config, onClose, disableButtons }) => {

  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [productsOrderedArray, setProductsOrderedArray] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState([])

  React.useEffect(() => {
    const productOrderedListArray = [];
    orderInfo?.orderdetailList.map((rowdetail) => {
      productOrderedListArray.push({
        ...rowdetail
      })
    });
    setProductsOrderedArray(productOrderedListArray);
  }, [orderInfo])

  const onGridReady = params => {
    setGridApi(params?.api);
    setGridColumnApi(params?.columnApi)
    params?.api?.sizeColumnsToFit()
  }

  const frameWorkComponentChange = ({ api, changedPrice }) => {
    const productOrderedArray = [];
    api.forEachNode((node) => {
      if (changedPrice && changedPrice?.data?.productid === node?.data?.productid && changedPrice?.data?.vat > 0) {
        node.data.vat = 0.20 * changedPrice?.data?.price;
      }
      let productOrderedObject = {
        ...node?.data,
        amount: Number((Number(node?.data?.quantity) * Number(node?.data?.price)) + (Number(node?.data?.quantity) * Number(node?.data?.vat)))
      };
      productOrderedArray.push(productOrderedObject);
    });
    setProductsOrderedArray(productOrderedArray);
  }

  const columnDefs = ({ frameWorkComponentChange }) => [
    { field: 'brand', headerName: "Brand" },
    { field: 'producttype', headerName: "Product Type" },
    { field: 'productdesc', headerName: "Product Description" },
    {
      field: 'quantity',
      headerName: "Quantity",
      headerTooltip: "Quantity",
      editable: false
    },
    {
      field: 'price',
      headerName: "Sales Price Unit",
      headerTooltip: "Sales Price Unit",
    },
    {
      field: 'vat',
      headerName: "Product VAT",
      headerTooltip: "Product VAT",
    },
    {
      field: 'amount',
      headerName: "Total",
      headerTooltip: "Total",
    },
    {
      field: 'amountpaid',
      headerName: "Amount Paid",
      headerTooltip: "Amount Paid",
    },
    {
      field: 'scanProduct',
      headerName: "Scan Product",
      headerTooltip: "Scan Product",
      cellRenderer: "scanProductButtonRenderer",
      editable: false,
      cellRendererParams: {
        tickKey: "status",
        refetch: refetchOrderData,
        onScan: (barcode) => {
          return verifyOrderItem(barcode, orderInfo?.id);
        }
      }
    },
  ];



  // const rowData = () => {
  // const productOrderedListArray = [];
  // orderInfo.orderdetailList.map((rowdetail) => {
  //   productOrderedListArray.push({
  //     ...rowdetail
  //   })
  // });
  // setProductsOrderedArray(productOrderedListArray);
  // return productOrderedListArray
  // };


  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    sortable: true
  }), []);

  return (<>
    <div
      className="ag-theme-alpine product-detail-table"
      style={{ width: "100%" }}
    >
      <AgGridReact
        rowData={productsOrderedArray}
        columnDefs={columnDefs({
          frameWorkComponentChange: frameWorkComponentChange,
        })}
        gridOptions={{ rowHeight: 50, autoSizeColumns: true }}
        style={{ margin: 0 }}
        cellS
        rowSelection={'multiple'}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        context={{ frameWorkComponentChange: frameWorkComponentChange }}
        pagination={true}
        suppressRowClickSelection={true}
        frameworkComponents={{
          productQuantityEditor: ProductQuantityEditor,
          scanProductButtonRenderer: ScanProductButtonRenderer
        }}
        paginationAutoPageSize={true}
      ></AgGridReact>

      <button className='btn button-primary dpd button-danger' onClick={() => downloadDpdLabel(orderInfo.id,"order")}>DPD Label</button>
    </div>
  </>)

}
export default ProductDetailsTab