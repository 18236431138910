import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { editProductApiCall } from "../../../serviceCalls/editProductApiCall";
import { updateProductImage } from "../../../serviceCalls/updateProductImage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateProductModal = (props) => {
  const {
    config,
    dispatch,
    dataForUpdateModal,
    onClose,
    history,
    selectedWarehouse,
    showDeleteProductModal,
  } = props;
  const [newProductDetails, setNewProductDetails] = React.useState({});
  const [isVatChecked, setIsVatChecked] = React.useState(!!dataForUpdateModal.vat)
  const handleVatCheckboxChange = (event) => {
    setIsVatChecked(event.target.checked);
  };

  React.useEffect(() => {
    console.log(dataForUpdateModal);
    setIsVatChecked(!!dataForUpdateModal.vat);
    setNewProductDetails({
      productId: dataForUpdateModal?.productid ?? "",
      productType: dataForUpdateModal?.producttype ?? "",
      productBrand: dataForUpdateModal.brand ?? "",
      productDescription: dataForUpdateModal?.productdesc ?? "",
      productPrice:
        Number(dataForUpdateModal?.salepriceperunit || 0).toFixed(2) ?? 0,
      productWarehouse: selectedWarehouse ?? "",
      productWareHouseStock:
        dataForUpdateModal?.warehouse?.[selectedWarehouse] ?? "",
      productShortCode: dataForUpdateModal?.shortcode ?? "",
      productStockYellow: Number(dataForUpdateModal?.threshold) ?? 0,
      productStockRed: Number(dataForUpdateModal?.breakpoint) ?? 0,
      costprice: Number(dataForUpdateModal.costprice).toFixed(2) ?? "",
      morethan20discount:
        Number(dataForUpdateModal?.morethan20discount).toFixed(2) ?? "",
      morethan50discount:
        Number(dataForUpdateModal?.morethan50discount).toFixed(2) ?? "",
      productstatus: dataForUpdateModal?.productstatus ?? "",
      productimage: dataForUpdateModal?.productImageUrl ? "Product already has image." : ""
    });
  }, [dataForUpdateModal, selectedWarehouse]);

  const [newProductDetailsError, setNewProductDetailsError] = React.useState({
    productIdError: "",
    productTypeError: "",
    productBrandError: "",
    productDescriptionError: "",
    productPriceError: "",
    productWarehouseError: "",
    productWareHouseStockError: "",
    productVatError: "",
    productShortCodeError: "",
    productStockYellowError: "",
    productStockRedError: "",
    costPriceError: "",
    morethan20discountError: "",
    morethan50discountError: "",
    productstatusError: "",
  });

  const validateValue = (value, fieldName) => {
    value === ""
      ? setNewProductDetailsError({
          ...newProductDetailsError,
          [`${fieldName}Error`]: "Please enter a proper value",
        })
      : setNewProductDetailsError({
          ...newProductDetailsError,
          [`${fieldName}Error`]: "",
        });
  };

  const {
    productId,
    productType,
    productBrand,
    productDescription,
    productPrice,
    productWarehouse,
    productWareHouseStock,
    productVat,
    productShortCode,
    productStockYellow,
    productStockRed,
    costprice,
    morethan20discount,
    morethan50discount,
    productstatus,
    productimage,
  } = newProductDetails;

  const {
    productIdError,
    productTypeError,
    productBrandError,
    productDescriptionError,
    productPriceError,
    productWareHouseStockError,
    productShortCodeError,
    productStockYellowError,
    productStockRedError,
    costPriceError,
    morethan20discountError,
    morethan50discountError,
  } = newProductDetailsError;

  const onEdit = () => {
    const nProductPrice = Number(productPrice);
    const requestBodyForEdit = {
      product: {
        productid: productId,
        brand: productBrand,
        producttype: productType,
        productdesc: productDescription,
        salepriceperunit: productPrice,
        vat: isVatChecked ? Number((nProductPrice * 0.20).toFixed(2)) : 0,
        threshold: productStockYellow,
        breakpoint: productStockRed,
        shortcode: productShortCode,
        costprice: costprice,
        morethan20discount: morethan20discount,
        morethan50discount: morethan50discount,
        productstatus: productstatus,
      },
      warehousename: selectedWarehouse,
      warehousequantity: productWareHouseStock,
    };
    editProductApiCall({
      dispatch: dispatch,
      authToken: config.authToken,
      requestBodyForEdit: requestBodyForEdit,
      history: history,
      config: config,
    });
    onClose();
  };

  const onImageUpdate = (e) => {
    // console.log(productId, e.target.files[0]);
    updateProductImage(productId, e.target.files[0], imageUpdated);
  }

  const imageUpdated = () => {
    setNewProductDetails({
        ...newProductDetails,
        productimage : 'Product image updated successfully.'
    });
  }

  return (
    <>
      {props.dataForUpdateModal &&
        Object.keys(props.dataForUpdateModal).length > 0 && (
          <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
          >
            <div className="modal enter-done">
              <ToastContainer />
              <div
                className="modal-content"
                style={{ overflow: "auto", maxHeight: "600px" }}
              >
                <div className="modal-header">
                  <h4 className="modal-title">Edit Product Details</h4>
                </div>
                <div className="modal-body">
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="productId">
                        Product Id<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productId"
                        value={productId}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productIdError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productId: e.target.value,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productId")
                        }
                      />
                      {productIdError ? (
                        <span className="error">{productIdError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>

                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="productType">
                        Product Type<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productType"
                        value={productType}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productTypeError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productType: e.target.value,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productType")
                        }
                      />
                      {productTypeError ? (
                        <span className="error">{productTypeError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="productBrand">
                        Product Brand<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productBrand"
                        value={productBrand}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productBrandError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productBrand: e.target.value,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productBrand")
                        }
                      />

                      {productBrandError ? (
                        <span className="error">{productBrandError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="productDescription">
                        Product Description<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productDescription"
                        value={productDescription}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productDescriptionError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productDescription: e.target.value,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productDescription")
                        }
                      />

                      {productDescriptionError ? (
                        <span className="error">{productDescriptionError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="productPrice">
                        Product Price<span class="required">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control form-data"
                        id="productPrice"
                        value={productPrice}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productPriceError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productPrice: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          setNewProductDetails({
                            ...newProductDetails,
                            productPrice: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                          validateValue(e.target.value, "productPrice");
                        }}
                      />

                      {productPriceError ? (
                        <span className="error">{productPriceError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="productWareHouseStock">
                        Product Warehouse Stock<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productWareHouseStock"
                        placeholder="productWareHouseStock"
                        value={productWareHouseStock}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productWareHouseStockError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productWareHouseStock: e.target.value
                              ? Number(e.target.value)
                              : 0,
                            // productWareHouseStock: dataForUpdateModal.warehouse[productWarehouse],
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productWareHouseStock")
                        }
                      />

                      {productWareHouseStockError ? (
                        <span className="error">
                          {productWareHouseStockError}
                        </span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="productVat">
                        Product Vat<span class="required">*</span>
                      </label>
                      <input
                        type="checkbox"
                        className="form-data"
                        id="productVat"
                        checked={isVatChecked}
                        onChange={handleVatCheckboxChange}
                      />
                      {/* <input
                        type="text"
                        className="form-control form-data"
                        id="productVat"
                        value={productVat}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productVatError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productVat: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        onBlur={(e) => {
                          setNewProductDetails({
                            ...newProductDetails,
                            productVat: e.target.value ? e.target.value : 0,
                          });
                          validateValue(e.target.value, "productVat");
                        }}
                      />

                      {productVatError ? (
                        <span className="error">{productVatError}</span>
                      ) : (
                        <React.Fragment />
                      )} */}
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="productShortCode">
                        Product Short Code
                      </label>

                      <input
                        type="text"
                        className="form-control form-data"
                        id="productShortCode"
                        value={productShortCode}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productShortCodeError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productShortCode: e.target.value,
                          });
                        }}
                      />
                      {productShortCodeError ? (
                        <span className="error">{productShortCodeError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="productStockYellow">
                        Product Stock Yellow<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productStockYellow"
                        value={productStockYellow}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productStockYellowError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productStockYellow: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productStockYellow")
                        }
                      />

                      {productStockYellowError ? (
                        <span className="error">{productStockYellowError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="productStockRed">
                        Product Stock Red<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="productStockRed"
                        value={productStockRed}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            productStockRedError: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            productStockRed: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "productStockRed")
                        }
                      />

                      {productStockRed ? (
                        <span className="error">{productStockRedError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="costprice">
                        Cost Price<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="costprice"
                        value={costprice}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            costprice: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            costprice: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "costprice")
                        }
                      />

                      {costPriceError ? (
                        <span className="error">{costPriceError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="morethan20discount">
                        More Than 20 Discount<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="morethan20discount"
                        value={morethan20discount}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            morethan20discount: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            morethan20discount: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "morethan20discount")
                        }
                      />

                      {morethan20discountError ? (
                        <span className="error">{morethan20discountError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="morethan50discount">
                        More Than 50 Discount<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="morethan50discount"
                        value={morethan50discount}
                        onChange={(e) => {
                          setNewProductDetailsError({
                            ...newProductDetailsError,
                            morethan50discount: "",
                          });
                          setNewProductDetails({
                            ...newProductDetails,
                            morethan50discount: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        onBlur={(e) =>
                          validateValue(e.target.value, "morethan50discount")
                        }
                      />

                      {morethan50discountError ? (
                        <span className="error">{morethan50discountError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="productstatus">
                        Status<span class="required">*</span>
                      </label>
                      <select
                        className="form-select form-data"
                        id="productstatus"
                        name="productstatus"
                        value={productstatus}
                        onChange={(e) => {
                          console.log(newProductDetails);
                          setNewProductDetails({
                            ...newProductDetails,
                            productstatus: e.target.value,
                          });
                        }}
                      >
                        <option value="">Please select</option>
                        <option value="live">Live</option>
                        <option value="draft">Draft</option>
                      </select>
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="productimage">
                        Product Image
                      </label>
                      <input
                        type="file"
                        id="productimage"
                        className="form-control form-data"
                        accept="image/*"
                        onChange={(e) => onImageUpdate(e)}
                      />
                      <span>{productimage}</span>
                    </div>
                  </div>
                  <div className="modal-footer text-align-center">
                    <button
                      className="btn btn-main btn-update"
                      onClick={() => onEdit()}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-main btn-delete"
                      onClick={() => showDeleteProductModal(true)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-secondary"
                      onClick={() => props.onClose()}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
    </>
  );
};
export default UpdateProductModal;