import * as React from "react";
import { useHistory, Redirect } from "react-router-dom";
import { SET_SIGN_IN_DATA, SET_IS_LOADING } from "../constants/actionTypes";
import { getProductsApiCall } from "../serviceCalls/getProductsApiCall";

const PrescriberAuth = (WrappedComponent, props) => {
    const history = useHistory();
    const { applicationState, dispatch } = props;
    const { config, productList } = applicationState;
    const [isAuthenticated, setAuthentication] = React.useState(true);

    const activeSession = window.localStorage.getItem("sessionKey");
    const userType = window.localStorage.getItem("userType");
    const email = window.localStorage.getItem("email");
    const counter = window.localStorage.getItem("counter");

    const payload = {
        authToken: activeSession,
        userType: userType
    }

    React.useEffect(() => {
        // try{
        //     if(!window.sessionStorage.getItem("userType")){
        //         history.push('/')
        //     }
        // }
        // catch(e){

        // }


        if (userType == "PRESCRIBER") {
            setAuthentication(true)
        } else {
            setAuthentication(false)
            return (<Redirect to="/aboutus"></Redirect>)
        }

    }, [config])


    const loginErrorMessage = (
        <div>
            {/* Only Admin Can Access This Page..
      <br />
      <a href="/">Click here!</a>
       */}
            <Redirect to="/"></Redirect>
        </div>
    );

    return (
        <div>
            {isAuthenticated === true ? (
                <WrappedComponent {...props} />
            ) : (
                loginErrorMessage
            )}
        </div>
    );
};

export default PrescriberAuth;
