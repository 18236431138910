import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Spinner } from "react-bootstrap";
import { Alert } from "@material-ui/lab";
import { UpdateProductColumn } from "./UpdateProductColumn";
import UpdateProductModal from "./UpdateProductModal";
import { DeleteProductColumn } from "./DeleteProductColumn";
import DeleteProductModal from "./DeleteProductModal";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import AddProductModal from "./AddProductModal";
import { StockFlagColumn } from "./StockFlagColumn";
import FileUpload from "./FileUpload";
import {
  SET_IS_LOADING,
  SET_ADD_DELETE_NEW_PRODUCT,
} from "../../../constants/actionTypes";
import { exportProducts } from "../../../serviceCalls/exportProducts";
import { exportCRM } from "../../../serviceCalls/exportCRM";
import { SalesPriceColumn } from "./SalesPriceColumn";
import { SUPPORTED_WAREHOUSE } from "../../../constants/common";
import WarehouseProductQuantity from "./WarehouseProductQuantity";
import NavigationBar from "../../common/NavigationBar";
import PopupCellRenderer from "./PopupCellRenderer";

const AdminProductList = (props) => {
  const { applicationState, dispatch } = props;
  const { isLoading, config, productList, addDeleteStatus, alertMessage } =
    applicationState;
  const history = useHistory();

  const [selectedWarehouse, setSelectedWarehouse] = useState(
    SUPPORTED_WAREHOUSE.Online_Warehouse
  );
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [dataForUpdateModal, setDataForUpdateModal] = React.useState({});
  const [dataForDeleteModal, setDataForDeleteModal] = React.useState({});
  const [showUpdateModal, setUpdateProductModal] = React.useState(false);
  const [showDeleteModal, setDeleteProductModal] = React.useState(false);
  const [showAddModal, setAddProductModal] = React.useState(false);

  const [showFileUploadModal, setFileUploadModal] = React.useState(false);

  const closeAlert = () => {
    dispatch({
      type: SET_ADD_DELETE_NEW_PRODUCT,
      payload: "",
      message: "",
    });
  };

  const _setSelectedWarehouse = (warehouse) => {
    setSelectedWarehouse(warehouse);
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const frameWorkComponentChange = ({ api, data, column, node, context }) => {
    setDataForUpdateModal(node.data);
    showUpdateProductModal(true);
  };

  const deleteComponentClick = ({ api, data, column, node, context }) => {
    console.log(node, data, 80);
    setDataForDeleteModal(node.data);
    setDeleteProductModal(true);
  };

  const columnDefs = () => [
    // {
    //   field: "stockFlag",
    //   headerName: "Stock Flag",
    //   cellRenderer: "stockFlagComponent",
    //   cellRendererParams: {
    //     selectedWarehouse
    //   }
    // },
    {
      field: "barcode", //replace with barcode number
      headerName: "Barcode Number",
      valueGetter: (params) => params.data.barCode?.code,
      cellStyle: { color: "#31A191", fontSize: "14px" },
      cellRendererFramework: PopupCellRenderer,
      width: 150,
    },
    {
      field: "brand",
      headerName: "Brand",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 200,
    },
    {
      field: "producttype",
      headerName: "Product type",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 195,
    },
    {
      field: "productdesc",
      headerName: "Description",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "numberofstock",
      headerName: "Quantity",
      cellRenderer: "warehouseFilter",
      cellRendererParams: {
        selectedWarehouse,
      },
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "salepriceperunit",
      headerName: "Price",
      cellRenderer: "salepriceperunit",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "salepriceperunitxxx",
      headerName: "Total Price",
      cellRenderer: (params) => {
        // console.log('params',params)
        let temp = params?.data?.salepriceperunit + params?.data?.vat
        return `£${temp.toFixed(2)}`;
      },
    
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "productstatus", //replace with status
      headerName: "Status",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      valueFormatter: function (params) {
        // Get the value of productstatus
        const status = params.value;

        // Convert the first letter to uppercase
        const capitalizedStatus =
          status.charAt(0).toUpperCase() + status.slice(1);

        return capitalizedStatus;
      },
    },
    {
      field: "update",
      headerName: "Update",
      cellRendererFramework: UpdateProductColumn,
      resizable: false,
      width: 145,
    },
  ];

  const rowData = () => {
    console.log('productList',productList)
    return productList || [];
  };

  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // minWidth: 256,
    }),
    []
  );

  const onFilterTextBoxChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };
  const showUpdateProductModal = (showModalValue) => {
    setUpdateProductModal(showModalValue);
  };

  const showDeleteProductModal = (showModalValue) => {
    setDeleteProductModal(showModalValue);
  };
  const showAddProductModal = (showModalValue) => {
    setAddProductModal(showModalValue);
  };

  const exportProductList = (e) => {
    e.preventDefault();
    dispatch({ type: SET_IS_LOADING, payload: true });
    exportProducts({
      dispatch,
      authToken: config.authToken,
    });
  };
  const exportCRMDocument = (e) => {
    e.preventDefault();
    dispatch({ type: SET_IS_LOADING, payload: true });
    exportCRM({
      dispatch,
      authToken: config.authToken,
    });
  };

  // set background colour on even rows again, this looks bad, should be using CSS classes
  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F8F7FF" };
      // E3ADAB 3141A1
    }
  };

  return (
    <div id="adminproductlist" className="admin">
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div className="container-fluid">
        {addDeleteStatus && (
          <Alert
            variant="filled"
            className="mb-2"
            severity={addDeleteStatus}
            onClose={() => closeAlert()}
          >
            {alertMessage}
          </Alert>
        )}
        <div>
          {" "}
          <NavigationBar />
        </div>
        {/* <div className="card"> */}
        <div className="list-actions">
          <select
            className="form-select"
            onChange={(e) => _setSelectedWarehouse(e.target.value)}
          >
            {SUPPORTED_WAREHOUSE.LIST.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
          <input
            className="search-bottom-margin mt-4"
            type="text"
            id="filter-text-box"
            placeholder="Type to search..."
            onChange={(event) => onFilterTextBoxChanged(event)}
          />
        </div>
        <div className="text-center mrt-20 btn-container">
          <button
            className="btn btn-main"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={() => showAddProductModal(true)}
          >
            Add Product
          </button>
          <button
            className="btn btn-secondary"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={() => setFileUploadModal(!showFileUploadModal)}
          >
            Import Product
          </button>
          <button
            className="btn btn-secondary"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={(e) => exportProductList(e)}
          >
            Export Products
          </button>
          {/* <button
            className="btn btn-secondary"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={(e) => exportCRMDocument(e)}
          >
            Export CRM
          </button> */}
        </div>
        <div
          className="ag-theme-alpine"
          // style={{ height: "calc(100vh - 309px)", width: "100%" }}
          style={{
            // height: `${productList?.length * 25 + 40}px`,
            height: `2240px`,
            width: "100%",
          }}
        >
          <AgGridReact
            getRowStyle={getRowStyle}
            rowData={rowData()}
            columnDefs={columnDefs()}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            context={{
              frameWorkComponentChange: frameWorkComponentChange,
              deleteComponentClick: deleteComponentClick,
              showUpdateProductModal: showUpdateProductModal,
              showDeleteProductModal: showDeleteProductModal,
            }}
            // rowSelection={"multiple"}
            // paginationAutoPageSize={true}
            paginationPageSize={50}
            pagination={true}
            frameworkComponents={{
              stockFlagComponent: StockFlagColumn,
              salepriceperunit: SalesPriceColumn,
              warehouseFilter: WarehouseProductQuantity,
            }}
          ></AgGridReact>
        </div>
        {/* </div> */}
        {/* {showFileUploadModal && <FileUpload />} */}
        {productList && (
          <>
            <UpdateProductModal
              onClose={() => showUpdateProductModal(false)}
              show={showUpdateModal}
              dataForUpdateModal={dataForUpdateModal}
              config={config}
              history={history}
              dispatch={dispatch}
              selectedWarehouse={selectedWarehouse}
              showDeleteProductModal={showDeleteProductModal}
            />
            <DeleteProductModal
              title="Delete Product"
              onClose={() => {
                showDeleteProductModal(false);
                showUpdateProductModal(false);
              }}
              show={showDeleteModal}
              dataForDeleteModal={dataForUpdateModal}
              config={config}
              history={history}
              dispatch={dispatch}
            >
              <p>
                <strong>Are you sure you want to delete this product?</strong>
              </p>
            </DeleteProductModal>
            <AddProductModal
              onClose={() => showAddProductModal(false)}
              show={showAddModal}
              config={config}
              history={history}
              dispatch={dispatch}
            />
            <FileUpload
              onClose={() => setFileUploadModal(false)}
              show={showFileUploadModal}
              dispatch={dispatch}
              authToken={config.authToken}
              config={config}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AdminProductList;
