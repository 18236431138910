import * as React from "react";

const CheckoutProgressBar = (props) => {
  return (
    <>
      <div class="section_wizard">
        <ul class="flex flex-wrap">
        {props.userType === "USER" && (
          <li
            className={props.progressItem === "Cart" ? "active" : ""}
            id="account"
          >
            <span class="icon_d">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.6668 23.9998C21.1868 23.9998 20.0002 25.1865 20.0002 26.6665C20.0002 27.3737 20.2811 28.052 20.7812 28.5521C21.2813 29.0522 21.9596 29.3332 22.6668 29.3332C23.3741 29.3332 24.0523 29.0522 24.5524 28.5521C25.0525 28.052 25.3335 27.3737 25.3335 26.6665C25.3335 25.9593 25.0525 25.281 24.5524 24.7809C24.0523 24.2808 23.3741 23.9998 22.6668 23.9998ZM1.3335 2.6665V5.33317H4.00016L8.80016 15.4532L6.98683 18.7198C6.78683 19.0932 6.66683 19.5332 6.66683 19.9998C6.66683 20.7071 6.94778 21.3854 7.44788 21.8855C7.94797 22.3856 8.62625 22.6665 9.3335 22.6665H25.3335V19.9998H9.8935C9.80509 19.9998 9.72031 19.9647 9.65779 19.9022C9.59528 19.8397 9.56016 19.7549 9.56016 19.6665C9.56016 19.5998 9.57349 19.5465 9.60016 19.5065L10.8002 17.3332H20.7335C21.7335 17.3332 22.6135 16.7732 23.0668 15.9598L27.8402 7.33317C27.9335 7.11984 28.0002 6.89317 28.0002 6.6665C28.0002 6.31288 27.8597 5.97374 27.6096 5.7237C27.3596 5.47365 27.0205 5.33317 26.6668 5.33317H6.94683L5.6935 2.6665M9.3335 23.9998C7.8535 23.9998 6.66683 25.1865 6.66683 26.6665C6.66683 27.3737 6.94778 28.052 7.44788 28.5521C7.94797 29.0522 8.62625 29.3332 9.3335 29.3332C10.0407 29.3332 10.719 29.0522 11.2191 28.5521C11.7192 28.052 12.0002 27.3737 12.0002 26.6665C12.0002 25.9593 11.7192 25.281 11.2191 24.7809C10.719 24.2808 10.0407 23.9998 9.3335 23.9998Z" fill="white"/>
              </svg>
            </span>
            <span>
                My Cart
            </span>
          </li>
        )}
          <li
            className={props.progressItem === "Billing" ? "active" : ""}
            id="personal"
          >
            <span class="icon_d">
              <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 28H20V23C19.9979 21.1441 19.2597 19.3649 17.9474 18.0526C16.6351 16.7403 14.8559 16.0021 13 16H7C5.14413 16.0021 3.36489 16.7403 2.05259 18.0526C0.740295 19.3649 0.00211736 21.1441 0 23V28ZM3 7C3 8.38447 3.41054 9.73785 4.17971 10.889C4.94888 12.0401 6.04213 12.9373 7.32122 13.4672C8.6003 13.997 10.0078 14.1356 11.3656 13.8655C12.7235 13.5954 13.9708 12.9287 14.9497 11.9497C15.9287 10.9708 16.5954 9.7235 16.8655 8.36563C17.1356 7.00776 16.997 5.6003 16.4672 4.32122C15.9373 3.04213 15.0401 1.94888 13.889 1.17971C12.7378 0.410543 11.3845 0 10 0C8.14348 0 6.36301 0.737498 5.05025 2.05025C3.7375 3.36301 3 5.14348 3 7Z" fill="white"/>
              </svg>
            </span>
            <span>
                Billing Address
            </span>
          </li>
          <li
            className={props.progressItem === "Payment" ? "active" : ""}
            id="payment"
          >
            <span class="icon_d">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.83317 6C5.7281 6 4.66829 6.43899 3.88689 7.22039C3.10549 8.00179 2.6665 9.0616 2.6665 10.1667V12.3333H29.3332V10.1667C29.3332 9.0616 28.8942 8.00179 28.1128 7.22039C27.3314 6.43899 26.2716 6 25.1665 6H6.83317ZM2.6665 21.8333V14H29.3332V21.8333C29.3332 22.9384 28.8942 23.9982 28.1128 24.7796C27.3314 25.561 26.2716 26 25.1665 26H6.83317C5.7281 26 4.66829 25.561 3.88689 24.7796C3.10549 23.9982 2.6665 22.9384 2.6665 21.8333ZM20.8332 19.6667C20.6122 19.6667 20.4002 19.7545 20.2439 19.9107C20.0876 20.067 19.9998 20.279 19.9998 20.5C19.9998 20.721 20.0876 20.933 20.2439 21.0893C20.4002 21.2455 20.6122 21.3333 20.8332 21.3333H24.4998C24.7208 21.3333 24.9328 21.2455 25.0891 21.0893C25.2454 20.933 25.3332 20.721 25.3332 20.5C25.3332 20.279 25.2454 20.067 25.0891 19.9107C24.9328 19.7545 24.7208 19.6667 24.4998 19.6667H20.8332Z" fill="white"/>
              </svg>
            </span>
            <span>
                Payment
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default CheckoutProgressBar;
