import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_ERROR,
  SET_IS_LOADING,
  UPDATE_USER_DETAILS,
  SET_PROFILE_UPDATE_STATUS,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { uploadUserDoc } from "./signUpApiCall";

const _uploadFile = (file, email) => {
  return file
    ? uploadUserDoc(file, email)
        .catch(() => Promise.resolve())
    : Promise.resolve()
}

export const updateCustomerDetails = async ({
  dispatch,
  customerDetails,
  config,
  history,
  flag,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": config.authToken },
    null
  );
  const apiUrl = flag
    ? `${envConfig.apiUrl}:${envConfig.apiPort}/user/updatepassword`
    : `${envConfig.apiUrl}:${envConfig.apiPort}/user/editaccountdetails`;
  await axios
    .post(apiUrl, customerDetails, apiRequestHeader)
    .then((apiResponse) => {
      if (apiResponse?.status === 200) {
        dispatch({
          type: SET_PROFILE_UPDATE_STATUS,
          payload: apiResponse.data,
        });
      }
      if (!flag) {
        _uploadFile(customerDetails.idFile, customerDetails.email)
          .then(() => axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/user/account/${customerDetails.email}`,
              apiRequestHeader
          ))
          .then((customerDetails) => {
            const shippingAddressDetails = {
              firstName: customerDetails?.data?.firstname ?? "",
              lastName: customerDetails?.data?.lastname ?? "",
              email: customerDetails?.data?.email ?? "",
              phoneNo: customerDetails?.data?.mobileno ?? "",
              postCode: customerDetails?.data?.address?.postcode ?? "",
              city: customerDetails?.data?.address?.city ?? "",
              addressLineOne: customerDetails?.data?.address?.addressLineOne ?? "",
              addressLineTwo: customerDetails?.data?.address?.addressLineTwo ?? "",
              instagramId: customerDetails?.data?.instaname ?? "",
              tradeOfBusiness: customerDetails?.data?.tradeofbuisness ?? "",
            };
            dispatch({
              type: UPDATE_USER_DETAILS,
              payload: customerDetails.data,
              shippingAddressDetails: shippingAddressDetails,
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
          });
      } else {
        dispatch({ type: SET_IS_LOADING, payload: false });
      }
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
};
