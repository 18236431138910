

import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import "./prescriber.css";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Header from "./Header";
import { prescriberApiCall } from "../../serviceCalls/prescriberApiCall";
// const Prescriber = (props) => {
//     const { applicationState, dispatch } = props;
function Prescriber() {
    const [monthYear, setMonthYear] = useState("");
    const [spin, setSpin] = useState(false);
    const [prescriberData, setPrescriberData] = useState([]);
    const [tableArray, setTableArray] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredData, setFilterData] = useState([])
    const [scriptTotal, setScriptTotal] = useState(0);
    const [psTotal, setPsTotal] = useState(0);
    const history = useHistory();
    let prescriberEmail = "";
    let token = "";
    let finalArray = []
    let filteredDara = []
    let scriptTotal1 = 0;
    let psTotal1 = 0;



    React.useEffect(() => {

        const myFunc = async () => {
            let userType = window.localStorage.getItem("userType");
            if (userType === "ADMIN" || userType === "PRESCRIBER") {
                // do nothing
            } else {
                history.push("/");
            }
            // api call
         
            let response = await prescriberApiCall()

            setPrescriberData(response.data);

            response.data.pharmaorders.map((row) => {
                // console.log("old format -->",row.orderdate)
                let date = new Date(row.orderdate);
                let finalDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                row.orderdate = finalDate
            })

            setTableArray(response.data.pharmaorders);
        }
        myFunc()

    }, []);


    const handleChange = () => {
        // set loading on
        setSpin(true);

        // calendar selected dates
        let selectedYear = monthYear.substring(0, 4);
        // console.log("selected year -->", selectedYear)
        let selectedMonth = monthYear.substring(5, 7);
        // console.log("selected month -->", selectedMonth)


        // filter data based on year
        let yearFilterDated = finalArray.map((row) => {
            let date = new Date(row.orderdate);
            let year = date.getFullYear()
            // console.log("year -->", year)
            if (year == selectedYear) {
                return row;
            }
            else {
                // console.log("not match ", year)
                return
            }
        })

        const results = yearFilterDated.filter(element => {
            return element !== undefined;
        });
        filteredDara = results;

        let scTotal = filteredDara
        let selected = parseInt(selectedMonth)

        // filter based on month
        let monthFilter = results.map((i) => {
            if (parseInt(selectedMonth) == parseInt(i.orderdate.substring(5, 7).replace("-", ""))) {
                return i;
            }
        })
        const results2 = monthFilter.filter(element => {
            return element !== undefined;
        });
        // console.log("month filter -->", results2)


        setFilterData(results2)
        setIsFiltered(true)

        scriptTotal1 = 0
        psTotal1 = 0

        // filtered data total 
        results2.map((i) => {
            scriptTotal1 += i.scriptvalue;
            psTotal1 += i.ps
        })


        if (results2.length == 0) {
            setScriptTotal(0)
            setPsTotal(0)
        }
        else {

            setScriptTotal(scriptTotal1)
            setPsTotal(psTotal1)
        }


        setSpin(false);
    };


    finalArray = [...tableArray].reverse()

    // without filter data 
    finalArray.map((i) => {
        scriptTotal1 += i.scriptvalue;
        psTotal1 += i.ps
    })



    // console.log("sc -->", scriptTotal1, "ps -->", psTotal1)



    return (
        // parent component
        <div className="parent">
            {/* flex with column direction */}
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                    <Header />
                </div>
                <div className="window">
                    <div className="topbox">Prescriber Profit Share</div>
                </div>
                {/* name ,sale year and month dropdown */}
                <div className="nametag">
                    {/* prescriber name */}
                    <div className="prename">
                        <span>Prescriber Name : </span> <span>{prescriberData.fullname || "No Name"}</span>
                    </div>
                    {/* sale year */}
                    <div className="saleyear" >
                        <span>Sales Year & Month : </span>
                    </div>
                    <div className="calender">
                        <input
                            type="month"
                            value={monthYear}
                            id="selectedDate"
                            name="selectedDate"
                            onChange={(e) => setMonthYear(e.target.value)}
                        />
                    </div>
                    <div className="submitButton">
                        {" "}
                        {spin ? (
                            <Spinner style={{ marginLeft: 55 }} animation="border" role="status">
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        ) : (
                            <button
                                style={{
                                    padding: "6px 14px 6px 14px",
                                    marginLeft: 50,
                                    border: "2px solid #595959",
                                    borderRadius: 5,
                                    boxShadow: "4px 4px 7px 3px #BFBFBF",
                                    backgroundColor: "#E9ADAC",
                                    color: "black"
                                }}
                                // rgba(0, 0, 0, 0.16)
                                type="button"
                                onClick={handleChange}
                            >
                                Filter
                            </button>
                        )}
                    </div>
                </div>
                {/* table div */}
                <div className="table">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Date</th>
                                <th>Practitioner</th>
                                <th>Script Value</th>
                                <th>Profit Share</th>
                            </tr>
                        </thead>
                        {(isFiltered) ? (<>
                            <tbody >
                                {prescriberData.pharmaorders && filteredData.map((row) => (
                                    <tr>
                                        <td>{row.id}</td>
                                        <td>{row.orderdate}</td>
                                        <td>{row.practioner}</td>
                                        <td>£ {Number(row.scriptvalue).toFixed(2)}</td>
                                        <td>£ {Number(row.ps).toFixed(2)}</td>
                                    </tr>
                                ))}

                                <tr className="total">
                                    <td></td>
                                    <td colSpan={2}>Total</td>
                                    <td>£ {scriptTotal || 0}</td>
                                    <td>£ {psTotal || 0}</td>
                                </tr>
                            </tbody>
                        </>) : (<>
                            <tbody >
                                {prescriberData.pharmaorders && finalArray.map((row) => (
                                    <tr>
                                        <td>{row.id}</td>
                                        <td>{row.orderdate}</td>
                                        <td>{row.practioner}</td>
                                        <td>£ {Number(row.scriptvalue).toFixed(2)}</td>
                                        <td>£ {Number(row.ps).toFixed(2)}</td>
                                    </tr>
                                ))}
                                <tr className="total">
                                    <td></td>
                                    <td colSpan={2}>Total</td>
                                    <td>£ {Number(scriptTotal1).toFixed(2) || 0}</td>
                                    <td>£ {Number(psTotal1).toFixed(2) || 0}</td>
                                </tr>
                            </tbody>
                        </>)}

                    </Table>
                </div>
            </div>
        </div>
    );
}
// };
export default Prescriber;