import React from 'react';
import ToggleButton from "react-toggle-button";
import { updateCPPDetails } from '../../../serviceCalls/updateCPPDetails';
import { toggleCppCall } from '../../../serviceCalls/toggleCppCall';
import { CPP_UPDATE } from '../../../constants/actionTypes';
import Alert from "@material-ui/lab/Alert";

const CPPList = (props) => {
	const { authToken, profile, dispatch, iscppenabled, adminEmail, cppList, updateCppMessage, cppStatus, isAdmin } = props;
	const [cppCode, setCppCode] = React.useState(cppList?.cppcode);
	const [profitPercent, setProfitPercent] = React.useState(cppList?.profitpercentshare);
	const [minProfit, setMinProfit] = React.useState(cppList?.minprofit);
	const [adminFee, setAdminFee] = React.useState(cppList?.adminfee);
	const [error, setError] = React.useState("");
	const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	React.useEffect(() => {
		setCppCode(cppList?.cppcode);
		setProfitPercent(cppList?.profitpercentshare);
		setAdminFee(cppList?.adminfee);
		setMinProfit(cppList?.minprofit);
	}, [cppList?.cppcode, cppList?.profitpercentshare, cppList?.adminfee, cppList?.minprofit]);

	const onCppChange = (value) => {
		setCppCode(value);
	};

	const onProfitChange = (value) => {
		setProfitPercent(value);
	};
	const onMinProfitChange = (value) => {
		setMinProfit(value);
	}

	const onAdminFeeChange = (value) => {
		setAdminFee(value);
	};

	const onClearCppMessage = () => {
		dispatch({
			type: CPP_UPDATE,
			payload: { message: "", status: "" }
		});
	}

	const updateCppDetails = () => {
		if (!cppCode || !profitPercent || !adminFee)
			setError("Please complete all required fields")
		else {
			const request = {
				cppuseremail: profile.email,
				cppcode: cppCode,
				profitpercentshare: profitPercent,
				adminfee: adminFee,
				approveremail: adminEmail,
				minprofit: minProfit
			}
			updateCPPDetails({ authToken, dispatch, cppUpdateRequest: request });
			setError("");
		}
	};

	return (
		<div className="cpp__page">
			<div className="container-fluid">
				<div className="cpp_inner_div">
					{isAdmin && <div className="toggle mrt-20 pdb-30" >
						<span>
							<ToggleButton
								value={iscppenabled}
								inactiveLabel={'NO'}
								activeLabel={'YES'}
								onToggle={() => {
									toggleCppCall({ authToken, profile, dispatch, toggleValue: !iscppenabled, adminEmail });
								}}
								thumbStyle={{ borderRadius: 2 }}
								trackStyle={{ borderRadius: 2 }}
							/>
						</span>
						<span className="float-left mrl-15">
							Enable EASI
						</span>
					</div >}
					{iscppenabled && <>
						<div style={{ background: '#fff', marginBottom: 20 }}>
							<h3 style={{ textAlign: "center", fontWeight: 'bold' }}>Details</h3>
							<div className="row form-card mt-3">
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
									<div className="form-floating mb-3">
										<input
											type="text"
											className="form-control"
											id="cppCpdeText"
											disabled={!isAdmin}
											placeholder="CPP Code"
											onChange={(e) => onCppChange(e.target.value)}
											value={cppCode}
										/>
										<label htmlFor="floatingInput">EASI Code</label>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
									<div className="form-floating">
										<select
											className="form-select"
											id="profitPercentage"
											disabled={!isAdmin}
											name="profitPercentage"
											onChange={(e) => onProfitChange(e.target.value)}
											value={profitPercent}
										>
											{
												array.map((value) => {
													return <option value={value}>{value}%</option>
												})
											}
										</select>
										<label htmlFor="trade_business">Profit Percentage</label>
									</div>
								</div>
								<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
									<div className="form-floating">
										<select
											className="form-select"
											id="minProfit"
											disabled={!isAdmin}
											name="minProfit"
											onChange={(e) => onMinProfitChange(e.target.value)}
											value={minProfit}
										>
											<option value={0}>£ 0.00</option>
											{
												array.map((value) => {
													return <option value={value}>£ {Number(value).toFixed(2)}</option>
												})
											}
										</select>
										<label htmlFor="trade_business">Min. Profit</label>
									</div>
								</div>
							</div>
							<div className="row form-card mt-3">
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
									<div className="form-floating mb-3">
										<span style={{ position: 'absolute', marginLeft: 1, marginTop: 27 }}>£</span>
										<input
											type="number"
											className="form-control"
											id="adminFeeCpp"
											style={{ paddingLeft: 10 }}
											disabled={!isAdmin}
											placeholder="Admin Fee"
											onChange={(e) => onAdminFeeChange(e.target.value)}
											onBlur={(e) => onAdminFeeChange(Number(e.target.value).toFixed(2))}
											value={adminFee}
										/>
										<label htmlFor="floatingInput">Admin Fee</label>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
									<div className="form-floating mb-3">
										<input
											type="text"
											disabled
											className="form-control"
											id="approverEmail"
											placeholder="Approver Email"
											value={cppList?.approveremail}
										/>
										<label htmlFor="floatingInput">Approver Email</label>
									</div>
								</div>
							</div>
							{isAdmin && <div className="text-center mt-4">
								{error ? (
									<div className="mrb-20 error">{error}</div>
								) : (
									<React.Fragment />
								)}
								{updateCppMessage && <Alert className="mb-4" severity={cppStatus} onClose={onClearCppMessage}>
									{updateCppMessage}
								</Alert>}
								<button type="text" className="btn btn-main" onClick={updateCppDetails}>
									Update
								</button>
							</div>}

						</div>
						<><h3 style={{ textAlign: "center", fontWeight: 'bold' }}>Order's Made</h3><div className="table__div OMTable">
								<table>
									<tbody>
										<tr style={{ height: 40, width: '100%', backgroundColor: '#7984a3', color: '#fff' }}>
										    <th id="amount" style={{ textAlign: "center" }}>Order Id</th>
											<th id="product-name" style={{ textAlign: "center" }}>Customer Name</th>
											<th id="amount" style={{ textAlign: "center" }}>Total Amount</th>
											<th id="amount" style={{ textAlign: "center" }}>EASI Profit Share</th>
										</tr>
										{cppList?.orders?.map((order) => {
											return (<tr>
												<td>{order.orderid}</td>
												<td>{order.customername}</td>
												<td>{'£' + Number(order.totalamount).toFixed(2)}</td>
												<td>{'£' + Number(order.esaiprofitshare).toFixed(2)}</td>
											</tr>)
										})}
									</tbody>
								</table>
							</div><div className="monthly_yearly_divs">
								<div class="content_div">
									<h3 style={{ textAlign: "center", fontWeight: 'bold' }}>Monthly Profit</h3>
									<div class="table__div MPTable">
										<table>
											<tbody>
												<tr style={{ height: 40, width: '100%', backgroundColor: '#7984a3', color: '#fff' }}>
													<th id="product-name" style={{ textAlign: "center" }}>Month</th>
													<th id="amount" style={{ textAlign: "center" }}>Amount</th>
												</tr>
												{cppList?.monthlyprofit?.map((month) => {
													return (
														<tr>
															<td>{month.monthname}</td>
															<td>{'£' + Number(month.amount).toFixed(2)}</td>
														</tr>)
												})}

											</tbody>
										</table>
									</div>
								</div>
								<div class="content_div">
									<h3 style={{ textAlign: "center", fontWeight: 'bold' }}>Yearly Profit</h3>
									<div class="table__div YPTable">
										<table>
											<tbody>
												<tr style={{ height: 40, width: '100%', backgroundColor: '#7984a3', color: '#fff' }}>
													<th id="product-name" style={{ textAlign: "center" }}>Year</th>
													<th id="amount" style={{ textAlign: "center" }}>Amount</th>
												</tr>
												{cppList?.yearlyprofit?.map((year) => {
													return (
														<tr>
															<td>{year.year}</td>
															<td>{'£' + Number(year.amount).toFixed(2)}</td>
														</tr>)
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div></>

					</>
					}
				</div>
			</div>
		</div>
	)
}
export default CPPList;