import React, { useState } from "react";

const StudentsModal = ({
    taProductRates2,
    pdList,
    setPdList,
    closeModal,
    noOfSTD,
    productArray,
    stdData,
    setStdData,
    setError,
    error1,
    emptyCred,
    validate,
    errorMessage2,
    errorMessage3,
}) => {
    const [pass, setPass] = useState(false);

    let {
        std1_trained,
        std1_noboxes,
        std2_trained,
        std2_noboxes,
        std3_trained,
        std3_noboxes,
        std4_trained,
        std4_noboxes,
        std5_trained,
        std5_noboxes,
        std6_trained,
        std6_noboxes,
        std7_trained,
        std7_noboxes,
        std8_trained,
        std8_noboxes,
        std9_trained,
        std9_noboxes,
        std10_trained,
        std10_noboxes,
    } = stdData;

    // std1_trained == "" || std1_noboxes == "" || std2_trained == "" || std2_noboxes == "" || std3_trained == "" || std3_noboxes == "" || std4_trained == "" || std4_noboxes == "" || std5_trained == "" || std5_noboxes == "" || std6_trained == "" || std6_noboxes == ""

    const checkAllValues = () => {
        if (noOfSTD == "1") {
            if (std1_trained == "" || std1_noboxes == 0) {
                setPass(false);
            } else {
                setPass(true);
                localStorage.setItem("modalState", "true");
                // selected product name
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                });

                closeModal(false);
            }
        } else if (noOfSTD == "2") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "3") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "4") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "5") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0 ||
                std5_trained == "" ||
                std5_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                    if (std5_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std5_name: prod,
                        });
                        localStorage.setItem("product5", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "6") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0 ||
                std5_trained == "" ||
                std5_noboxes == 0 ||
                std6_trained == "" ||
                std6_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                    if (std5_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std5_name: prod,
                        });
                        localStorage.setItem("product5", JSON.stringify(prod));
                    }
                    if (std6_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std6_name: prod,
                        });
                        localStorage.setItem("product6", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "7") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0 ||
                std5_trained == "" ||
                std5_noboxes == 0 ||
                std6_trained == "" ||
                std6_noboxes == 0 ||
                std7_trained == "" ||
                std7_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                    if (std5_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std5_name: prod,
                        });
                        localStorage.setItem("product5", JSON.stringify(prod));
                    }
                    if (std6_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std6_name: prod,
                        });
                        localStorage.setItem("product6", JSON.stringify(prod));
                    }
                    if (std7_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std6_name: prod,
                        });
                        localStorage.setItem("product7", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "8") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0 ||
                std5_trained == "" ||
                std5_noboxes == 0 ||
                std6_trained == "" ||
                std6_noboxes == 0 ||
                std7_trained == "" ||
                std7_noboxes == 0 ||
                std8_trained == "" ||
                std8_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                    if (std5_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std5_name: prod,
                        });
                        localStorage.setItem("product5", JSON.stringify(prod));
                    }
                    if (std6_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std6_name: prod,
                        });
                        localStorage.setItem("product6", JSON.stringify(prod));
                    }
                    if (std7_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std7_name: prod,
                        });
                        localStorage.setItem("product7", JSON.stringify(prod));
                    }
                    if (std8_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std8_name: prod,
                        });
                        localStorage.setItem("product8", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "9") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0 ||
                std5_trained == "" ||
                std5_noboxes == 0 ||
                std6_trained == "" ||
                std6_noboxes == 0 ||
                std7_trained == "" ||
                std7_noboxes == 0 ||
                std8_trained == "" ||
                std8_noboxes == 0 ||
                std9_trained == "" ||
                std9_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                    if (std5_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std5_name: prod,
                        });
                        localStorage.setItem("product5", JSON.stringify(prod));
                    }
                    if (std6_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std6_name: prod,
                        });
                        localStorage.setItem("product6", JSON.stringify(prod));
                    }
                    if (std7_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std7_name: prod,
                        });
                        localStorage.setItem("product7", JSON.stringify(prod));
                    }
                    if (std8_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std8_name: prod,
                        });
                        localStorage.setItem("product8", JSON.stringify(prod));
                    }
                    if (std9_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std9_name: prod,
                        });
                        localStorage.setItem("product9", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else if (noOfSTD == "10") {
            if (
                std1_trained == "" ||
                std1_noboxes == 0 ||
                std2_trained == "" ||
                std2_noboxes == 0 ||
                std3_trained == "" ||
                std3_noboxes == 0 ||
                std4_trained == "" ||
                std4_noboxes == 0 ||
                std5_trained == "" ||
                std5_noboxes == 0 ||
                std6_trained == "" ||
                std6_noboxes == 0 ||
                std7_trained == "" ||
                std7_noboxes == 0 ||
                std8_trained == "" ||
                std8_noboxes == 0 ||
                std9_trained == "" ||
                std9_noboxes == 0 ||
                std10_trained == "" ||
                std10_noboxes == 0
            ) {
                setPass(false);
            } else {
                localStorage.setItem("modalState", "true");
                setPass(true);
                taProductRates2.map((prod) => {
                    if (std1_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std1_name: prod,
                        });
                        localStorage.setItem("product1", JSON.stringify(prod));
                    }
                    if (std2_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std2_name: prod,
                        });
                        localStorage.setItem("product2", JSON.stringify(prod));
                    }
                    if (std3_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std3_name: prod,
                        });
                        localStorage.setItem("product3", JSON.stringify(prod));
                    }
                    if (std4_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std4_name: prod,
                        });
                        localStorage.setItem("product4", JSON.stringify(prod));
                    }
                    if (std5_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std5_name: prod,
                        });
                        localStorage.setItem("product5", JSON.stringify(prod));
                    }
                    if (std6_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std6_name: prod,
                        });
                        localStorage.setItem("product6", JSON.stringify(prod));
                    }
                    if (std7_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std7_name: prod,
                        });
                        localStorage.setItem("product7", JSON.stringify(prod));
                    }
                    if (std8_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std8_name: prod,
                        });
                        localStorage.setItem("product8", JSON.stringify(prod));
                    }
                    if (std9_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std9_name: prod,
                        });
                        localStorage.setItem("product9", JSON.stringify(prod));
                    }
                    if (std10_trained == prod.product.productid) {
                        setPdList({
                            ...pdList,
                            std10_name: prod,
                        });
                        localStorage.setItem("product10", JSON.stringify(prod));
                    }
                });
                closeModal(false);
            }
        } else {
            localStorage.setItem("modalState", "true");
            console.log("close modal");
            closeModal(false);
        }
    };

    return (
        <>
            <div className="modal enter-done">
                <div
                    className="modal-content"
                    style={{ overflow: "auto", maxHeight: "600px" }}
                >
                    <div className="modal-header">
                        <h4 className="modal-title">
                            Select Products And Quantity
                        </h4>
                    </div>
                    <div>
                        <label style={{ "float": "left", "width": "50%", "textAlign":
                        "left", "fontWeight": "bold" }}>Select Products</label>
                        <label style={{ "float": "right", "width": "50%", "textAlign":
                        "center", "fontWeight": "bold" }}>No.of boxes purchased weekly</label>
                            </div>
                    <div className="modal-body">
                        {/* ============================================================ */}
                        {/* main form body */}
                        {/* student 1 */}
                        {noOfSTD > 0 ? (
                            <>
                                <div className="form-floating pass-wrapper">
                                    <div style={{ width: "55%" }}>
                                        {/* trained part start */}
                                        <select
                                            className="form-select"
                                            id="productsTrainedOn"
                                            name="productsTrainedOn"
                                            value={std1_trained}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std1_trained:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    productsTrainedOnError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "productsTrainedOn"
                                                )
                                            }
                                        >
                                            <option value="">
                                                Student 1
                                            </option>
                                            {productArray?.map((prod) => (
                                                <option
                                                    key={
                                                        prod?.product?.productid
                                                    }
                                                    value={
                                                        prod?.product?.productid
                                                    }
                                                >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                            ))}
                                        </select>

                                        {/* trained part end */}
                                        {std1_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std1_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std1_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std1_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 2 */}
                        {noOfSTD > 1 ? (
                            <>
                                <div className="form-floating pass-wrapper">
                                    <div style={{ width: "55%" }}>
                                        {/* trained part start */}
                                        <select
                                            className="form-select"
                                            id="productsTrainedOn"
                                            name="productsTrainedOn"
                                            value={std2_trained}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std2_trained:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    productsTrainedOnError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "productsTrainedOn"
                                                )
                                            }
                                        >
                                            <option value="">
                                                Student 2
                                            </option>
                                            {productArray?.map((prod) => (
                                                <option
                                                    key={
                                                        prod?.product?.productid
                                                    }
                                                    value={
                                                        prod?.product?.productid
                                                    }
                                                >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                            ))}
                                        </select>

                                        {/* trained part end */}
                                        {std2_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>

                                <div style={{ "width": "50%", "marginLeft": "5px" }}>
                                    {/* box part start */}
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="noOfBoxes"
                                        placeholder="Number of boxes purchased per week"
                                        value={std2_noboxes}
                                        onChange={(e) => {
                                            setStdData({
                                                ...stdData,
                                                std2_noboxes: e.target.value,
                                            });
                                            setError({
                                                ...error1,
                                                noOfBoxesError: "",
                                            });
                                            emptyCred("");
                                        }}
                                        onBlur={(e) => validate(e.target.value, "noOfBoxes")}
                                        min={0}
                                    />

                                    {/* box part end */}
                                    {(std1_noboxes == "") ? (
                                        <span className="error">{errorMessage3}</span>
                                    ) : (
                                        <React.Fragment />
                                    )}
                                </div>
                            </div>
                        </>) : (<></>)}
                        {/* student 3 */}
                        {(noOfSTD > 2) ? (<>
                            <div className="form-floating pass-wrapper">
                                <div style={{ "width": "55%" }}>
                                    {/* trained part start */}
                                    <select
                                        className="form-select"
                                        id="productsTrainedOn"
                                        name="productsTrainedOn"
                                        value={std3_trained}
                                        onChange={(e) => {
                                            setStdData({
                                                ...stdData,
                                                std3_trained: e.target.value,
                                            });
                                            setError({
                                                ...error1,
                                                productsTrainedOnError: "",
                                            });
                                            emptyCred("");
                                        }}
                                    >
                                        {/* box part start */}
                                        <option value="">Student 3</option>
                                        {productArray?.map((prod) => (
                                            <option
                                                key={prod?.product?.productid}
                                                value={prod?.product?.productid}
                                            >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                        ))}
                                    </select>
                                    {/* trained part end */}
                                    {std3_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>
                                <div style={{ "width": "50%", "marginLeft": "5px" }}>
                                    {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std3_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std3_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std3_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                                </>) : (<></>)}
                        {/* student 4 */}
                        {(noOfSTD > 3) ? (<>
                            <div className="form-floating pass-wrapper">
                                <div style={{ "width": "55%" }}>
                                    {/* trained part start */}
                                    <select
                                        className="form-select"
                                        id="productsTrainedOn"
                                        name="productsTrainedOn"
                                        value={std4_trained}
                                        onChange={(e) => {
                                            setStdData({
                                                ...stdData,
                                                std4_trained: e.target.value,
                                            });
                                            setError({
                                                ...error1,
                                                productsTrainedOnError: "",
                                            });
                                            emptyCred("");
                                        }}
                                    >
                                        {/* box part start */}
                                        <option value="">Student 4</option>
                                        {productArray?.map((prod) => (
                                            <option
                                                key={prod?.product?.productid}
                                                value={prod?.product?.productid}
                                            >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                        ))}
                                    </select>
                                    {/* trained part end */}
                                    {std4_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>
                                <div style={{ "width": "50%", "marginLeft": "5px" }}>
                                    {/* box part start */}
                                      <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std4_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std4_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std4_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 5 */}
                        {(noOfSTD > 4) ? (<>
                            <div className="form-floating pass-wrapper">
                                <div style={{ "width": "55%" }}>
                                    {/* trained part start */}
                                    <select
                                        className="form-select"
                                        id="productsTrainedOn"
                                        name="productsTrainedOn"
                                        value={std5_trained}
                                        onChange={(e) => {
                                            setStdData({
                                                ...stdData,
                                                std5_trained: e.target.value,
                                            });
                                            setError({
                                                ...error1,
                                                productsTrainedOnError: "",
                                            });
                                            emptyCred("");
                                        }}
                                    >
                                        {/* box part start */}
                                        <option value="">Student 5</option>
                                        {productArray?.map((prod) => (
                                            <option
                                                key={prod?.product?.productid}
                                                value={prod?.product?.productid}
                                            >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                        ))}
                                    </select>
                                    {/* trained part end */}
                                    {std5_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>
                                <div style={{ "width": "50%", "marginLeft": "5px" }}>
                                    {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std5_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std5_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std5_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 6 */}
                        {(noOfSTD > 5) ? (<>
                            <div className="form-floating pass-wrapper">
                                <div style={{ "width": "55%" }}>
                                    {/* trained part start */}
                                    <select
                                        className="form-select"
                                        id="productsTrainedOn"
                                        name="productsTrainedOn"
                                        value={std6_trained}
                                        onChange={(e) => {
                                            setStdData({
                                                ...stdData,
                                                std6_trained: e.target.value,
                                            });
                                            setError({
                                                ...error1,
                                                productsTrainedOnError: "",
                                            });
                                            emptyCred("");
                                        }}
                                    >
                                        {/* box part start */}
                                        <option value="">Student 6</option>
                                        {productArray?.map((prod) => (
                                            <option
                                                key={prod?.product?.productid}
                                                value={prod?.product?.productid}
                                            >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                        ))}
                                    </select>
                                    {/* trained part end */}
                                    {std6_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>
                                <div style={{ "width": "50%", "marginLeft": "5px" }}>
                                    {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std6_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std6_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std6_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 7 */}
                        {noOfSTD > 6 ? (
                            <>
                                <div className="form-floating pass-wrapper">
                                    <div style={{ width: "55%" }}>
                                        {/* trained part start */}
                                        <select
                                            className="form-select"
                                            id="productsTrainedOn"
                                            name="productsTrainedOn"
                                            value={std7_trained}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std7_trained:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    productsTrainedOnError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "productsTrainedOn"
                                                )
                                            }
                                        >
                                            <option value="">
                                                Student 7
                                            </option>
                                            {productArray?.map((prod) => (
                                                <option
                                                    key={
                                                        prod?.product?.productid
                                                    }
                                                    value={
                                                        prod?.product?.productid
                                                    }
                                                >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                            ))}
                                        </select>

                                        {/* trained part end */}
                                        {std7_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>
                                <div style={{ "width": "50%", "marginLeft": "5px" }}>
                                    {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std7_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std7_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std7_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 8 */}
                        {noOfSTD > 7 ? (
                            <>
                                <div className="form-floating pass-wrapper">
                                    <div style={{ width: "55%" }}>
                                        {/* trained part start */}
                                        <select
                                            className="form-select"
                                            id="productsTrainedOn"
                                            name="productsTrainedOn"
                                            value={std8_trained}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std8_trained:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    productsTrainedOnError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "productsTrainedOn"
                                                )
                                            }
                                        >
                                            <option value="">
                                                Student 8
                                            </option>
                                            {productArray?.map((prod) => (
                                                <option
                                                    key={
                                                        prod?.product?.productid
                                                    }
                                                    value={
                                                        prod?.product?.productid
                                                    }
                                                >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                            ))}
                                        </select>

                                        {/* trained part end */}
                                        {std8_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std8_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std8_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std8_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 9 */}
                        {noOfSTD > 8 ? (
                            <>
                                <div className="form-floating pass-wrapper">
                                    <div style={{ width: "55%" }}>
                                        {/* trained part start */}
                                        <select
                                            className="form-select"
                                            id="productsTrainedOn"
                                            name="productsTrainedOn"
                                            value={std9_trained}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std9_trained:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    productsTrainedOnError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "productsTrainedOn"
                                                )
                                            }
                                        >
                                            <option value="">
                                                Student 9
                                            </option>
                                            {productArray?.map((prod) => (
                                                <option
                                                    key={
                                                        prod?.product?.productid
                                                    }
                                                    value={
                                                        prod?.product?.productid
                                                    }
                                                >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                            ))}
                                        </select>

                                        {/* trained part end */}
                                        {std9_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std9_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std9_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std9_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* student 10 */}
                        {noOfSTD > 9 ? (
                            <>
                                <div className="form-floating pass-wrapper">
                                    <div style={{ width: "55%" }}>
                                        {/* trained part start */}
                                        <select
                                            className="form-select"
                                            id="productsTrainedOn"
                                            name="productsTrainedOn"
                                            value={std10_trained}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std10_trained:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    productsTrainedOnError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "productsTrainedOn"
                                                )
                                            }
                                        >
                                            <option value="">
                                                Student 10
                                            </option>
                                            {productArray?.map((prod) => (
                                                <option
                                                    key={
                                                        prod?.product?.productid
                                                    }
                                                    value={
                                                        prod?.product?.productid
                                                    }
                                                >{`${prod?.product?.brand} ${prod?.product?.producttype} ${prod?.product?.productdesc}`}</option>
                                            ))}
                                        </select>

                                        {/* trained part end */}
                                        {std10_trained == "" ? (
                                            <span className="error">
                                                {errorMessage2}
                                            </span>
                                        ) : (
                                            <>
                                                <React.Fragment />
                                            </>
                                        )}
                                    </div>

                                    <div
                                        style={{
                                            width: "50%",
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {/* box part start */}
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="noOfBoxes"
                                            placeholder="Number of boxes purchased per week"
                                            value={std10_noboxes}
                                            onChange={(e) => {
                                                setStdData({
                                                    ...stdData,
                                                    std10_noboxes:
                                                        e.target.value,
                                                });
                                                setError({
                                                    ...error1,
                                                    noOfBoxesError: "",
                                                });
                                                emptyCred("");
                                            }}
                                            onBlur={(e) =>
                                                validate(
                                                    e.target.value,
                                                    "noOfBoxes"
                                                )
                                            }
                                            min={0}
                                        />

                                        {/* box part end */}
                                        {std10_noboxes == "" ? (
                                            <span className="error">
                                                {errorMessage3}
                                            </span>
                                        ) : (
                                            <React.Fragment />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {/* main form body end */}
                    </div>

                    {/* ========================================================================= */}
                    <div className="modal-footer text-align-center">
                        <button
                            onClick={checkAllValues}
                            className="btn floating-modal-btn btn-secondary"
                        >
                            Update
                        </button>
                        <button
                            className="btn btn-main"
                            onClick={() => closeModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentsModal;
