import * as React from "react";
import { useHistory } from "react-router";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { SET_CPP_DETAILS } from "../../../constants/actionTypes";
import Button from "react-bootstrap/Button";
import 'bootstrap/dist/css/bootstrap.css';
import StudentsModal from "./StudentsModal";
import { makeApiRequestHeader } from "../../../utils/makeApiRequestHeader";
import envConfig from "../../../envConfigurations";
import axios from "axios";

const CppPage1 = (props) => {
  const [productList2, setProductList2] = React.useState({
    std1_name: "",
    std2_name: "",
    std3_name: "",
    std4_name: "",
    std5_name: "",
    std6_name: "",
    std7_name: "",
    std8_name: "",
    std9_name: "",
    std10_name: "",

  })
  const [sellTotal, setSellTotal] = React.useState(0);
  const [costTotal, setCostTotal] = React.useState(0);
  const [studentData, setStudentData] = React.useState({
    std1_trained: "",
    std1_noboxes: 0,

    std2_trained: "",
    std2_noboxes: 0,

    std3_trained: "",
    std3_noboxes: 0,

    std4_trained: "",
    std4_noboxes: 0,

    std5_trained: "",
    std5_noboxes: 0,

    std6_trained: "",
    std6_noboxes: 0,

    std7_trained: "",
    std7_noboxes: 0,

    std8_trained: "",
    std8_noboxes: 0,

    std9_trained: "",
    std9_noboxes: 0,

    std10_trained: "",
    std10_noboxes: 0,

    // std11_trained: "",
    // std11_noboxes: 0,

    // std12_trained: "",
    // std12_noboxes: 0,

    // std13_trained: "",
    // std13_noboxes: 0,

    // std14_trained: "",
    // std14_noboxes: 0,

    // std15_trained: "",
    // std15_noboxes: 0,

    // std16_trained: "",
    // std16_noboxes: 0,

    // std17_trained: "",
    // std17_noboxes: 0,

    // std18_trained: "",
    // std18_noboxes: 0,

    // std19_trained: "",
    // std19_noboxes: 0,

    // std20_trained: "",
    // std20_noboxes: 0,

  })

  const [showModal, setShowModal] = React.useState(false);
  const { applicationState, dispatch } = props;
  const authToken = applicationState.config.authToken
  const history = useHistory();

  const { productList, taProductRates, cppFinalDetails } = applicationState;
  const [cppDetails, setCppDetails] = React.useState({
    trainerName: cppFinalDetails?.trainerName,
    trainingAcademy: cppFinalDetails?.trainingAcademy,
    noOfStudents: cppFinalDetails?.noOfStudents,
    productsTrainedOn: cppFinalDetails?.productsTrainedOn,
    noOfBoxes: cppFinalDetails?.noOfBoxes,
    minimumProfit: "5",
    overhead: cppFinalDetails?.overhead,
    profitShare: "4"
  });

  const [cppDetailsError, setCppDetailsError] = React.useState({
    trainerNameError: "",
    trainingAcademyError: "",
    noOfStudentsError: "",
    productsTrainedOnError: "",
    noOfBoxesError: "",
    minimumProfitError: "",
    overheadError: "",
    profitShareError: ""
  });
  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");
  const {
    trainerName,
    trainingAcademy,
    noOfStudents,
    productsTrainedOn,
    noOfBoxes,
    minimumProfit,
    overhead,
    profitShare
  } = cppDetails;

  const {
    trainerNameError,
    trainingAcademyError,
    noOfStudentsError,
    productsTrainedOnError,
    noOfBoxesError,
    minimumProfitError,
    overheadError,
    profitShareError
  } = cppDetailsError;

  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const validateValue = (value, fieldName) => {
    value === ""
      ? setCppDetailsError({
        ...cppDetailsError,
        [`${fieldName}Error`]: "Please enter a proper value",
      })
      : setCppDetailsError({
        ...cppDetailsError,
        [`${fieldName}Error`]: "",
      });
    if (fieldName === "minimumProfit" || fieldName === "overhead") {
      value === "0" &&
        setCppDetailsError({
          ...cppDetailsError,
          [`${fieldName}Error`]: "Value should be greater than 0",
        });
    }
  };


  const validateSubmit = (e) => {
    let pass = localStorage.getItem("modalState")
    e.preventDefault();
    if (
      trainerNameError ||
      trainingAcademyError ||
      noOfStudentsError ||
      // productsTrainedOnError ||
      // noOfBoxesError ||
      minimumProfitError ||
      overheadError
    ) {
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try? 1"
      );
    } else if (
      trainerName === "" ||
      trainingAcademy === "" ||
      noOfStudents === "" ||
      // productsTrainedOn === "" ||
      // noOfBoxes === "" ||
      minimumProfit === "" ||
      overhead === ""
    ) {
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try? 2"
      );
    } else if (pass == "false") {
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try? 3"
      );
    }

    else {
      localStorage.setItem("modalState", "false")

      const totalBoxesPurchasedWeekly = Number(studentData.std1_noboxes) + Number(studentData.std2_noboxes) + Number(studentData.std3_noboxes) +
        Number(studentData.std4_noboxes) + Number(studentData.std5_noboxes) + Number(studentData.std6_noboxes) + Number(studentData.std7_noboxes) + Number(studentData.std8_noboxes) + Number(studentData.std9_noboxes) + Number(studentData.std10_noboxes);

      const totalBoxesPurchasedMonthly = Math.ceil((4.3 * Number(studentData.std1_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std2_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std3_noboxes)).toFixed(2)) +
      Math.ceil((4.3 * Number(studentData.std4_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std5_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std6_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std7_noboxes)).toFixed(2)) + 
      Math.ceil((4.3 * Number(studentData.std8_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std9_noboxes)).toFixed(2)) + Math.ceil((4.3 * Number(studentData.std10_noboxes)).toFixed(2));

      // ======================== filter selected producrs ====================
      const selectedProduct = taProductRates.filter(
        (prod) => prod?.product?.productid === studentData.std1_trained ||
          prod?.product?.productid === studentData.std2_trained ||
          prod?.product?.productid === studentData.std3_trained ||
          prod?.product?.productid === studentData.std4_trained ||
          prod?.product?.productid === studentData.std5_trained ||
          prod?.product?.productid === studentData.std6_trained ||
          prod?.product?.productid === studentData.std7_trained ||
          prod?.product?.productid === studentData.std8_trained ||
          prod?.product?.productid === studentData.std9_trained ||
          prod?.product?.productid === studentData.std10_trained
      );
      let studentDataArr = [];
      for (let key in studentData) {

        if (key === 'std1_trained' && (studentData['std1_trained'] != 0 || studentData['std1_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std1_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std1_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std2_trained' && (studentData['std2_trained'] != 0 || studentData['std2_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std2_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std2_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std3_trained' && (studentData['std3_trained'] != 0 || studentData['std3_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std3_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std3_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std4_trained' && (studentData['std4_trained'] != 0 || studentData['std4_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std4_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std4_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std5_trained' && (studentData['std5_trained'] != 0 || studentData['std5_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std5_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std5_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std6_trained' && (studentData['std6_trained'] != 0 || studentData['std6_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std6_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std6_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std7_trained' && (studentData['std7_trained'] != 0 || studentData['std7_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std7_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std7_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std8_trained' && (studentData['std8_trained'] != 0 || studentData['std8_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std8_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std8_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std9_trained' && (studentData['std9_trained'] != 0 || studentData['std9_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std9_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std9_noboxes']);
          studentDataArr.push(studentDataObj);
        }
        if (key === 'std10_trained' && (studentData['std10_trained'] != 0 || studentData['std10_trained'] != '')) {
          let studentDataObj = {};
          studentDataObj['productid'] = studentData['std10_trained'];
          studentDataObj['noofboxes'] = Number(studentData['std10_noboxes']);
          studentDataArr.push(studentDataObj);
        }
      }


      let purchaseCount2 = totalBoxesPurchasedWeekly;
      let overhead2 = cppDetails.overhead;
      let minimumProfit2 = cppDetails.minimumProfit;

      const effectiveCost1 =
        getSellingPrice(
          selectedProduct,
          overhead2,
          minimumProfit2
        ) - getCostPrice(selectedProduct);
      // console.log("effective cost " + effectiveCost1);
      const effectiveCost2 = Number(effectiveCost1 * totalBoxesPurchasedMonthly)
      // console.log("effective cost 2 " + effectiveCost2);

      // const totalTAProfitShare =
      //   Number(Number(effectiveCost2) *
      //     (Number(cppDetails.profitShare) / 100));
      // console.log("total ta profit share cost " + totalTAProfitShare);
      // for (let month = 1; month <= 36; month++) {
      //   total = total + totalTAProfitShare;
      //   if (month === 12) {
      //     year1 = Number(total - totalTAProfitShare);
      //   } else if (month === 24) {
      //     year2 = Number(total - totalTAProfitShare);
      //   } else if (month === 36) {
      //     year3 = Number(total - totalTAProfitShare);
      //   }
      // }
      let totalTAProfitShare;
      let year1;
      let year2;
      let year3;

      let studentDataObjWithOtherValue = {};
      studentDataObjWithOtherValue = {
        studentDataArr,
        TAProfitShare: Number(totalTAProfitShare),
        year1: year1,
        year2: year2,
        year3: year3,
        profitSharePercentage: Number(cppDetails.profitShare),
        overhead: Number(cppDetails.overhead),
        minProfit: Number(cppDetails.minimumProfit)
      }



      const callStudentOdjApi = async (studentDataObjWithOtherValue) => {
        const apiRequestHeader = makeApiRequestHeader(
          "POST",
          { "x-auth-token": authToken }
        );
        const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/product/ESAIDemoCalculations`;
        await axios
          .post(apiUrl, studentDataObjWithOtherValue, apiRequestHeader)
          .then((apiResponse) => {
            // console.log('apiResponse.data.taprofitShare :', apiResponse.data);
            totalTAProfitShare = apiResponse.data.taprofitShare
            year1 = apiResponse.data.year1
            year2 = apiResponse.data.year2
            year3 = apiResponse.data.year3
            const cppFinalDetails = {
              studentData,
              selectedProduct,
              totalBoxesPurchasedWeekly,
              totalBoxesPurchasedMonthly,
              effectiveCost2,
              totalTAProfitShare,
              taProductRates,
              trainerName,
              trainingAcademy,
              noOfStudents,
              productsTrainedOn,
              noOfBoxes,
              minimumProfit,
              overhead,
              year1,
              year2,
              year3,
              profitShare,
              taProductRates
            };

            // console.log('cppFinalDetails :', cppFinalDetails);

            dispatch({ type: SET_CPP_DETAILS, cppFinalDetails });
            history.push("/cppPage2");
          })
          .catch(() => {

          });
      }

      callStudentOdjApi(studentDataObjWithOtherValue);



    }
  };

  const getSellingPrice = (selectedProduct, overhead2, minimumProfit2) => {
    let total = 0;
    for (let i = 0; i < selectedProduct.length; i++) {
      let purchaseCount2 = Number(studentData[`std${i + 1}_noboxes`]); //
      if (purchaseCount2 <= 20) {
        total = total + ((Number(selectedProduct[i]?.boxRate1To20))) - overhead2 - minimumProfit2;
      }
      else if (purchaseCount2 > 20 && purchaseCount2 <= 50) {
        total = total + ((Number(selectedProduct[i]?.boxRate21To50))) - overhead2 - minimumProfit2;
      } else {
        total = total + ((Number(selectedProduct[i]?.boxRateMoreThan51))) - overhead2 - minimumProfit2;
      }
    }
    setSellTotal(total);
    console.log("total selling price - " + total);
    return total
  }

  const getCostPrice = (selectedProduct) => {
    let total = 0;
    for (let i = 0; i < selectedProduct.length; i++) {
      total = total + (Number(selectedProduct[i]?.cost))
    }
    setCostTotal(total);
    console.log("total cost - " + total);
    return total
  };
  React.useEffect(() => {
    let modalState = localStorage.getItem("modalState")
    if (modalState == "true") {
      setShowModal(false)
    }
    localStorage.setItem("modalState", "false")
  }, [studentData])

  return (
    <div className="admin" id="cpp">
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div>
        <div className="row">
          {/* image part */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
            <div className="cpp-image-wrapper">
              <img
                src="./Practitioner image 2.jpg"
                alt="cpp background image"
              />
            </div>
            <div className="cpp-text-overlap text-white">
              <div className="cpp-image-title">
                BLACK'S AESTHETICS
                <p>EASI (Earn and Save Incentives)</p>
                <p> SCHEME FOR ACADEMICS</p>
              </div>
              <div className="cpp-image-subtitle mt-5">
                SAVE ON AESTHETIC PRODUCTS AND EARN AS EACH OF YOUR STUDENTS
                PLACE THEIR ORDERS WITH BLACK'S AESTHETICS
              </div>
            </div>
          </div>
          {/* image part end */}
          {/* form part start */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="container-fluid">
              <div role="main">
                <form method="post">
                  {/* name part error */}
                  {trainerNameError ? (
                    <span className="error">{trainerNameError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                  <div className="form-floating mb-3">
                    {/* name part */}
                    <input
                      type="text"
                      className="form-control"
                      id="trainerName"
                      placeholder="Name"
                      value={trainerName}
                      onChange={(e) => {
                        setCppDetails({
                          ...cppDetails,
                          trainerName: e.target.value,
                        });
                        setCppDetailsError({
                          ...cppDetailsError,
                          trainerNameError: "",
                        });
                        setEmptyCredentialsError("");
                      }}
                      onBlur={(e) =>
                        validateValue(e.target.value, "trainerName")
                      }
                    />
                    <label htmlFor="trainerName">Name</label>
                    {/* name part end */}
                  </div>
                  {/* academy error */}
                  {trainingAcademyError ? (
                    <span className="error">{trainingAcademyError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                  <div className="form-floating pass-wrapper">
                    {/* academy name part */}
                    <input
                      type="text"
                      className="form-control"
                      id="trainingAcademy"
                      placeholder="Training Academy Name"
                      value={trainingAcademy}
                      onChange={(e) => {
                        setCppDetails({
                          ...cppDetails,
                          trainingAcademy: e.target.value,
                        });
                        setCppDetailsError({
                          ...cppDetailsError,
                          trainingAcademyError: "",
                        });
                        setEmptyCredentialsError("");
                      }}
                      onBlur={(e) =>
                        validateValue(e.target.value, "trainingAcademy")
                      }
                    />
                    <label htmlFor="trainingAcademy">
                      Training Academy Name
                    </label>
                  </div>
                  {/* acedemy part end */}

                  {/* no of student error part */}
                  {noOfStudentsError ? (
                    <span className="error">{noOfStudentsError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                  <div className="form-floating pass-wrapper">

                    <select
                      className="form-select"
                      id="noOfStudents"
                      name="noOfStudents"
                      value={noOfStudents}
                      onChange={(e) => {
                        setCppDetails({
                          ...cppDetails,
                          noOfStudents: e.target.value,
                        });
                        setCppDetailsError({
                          ...cppDetailsError,
                          noOfStudentsError: "",
                        });
                        setEmptyCredentialsError("");
                      }}
                      onBlur={(e) =>
                        validateValue(e.target.value, "noOfStudents")
                      }
                    >
                      <option value="">Please select no of student</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      {/* <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option> */}

                    </select>
                    <label htmlFor="noOfStudents">
                      Number of students trained per month
                    </label>
                    {/* no of student end */}
                  </div>

                  Please select product trained on and no. of boxes per week
                  {/* <br /> */}
                  {/* ================================================================ */}

                  <Button variant="dark" onClick={() => { setShowModal(true) }} style={{ "backgroundColor": "blue" }}>Select  Options</Button>
                  {/* =====================  making it dynamic ================================= */}
                  {/* trainded on error */}


                  {/* box part error */}
                  {/* ======================================================================= */}

                  {minimumProfitError ? (
                    <span className="error">{minimumProfitError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                  <div className="form-floating pass-wrapper">
                    <select
                      className="form-select"
                      id="minimumProfit"
                      name="minimumProfit"
                      defaultValue={"5"}
                      onChange={(e) => {
                        setCppDetails({
                          ...cppDetails,
                          minimumProfit: e.target.value,
                        });
                        setCppDetailsError({
                          ...cppDetailsError,
                          minimumProfitError: "",
                        });
                        setEmptyCredentialsError("");
                      }}
                      onBlur={(e) =>
                        validateValue(e.target.value, "minimumProfit")
                      }
                      value={minimumProfit}
                    >
                      {
                        array.map((value) => {
                          return <option value={value}>£ {Number(value).toFixed(2)}</option>
                        })
                      }
                    </select>
                    <label htmlFor="minimumProfit">Minimum profit</label>
                  </div>
                  {overheadError ? (
                    <span className="error">{overheadError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                  <div className="form-floating pass-wrapper">
                    <select
                      className="form-select"
                      id="overhead"
                      name="overhead"
                      onChange={(e) => {
                        setCppDetails({
                          ...cppDetails,
                          overhead: e.target.value,
                        });
                        setCppDetailsError({
                          ...cppDetailsError,
                          overheadError: "",
                        });
                        setEmptyCredentialsError("");
                      }}
                      value={overhead}
                    >
                      {
                        array.map((value) => {
                          return <option value={value}>£ {Number(value).toFixed(2)}</option>
                        })
                      }
                    </select>
                    <label htmlFor="overhead">Admin fee</label>
                  </div>
                  <div className="form-floating pass-wrapper">
                    <select
                      className="form-select"
                      id="ProfitShare"
                      name="ProfitShare"
                      defaultValue={"4"}
                      onChange={(e) => {
                        setCppDetails({
                          ...cppDetails,
                          profitShare: e.target.value,
                        });
                        setCppDetailsError({
                          ...cppDetailsError,
                          profitShareError: "",
                        });
                        setEmptyCredentialsError("");
                      }}
                      value={profitShare}
                    >
                      {
                        array.map((value) => {
                          return <option value={value}>{value}%</option>
                        })
                      }
                    </select>
                    <label htmlFor="ProfitShare">Profit Share</label>
                  </div>
                  <div className="form mt-4">
                    {emptyCredentialsError ? (
                      <div className="mrb-20 error">
                        {emptyCredentialsError}
                      </div>
                    ) : (
                      <React.Fragment />
                    )}
                    <button
                      className="btn btn-lg btn-main"
                      type="submit"
                      onClick={(e) => validateSubmit(e)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {/* ===================== modal ================ */}
                {(showModal) ?
                  (<>
                    <StudentsModal
                      closeModal={setShowModal}
                      stdData={studentData}
                      setStdData={setStudentData}
                      noOfSTD={noOfStudents}
                      productArray={taProductRates}
                      setError={setCppDetailsError}
                      error1={cppDetailsError}
                      emptyCred={setEmptyCredentialsError}
                      validate={validateValue}
                      errorMessage2={productsTrainedOnError}
                      errorMessage3={noOfBoxesError}
                      pdList={productList2}
                      setPdList={setProductList2}
                      taProductRates2={taProductRates}
                    />
                  </>) : <></>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CppPage1;
