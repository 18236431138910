const ApprovalPending = () => {
  return (
    <div className="page-center text-center page-center col">
      <p>Your Account is waiting for approval please standby</p>
      <button
        className="btn btn-lg btn-main"
        onClick={() => window.location.reload()}
      >
        Return To Login
      </button>
    </div>
  )
}

export default ApprovalPending;
