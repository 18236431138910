import React from "react";
import { updateMatchedPrice } from "../../../serviceCalls/updateMatchedPrice";
import CRMPopupModal from "../CRMTab/CRMPopupModal";

const BeSpokenSection = (props) => {
    const { productList, authToken, dispatch, profile } = props;
    const keyValueList = profile?.priceKeyValue;
    const [productKeyValue, setProductKeyValue] = React.useState(keyValueList ?? [])
    const [productItem, setProductItem] = React.useState(false);
    const [matchPrice, setMatchPrice] = React.useState("");
    const [ogPrice, setOgPrice] = React.useState("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    React.useEffect(() => {
        if (keyValueList && keyValueList.length > 0)
            setProductKeyValue(keyValueList)
    }, [keyValueList]);

    const saveInput = (e, item) => {
        if (e.target.value) {
            const product = productKeyValue.map((prod) => {
                if (item.productId === prod.productId)
                    prod.matchedPrice = e.target.value
                return prod;
            })
            setProductKeyValue(product);
        }
    };

    const addNewItem = () => {
        setProductItem(true);
    };

    const updatePrice = (e) => {
        if (e.target.value)
            setMatchPrice(e.target.value);
        else
            setMatchPrice("");
    }

    const saveProduct = () => {
        const value = document.getElementById("all-products")?.value;
        if (value && matchPrice) {
            const product = JSON.parse(value);
            productKeyValue.push({
                productId: product.productid,
                productName: product.brand + " " + product.producttype + " " + product.productdesc,
                matchedPrice: matchPrice,
                productVat: product.vat,
                actualPrice: product.salepriceperunit
            })
        }
        updateMatchedPrice({
            authToken,
            dispatch,
            email: profile?.email,
            priceKeyValueList: productKeyValue
        })
        setProductItem(false);
    }

    const updateOGPrice = (e) => {
        if (e.target.type.includes('select') && e.target.value) {
            const product = JSON.parse(e.target.value)
            setOgPrice(product.salepriceperunit)
        }
    };

    const removeItemFromCart = (e, item) => {
        setProductKeyValue(productKeyValue.filter(product => product.productId != item.productId));
        closePopup(true)
    };

    const closePopup = (value) => {
        setDeleteModal(value);
    }

    return (
        <div className="be-spoken container-fluid">
            <div className="bespoken-container">
                <table id="myTable" className="bespoken-table">
                    <tr style={{ height: 40, width: '100%', background: 'linear-gradient(90deg, #3141A1 0%, rgba(49, 65, 161, 0.90) 26%, rgba(49, 65, 161, 0.70) 50%, rgba(227, 173, 171, 0.70) 76%, #E3ADAB 100%)', color: '#fff' }}>
                        <th id="product-list" style={{ width: '60%', textAlign: "center" }}>Product List</th>
                        <th id="og-price" style={{ width: '15%', textAlign: "center" }}>OG Price</th>
                        <th id="match-price" style={{ width: '15%', textAlign: "center" }}>Match Price</th>
                        <th id="delete" style={{ width: '10%', textAlign: "center" }}>Delete</th>
                    </tr>
                    {productKeyValue?.map((item, sIndex) => {
                        return (
                            <tr className={`table-${sIndex % 2 ? "even" : "odd"}`}>
                                <td headers="product-list" style={{ padding: 3 }}>{`${item.productName}`}</td>
                                <td headers="og-price" style={{ textAlign: "center" }}>{item.actualPrice}</td>
                                <td headers="match-price" className="match-price"><input
                                    type="text"
                                    value={item.matchedPrice ? item.matchedPrice : ""}
                                    onChange={(e) => saveInput(e, item)} /></td>
                                <td headers="delete" style={{ textAlign: "center" }}><span
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                        removeItemFromCart(e, item)
                                    }
                                >
                                    <i className="fa fa-trash icon-red"></i>
                                </span></td>
                            </tr>
                        )
                    })}
                    {productItem ? (<tr onChange={(e) => updateOGPrice(e)}>
                        <td headers="product-list">
                            <select name="all-products" id="all-products" className="all-products">
                                <option value="">Select Product</option>
                                {productList.map((product) => {
                                    return (
                                        <option value={JSON.stringify(product)}>{`${product.brand} ${product.producttype} ${product.productdesc}`}</option>)
                                })}
                            </select>
                        </td>
                        <td headers="og-price" style={{ textAlign: "center" }}>{ogPrice}</td>
                        <td headers="match-price" className="match-price" style={{ textAlign: "center" }}><input
                            type="number"
                            value={matchPrice ? matchPrice : ""}
                            onChange={(e) => updatePrice(e)} /></td>
                    </tr>) : null}
                </table>

                <button onClick={addNewItem} className="btn btn-main"> Add Item </button>
                <button onClick={saveProduct} className="btn btn-main"> Update Products </button>
            </div>
            <CRMPopupModal
                title="Delete Product"
                onClose={() => closePopup(false)}
                show={deleteModal}>
                <p>
                    <strong>Please update changes</strong>
                </p>

            </CRMPopupModal>
        </div>
    );
};
export default BeSpokenSection;
