import * as React from "react";

const CustomerDetailsTab = ({ orderInfo, showEmailPopUp, disableButtons }) => {

  return (<>
    <div className="row pt-5 form-card p-3">
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label><strong>Customer Name -</strong></label> {orderInfo?.customerName || "N/A"}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label><strong>Customer Mobile -</strong></label> {orderInfo?.customerPhone || "N/A"}
      </div>
    </div>
    <div className="row form-card p-3">
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label><strong>Customer Email -</strong></label> {orderInfo?.customerEmail || "N/A"}
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label><strong>Delivery Address -</strong></label> {orderInfo?.customerAddress || "N/A"} Postal Code - {orderInfo?.postalcode || "N/A"}
      </div>
    </div>
  </>)
}
export default CustomerDetailsTab