import React from "react";

import { Spinner } from "react-bootstrap";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import NavigationBar from "../../common/NavigationBar";

import Profiles from "./UserProfile";
import Stocks from "./Stock";

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                    <Typography>{children}</Typography>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    activeTab: {
        backgroundColor: '#F1EDFF',
    },
}));

const StockOrder = (props) => {
    const { applicationState, dispatch } = props;
    const { isLoading } = applicationState;
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <div id="adminproductlist" className="admin">
            {isLoading && (
                <div className="d-flex justify-content-center loader">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <div>
                <AdminHeaderMenu dispatch={dispatch} />
            </div>
            <div className="container-fluid">
                <div>
                    {" "}
                    <NavigationBar />
                </div>
                <div className={classes.root}>
                    <Tabs value={value} classes={{ indicator: classes.activeTab }} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Profiles" {...a11yProps(0)} style={value === 0 ? { backgroundColor: '#F1EDFF', color: '#495057' } : {}} />
                        <Tab label="Order Stocks" {...a11yProps(1)} style={value === 1 ? { backgroundColor: '#F1EDFF', color: '#495057' } : {}} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Profiles dispatch={dispatch} applicationState={applicationState}  userDetails={applicationState.userDetails} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Stocks productList={applicationState.productList} dispatch={dispatch} applicationState={applicationState} stockOrders={applicationState.stockOrders} />
                    </TabPanel>

                </div>

            </div>
        </div>
    )
}


export default StockOrder;
