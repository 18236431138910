import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";
import HeaderMenu from "../../common/HeaderMenu";
import {ProductsPurchasedColumnForMyOrders} from './ProductsPurchasedColumnForMyOrders';

const MyOrders = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const { cartDetails } = applicationState;
  const [tempCart, setTempCart] = React.useState(cartDetails);
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const getRowHeight = () => (applicationState.mobileView ? 300 : 100);

  const frameWorkComponentChange = ({ api }) => {

  };

  const rowData = () =>
    applicationState?.orderDetails ? applicationState.orderDetails : [];

  const columnDefs = () => [
    { field: "orderid", headerName: "Order Id" },
    { field: "orderpaymentmethod", headerName: "Paid with" },
    { field: "orderDate", headerName: "Order placed on" },
    { field: "productsPurchased", headerName: "Products purchased", cellRenderer: 'productsPurchasedColumn',
      cellStyle: {'overflow-x': 'auto', 'white-space': 'nowrap','overflow-y': 'auto'} },
    { field: "orderamount", headerName: "Total Amount", cellRenderer: "orderamount" },
  ];

  const orderAmount = (params) => {
    return (
      <div className="orderAmount">{`£${Number(params?.data?.orderamount).toFixed(2) || "N/A"}`}</div>
    )
  };

  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

const getRowStyle = params => {
  if (params.node.rowIndex % 2 === 0) {
      return { background: '#e3adab' };
  }
};

const setAutoHeight = () => {
  gridApi.setDomLayout('autoHeight');
  document.querySelector('#myGrid').style.height = '';
};

  return (
    <div id="myorders">
      <div>
        <HeaderMenu dispatch={dispatch} cartCount={tempCart.length} />
      </div>
      <div className="template_myOrder">
        <div className="container">
          <div
            id="myGrid"
            className="ag-theme-alpine"
            style={{
              height: "calc(100vh - 250px)",
              width: '100%',
            }}
          >
            <AgGridReact
              getRowStyle={getRowStyle}
              getRowHeight={getRowHeight}
              rowData={rowData()}
              columnDefs={columnDefs()}

              defaultColDef={defaultColDef}
              onGridReady={onGridReady}


              frameworkComponents={{
                productsPurchasedColumn: ProductsPurchasedColumnForMyOrders,
                orderamount: orderAmount
              }}
              paginationAutoPageSize={true}
                pagination={true}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
