import * as React from "react";
import { CSSTransition } from "react-transition-group";
import FileViewer from 'react-file-viewer';

const PrivacyTermsDetailsModal = (props) => {
    const { show, title, onClose } = props;
    return (
        <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
            <div className="modal pdfviewer">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                    </div>
                    <div className="modal-body pdf-modal-body">
                        <FileViewer
                            fileType={'pdf'}
                            filePath={title.includes('Privacy') ? 'PrivacyPolicy.pdf' : 'TermsAndConditions.pdf'}
                            disableVisibilityCheck
                            />
                    </div>
                    <div className="modal-footer text-align-center">
                        <button
                            onClick={() => onClose(title)}
                            className="btn floating-modal-btn btn-secondary"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default PrivacyTermsDetailsModal;