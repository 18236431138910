import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    ADD_COUPON,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { getCouponApiCall } from "./getCouponApiCall"

export const addCouponApiCall = async ({ dispatch, requestBodyForAdd, config }) => {
    // console.log("dispatch, requestBodyForAdd, config ", requestBodyForAdd);
    // return
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({ 
        type: NO_DATA_FOUND, 
        payload: { error: ""}
    });
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { 
            "x-auth-token": config && config.authToken,
            'Content-Type': 'application/json',
            'Cookie': 'JSESSIONID=0219AB5B01F985FD5218404C0C1CBE0F'
        },
        null
    );
    await axios
        .post(`${envConfig.apiUrl}:${envConfig.apiPort}/order/couponlines/add`,
        requestBodyForAdd,apiRequestHeader)
        .then((apiResponse) => {
            // console.log(apiResponse)
            dispatch({
                type: ADD_COUPON,
                payload:apiResponse.data,
                message:"Coupon Added"
            });
            getCouponApiCall({ dispatch: dispatch, config: config })
            // alert(apiResponse.data)
            dispatch({ type: SET_IS_LOADING, payload: false });
        }).catch((error) => {
            console.log('error ', error);
            dispatch({ 
                type: NO_DATA_FOUND, 
                payload: { error: error.response.data ?? "No Coupon found" }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
}