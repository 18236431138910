import axios from "axios";
import { SET_USER_VERIFY_STATE } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";

export const getPhonenumberOtp = async ({ email, phonenumber }) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/notification/otp/smsotp`;
    const response = await axios.post(apiUrl, { phonenumber, email }, apiRequestHeader);
    return response;
  } catch(e) {
    return logError(e, 'getPhonenumberOtp');
  }
}

export const verifyPhonenumber = async ({ dispatch, phonenumber, email, otp }) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/notification/otp/validatesmsotp`;
    const response = await axios.post(apiUrl, { phonenumber, email, otp }, apiRequestHeader);
    dispatch({
      type: SET_USER_VERIFY_STATE,
      payload: {
        smsverified: true
      }
    });
    return response;
  } catch(e) {
    return logError(e, 'verifyPhonenumber');
  }
}