import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CPPList from "../CppFutureWork/Cpp";
import BeSpokenSection from "./BeSpokenSection";
import TraderAccountSection from "./TradeAccountSection";
import PPS from "./PPS";

const CustomerProfileDiscountSection = ({ productList, authToken, profile, dispatch, adminEmail, cppList, updateCppMessage, cppStatus, isAdmin, isPharma, tacredituser, updateSendCreditFormMessage, approvedCreditLimitMsg, savedTACreditMsg, taForm}) => {
    const admin = isAdmin && profile.tradeofbuisness === "Training Academy";
    const user = !isAdmin && profile.iscppenabled && profile.tradeofbuisness === "Training Academy";
    const isTAUser = !isAdmin && profile.istradeaccountenabled
    const isPPS = isPharma || false;
    const userRole = profile.roles || "not found";
    let finalUserRole = userRole.role || "not found";

    return (
        <>
            <Tabs style={{ padding: 20, borderTop: '1px solid #dedede' }}>
                <TabList>
                    {(admin || user) && <Tab>EASI Live</Tab>}
                    {isAdmin && <Tab>Bespoke Price Match</Tab>}
                    {(isAdmin || isTAUser) && <Tab>Trade Account</Tab>}
                    {(finalUserRole === 'PRESCRIBER') &&<Tab>PPS</Tab>}
                </TabList>
                {(admin || user) && <TabPanel className="tab-content">
                    <CPPList
                        authToken={authToken}
                        profile={profile}
                        dispatch={dispatch}
                        adminEmail={adminEmail}
                        cppList={cppList}
                        iscppenabled={profile.iscppenabled}
                        updateCppMessage={updateCppMessage}
                        cppStatus={cppStatus}
                        isAdmin={admin} />
                </TabPanel>}
                {isAdmin && <TabPanel className="tab-content">
                    <BeSpokenSection
                        productList={productList}
                        authToken={authToken}
                        profile={profile}
                        dispatch={dispatch}
                    />
                </TabPanel>}
                {isTAUser || isAdmin ?
                    <TabPanel className="tab-content">
                        <TraderAccountSection
                            productList={productList}
                            authToken={authToken}
                            profile={profile}
                            dispatch={dispatch}
                            isTAenabled={profile.istradeaccountenabled}
                            isAdmin={isAdmin}
                            adminEmail={adminEmail}
                            tacredituser={tacredituser}
                            updateSendCreditFormMessage={updateSendCreditFormMessage}
                            approvedCreditLimitMsg={approvedCreditLimitMsg}
                            savedTACreditMsg={savedTACreditMsg}
							taForm={taForm}
                        />
                    </TabPanel>
                    : ''}
                {( finalUserRole === 'PRESCRIBER') ? (<TabPanel className="tab-content">
                    <PPS
                        productList={productList}
                        authToken={authToken}
                        profile={profile}
                        dispatch={dispatch}
                    />
                </TabPanel>):(<></>)}
            </Tabs>
        </>
    );
}
export default CustomerProfileDiscountSection