import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { getCouponApiCall } from "../../../serviceCalls/getCouponApiCall";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import AdminDeleteColumnColumn from "./AdminDeleteColumnColumn";
import AdminUpdateCouponColumn from "./AdminUpdateCouponColumn";
import AdminUpdateCouponModel from "./AdminUpdateCouponModel";
import AdminDeleteCouponModel from "./AdminDeleteCouponModel";
import AdminCouponModel from "./AdminCouponModel";
import { useHistory } from "react-router-dom";

const AdminCouponList = ({ applicationState, dispatch }) => {
  const { config, couponList } = applicationState;
  const history = useHistory();
  React.useEffect(() => {
    getCouponApiCall({ dispatch: dispatch, config: config });
  }, [dispatch, config]);

  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [dataForUpdateModal, setDataForUpdateModal] = React.useState({});
  const [showUpdateModal, setUpdateUserModal] = React.useState(false);
  const [showDeleteModal, setDeleteUserModal] = React.useState(false);
  const [showAddModal, setAddUserModal] = React.useState(false);
  const [dataForDeleteModal, setDataForDeleteModal] = React.useState({});
  const [userDetails, setUserDetails] = useState([]);

  const columnDefs = () => [
    {
      field: "sno",
      headerName: "Sno.",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 150,
    },
    {
      field: "code",
      headerName: "Code",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 200,
    },
    {
      field: "couponType",
      headerName: "Coupon Type",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 195,
    },
    {
      field: "amount",
      headerName: "Coupon Amount",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "productDetails",
      headerName: "Select Product",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "usageCount",
      headerName: "Usage",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "usageLimit",
      headerName: "Limit",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
    },
    {
      field: "update",
      headerName: "Update",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
      cellRendererFramework: AdminUpdateCouponColumn,
    },
    {
      field: "delete",
      headerName: "Delete",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      width: 180,
      cellRendererFramework: AdminDeleteColumnColumn,
    },
  ];
  const rowData = () => {
    if (couponList && couponList?.length > 0) {
      couponList.map((coupon, index) => {
        coupon.sno = index + 1;
        coupon.productDetails = coupon.productDetailsList && coupon.productDetailsList.map((data) => data.brand+" "+data.producttype+" "+data.productdesc)
      });
      return couponList;
    } else {
      return [];
    }
  };
  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F8F7FF" };
    }
  };
  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };
  const showUpdateCouponModal = (showModalValue) => {
    setUpdateUserModal(showModalValue);
  };

  const showDeleteCouponModal = (showModalValue) => {
    setDeleteUserModal(showModalValue);
  };
  const showAddCouponModal = (showModalValue) => {
    setAddUserModal(showModalValue);
  };
  const frameWorkComponentChange = ({ api, data, column, node, context }) => {
    setDataForUpdateModal(node.data);
    showUpdateCouponModal(true);
  };

  const deleteComponentClick = ({ api, data, column, node, context }) => {
    setDataForDeleteModal(node.data);
    setDeleteUserModal(true);
  };
  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: `1140px`,
        width: "100%",
      }}
    >
      <div style={{ margin: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => showAddCouponModal(true)}
        >
          Add Coupon
        </Button>
      </div>

      <AgGridReact
        getRowStyle={getRowStyle}
        rowData={rowData()}
        columnDefs={columnDefs()}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        paginationPageSize={50}
        pagination={true}
        context={{
          frameWorkComponentChange: frameWorkComponentChange,
          deleteComponentClick: deleteComponentClick,
          showUpdateCouponModal: showUpdateCouponModal,
          showDeleteCouponModal: showDeleteCouponModal,
        }}
      ></AgGridReact>

      <AdminCouponModel
        onClose={() => showAddCouponModal(false)}
        show={showAddModal}
        config={applicationState?.config}
        history={history}
        dispatch={dispatch}
        applicationState={applicationState}
      />

      <AdminUpdateCouponModel
        onClose={() => showUpdateCouponModal(false)}
        show={showUpdateModal}
        dataForUpdateModal={dataForUpdateModal}
        config={applicationState?.config}
        history={history}
        dispatch={dispatch}
        showDeleteCouponModal={showDeleteCouponModal}
        applicationState={applicationState}
      />
      <AdminDeleteCouponModel
        title="Delete Coupon"
        onClose={() => {
          showDeleteCouponModal(false);
          showUpdateCouponModal(false);
        }}
        show={showDeleteModal}
        dataForDeleteModal={dataForDeleteModal}
        config={applicationState?.config}
        history={history}
        dispatch={dispatch}
        applicationState={applicationState}
      >
        <p>
          <strong>Are you sure you want to delete this Coupon?</strong>
        </p>
      </AdminDeleteCouponModel>
    </div>
  );
};

export default AdminCouponList;
