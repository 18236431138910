import axios from "axios";
import fileSaver from "file-saver";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_SIGN_UP_DATA,
  SET_ADD_DELETE_NEW_PRODUCT,
  SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const exportLastOrders = async ({ dispatch, authToken }) => {
  let apiRequestHeader = makeApiRequestHeader(
    "GET",
    { "x-auth-token": authToken },
    null,
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/order_report`;
  apiRequestHeader = {
    ...apiRequestHeader,
    responseType: 'blob'
  }

  await axios
    .get(apiUrl, apiRequestHeader)
    .then((apiResponse) => {
      const { data, headers } = apiResponse;
      const fileData = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
      fileSaver.saveAs(fileData, "Customer_Last_Orders.xlsx");
      dispatch({ type: SET_IS_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ADD_DELETE_NEW_PRODUCT,
        payload: "error",
        message: "There was some error while exporting the Customers Last orders .",
      });
    });
};

export const exportMultiRangeOrders = async ({ dispatch, authToken, startdate, enddate, type }) => {
  let apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": authToken },
    null,
    null
  );
  let data = {
    startdate,
    enddate,
    type
  }
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/report/Reports`;
  apiRequestHeader = {
    ...apiRequestHeader,
    responseType: 'blob'
  }

  await axios
    .post(apiUrl, data, apiRequestHeader)
    .then((apiResponse) => {
      const { data, headers } = apiResponse;
      const fileData = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
      });
      fileSaver.saveAs(fileData, type + '.xlsx');
      dispatch({ type: SET_IS_LOADING, payload: false });
    })
    .catch(() => {      
      alert('Not able to generate report please try again later.');
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ADD_DELETE_NEW_PRODUCT,
        payload: "error",
        message: "Not able to generate report please try again later.",
      });
    });
};
