import * as React from "react";
import HeaderMenu from "../../common/HeaderMenu";
import { useHistory } from "react-router-dom";

import CheckoutProgressBar from "./CheckoutProgressBar";
import CustomerAmountDetails from "./CustomerAmountDetails";

import {
  SET_PAYMENT_METHOD,
  SET_TOTAL_AMOUNT,
  SET_IS_LOADING,
} from "../../../constants/actionTypes";
import { STORAGE_KEYS } from "../../../constants/common";
import { placeOrderApiCall } from "../../../serviceCalls/placeOrderApiCall";
import { fetchCreditUserApiCall } from "../../../serviceCalls/fetchCreditUserApiCall";

import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { Spinner } from "react-bootstrap";

const CashPaidModal = ({ onSubmit, onCancel, maxPayableAmount }) => {
  const [cashPaidAmount, setCashPaidAmount] = React.useState();
  const [formError, setFormError] = React.useState(0);
  const _validate = () => {
    if (cashPaidAmount < 1 || cashPaidAmount > maxPayableAmount) {
      setFormError(`Please enter a number between 1 and ${maxPayableAmount}`)
      return false;
    }
    return true;
  }
  const _onSubmit = () => {
    if (!_validate()) {
      return false;
    }

    onSubmit(cashPaidAmount);
  }
  return (
    <div className="modal enter-done">
      <div
        className="modal-content"
        style={{ overflow: "auto", maxHeight: "600px" }}
      >
        <div className="modal-header">
          <h4 className="modal-title">Cash Paid</h4>
        </div>
        <div className="modal-body">
          <div className="form-floating mb-3">
          <input
              type="number"
              className="form-control"
              id="cashPaid"
              placeholder="Enter amount paid"
              value={cashPaidAmount}
              onChange={(e) => setCashPaidAmount(parseFloat(e.target.value))}
            />
            <label for="floatingInput">Cash Paid in £</label>
          </div>
          {formError ? <span className="error">{formError}</span> : null}
        </div>
        <div className="modal-footer text-align-center">
          <button
            className="btn btn-main"
            onClick={() => _onSubmit()}
          >
            Submit
          </button>
          <button
            className="btn floating-modal-btn btn-secondary"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

const CustomerPaymentWalkin = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const {
    cartDetails,
    subTotalAmount,
    totalVatAmount,
    totalAmount,
    shippingCost,
    config,
	promocodeDiscount,
    discountAmount,
	promocode,
    cppCodeExist,
    isLoading,
    isBulkDiscount,
    existingCustomerData,
    tacredituser,
    warehouseAttr
  } = applicationState;

  const [paymentMethod, setPaymentMethod] = React.useState(
    applicationState.paymentMethod
  );
  const [tempCart, setTempCart] = React.useState(cartDetails);
  const [showCashPaymentModal, setShowCashPaymentModal] = React.useState(false);
  const [cashPaidAmount, setCashPaidAmount] = React.useState(0);

  React.useEffect(() => {
    const cartData = JSON.parse(window.sessionStorage.getItem("cart"));
    if (cartData) {
      setTempCart(cartData);
      if (existingCustomerData && existingCustomerData.istradeaccountenabled) {
        fetchCreditUserApiCall({
          dispatch: dispatch,
          authToken: applicationState?.config?.authToken,
          email: existingCustomerData.email
        })
      }
    }
  }, []);

  const purchase_units = [];
  tempCart.map((item) => {
    const purchaseUnitObject = Object.assign({});
    purchaseUnitObject.description =
      item.brand + " " + item.description + " " + item.productType;
    purchaseUnitObject.amount = {
      currency_code: "GBP",
      value: Number(item.quantity * item.salesPerUnit),
    };
    purchase_units.push(purchaseUnitObject);
  });

  const validateSubmit = (e) => {
    dispatch({
      type: SET_PAYMENT_METHOD,
      payload: paymentMethod,
    });

      dispatch({ type: SET_IS_LOADING, payload: true });
      const itemList = applicationState?.cartDetails?.map((item) => [
        item.productid,
        item.quantity,
      ]);
      let productidcartmap = Object.fromEntries(itemList);
      const placeOrderRequest = {
        cart: {
          ordershippingcost: Number(applicationState?.shippingCost),
          productidcartmap,
          subtotal: applicationState?.subTotalAmount,
          totalvat: applicationState?.totalVatAmount,
          userId: applicationState?.shippingAddressDetails?.email || "",
        },
        cppcode: "",
        email: applicationState?.shippingAddressDetails?.email || "",
        firstname: applicationState?.shippingAddressDetails?.firstName || "",
        lastname: applicationState?.shippingAddressDetails?.lastName || "",
        mobileno: applicationState?.shippingAddressDetails?.phoneNo || "",
        paymentMethod: paymentMethod,
        postalcode: applicationState?.shippingAddressDetails?.postCode || "",
		promocodeapplied: promocodeDiscount ? true : false,
        promocode: promocode,
        cashPaidAmount: cashPaidAmount && totalAmount ? cashPaidAmount : 0,
        warehouse: localStorage.getItem(STORAGE_KEYS.WALKING_SELECTED_WAREHOUSE)
          || (warehouseAttr != null ? warehouseAttr : "Online-Warehouse"),
        addressLineOne: applicationState?.shippingAddressDetails?.addressLineOne,
        addressLineTwo: applicationState?.shippingAddressDetails?.addressLineTwo,
        city: applicationState?.shippingAddressDetails?.city,
        isSameAddress: applicationState?.isSameAddress ?? false,
        billingAddressLineOne: applicationState?.billingAddressDetails?.billingAddressLineOne,
        billingAddressLineTwo: applicationState?.billingAddressDetails?.billingAddressLineTwo,
        billingCity: applicationState?.billingAddressDetails?.billingCity,
        billingPostCode: applicationState?.billingAddressDetails?.billingPostCode
      };
	  if(cppCodeExist){
        placeOrderRequest.cppcode = cppCodeExist;
      }
      placeOrderApiCall({
        dispatch,
        history,
        placeOrderRequest,
        authToken: applicationState?.config?.authToken,
        userType: applicationState?.config?.userType
      });

  };

  const settingAmountDetails = (shippingCost, replaceTotalAmount) => {
    dispatch({
      type: SET_TOTAL_AMOUNT,
      payload: {
        shippingCost,
        subTotalAmount: subTotalAmount,
        totalVatAmount: totalVatAmount,
        totalAmount: replaceTotalAmount !== undefined
          ? replaceTotalAmount
          : Number(subTotalAmount) +
            Number(totalVatAmount) +
            Number(shippingCost)
        ,
      },
    });
  };

  const revertCashPaidAmount = () => {
    settingAmountDetails(shippingCost, totalAmount + cashPaidAmount); // +ve
    setCashPaidAmount(0);
  };

  const _setShowCashPaymentModal = () => {
    if (paymentMethod === 'CASH' && !cashPaidAmount && totalAmount && config?.userType === "ADMIN") {
      setShowCashPaymentModal(true);
    } else {
      setShowCashPaymentModal(false);
    }
  }

  const _setPaymentMethod = (method) => {
    setPaymentMethod(method);
  }

  const onSubmitCashPayment = (pAmount) => {
    if (pAmount) {
      setCashPaidAmount(pAmount);
      settingAmountDetails(shippingCost, totalAmount - pAmount); // -ve
      setShowCashPaymentModal(false);
    }
  }

  React.useEffect(() => {
    _setShowCashPaymentModal();
  }, [paymentMethod, totalAmount]);

  return (
    <div>
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {config?.userType === "ADMIN" ? (
        <AdminHeaderMenu dispatch={dispatch} />
      ) : (
        <HeaderMenu dispatch={dispatch} cartCount={tempCart.length} />
      )}
      <div
        id="checkout"
        style={{marginTop:'58px'}}
        className={` ${config?.userType === "ADMIN" ? "admin" : ""}`}
      >
        <div className="container-fluid">
          <div className="card px-0 pb-0 mb-3">
            <div className="row">
              <div className="col-md-12 mx-0 px-0" id="msform">
                <CheckoutProgressBar
                  progressItem="Payment"
                  userType={config?.userType}
                />
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-md-first order-last px-0 admin-shipaddress">
                    <fieldset>
                      <h2 className="fs-title">Payment Information</h2>
                      <div className="my-3 form-card">
                        <div className="custom-control custom-radio">
                          <input
                            id="paypal"
                            name="paymentMethod"
                            type="radio"
                            className="custom-control-input"
                            value="PAYPAL"
                            checked={paymentMethod === "PAYPAL" ? true : false}
                            onChange={(e) => _setPaymentMethod(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="paypal"
                          >
                            Paypal
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            id="credit"
                            name="paymentMethod"
                            type="radio"
                            className="custom-control-input"
                            value="CARD"
                            checked={paymentMethod === "CARD" ? true : false}
                            onChange={(e) => _setPaymentMethod(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="credit"
                          >
                            Credit Card/Debit Card
                          </label>
                        </div>

                        <div className="custom-control custom-radio">
                          <input
                            id="payondelivery"
                            name="paymentMethod"
                            type="radio"
                            className="custom-control-input"
                            value="CASH"
                            checked={paymentMethod === "CASH" ? true : false}
                            onChange={(e) => _setPaymentMethod(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="payondelivery"
                          >
                            {config?.userType === "ADMIN" ? "CASH" : "Pay on Delivery"}
                          </label>
                        </div>

                        {/* {
                          config?.userType === "ADMIN" && totalAmount ?
                            <div className="custom-control custom-radio">
                            <input
                              id="cashPaid"
                              name="paymentMethod"
                              type="radio"
                              className="custom-control-input"
                              value="CASHPAID"
                              checked={paymentMethod === "CASHPAID" ? true : false}
                              onChange={(e) => _setPaymentMethod(e.target.value)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="cashPaid"
                            >
                              CASH PAID
                            </label>
                          </div> : null
                        } */}

                        <div className="custom-control custom-radio">
                          <input
                            id="banktransfer"
                            name="banktransfer"
                            type="radio"
                            className="custom-control-input"
                            value="BANK TRANSFER"
                            checked={paymentMethod === "BANK TRANSFER" ? true : false}
                            onChange={(e) => _setPaymentMethod(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="banktransfer"
                          >
                            BANK TRANSFER
                          </label>
                        </div>
                        <div className="custom-control custom-radio">
                          <input
                            id="paymentlink"
                            name="paymentlink"
                            type="radio"
                            className="custom-control-input"
                            value="PAYMENT LINK"
                            checked={paymentMethod === "PAYMENT LINK" ? true : false}
                            onChange={(e) => _setPaymentMethod(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="paymentlink"
                          >
                            PAYMENT LINK
                          </label>
                        </div>
                        { existingCustomerData && existingCustomerData.istradeaccountenabled ?
                        <div className="custom-control custom-radio">
                          <input
                            id="tacredit"
                            name="paymentMethod"
                            type="radio"
                            className="custom-control-input"
                            value="TACREDIT"
                            disabled={tacredituser && tacredituser.tacredit < Number(totalAmount)
                              ? true : false}
                            checked={paymentMethod === "TACREDIT" ? true : false}
                            onChange={(e) => _setPaymentMethod(e.target.value)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="tacredit"
                          >
                            TA Credit
                          </label>
                          { tacredituser && tacredituser.tacredit < Number(totalAmount) ?
                          <p>
                              Your TA credit is £ {tacredituser && tacredituser.tacredit} which is less than order amount £ {Number(totalAmount)}
                          </p>
                          : ''
                          }
                        </div>
                        : ''  && tacredituser && tacredituser.tacredit < 1 }
                      </div>
                      <button
                        className="previous action-button-previous"
                        type="submit"
                        onClick={() => {
                          history.push("/customershipping_info_admin");
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="next action-button"
                        type="submit"
                        onClick={validateSubmit}
                        disabled={!paymentMethod}
                      >
                        Proceed to Pay
                      </button>
                    </fieldset>
                  </div>
                  {
                    showCashPaymentModal ?
                    <CashPaidModal
                      maxPayableAmount={totalAmount}
                      onSubmit={onSubmitCashPayment}
                      onCancel={() => setShowCashPaymentModal(false)}
                    /> : null
                  }
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mobile-padding">
                    <CustomerAmountDetails
                      subTotalAmount={subTotalAmount}
                      finalVatAmount={totalVatAmount}
                      totalAmount={totalAmount}
                      shippingCost={shippingCost}
                      cashPaidAmount={cashPaidAmount}
                      isBulkDiscount={isBulkDiscount}
                      changeShippingCost={(newShippingCost) => {
                        settingAmountDetails(newShippingCost);
                      }}
                      cancelCashPaid={() => {
                        revertCashPaidAmount();
                        setPaymentMethod('');
                      }}
                      dispatch={dispatch}
					            promocodeDiscount={promocodeDiscount}
                      discountAmount={discountAmount}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPaymentWalkin;
