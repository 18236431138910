import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { updateUserProfile } from "../../../serviceCalls/updateUserProfileApiCall";
import { SUPPORTED_WAREHOUSE } from "../../../constants/common";

const UpdateUpdateModal = (props) => {
  const {
    config,
    dispatch,
    dataForUpdateModal,
    onClose,
    history,
    showDeleteUserModal,
  } = props;
  const [newUserDetails, setNewUserDetails] = React.useState({});
  React.useEffect(() => {
    setNewUserDetails({
      id:dataForUpdateModal?.id ?? "",
      address: dataForUpdateModal?.address ?? "",
      city: dataForUpdateModal?.city ?? "",
      postcode: dataForUpdateModal.postcode ?? "",
      name: dataForUpdateModal.name ?? "",
      location: dataForUpdateModal.location ?? "",
      email: dataForUpdateModal.email ?? "",

    });
  }, [dataForUpdateModal]);

  const {
    id,
    name,
    email,
    address,
    postcode,
    city,
    location,
  } = newUserDetails;

  const onEdit = () => {
    const requestBodyForEdit = {
        id: id,
        name: name,
        location: location,
        city: city,
        postcode: postcode,
        email: email,
        address: address,
      
    };
    updateUserProfile({
      dispatch: dispatch,
      requestBodyForEdit: requestBodyForEdit,
      config: config,
    });
    onClose();
  };

  return (
    <>
      {props.dataForUpdateModal &&
        Object.keys(props.dataForUpdateModal).length > 0 && (
          <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
          >
            <div className="modal enter-done">
              <div
                className="modal-content"
                style={{ overflow: "auto", maxHeight: "600px" }}
              >
                <div className="modal-header">
                  <h4 className="modal-title">Edit User Details</h4>
                </div>
                <div className="modal-body">
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="name">
                        Name<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => {
                          setNewUserDetails({
                            ...newUserDetails,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>

                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="email">
                        Email<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="email"
                        placeholder="Example@abc.com"
                        value={email}
                        onChange={(e) => {
                          setNewUserDetails({
                            ...newUserDetails,
                            email: e.target.value,
                          });
                        }}
                      />
                     
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                        <label
                          className="form-label"
                          for="walkin-order-select"
                        >
                          Location<span class="required">*</span>
                        </label>
                        <select
                        style={{width:"100%"}}
                          className="form-select form-data"
                          id="walkin-order-select"
                          name="walkin-order-select"
                          value={location}
                          disabled
                          onChange={(e) => {
                            setNewUserDetails({
                              ...newUserDetails,
                              location: e.target.value,
                            });
                          }}
                        >
                          {SUPPORTED_WAREHOUSE.LIST.map((o) => (
                            <option key={o} value={o}>
                              {o}
                            </option>
                          ))}
                        </select>
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="address">
                        Address<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="address"
                        placeholder="Address"

                        value={address}
                        onChange={(e) => {
                          setNewUserDetails({
                            ...newUserDetails,
                            address: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                   <div style={{ display: "flex" }}>
                    <div className="form-group mb-3">
                      <label className="form-label" for="city">
                        City<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="city"
                        placeholder="City"
                        value={city}
                        onChange={(e) => {
                          setNewUserDetails({
                            ...newUserDetails,
                            city: e.target.value,
                          });
                        }}
                        onBlur={(e) => {
                          setNewUserDetails({
                            ...newUserDetails,
                            city:e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="form-group form-margin mb-3">
                      <label className="form-label" for="postcode">
                        Postcode<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-data"
                        id="postcode"
                        placeholder="Postcode"
                        value={postcode}
                        onChange={(e) => {
                          setNewUserDetails({
                            ...newUserDetails,
                            postcode: e.target.value
                              ? Number(e.target.value)
                              : 0,
                          });
                        }}
                        
                      />

                    </div>
                  </div> 
                  <div className="modal-footer text-align-center">
                    <button
                      className="btn btn-main btn-update"
                      onClick={onEdit}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-main btn-delete"
                      onClick={() => showDeleteUserModal(true)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-main "
                      onClick={() => props.onClose()}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
    </>
  );
};
export default UpdateUpdateModal;
