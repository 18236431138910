import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_PRODUCT_LIST,
  SET_ERROR,
  SET_KANBAN_DETAILS,
  SET_USER_DETAILS,
  SET_IS_LOADING,
  SET_TOTAL_AMOUNT,
  SET_CUSTOMER_DETAILS,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { fetchCppDetails } from "./fetchCppDetails";

export const getProductsApiCall = async ({
  dispatch,
  history,
  signInResponse,
  email,
  moveToNextPage = true,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "GET",
    {
      "x-auth-token": signInResponse.authToken,
    },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/product/all`;
  await axios
    .get(apiUrl, apiRequestHeader)
    .then((apiResponse) => {
      dispatch({
        type: SET_PRODUCT_LIST,
        payload: apiResponse.data,
      });
      if (signInResponse.userType.toUpperCase() === "USER") {
        axios
          .all([
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/user/account/${email}`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/order/customers/email?email=${email}`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/cart/getDetails?userId=${email}`,
              apiRequestHeader
            ),
          ])
          .then(
            axios.spread((customerDetails, customerOrders, customerCart) => {
              if (
                customerDetails.data.priceKeyValue &&
                customerDetails.data.priceKeyValue.length > 0
              ) {
                const matchedPrice = apiResponse.data.map((product) => {
                  customerDetails.data.priceKeyValue.map((priceMatch) => {
                    if (priceMatch.productId === product.productid) {
                      product.vat = priceMatch.productVat;
                      product.salepriceperunit = Number(
                        priceMatch.matchedPrice
                      );
                    }
                  });
                  return product;
                });
                dispatch({
                  type: SET_PRODUCT_LIST,
                  payload: matchedPrice,
                });
              }
              if (
                customerDetails.data.iscppenabled &&
                customerDetails.data.tradeofbuisness === "Training Academy"
              ) {
                fetchCppDetails({
                  authToken: signInResponse.authToken,
                  dispatch,
                  email: customerDetails.data.email,
                });
              }
              const shippingAddressDetails = {
                firstName: customerDetails?.data?.firstname ?? "",
                lastName: customerDetails?.data?.lastname ?? "",
                email: customerDetails?.data?.email ?? "",
                phoneNo: customerDetails?.data?.mobileno ?? "",
                address: customerDetails?.data?.address ?? "",
                addressLineOne: customerDetails?.data?.address?.addressLineOne ?? "",
                addressLineTwo: customerDetails?.data?.address?.addressLineTwo ?? "",
                city: customerDetails?.data?.address?.city ?? "",
                postCode: customerDetails?.data?.address?.postcode ?? "",
                instagramId: customerDetails?.data?.instaname ?? "",
                tradeOfBusiness: customerDetails?.data?.tradeofbuisness ?? "",
                insuranceexpirydate:
                  customerDetails?.data?.insuranceexpirydate ?? "",
              };

              const customerCartApiResponse =
                customerCart && customerCart.data ? customerCart.data : [];
              const cartDetailsFromService = [];
              const cartDetails = [];

              customerCartApiResponse &&
                Object.keys(customerCartApiResponse).length > 0 &&
                customerCartApiResponse.productidcartmap &&
                Object.keys(customerCartApiResponse.productidcartmap).length >
                  0 &&
                Object.keys(customerCartApiResponse.productidcartmap).map(
                  (key) =>
                    cartDetailsFromService.push({
                      productid: key,
                      quantity: customerCart.data.productidcartmap[key],
                    })
                );

              cartDetailsFromService &&
                cartDetailsFromService.length > 0 &&
                cartDetailsFromService.map((productincart) => {
                  const findProduct = apiResponse.data.find((product) => {
                    return product.productid === productincart.productid;
                  });
                  const findIndexOfTheRowInProductList =
                    apiResponse.data.findIndex((product) => {
                      return product.productid === productincart.productid;
                    });
                  apiResponse.data[findIndexOfTheRowInProductList] =
                    Object.assign(
                      {},
                      {
                        ...findProduct,
                        quantity: productincart.quantity,
                      }
                    );
                });

              apiResponse.data.forEach((product) => {
                if (product.quantity && product.quantity !== 0) {
                  cartDetails.push({
                    ...product,
                    vat: product.vat * product.quantity,
                  });
                }
              });
              dispatch({
                type: SET_TOTAL_AMOUNT,
                payload: {
                  shippingCost:
                    customerCartApiResponse &&
                    Object.keys(customerCartApiResponse).length > 0 &&
                    customerCartApiResponse.ordershippingcost
                      ? customerCartApiResponse.ordershippingcost
                      : 0,
                  subTotalAmount:
                    customerCartApiResponse &&
                    Object.keys(customerCartApiResponse).length > 0 &&
                    customerCartApiResponse.subtotal
                      ? customerCartApiResponse.subtotal
                      : 0,
                  totalVatAmount:
                    customerCartApiResponse &&
                    Object.keys(customerCartApiResponse).length > 0 &&
                    customerCartApiResponse.totalvat
                      ? customerCartApiResponse.totalvat
                      : 0,
                  totalAmount: 0,
                },
              });
              dispatch({
                type: SET_USER_DETAILS,
                payload: customerDetails.data,
                orderDetails: customerOrders.data,
                shippingAddressDetails,
                productList: apiResponse.data,
                cartDetails: cartDetails,
              });
              if (moveToNextPage) {
                history.push("/producttypes");
              }
            })
          );
        // history.push("/productlist");
      }

      if (signInResponse.userType.toUpperCase() === "ADMIN") {
        axios
          .all([
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/product/TAProductRates/all`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/order/all?ordertype=INSTA_SITE`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcompletedorders?ordertype=INSTA_SITE`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcompletedorders?ordertype=SHOPIFY`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcompletedorders?ordertype=PHARMA`,
              apiRequestHeader
            ),
            axios.get(
              `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcompletedorders?ordertype=STORE`,
              apiRequestHeader
            ),
          ])
          .then(
            axios.spread(
              (
                taProductRates,
                allOrders,
                instaCompletedOrders,
                shopifyCompletedOrders,
                pharmaCompletedOrders,
                storeCompletedOrders
              ) => {
                dispatch({
                  type: SET_KANBAN_DETAILS,
                  payload: allOrders.data,
                  taProductRates: taProductRates.data,
                  instaCompletedOrders: instaCompletedOrders.data,
                  shopifyCompletedOrders: shopifyCompletedOrders.data,
                  pharmaCompletedOrders: pharmaCompletedOrders.data,
                  storeCompletedOrders: storeCompletedOrders.data,
                });
              }
            )
          );
        if (moveToNextPage) {
           history.push("/admin_product_list");
          //history.push("/homepage");
        }
      }
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
};

export const fetchCustomerDetails = (dispatch) => {
  const authToken = localStorage.getItem("sessionKey");
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    {
      "x-auth-token": authToken,
    },
    null
  );

  return axios
    .all([
      axios.get(
        `${envConfig.apiUrl}:${envConfig.apiPort}/admin/user/management/GANDYS_USER`,
        apiRequestHeader
      ),
      axios.get(
        `${envConfig.apiUrl}:${envConfig.apiPort}/admin/user/management/WHOLESALE_USER`,
        apiRequestHeader
      ),
      axios.get(
        `${envConfig.apiUrl}:${envConfig.apiPort}/admin/user/management/PHARMA_USER`,
        apiRequestHeader
      ),
    ])
    .then(
      axios.spread((instaUser, wholesaleUser, pharmaUser) => {
        const allCustomersData = [];
        instaUser &&
          instaUser.data &&
          instaUser.data.forEach((user) => {
            allCustomersData.push({
              ...user,
              username: `${user.firstname ? user.firstname : ""} ${
                user.lastname ? user.lastname : ""
              }`,
              postcode:
                user.address && user.address.length > 0
                  ? user.address[0].postcode
                  : "",
            });
          });

        const newWholeUser = [];
        wholesaleUser &&
          wholesaleUser.data &&
          wholesaleUser.data.forEach((user) => {
            newWholeUser.push({
              ...user,
              usertype: "WHOLESALE_USER",
              username: `${user.firstname ? user.firstname : ""} ${
                user.lastname ? user.lastname : ""
              }`,
              postcode:
                user.address && user.address.length > 0
                  ? user.address[0].postcode
                  : "",
            });
          });

        const newPharmaUser = [];
        pharmaUser &&
          pharmaUser.data &&
          pharmaUser.data.forEach((user) => {
            newPharmaUser.push({
              ...user,
              usertype: "PHARMA_USER",
              username: `${user.firstname ? user.firstname : ""} ${
                user.lastname ? user.lastname : ""
              }`,
              postcode:
                user.address && user.address.length > 0
                  ? user.address[0].postcode
                  : "",
            });
          });
        console.log(allCustomersData, "all");
        console.log(newWholeUser);
        console.log(newPharmaUser);

        dispatch({
          type: SET_CUSTOMER_DETAILS,
          customerList: allCustomersData,
          wholesaleCustList: newWholeUser,
          pharmaCustList: newPharmaUser,
        });

        return Promise.resolve();
      })
    )
    .catch((e) => Promise.reject(e));
};
