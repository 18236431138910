import React from "react";
import Group186 from "./Group186";
import "./css/GBEASIPROFITPAGE.css";
import HeaderMenu from '../../common/AdminHeaderMenu';

function GBEASIPROFITPAGE(props) {
    
  const {
    title,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    path198,
    spanText5,
    spanText6,
    path199,
    line1,
    line2,
    line3,
    spanText7,
    spanText8,
    spanText9,
    spanText10,
    line4,
    line5,
    update,
    ordersReceived,
    orderId,
    easiProfitShare,
    customerName,
    totalAmount,
    line6,
    line9,
    line11,
    monthlyEasiProfit,
    month,
    amount1,
    line12,
    line13,
    line16,
    march2022,
    name,
    price1,
    price2,
    previous,
    yearlyEasiProfit,
    yearly,
    amount2,
    line14,
    line15,
    number,
    price3,
    line17,
    next,
    group1861Props,
    group1862Props,
    applicationState, 
    dispatch
  } = props;
  
  const { cppFinalDetails } = applicationState;
  return (
  <>
  <HeaderMenu />
      
    <div className="container-center-horizontal">
      <div className="g-b-easi-profit-page screen">
        <div className="rectangle-209"></div>
        <div className="overlap-group11">
          <h1 className="title poppins-normal-white-30px">{title}</h1>
        </div>
        <div className="flex-row poppins-normal-mountain-mist-24px-2">
          <div className="easi-codeeasi123">
            <span className="poppins-normal-waikawa-gray-24px">{spanText1}</span><br></br>
            <span className="poppins-normal-mountain-mist-24px">{spanText2}</span>
          </div>
          <div className="profit-percentage4">
            <span className="poppins-normal-waikawa-gray-24px">{spanText3}</span><br></br>
            <span className="poppins-normal-mountain-mist-24px">{spanText4}</span>
          </div>
          <img className="path-198" src={path198} />
          <div className="name">
            <span className="poppins-normal-waikawa-gray-24px">{spanText5}</span><br></br>
            <span className="poppins-normal-mountain-mist-24px">{spanText6}</span>
          </div>
          <img className="path-199" src={path199} />
        </div>
        <div className="flex-col">
          <div className="line-container">
            <img className="line-1-3" src={line1} />
            <img className="line-2" src={line2} />
            <img className="line-3" src={line3} />
          </div>
          <div className="flex-row-1 poppins-normal-mountain-mist-24px-2">
            <div className="admin-fee100">
              <span className="poppins-normal-waikawa-gray-24px">{spanText7}</span><br></br>
              <span className="poppins-normal-mountain-mist-24px">{spanText8}</span>
            </div>
            <div className="approver-emailabhis">
              <span className="poppins-normal-waikawa-gray-24px">{spanText9}</span><br></br>
              <span className="poppins-normal-mountain-mist-24px">{spanText10}</span>
            </div>
          </div>
          <div className="line-container-1">
            <img className="line-4" src={line4} />
            <img className="line-5" src={line5} />
          </div>
          <div className="overlap-group4">
            <div className="update poppins-normal-white-24px">{update}</div>
          </div>
          <div className="overlap-group7">
            <div className="rectangle-219"></div>
            <div className="orders-received poppins-normal-white-30px">{ordersReceived}</div>
          </div>
          <div className="overlap-group6 poppins-normal-white-24px">
            <div className="scroll-group-1">
              <div className="rectangle-220"></div>
            </div>
            <div className="order-id">{orderId}</div>
            <div className="easi-profit-share">{easiProfitShare}</div>
            <div className="customer-name">{customerName}</div>
            <div className="total-amount-1">{totalAmount}</div>
            <img className="line-6" src={line6} />
            <img className="line-9" src={line9} />
            <img className="line-11" src={line11} />
            <Group186
              x4Ab406B46B92={group1861Props.x4Ab406B46B92}
              price1={group1861Props.price1}
              price2={group1861Props.price2}
            />
          </div>
          <Group186
            x4Ab406B46B92={group1862Props.x4Ab406B46B92}
            price1={group1862Props.price1}
            price2={group1862Props.price2}
            className={group1862Props.className}
          />
          <div className="flex-row-2">
            <div className="group-container">
              <div className="overlap-group">
                <div className="rectangle-22"></div>
                <div className="ly-easi-profit poppins-normal-white-30px">{monthlyEasiProfit}</div>
                <div className="rectangle-22-1"></div>
                <div className="month poppins-normal-white-24px">{month}</div>
                <div className="amount poppins-normal-white-24px">{amount1}</div>
                <img className="line-1" src={line12} />
              </div>
              <div className="overlap-group5 poppins-normal-mountain-mist-24px">
                <div className="rectangle-22-2"></div>
                <div className="rectangle-230"></div>
                <img className="line-1-1" src={line13} />
                <img className="line-1-2" src={line16} />
                <div className="march-2022">{march2022}</div>
                <div className="name-1">{name}</div>
                <div className="price">{price1}</div>
                <div className="price-1">{price2}</div>
              </div>
            </div>
            <div className="group-container-1">
              <div className="overlap-group">
                <div className="rectangle-22"></div>
                <div className="ly-easi-profit poppins-normal-white-30px">{yearlyEasiProfit}</div>
                <div className="rectangle-22-1"></div>
                <div className="yearly poppins-normal-white-24px">{yearly}</div>
                <div className="amount poppins-normal-white-24px">{amount2}</div>
                <img className="line-1" src={line14} />
              </div>
              <div className="overlap-group12">
                <div className="overlap-group9 poppins-normal-mountain-mist-24px">
                  <div className="rectangle-22-2"></div>
                  <img className="line-1-1" src={line15} />
                  <div className="number">{number}</div>
                  <div className="price">{price3}</div>
                </div>
                <img className="line-1-2" src={line17} />
              </div>
              <a href="cpppage1">
              <div className="group-46-a border-1px-white">
                <div className="next poppins-normal-white-30px">{next}</div>
              </div>
              </a>
            </div>
          </div>
          <div className="rectangle-204"></div>
        </div>
      </div>
    </div>
    </>
  );
}

export default GBEASIPROFITPAGE;
