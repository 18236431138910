import * as React from "react";
import { SET_TOTAL_AMOUNT } from "../../constants/actionTypes";

const CustomerAmountDetails = (props) => {
  const {
    subTotalAmount,
    finalVatAmount,
    totalAmount,
    shippingCost,
    changeShippingCost,
    promocodeDiscount,
    shippingDisabled,
    discountAmount,
  } = props;

  let msg = "";
  if(shippingCost == 9.95){
    msg = "Discount applied £ 9.95";
  }else{
    msg = "Offer only on shipping £ 9.95";
  }
  const promoCodeTitle =  promocodeDiscount && promocodeDiscount === 619 ? msg :  `${promocodeDiscount}%` ;


    const setAttributeValue = (e) => {
      var index = e.target.selectedIndex;
      var optionElement = e.target.childNodes[index]
      var warehouse = optionElement.getAttribute('data-warehouse');
      changeShippingCost(e.target.value, warehouse)
    }

  return (
    <>
        <h3 class="title_name">Order Summary</h3>
        <div class="cart__side_inner">
            <div class="s_itemD">
                <div class="label_d">Subtotal</div>
                <div class="info_d">{`£${Number(subTotalAmount).toFixed(2)}`}</div>
            </div>
            <div class="s_itemD">
                <div class="label_d">VAT</div>
                <div class="info_d">{`£${Number(finalVatAmount).toFixed(2)}`}</div>
            </div>
            <div class="s_itemD">
                <div class="label_d">Shipping Cost</div>
                <div class="info_d">
                    <div class="delivery__d">
                    <select
                      className="form-select"
                      id="shipping"
                      name="shipping"
                      // onChange={(e) => changeShippingCost(e.target.value)}
                      onChange={(e) => setAttributeValue(e)}
                      value={shippingCost}
                      disabled={shippingDisabled ? true: false}
                    >
                      <option data-warehouse='Glasgow' value="0.00">Click and collect £ 0 Glasgow</option>
                      <option data-warehouse='London' value="0">Click and collect £ 0 London</option>
                      <option data-warehouse='Hale' value="0.000">Click and collect £ 0 Hale</option>
                      <option data-warehouse='Next day delivery' value="9.95">Next day delivery £ 9.95</option>
                      <option data-warehouse='Nextday by 12pm' value="15.95">Next day by 12pm £ 15.95</option>
                      <option data-warehouse='Nextday by 10:30am' value="19.95">Next day by 10.30am £ 19.95</option>
                      <option data-warehouse='Saturday Delivery' value="14">Saturday Delivery - £ 14
                        (If delivery isn't completed we won't be responsible for any fridge lines)</option>
                    </select>
                    </div>
                </div>
            </div>
            {promocodeDiscount && <div className="h6 container-promo">
              <span>Discount ({promoCodeTitle})</span>
              {promoCodeTitle != "Discount applied £ 9.95" && <span style={{ float: "right" }}>{`- £${Number(discountAmount).toFixed(2)}`}</span>}
            </div>}
            <hr />
            <div class="s_itemD totalCartD">
                <div class="label_d">Total</div>
                <div class="info_d">{`£${Number(totalAmount).toFixed(2)}`}</div>
            </div>
        </div>
    </>
  );
};

export default CustomerAmountDetails;
