import * as React from "react";
import { useHistory } from "react-router";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";

const CppPage2 = (props) => {
  // console.log('props :',props);
  const { applicationState, dispatch } = props;
  const history = useHistory();
  const { cppFinalDetails } = applicationState;
  const taProfitShare = cppFinalDetails.totalTAProfitShare;
  
  const productName =
    cppFinalDetails?.selectedProduct[0]?.product?.brand +
    " " +
    cppFinalDetails?.selectedProduct[0]?.product?.producttype +
    " " +
    cppFinalDetails?.selectedProduct[0]?.product?.productdesc;


  // get value from localstorage
  let p1 = JSON.parse(localStorage.getItem("product1"))
  let p2 = JSON.parse(localStorage.getItem("product2"))
  let p3 = JSON.parse(localStorage.getItem("product3"))
  let p4 = JSON.parse(localStorage.getItem("product4"))
  let p5 = JSON.parse(localStorage.getItem("product5"))
  let p6 = JSON.parse(localStorage.getItem("product6"))
  let p7 = JSON.parse(localStorage.getItem("product6"))
  let p8 = JSON.parse(localStorage.getItem("product6"))
  let p9 = JSON.parse(localStorage.getItem("product6"))
  let p10 = JSON.parse(localStorage.getItem("product6"))

  return (
    <div className="admin" id="cpp">
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
            <div className="cpp-image-wrapper">
              <img
                src="./Practitioner image 2.jpg"
                alt="cpp background image"
              />
            </div>
            <div className="cpp-text-overlap text-white">
              <div className="cpp-image-title">
                EASI (Earn and Save Incentives)
                <p>REPORTS</p>
              </div>
              <div className="cpp-image-subtitle mt-5">
                CHECK YOUR ORDERING HISTORY IN
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="container-fluid">
              <h4 className="text-center mt-3">
                TRACK YOUR WEEKLY & MONTHLY ORDERING
              </h4>
              <p className="text-center mt-3" style={{ color: "#808080" }}>
                Below shows you your projected earnings
              </p>
              <div role="main">
                <form method="post">
                  <div className="form-floating pass-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingBoxesPurchasedWeekly"
                      placeholder="Total boxes purchased weekly"
                      defaultValue={cppFinalDetails.totalBoxesPurchasedWeekly}
                    />
                    <label htmlFor="floatingBoxesPurchasedWeekly">
                      Total boxes purchased weekly
                    </label>
                  </div>

                  <div className="form-floating pass-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingBoxesPurchasedWeekly"
                      placeholder="Total boxes purchased monthly"
                      defaultValue={cppFinalDetails.totalBoxesPurchasedMonthly}
                    />
                    <label htmlFor="floatingBoxesPurchasedWeekly">
                      Total boxes purchased monthly
                    </label>
                  </div>

                  {/* <div className="form-floating pass-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="productPurchased"
                      placeholder="Product Purchased"
                      defaultValue={productName ?? ""}
                    />
                    <label htmlFor="productPurchased">Product Purchased</label>
                  </div> */}

                  <div className="form-floating pass-wrapper">

                    <div>
                      <label style={{ "color": "grey" }}>Product List</label> <br />
                      {(cppFinalDetails?.studentData?.std1_trained != "") ? (<>1.{p1?.product?.brand} {p1?.product?.producttype} X {cppFinalDetails?.studentData?.std1_noboxes}  </>) : (<></>)} <br />
                      {(cppFinalDetails?.studentData?.std2_trained != "") ? (<>2.{p2?.product?.brand} {p2?.product?.producttype} X {cppFinalDetails?.studentData?.std2_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std3_trained != "") ? (<>3.{p3?.product?.brand} {p3?.product?.producttype} X {cppFinalDetails?.studentData?.std3_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std4_trained != "") ? (<>4.{p4?.product?.brand} {p4?.product?.producttype} X {cppFinalDetails?.studentData?.std4_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std5_trained != "") ? (<>5.{p5?.product?.brand} {p5?.product?.producttype} X {cppFinalDetails?.studentData?.std5_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std6_trained != "") ? (<>6.{p6?.product?.brand} {p6?.product?.producttype} X {cppFinalDetails?.studentData?.std6_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std7_trained != "") ? (<>7.{p7?.product?.brand} {p7?.product?.producttype} X {cppFinalDetails?.studentData?.std7_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std8_trained != "") ? (<>8.{p8?.product?.brand} {p8?.product?.producttype} X {cppFinalDetails?.studentData?.std8_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std9_trained != "") ? (<>9.{p9?.product?.brand} {p9?.product?.producttype} X {cppFinalDetails?.studentData?.std9_noboxes}  </>) : (<></>)}<br />
                      {(cppFinalDetails?.studentData?.std10_trained != "") ? (<>10.{p10?.product?.brand} {p10?.product?.producttype} X {cppFinalDetails?.studentData?.std10_noboxes}  </>) : (<></>)}<br />

                    </div>
                  </div>

                  <div className="form-floating pass-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      id="taProfitShare"
                      placeholder="TA profit Share"
                      defaultValue={'£ ' + Number(taProfitShare).toFixed(2)}
                      disabled
                    />
                    <label htmlFor="taProfitShare">TA profit Share</label>
                  </div>

                  <div className="form mt-5">
                    <button
                      style={{ flexGrow: "1" }}
                      className="btn btn-lg btn-main"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("cppPage1");
                      }}
                    >
                      Back to EASI home
                    </button>
                    <button
                      style={{ flexGrow: "1" }}
                      className="btn btn-lg btn-main"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("cppPage3");
                      }}
                    >
                      Projections
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CppPage2;
