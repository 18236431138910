import React from "react";
import ToggleButton from "react-toggle-button";
//import { RxCrossCircled as X } from "react-icons/rx";
//import { FaRegCircleCheck as Check } from "react-icons/fa6";

export const CustomerApprovalStatus = (param, changeApprovalStatus) => {
  console.log(param, changeApprovalStatus);
  return (
    <div className="toggle mrt-10 pdb-30">
      <span>
        {/* <ToggleButton
          value={param.data.userapproved}
          onToggle={(value) => {
            changeApprovalStatus(!value, param.data);
          }}
          thumbStyle={{ borderRadius: 2 }}
          trackStyle={{ borderRadius: 2 }}
        /> */}
        <ToggleButton
          value={param.data.userapproved}
          onToggle={(value) => {
            changeApprovalStatus(!value, param.data);
          }}
        />
      </span>
    </div>
  );
};
