import React from "react";
import { orderStatus } from "../../../constants/orderStatusConstants";

export const CustomDropdownEditor = (props) => {
  // console.log(props);
  const { data, value, colDef, context } = props;
  const [selectedValues, setSelectedValues] = React.useState(value);
  const [disable, setDisable] = React.useState(true);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValues(newValue);
    // window.localStorage.setItem('dropdownValue', value);
    // console.log(data);
    const { rowIndex } = data;
    const updatedData = context.updateData(rowIndex, colDef.field, newValue);
    context.onDataUpdated(updatedData, rowIndex);

    // console.log(event.target.value, typeof event.target.value);
  };
  return (
    <div>
      <select
        style={{ width: "110px" }}
        value={selectedValues}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {orderStatus.map((option, index) => (
          <option
            key={index}
            value={option.value}
            disabled={
              selectedValues &&
              orderStatus.findIndex((option) => option.value === selectedValues)>index
            }
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
