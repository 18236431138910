// import * as React from 'react';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { AppRouter } from './routes/index';

// import {SET_MOBILE_VIEW} from './constants/actionTypes';
// import {useStateManager} from './reducer/useStateManager';

// const theme = createTheme();

// const App = () => {
//   const[reducer, dispatch] = React.useReducer(useStateManager, null);
//   React.useEffect(() => {
//     const setResponsiveness = () => {
//       return dispatch({
//           type : SET_MOBILE_VIEW,
//           payload: window.innerWidth < 900
//       })
//     };

//     setResponsiveness();
//     window.addEventListener("resize", () => setResponsiveness());

//     return () => {
//       window.removeEventListener("resize", () => setResponsiveness());
//     }
//   }, []);

// if(reducer) {
//   return (
//    <ThemeProvider theme={theme}>
//       <div><AppRouter applicationState={reducer} dispatch={dispatch} /></div>
//     </ThemeProvider>
//   );

// } else {
//   return (
//     <ThemeProvider theme={theme}>
//       <div>Loader</div>
//     </ThemeProvider>
//   );
// }

// }
// export default App;

import * as React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppRouter } from "./routes/index";
import { useStateManager } from "./reducer/useStateManager";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/common.css";
import "./styles/glowbutton.css"
import "./styles/login.css";
import "./styles/checkout.css";
import "./styles/g-b-insta-landing-page.css";
import "./styles/globals.css";
import "./styles/styleguide.css";
import "./styles/admin.css";
import "./styles/latestTheme.css";
import "./styles/customer-design.css";
import { SET_MOBILE_VIEW, SET_SIGN_IN_DATA, SET_TILE_CLICKED, SET_IS_LOADING } from "./constants/actionTypes";
import { getProductsApiCall } from "./serviceCalls/getProductsApiCall";
import { useHistory } from "react-router-dom";
import envConfig from "./envConfigurations";

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(
  "pk_live_51M57B0HbgfZcbUzwxjIi2Ns7KO9kT1uQC9If2PFbx0KYmjHC53LZQqp57waeZ86Rdopc9nLVTS8MCOQd51Fms7JR00F6QJM14G"
);
const apiurl = envConfig.apiUrl;
const App = () => {
  const history = useHistory();
  const [reducer, dispatch] = React.useReducer(useStateManager, {
    mobileView: false,
    drawerOpen: false,
    productList: [],
    customerList: [],
    pharmaCustList: [],
    wholesaleCustList: [],
    cartDetails: [],
    customerDetails: {},
    productDetailData: {},
    shippingAddressDetails: {},
    billingAddressDetails: {},
    isSameAddress: true,
    orderDetails: [],
    config: {
      signInError: false,
      authToken: "",
    },
    isCartEmpty: true,
    isLoading: false,
    forgotPasswordError: "",
    totalAmount: 0,
    totalVatAmount: 0,
    subTotalAmount: 0,
    isBulkDiscount: false,
    shippingCost: 0,
    paymentMethod: "",
    adminPlaceOrder: [],
    signUpStatus: {
      signUpError: false,
    },
    addDeleteStatus: "",
    alertMessage: "",
    orderMessage: "",
    orderDeleteStatus: "",
    profileUpdateStatus: "",
    getCustomerOrderList: {},
    taProductRates: [],
    cppFinalDetails: {
      selectedProduct: [],
      totalBoxesPurchasedWeekly: 0,
      totalBoxesPurchasedMonthly: 0,
      effectiveCost: 0,
      totalTAProfitShare: 0,
      trainerName: "",
      trainingAcademy: "",
      noOfStudents: "",
      productsTrainedOn: "",
      noOfBoxes: "",
      minimumProfit: "1",
      overhead: "1",
      year1: 0,
      year2: 0,
      year3: 0,
    },
    crmDetails: [],
    instaCompletedOrders: [],
    shopifyCompletedOrders: [],
    pharmaCompletedOrders: [],
    storeCompletedOrders: [],
    existingCustomerError: "",
    existingCustomerData: {
      firstname: "",
      lastname: "",
      instaname: "",
      mobileno: "",
      email: "",
      address: {
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        postcode: ""
      }
    },
    promotionalMessage: "",
    promocodeDiscount: null,
    promoCodeError: "",
    discountAmount: 0,
    promocode: "",
    cppList: [],
    updateCppMessage: "",
    cppStatus: "",
    cppCodeExist: "",
    reportsData: [],
    taAccountEnabled: false,
    tacredituser: [],
    updateSendCreditFormMessage: "",
    approvedCreditLimitMsg: "",
    savedTACreditMsg: "",
    taForm: {},
    gpReportsData: [],
    couponList: []
  });
  React.useEffect(() => {
    const setResponsiveness = () => {
      return dispatch({
        type: SET_MOBILE_VIEW,
        payload: window.innerWidth < 900,
      });
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  if (window.location.pathname === '/' &&
    window.location.pathname === '/signin' &&
    window.location.pathname === '/forgot_password' &&
    window.location.pathname === '/signup') {
    window.sessionStorage.setItem("sessionKey", null)
  }
  const activeSession = window.sessionStorage.getItem("sessionKey");

  React.useEffect(() => {
    if (activeSession && reducer.productList?.length === 0 &&
      window.location.pathname !== '/' &&
      window.location.pathname !== '/signin' &&
      window.location.pathname !== '/forgot_password' &&
      window.location.pathname !== '/signup') {
      try {
        const userType = window.sessionStorage.getItem("userType");
        const email = window.sessionStorage.getItem("email");
        const payload = {
          authToken: activeSession,
          userType: userType
        }
        const tileClicked = window.sessionStorage.getItem("tileClicked");
        dispatch({ type: SET_IS_LOADING, payload: true });
        dispatch({
          type: SET_SIGN_IN_DATA,
          payload: payload,
          signInError: false,
          email: email
        });
        if (tileClicked) {
          dispatch({
            type: SET_TILE_CLICKED,
            payload: tileClicked
          })
        }
        getProductsApiCall({
          dispatch,
          history,
          signInResponse: payload,
          email: email,
          moveToNextPage: false
        });

      } catch (error) {
        console.warn(error)
      }

    }
  }, [reducer.productList])
  return (
    <div>
      <Elements stripe={stripePromise}>
        <AppRouter applicationState={reducer} dispatch={dispatch} />
      </Elements>
    </div>
  );
};
export default App;
