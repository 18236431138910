import axios from "axios";
import { SET_USER_VERIFY_STATE } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";

export const getEmailOtp = async ({ email }) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/notification/otp/emailotp`;
    const response = await axios.post(apiUrl, { email}, apiRequestHeader);
    return response;
  } catch(e) {
    return logError(e, 'getEmailOtp');
  }
}

export const verifyEmail = async ({ dispatch, email, otp }) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/notification/otp/validateemailotp`;
    const response = await axios.post(apiUrl, { email, otp }, apiRequestHeader);
    dispatch({
      type: SET_USER_VERIFY_STATE,
      payload: {
        emailverified: true
      }
    });
    return response;
  } catch(e) {
    return logError(e, 'verifyEmail');
  }
}