import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
// import { CLEAR_ADD_ITEM_FOR_ORDER, DISCOUNT_AMOUNT, PROMOCODE_DISCOUNT, SET_ERROR, SET_IS_LOADING } from "../constants/actionTypes";
import { saveAs } from "file-saver";
import envConfig from "../envConfigurations";

export const downloadOrderInovice = async (orderId) => {
    let token = window.localStorage.getItem("sessionKey");

    const apiRequestHeader = makeApiRequestHeader(
        "GET",
        {
            "x-auth-token": token,
            "Cookie": "JSESSIONID=3BB58F9B16E3F4BE099B709BCFEC44EA"
        },
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/invoice?orderid=${orderId}`;
    
    apiRequestHeader['responseType'] = 'blob'
    await axios
        .get(apiUrl, apiRequestHeader)
        .then((apiResponse) => {

            const fileData = new Blob([apiResponse.data]);
            saveAs(fileData, "invoice.pdf");

        }

        )
        .catch((error) => {
        console.log("🚀 ~ file: downloadOrderInvoice.js ~ line 33 ~ downloadOrderInovice ~ error", error)

        });
};
