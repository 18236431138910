import axios from "axios";
import { DELETE_CUSTOMER } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { getAllUserProfileApiCall } from "./getAllUserProfileApiCall"

export const deleteCustomer = async ({dispatch, userId,config}) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/admin/delete_profile?userId=${userId}`;
    const response = await axios.post(apiUrl, { userId }, apiRequestHeader);
    dispatch({
      type: DELETE_CUSTOMER,
      payload: userId
    });
    getAllUserProfileApiCall({ dispatch: dispatch, config: config })
    return response;
  } catch(e) {
    return logError(e, 'deleteCustomer');
  }
}