import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { PROMOTIONAL_EMAIL_MESSAGE } from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const sendPromotionalEmail = async ({
    dispatch,
    authToken,
    requestBodyForEmail
}) => {
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/notification/email/send_promotional_mail`;
    await axios
        .post(apiUrl, requestBodyForEmail, apiRequestHeader)
        .then((response) => {
            dispatch({
                type: PROMOTIONAL_EMAIL_MESSAGE,
                payload: response.data
            })
        })
        .catch(() => {
            dispatch({
                type: PROMOTIONAL_EMAIL_MESSAGE,
                payload: "An unexpected error has occurred. Please try again later"
            })
        });
};
