import React from "react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Spinner } from "react-bootstrap";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import NavigationBar from "../../common/NavigationBar";
import { makeStyles } from '@material-ui/core/styles';
import AdminCouponList from "./AdminCouponList";


const useStyles = makeStyles((theme) => ({
  root: {
      flexGrow: 1,
  },
  activeTab: {
      backgroundColor: '#F1EDFF',
  },
}));

const AdminCoupon = (props) => {
  const { applicationState, dispatch } = props;
  const { isLoading } =
    applicationState;
  const classes = useStyles();

  return (
    <div id="adminproductlist" className="admin">
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div className="container-fluid">
        <div>
          {" "}
          <NavigationBar />
        </div>
        <div className={classes.root}>
          <AdminCouponList dispatch={dispatch} applicationState={applicationState} />
        </div>
      </div>
    </div>
  );
};

export default AdminCoupon;
