import React from "react";

const WarehouseProductQuantity = ({ data, selectedWarehouse }) => {
  const { threshold, breakpoint } = data;
  const numberofstock = data.warehouse[selectedWarehouse];
  const flagColor =
    Number(numberofstock) > Number(threshold)
      ? "#3fdf2c"
      : Number(numberofstock) > Number(breakpoint)
      ? "#FFBF00"
      : "#d91c1c";
  return (
    <div style={{ width: "100px", textAlign: "right" }}>
      {data && data.warehouse ? data.warehouse[selectedWarehouse] : 0}

      <i className="fa fa-flag fa-2x" style={{ color: flagColor }}></i>
    </div>
  );
};

export default WarehouseProductQuantity;
