import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import OrderDetailsTab from './OrderDetailsTab';
import CustomerDetailsTab from './CustomerDetailsTab';
import ProductDetailsTab from './ProductDetailsTab';
import CommentDetailsTab from "./CommentDetailsTab";
import CloseIcon from '@material-ui/icons/Close';
import { fetchUpdateCommentsApiCall } from "../../../serviceCalls/fetchUpdateCommentsApiCall";
import { Spinner } from "react-bootstrap";

const CRMTabList = ({ refetchOrderData, orderInfo, showEmailPopUp, dispatch, history, config, onClose, commentsData, isSpinnerEnabled, showStatus, disableButtons}) => {
  const commenttab = (index) => {
    if(index === 3)  
    fetchUpdateCommentsApiCall({dispatch: dispatch, orderId: orderInfo?.id, fetchComment: true, authToken: config?.authToken, history: history})
  }
  return (
    <>
      <Tabs className="tab-container position-relative pt-4" onSelect={(index, params) => commenttab(index, params)}>
        <button className="close-button button-danger" onClick={onClose}>Close <CloseIcon style={{ width: 20 }} /></button>
        <TabList className="m-0">
          <Tab>Order Details</Tab>
          <Tab>Customer Details</Tab>
          <Tab>Products Ordered</Tab>
        </TabList>
        {/* Order Details */}
        <TabPanel className="tab-content">
          <OrderDetailsTab orderInfo={orderInfo} showEmailPopUp={showEmailPopUp} showStatus={showStatus} disableButtons={disableButtons}/>
        </TabPanel>
        {/* Customer Details */}
        <TabPanel className="tab-content">
          <CustomerDetailsTab orderInfo={orderInfo} showEmailPopUp={showEmailPopUp} disableButtons={disableButtons}/>
        </TabPanel>
        <TabPanel className="tab-content">
          <ProductDetailsTab
            orderInfo={orderInfo}
            showEmailPopUp={showEmailPopUp}
            dispatch={dispatch}
            history={history}
            config={config}
            onClose={onClose} 
            disableButtons={disableButtons}
            refetchOrderData={refetchOrderData}
            />
        </TabPanel>
      </Tabs>
      {isSpinnerEnabled && <div className="d-flex justify-content-center loader">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>}
    </>
  );
}
export default CRMTabList