import * as React from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Alert from "@material-ui/lab/Alert";
import {
  isValidName,
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from "../utils/regexUtils";
import Footer from "./common/Footer";
import { signUpApiCall } from "../serviceCalls/signUpApiCall";
import { SET_IS_LOADING } from "../constants/actionTypes";
import VerifyEmail from "./VerifyEmail";
import VerifyPhonenumber from "./VerifyPhonenumber";

const SignUp = (props) => {
  const history = useHistory();
  const { applicationState } = props;
  const [isSignUpDone, setIsSignUpDone] = React.useState(false);
  const { isLoading, signUpStatus, customerDetails } = applicationState;
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmailAddress] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phonenumber, setPhonenumber] = React.useState("");
  const [phonenumberError, setPhonenumberError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [idFile, setIdFile] = React.useState(null);
  const [idFileError, setIdFileError] = React.useState(null);
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");

  React.useEffect(() => {
    if (isSignUpDone && customerDetails?.emailverified && customerDetails?.smsverified) {
      history.push("/signin");
    }
  }, [isSignUpDone, customerDetails])

  const onNameChange = (e) => {


    let regex = /^[A-Za-z]+$/

    // check first char
    let first = e.target.value[0];

    if(first == undefined  && e.target.id === "floatingFirstName"){
      setFirstName("")
    }
    if(first == undefined  && e.target.id === "floatingLastName"){
      setLastName("")
    }


    if (regex.test(e.target.value) && e.target.id === "floatingFirstName") {
      setFirstName(e.target.value);
      setFirstNameError("");
    } else if (regex.test(e.target.value) && e.target.id === "floatingLastName") {
      setLastName(e.target.value);
      setLastNameError("");
    }
    else {

    }
    setEmptyCredentialsError("");
  };


  const validateName = (e) => {
    const { id, value } = e?.target;
    if (id === "floatingFirstName") {
      setFirstNameError(isValidName(value));
    } else {
      setLastNameError(isValidName(value));
    }
  };

  const validateEmail = (e) => {
    const { value } = e?.target;
    setEmailError(isValidEmail(value));
  };

  const validatePhonenumber = (e) => {
    const { value } = e?.target;
    setPhonenumberError(isValidPhone(value));
  };

  const onEmailChange = (e) => {
    setEmailAddress(e.target.value);
    setEmailError("");
  };

  const onPhonenumberChange = (e) => {
    setPhonenumber(e.target.value);
    setPhonenumberError("");
  };

  const validatePassword = (e) => {
    const { value } = e?.target;
    setPasswordError(isValidPassword(value));
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setEmptyCredentialsError("");
  };

  const validateIdFile = (file) => {
    setIdFileError(file ? null : 'Please provide ID document');
  }

  const onIdFileChange = (e) => {
    const file = e.target.files[0];
    setIdFile(file);
    validateIdFile(file);
  }

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const validateSignup = (e) => {
    if (firstNameError || lastNameError || passwordError || phonenumberError || emailError) {
      e.preventDefault();
    } else if (firstName === "" || lastName === "" || password === "") {
      e.preventDefault();
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try?"
      );
    } else if (!idFile) {
      validateIdFile(idFile);
    } else {
      e.preventDefault();
      props.dispatch({ type: SET_IS_LOADING, payload: true });
      signUpApiCall({
        dispatch: props.dispatch,
        history: history,
        firstName,
        lastName,
        email,
        phonenumber,
        password,
        idFile
      }).then(() => setIsSignUpDone(true));
    }
  };

  if (isSignUpDone && !customerDetails?.emailverified) {
    return <VerifyEmail email={email} {...props} />
  }

  if (isSignUpDone && !customerDetails?.smsverified) {

    return <VerifyPhonenumber email={email} mobileno={phonenumber} {...props} />
  }

  return (
    <div className="row">
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <header id="header" style={{background: 'none'}}>
          <img src="./GD LOGOS-01.jpeg" alt="" />
        </header>
        <div id="signup">
          {signUpStatus.signUpError && (
            <Alert severity="error">
              Somthing's gone wrong! Please try again.
            </Alert>
          )}
          <div role="main">
            <form method="post">
              <div className="form-floating mb-2">
                <input
                  className="form-control"
                  id="floatingFirstName"
                  onChange={(e) => onNameChange(e)}
                  onBlur={(e) => validateName(e)}
                  value={firstName}
                  placeholder="first name"
                  maxlength="25"
                  required
                />
                <label htmlFor="floatingFirstName">First Name</label>
              </div>
              {firstNameError ? (
                <span>
                  <div className="error">{firstNameError}</div>
                </span>
              ) : (
                <React.Fragment />
              )}
              <div className="form-floating mb-2">
                <input
                  id="floatingLastName"
                  className="form-control"
                  onChange={(e) => onNameChange(e)}
                  onBlur={(e) => validateName(e)}
                  value={lastName}
                  placeholder="last name"
                  maxlength="25"
                  required
                />
                <label htmlFor="floatingLastName">Last Name</label>
              </div>
              {lastNameError ? (
                <span>
                  <div className="error">{lastNameError}</div>
                </span>
              ) : (
                <React.Fragment />
              )}
              <div className="form-floating mb-2">
                <input
                  id="floatingEmail"
                  className="form-control"
                  onChange={(e) => onEmailChange(e)}
                  onBlur={(e) => validateEmail(e)}
                  value={email}
                  placeholder="email"
                  required
                />
                <label htmlFor="floatingEmail">Email</label>
              </div>
              {emailError ? (
                <span>
                  <div className="error">{emailError}</div>
                </span>
              ) : (
                <React.Fragment />
              )}
              <div className="form-floating mb-2">
                <input
                  id="floatingPhonenumber"
                  className="form-control"
                  onChange={(e) => onPhonenumberChange(e)}
                  onBlur={(e) => validatePhonenumber(e)}
                  value={phonenumber}
                  placeholder="Phone number"
                  required
                />
                <label htmlFor="floatingEmail">Phone Number</label>
              </div>
              {phonenumberError ? (
                <span>
                  <div className="error">{phonenumberError}</div>
                </span>
              ) : null}
              <div className="form-floating mb-3">
                <input
                  id="floatingPassword"
                  className="form-control"
                  type={passwordShown ? "text" : "password"}
                  onChange={(e) => onPasswordChange(e)}
                  onBlur={(e) => validatePassword(e)}
                  value={password}
                  placeholder="password"
                  required
                />
                <label htmlFor="floatingPassword" className="label">
                  Create your password
                </label>
                <i
                  className={`fa ${passwordShown ? `fa-eye-slash` : `fa-eye`
                    } sign-up-icon`}
                  onClick={() => togglePasswordVisiblity()}
                ></i>
              </div>
              {passwordError ? (
                <span>
                  <div className="error">{passwordError}</div>
                </span>
              ) : (
                <React.Fragment />
              )}
              <div className="form-floating mb-2">
                <input
                  type="file"
                  id="floatingIdFile"
                  className="form-control"
                  accept="image/*, application/pdf"
                  onChange={(e) => onIdFileChange(e)}
                  required
                />
                <label htmlFor="floatingIdFile">Upload Medical Insurance</label>
              </div>
              {idFileError ? (
                <span>
                  <div className="error">{idFileError}</div>
                </span>
              ) : (
                <React.Fragment />
              )}
              {emptyCredentialsError ? (
                <span>
                  <div className="error">{emptyCredentialsError}</div>
                </span>
              ) : (
                <React.Fragment />
              )}
              <div className="form">
                <button
                  className="btn btn-lg btn-main"
                  type="submit"
                  onClick={(e) => {
                    validateSignup(e);
                  }}
                >
                  Sign Up
                </button>
              </div>
              <div className="form">
                <button
                  className="btn-link"
                  type="submit"
                  onClick={() => {
                    history.push("/signin");
                  }}
                >
                  Already have an account? Login
                </button>
              </div>
            </form>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
