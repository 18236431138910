import * as React from "react";
import { useHistory } from "react-router-dom";
import { RESET_ALL_DATA, SET_TILE_CLICKED } from "../../constants/actionTypes";

const HeaderMenu = (props) => {
  const { cartCount, dispatch } = props;
  const history = useHistory();
  const logout = (e) => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      window.sessionStorage.clear();
      window.localStorage.clear()
      history.push("/");
      dispatch({
          type: RESET_ALL_DATA,
      });
    } else {
      e.preventDefault();
    }
  };

  const closeSideBar = () => {
    document.querySelector('#closeSideBarBtn').click();
  };

  return (

    <header>
        <div class="container">
            <div id="main-nav" class="">
                <div class="header_inner_d">
                    <nav class="navbar navbar-expand-lg ">
                       <button class="navbar-toggler" id="closeSideBarBtn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                           <svg class="visible-xs" width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.15625 16.9688H21.8438M2.15625 9.46875H21.8438M2.15625 1.96875H21.8438" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{flexGrow:1}}>
                             <div class="menuBack visible-xs" id="menuBack" onClick={() => closeSideBar()}>
                                <svg class="visible-xs" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_30_1513)">
                                <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9 6L6 9L9 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 9H6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_30_1513">
                                <rect width="18" height="18" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                                Back
                            </div>
                          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item active">
                                <a class="nav-link" href="javascript:void(0)"
                                  onClick={() => {
                                    dispatch({
                                      type: SET_TILE_CLICKED,
                                      payload: "",
                                    });
                                    history.push("/producttypes");
                                  }}
                                >
                                    <span>
                                    <svg class="visible-xs" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 2.25H1.5V6.75H2.25V15C2.25 15.3978 2.40804 15.7794 2.68934 16.0607C2.97064 16.342 3.35218 16.5 3.75 16.5H14.25C14.6478 16.5 15.0294 16.342 15.3107 16.0607C15.592 15.7794 15.75 15.3978 15.75 15V6.75H16.5V2.25ZM3 3.75H15V5.25H3V3.75ZM14.25 15H3.75V6.75H14.25V15ZM6.75 8.25H11.25C11.25 8.64782 11.092 9.02936 10.8107 9.31066C10.5294 9.59196 10.1478 9.75 9.75 9.75H8.25C7.85218 9.75 7.47064 9.59196 7.18934 9.31066C6.90804 9.02936 6.75 8.64782 6.75 8.25Z" fill="white"/>
                                    </svg>
                                    </span>
                                    <span>Products</span>
                                </a>
                            </li>
                            <li class="dropdown">
                                <a href="#" class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>
                                    <svg class="visible-xs" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 12.5C2 11.7044 2.31607 10.9413 2.87868 10.3787C3.44129 9.81607 4.20435 9.5 5 9.5H11C11.7956 9.5 12.5587 9.81607 13.1213 10.3787C13.6839 10.9413 14 11.7044 14 12.5C14 12.8978 13.842 13.2794 13.5607 13.5607C13.2794 13.842 12.8978 14 12.5 14H3.5C3.10218 14 2.72064 13.842 2.43934 13.5607C2.15804 13.2794 2 12.8978 2 12.5Z" stroke="white" stroke-width="2.5" stroke-linejoin="round"/>
                                    <path d="M8 6.5C9.24264 6.5 10.25 5.49264 10.25 4.25C10.25 3.00736 9.24264 2 8 2C6.75736 2 5.75 3.00736 5.75 4.25C5.75 5.49264 6.75736 6.5 8 6.5Z" stroke="white" stroke-width="2.5"/>
                                    </svg>
                                    </span>
                                    <span>My Profile</span>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a href="javascript:void(0)" onClick={() => history.push("/my_orders")}>My Orders</a></li>
                                    <li><a href="javascript:void(0)" onClick={() => history.push("/my_profile")}>Update Profile</a></li>
                                </ul>
                            </li>
                            <li class="nav-item" style={!cartCount ? { pointerEvents: "none" } : null}>
                                <a class="nav-link cartMenu" href="javascript:void(0)" onClick={() => history.push("/customercart_details")}>
                                    <span>
                                        <svg  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.75 12.5C11.9175 12.5 11.25 13.1675 11.25 14C11.25 14.3978 11.408 14.7794 11.6893 15.0607C11.9706 15.342 12.3522 15.5 12.75 15.5C13.1478 15.5 13.5294 15.342 13.8107 15.0607C14.092 14.7794 14.25 14.3978 14.25 14C14.25 13.6022 14.092 13.2206 13.8107 12.9393C13.5294 12.658 13.1478 12.5 12.75 12.5ZM0.75 0.5L0.75 2L2.25 2L4.95 7.6925L3.93 9.53C3.8175 9.74 3.75 9.9875 3.75 10.25C3.75 10.6478 3.90804 11.0294 4.18934 11.3107C4.47064 11.592 4.85218 11.75 5.25 11.75L14.25 11.75L14.25 10.25L5.565 10.25C5.51527 10.25 5.46758 10.2302 5.43242 10.1951C5.39725 10.1599 5.3775 10.1122 5.3775 10.0625C5.3775 10.025 5.385 9.995 5.4 9.9725L6.075 8.75L11.6625 8.75C12.225 8.75 12.72 8.435 12.975 7.9775L15.66 3.125C15.7125 3.005 15.75 2.8775 15.75 2.75C15.75 2.55109 15.671 2.36032 15.5303 2.21967C15.3897 2.07902 15.1989 2 15 2L3.9075 2L3.2025 0.5M5.25 12.5C4.4175 12.5 3.75 13.1675 3.75 14C3.75 14.3978 3.90804 14.7794 4.18934 15.0607C4.47064 15.342 4.85218 15.5 5.25 15.5C5.64782 15.5 6.02936 15.342 6.31066 15.0607C6.59196 14.7794 6.75 14.3978 6.75 14C6.75 13.6022 6.59196 13.2206 6.31066 12.9393C6.02936 12.658 5.64782 12.5 5.25 12.5Z" fill="white"/>
                                        </svg>
                                          <span className="position-absolute translate-middle badge rounded-pill bg-secondary">
                                          {cartCount}
                                        </span>
                                    </span>
                                    <span>Cart</span>
                                </a>
                            </li>
                          </ul>
                        </div>
                         <div class="overlay_nav"></div>
                    </nav>

                    <div class="site_account">
                        <div class="dropdown">
                            <div class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M35 19.9999C35 22.652 33.9464 25.1955 32.0711 27.0709C30.1957 28.9462 27.6522 29.9998 25 29.9998C22.3478 29.9998 19.8043 28.9462 17.9289 27.0709C16.0536 25.1955 15 22.652 15 19.9999C15 17.3478 16.0536 14.8042 17.9289 12.9289C19.8043 11.0536 22.3478 10 25 10C27.6522 10 30.1957 11.0536 32.0711 12.9289C33.9464 14.8042 35 17.3478 35 19.9999Z" fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.98 49.9795C10.6462 49.4445 -1.10073e-07 38.4646 -7.15412e-08 24.9997C-3.2029e-08 11.1924 11.1925 3.20293e-08 25 7.1542e-08C38.8075 1.11055e-07 50 11.1924 50 24.9997C50 38.8071 38.8075 49.9994 25 49.9994C24.8858 50.0002 24.7717 50.0002 24.6575 49.9994C24.4312 49.9994 24.205 49.9919 23.98 49.9795ZM8.9575 40.7745C8.77058 40.2378 8.70696 39.6658 8.77135 39.101C8.83575 38.5363 9.02651 37.9933 9.32947 37.5124C9.63244 37.0315 10.0398 36.625 10.5214 36.3231C11.003 36.0211 11.5464 35.8315 12.1112 35.7684C21.8563 34.6896 28.2037 34.7871 37.9012 35.7909C38.4669 35.8498 39.0116 36.037 39.494 36.3382C39.9763 36.6395 40.3836 37.0468 40.6847 37.5292C40.9858 38.0116 41.1729 38.5564 41.2316 39.1221C41.2904 39.6877 41.2193 40.2593 41.0238 40.7933C45.1799 36.5882 47.5074 30.9121 47.5 24.9997C47.5 12.5736 37.4263 2.49997 25 2.49997C12.5737 2.49997 2.5 12.5736 2.5 24.9997C2.5 31.1447 4.96375 36.7146 8.9575 40.7745Z" fill="white"/>
                                </svg>
                            </div>
                            <ul class="dropdown-menu" aria-labelledby="about-us">
                                <li><a href="javascript:void(0)" onClick={(e) => logout(e)}>Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  );
};
export default HeaderMenu;
