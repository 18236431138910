import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    EXISTING_CUSTOMER_DETAILS_USING_ID,
    EXISTING_CUSTOMER_DETAILS,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const fetchExistingCustomerUsingId = async ({
    dispatch,
    userId,
    config
}) => {
    const authToken = localStorage.getItem("sessionKey");
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({
        type: NO_DATA_FOUND,
        payload: { error: null } // Set error to null initially
    });
    const apiRequestHeader = makeApiRequestHeader(
        "GET",
        { "x-auth-token": config?.authToken || authToken },
        null
    );
    try {
        const apiResponse = await axios.get(
            `${envConfig.apiUrl}:${envConfig.apiPort}/user/${userId}`,
            apiRequestHeader
        );
        dispatch({
            type: EXISTING_CUSTOMER_DETAILS_USING_ID,
            payload: apiResponse.data,
        });
        dispatch({
            type: EXISTING_CUSTOMER_DETAILS,
            payload: apiResponse.data,
        });
    } catch (error) {
        const errorMessage = error.response?.data || "No profile found for user";
        dispatch({
            type: NO_DATA_FOUND,
            payload: { error: errorMessage }
        });
    } finally {
        dispatch({ type: SET_IS_LOADING, payload: false });
    }
};
