import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SUBMIT_TA_FORM_SUCCESS,  SUBMIT_TA_FORM_FAILED} from "../constants/actionTypes";
import { fetchCreditUserApiCall } from "../serviceCalls/fetchCreditUserApiCall";

import envConfig from "../envConfigurations";

export const submitTAFormApi = async ({
    dispatch,
    authToken,
    data,
    taemail
}) => {
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/submittaform`;
    await axios
        .post(apiUrl, data, apiRequestHeader)
        .then((response) => {
            dispatch({
                type: SUBMIT_TA_FORM_SUCCESS,
                payload: response.data
            })
            fetchCreditUserApiCall({
                dispatch: dispatch,
                authToken: authToken,
                email: taemail
            })
        })
        .catch(() => {
            dispatch({
                type: SUBMIT_TA_FORM_FAILED,
                payload: "Form Submission failed"
            })
        });
};
