import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    ADD_USER_DETAILS,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { getAllUserProfileApiCall } from "./getAllUserProfileApiCall"

export const addUserApiCall = async ({
    dispatch,
    requestBodyForAdd,
    config
}) => {
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({ 
        type: NO_DATA_FOUND, 
        payload: { error: ""}
    });
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": config.authToken },
        null
    );
    await axios
        .post(`${envConfig.apiUrl}:${envConfig.apiPort}/admin/add_profile`,
        requestBodyForAdd,apiRequestHeader)
        .then((apiResponse) => {
            console.log(apiResponse)
            dispatch({
                type: ADD_USER_DETAILS,
                payload:apiResponse.data,
                message:"User Profile Created"
            });
            getAllUserProfileApiCall({ dispatch: dispatch, config: config })
            alert(apiResponse.data)
            dispatch({ type: SET_IS_LOADING, payload: false });
        }).catch((error) => {
            dispatch({ 
                type: NO_DATA_FOUND, 
                payload: { error: error.response.data ?? "No User found for user" }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
