import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import AdminOrderDetailsModal from "./AdminOrderDetailsModal";
import CRMTabList from "../CRMTab/CRMTabList";
import {useEffect, useState} from "react";
import {getOrdersByEmail} from '../../../serviceCalls/getOrderListOfCustomerForAdmin';
import envConfig from "../../../envConfigurations"
import { makeApiRequestHeader } from "../../../utils/makeApiRequestHeader";
import axios from "axios";
import { CustomerEmailColumn } from "./CustomerEmailColumn";


const AdminCustomerOrderPharmaList = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const { crmDetails, config, customerList,pharmaCustList, config: { authToken }, commentsData } = applicationState;
  const [getCustomerOrderList, setCustomerOrderList] = useState([]);

  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);

  const [showModal, setShowModal] = React.useState(false);
  const [pharmaorderInfo, setOrderInfo] = React.useState("");

  const customerId = history?.location?.pathname?.split('/')?.[2];
  useEffect(() => {
    const customerDetails = pharmaCustList?.find(item => item?.id === customerId);
    loadOrders(customerDetails?.email).then(() => {});
  },[customerId, pharmaCustList]);

  const loadOrders =  async email => email && setCustomerOrderList(await getOrdersByEmail(email, authToken));

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const onEmailClicked = (params) => {
    onRowClicked(params)
  }

  const onRowClicked = async (params) => {
    let data;
    let apiRequestHeader = makeApiRequestHeader(
      "GET",
      { "x-auth-token": authToken }
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/orderidfetch?orderid=${params.data.orderid}&custemail=${params.data.email}`;
  
  await axios
        .get(apiUrl, apiRequestHeader)
        .then((apiResponse) => {
            setOrderInfo(apiResponse.data);
        })
        .catch((error) => {
            console.log('error ==> ',error);
        });
        setShowModal(true);
  };

  const frameWorkComponentChange = ({ api }) => {};


  const columnDefs = () => [
    { field: "email", headerName: "Email", cellRenderer: "emailcolumn"},
    { field: "orderid", headerName: "Order Id" },
    { field: "quantity", headerName: "Quantity" },
    { field: "orderDate", headerName: "Order placed on" },
    { field: "orderamount", headerName: "Total Amount", cellRenderer: "orderamount" },
    { field: "orderamountpaid", headerName: "Amount Paid", cellRenderer: "orderamountpaid" },
    { field: "balance", headerName: "Balance", cellRenderer: "balance" },
  ];

  const orderAmount = (params) => {
    return (
      <div className="orderamount">{`£${params.data.orderamount}`}</div>
    )
  };

  const orderAmountPaid = (params) => {
    return (
      <div className="orderamountpaid">{`£${params.data.orderamountpaid}`}</div>
    )
  };
  
  const balanceAmount = (params) => {
    return (
      <div className="balance">{`£${params.data.balance}`}</div>
    )
  };

  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#f7f7f7" };
    }
  };

  const onFilterTextBoxChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };

  return (
    <div className="admin crm" id="admincustorder">
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div className="container-fluid">
        <div className="image-overlap-container rounded">
          <div className="image-container">
            <img src="./world-map.png" />
          </div>
          <div className="pd-20 bg-main rounded">
            <div className="row form-card">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 px-0">
                <label>
                  <strong>Number Of Orders Made : </strong>{" "}
                  {getCustomerOrderList.numberoforders}
                </label>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 px-0">
                <label>
                  <strong>Total Amount: </strong> £
                  {getCustomerOrderList.totalamount}
                </label>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 px-0">
                <label>
                  <strong>Total Amount Paid : </strong> £
                  {getCustomerOrderList.totalamountpaid}
                </label>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 px-0">
                <label>
                  <strong>Balance : </strong> £
                  {(
                    Number(getCustomerOrderList.totalamount) -
                    Number(getCustomerOrderList.totalamountpaid)
                  ).toFixed(2)}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-4">
          <input
            className="search-bottom-margin mt-4"
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={(event) => onFilterTextBoxChanged(event)}
          />
          <div
            id="myGrid"
            className="ag-theme-alpine product-detail-table"
            style={{ height: "calc(100vh - 335px)",width: '100%'}}
          >
            <AgGridReact
              getRowStyle={getRowStyle}
              rowData={getCustomerOrderList?.customerorderlist}
              columnDefs={columnDefs()}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              paginationAutoPageSize={true}
              pagination={true}
              //onRowClicked={onRowClicked}
              frameworkComponents={{
                emailcolumn: CustomerEmailColumn,
                balance: balanceAmount,
                orderamount: orderAmount,
                orderamountpaid: orderAmountPaid
              }}
              context={{
                onEmailClicked: onEmailClicked,
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
      <AdminOrderDetailsModal
        title={`Order Id`}
        onClose={() => setShowModal(false)}
        show={showModal}
        orderInfo={pharmaorderInfo}
      >
        <CRMTabList 
          orderInfo={pharmaorderInfo}
          dispatch={dispatch}
          history={history}
          config={config}
          commentsData={commentsData}
          onClose={() => setShowModal(false)}
           />
      </AdminOrderDetailsModal>
    </div>
  );
};

export default AdminCustomerOrderPharmaList;
