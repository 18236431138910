import React from "react";
import { CSSTransition } from "react-transition-group";
import { SET_IS_LOADING } from "../../../constants/actionTypes";
import { deleteOrderApiCall } from "../../../serviceCalls/deleteOrderApiCall";

const DeleteCRMOrder = (props) => {
  const deleteOrder = () => {
    const { config, dispatch, dataForDeleteModal, onClose, history } = props;
    dispatch({ type: SET_IS_LOADING, payload: true });
    deleteOrderApiCall({
      dispatch: dispatch,
      authToken: config.authToken,
      orderid: dataForDeleteModal.cardId,
      history: history,
      config: config,
    });
    onClose();
  };
  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">{`${props.title} - ${props.dataForDeleteModal.cardId}`}</h4>
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer text-align-center">
            <button className="btn btn-main" onClick={deleteOrder}>
              Delete
            </button>
            <button
              onClick={props.onClose}
              className="btn floating-modal-btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default DeleteCRMOrder;
