import * as React from "react";
import DatePicker from "react-datepicker";
import GetAppIcon from '@material-ui/icons/GetApp';

import "react-datepicker/dist/react-datepicker.css";
import { downloadUserId } from "../../../serviceCalls/downloadUserId";

const UserProfileSection = (props) => {
  const {
    profileDetails,
    profileDetailsError,
    emptyCredentialsError,
    validateName,
    onNameChange,
    validateAddress,
    onAddressChange,
    validatePostcode,
    onPostcodeChange,
    validatePhone,
    onPhoneChange,
    onIdFileChange,
    setProfileDetails,
    validateSubmit,
    customerDetails,
  } = props;
  const maxDate = new Date();
  const greenStyle = {style: { color: "green"}};
  const redStyle = {style: { color: "red"}};
  const { smsverified, emailverified, userIdDoc, insuranceDocUrl } = customerDetails;
  const insuranceexpirydate = profileDetails?.insuranceexpirydate ? new Date(profileDetails?.insuranceexpirydate) : null;


  return (
    <>

      <div class="profile_header mb_30">
        Personal Details
      </div>
      <div class="PF_form_d personalDetailF mb_30">
          <form>
              <div class="twoBlock_d">
                  <div class="form_div">
                      <label for="firstName">First Name</label>
                      <input
                        type="text"
                        id="fname"
                        placeholder="First Name"
                        onChange={(e) => onNameChange(e)}
                        onBlur={(e) => validateName(e)}
                        value={profileDetails?.firstName}
                      />
                      {profileDetailsError.firstNameError ? (
                        <span>{profileDetailsError.firstNameError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                  </div>
                  <div class="form_div">
                      <label for="lastName">Last Name</label>
                      <input
                        type="text"
                        className=""
                        id="lname"
                        placeholder="Last Name"
                        onChange={(e) => onNameChange(e)}
                        onBlur={(e) => validateName(e)}
                        value={profileDetails?.lastName}
                      />
                      {profileDetailsError.lastNameError ? (
                        <span>{profileDetailsError.lastNameError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                  </div>
              </div>
              <div class="twoBlock_d">
                  <div class="form_div">
                      <label for="emailId">Email Id</label>
                      <input
                        type="email"
                        className={emailverified ? "emailReadOnly" : ""}
                        id="email"
                        placeholder="Email"
                        value={profileDetails?.email}
                        disabled
                      />
                  </div>
                  <div class="form_div">
                      <label for="PhoneNumber">Phone Number</label>
                      <div class="relative">
                        <input
                          type="text"
                          className={smsverified ? "emailReadOnly" : ""}
                          id="phoneNo"
                          placeholder="Phone Number"
                          onChange={(e) => onPhoneChange(e)}
                          onBlur={(e) => validatePhone(e)}
                          value={profileDetails?.phoneNo}
                          maxlength="11"
                        />
                      </div>
                  </div>
              </div>
              <div class="">
                  {/* <div class="form_div">
                      <label for="CustomerId">Customer Id</label>
                      <input
                        type="text"
                        className=""
                        id="instagramId"
                        placeholder="Customer Id"
                        disabled={true}
                        onChange={(e) =>
                          setProfileDetails({
                            ...profileDetails,
                            instagramId: e.target.value,
                          })
                        }
                        onBlur={(e) =>
                          setProfileDetails({
                            ...profileDetails,
                            instagramId: e.target.value,
                          })
                        }
                        value={profileDetails?.instagramId}
                      />
                  </div> */}
                  <div class="form_div">
                    <label for="addressLineOne">Address Line 1*</label>
                    {profileDetailsError.addressLineOneError ? (
                      <span className="error">
                      {profileDetailsError.addressLineOneError}
                      </span>
                    ) : (
                      <React.Fragment />
                    )}
                    <input
                      type="text"
                      name="addressLineOne"
                      id="addressLineOne"
                      placeholder="Address Line 1*"
                      className="form-control"
                      onChange={(e) => onAddressChange(e)}
                      onBlur={(e) => validateAddress(e)}
                      value={profileDetails?.addressLineOne}
                    />
                  </div>
              </div>
              <div class="twoBlock_d">
                <div class="form_div">
                    <label for="addressLineTwo">Address Line 2</label>
                    {profileDetailsError.addressLineTwoError ? (
                      <span className="error">
                      {profileDetailsError.addressLineTwoError}
                      </span>
                    ) : (
                      <React.Fragment />
                    )}
                    <input
                      type="text"
                      name="addressLineTwo"
                      id="addressLineTwo"
                      placeholder="Address Line 1"
                      className="form-control"
                      onChange={(e) => onAddressChange(e)}
                      onBlur={(e) => validateAddress(e)}
                      value={profileDetails?.addressLineTwo}
                    />
                  </div>
                  <div class="form_div">
                    <label for="city">City*</label>
                    {profileDetailsError.cityError ? (
                      <span className="error">
                      {profileDetailsError.cityError}
                      </span>
                    ) : (
                      <React.Fragment />
                    )}
                    <input
                      type="text"
                      name="city"
                      id="city"
                      placeholder="City"
                      className="form-control"
                      onChange={(e) => onAddressChange(e)}
                      value={profileDetails?.city}
                    />
                  </div>
              </div>
              <div class="twoBlock_d">
                  <div class="form_div">
                      <label for="Postcode">Postcode*</label>
                      <input
                        type="text"
                        className=""
                        id="postcode"
                        placeholder="Postcode"
                        onChange={(e) => onPostcodeChange(e)}
                        onBlur={(e) => validatePostcode(e)}
                        value={profileDetails?.postCode}
                      />
                      {profileDetailsError.postCodeError ? (
                        <span>{profileDetailsError.postCodeError}</span>
                      ) : (
                        <React.Fragment />
                      )}
                  </div>
                  <div class="form_div">
                      <label for="Address">Trade of business</label>
                      <select
                        className=""
                        id="tradeOfBusiness"
                        name="tradeOfBusiness"
                        onChange={(e) =>
                          setProfileDetails({
                            ...profileDetails,
                            tradeOfBusiness: e.target.value,
                          })
                        }
                        value={profileDetails?.tradeOfBusiness}
                      >
                        <option value="Mobile Practitioners">Mobile Practitioners</option>
                        <option value="Prescriber">Prescriber</option>
                        <option value="Clinics">Clinics</option>
                        <option value="Training Academy">Training Academy</option>
                      </select>
                  </div>
              </div>
              <div class="twoBlock_d">
                  <div class="form_div">
                      <label for="InsuranceExpiryDate">Insurance Expiry Date</label>
                      <DatePicker
                        name="insuranceexpirydate"
                        value={insuranceexpirydate}
                        selected={insuranceexpirydate}
                        onChange={date => setProfileDetails({ ...profileDetails, insuranceexpirydate: date })}
                      />
                  </div>
                  <div class="form_div">
                      <label for="UploadMedicalInsurance">Upload medical insurance</label>
                      <div className="form-floating">
                      <input
                        type="file"
                        id="floatingIdFile"
                        className="form-control"
                        accept="image/*, application/pdf"
                        onChange={(e) => onIdFileChange(e)}
                        required
                      />
                      {
                        insuranceDocUrl
                          ? <a target="_blank" href={insuranceDocUrl}><GetAppIcon /></a>
                          : null
                      }
                      </div>
                  </div>
              </div>
              {profileDetailsError.idFileError ? (
              <span>{profileDetailsError.idFileError}</span>
            ) : null}
              <div class="action_btn text-center mt_30">
              {emptyCredentialsError ? (
                <div className="mrb-20 error">{emptyCredentialsError}</div>
              ) : (
                <React.Fragment />
              )}
                  <button type="text" class="btn pink-btn" onClick={validateSubmit}>Update</button>
              </div>
          </form>
      </div>
    </>
  );
};

export default UserProfileSection;
