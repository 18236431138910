import React from 'react';

export const DeleteProductColumn = (params) => {

    const {  data,  onCardDelete} = params;
    return (
        <div>
            <>
                <button
                    type="button"
                    className="btn-icon"
                    onClick={() => onCardDelete(data.id, data.currentstage)}>

                    <i className="fa fa-trash icon-blue"></i>

                </button>


            </>
        </div>

    );
}