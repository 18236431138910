import * as actionTypes from "../constants/actionTypes";
import { tempData, customerList } from "../constants/temporaryData";

export const useStateManager = (
  state = {
    mobileView: false,
    drawerOpen: false,
    userDetails: {},
    stockOrders: {},
    productList: [],
    customerList: [],
    pharmaCustList: [],
    wholesaleCustList: [],
    cartDetails: [],
    customerDetails: {},
    shippingAddressDetails: {},
    billingAddressDetails: {},
    isSameAddress: true,
    orderDetails: [],
    config: {
      signInError: false,
      authToken: "",
    },
    isCartEmpty: true,
    isLoading: false,
    forgotPasswordError: "",
    totalAmount: 0,
    totalVatAmount: 0,
    subTotalAmount: 0,
    isBulkDiscount: false,
    shippingCost: 0,
    paymentMethod: "",
    adminPlaceOrder: [],
    signUpStatus: {
      signUpError: false,
    },
    addDeleteStatus: "",
    alertMessage: "",
    orderMessage: "",
    orderDeleteStatus: "",
    profileUpdateStatus: "",
    getCustomerOrderList: {},
    taProductRates: [],
    cppFinalDetails: {
      selectedProduct: [],
      totalBoxesPurchasedWeekly: 0,
      totalBoxesPurchasedMonthly: 0,
      effectiveCost: 0,
      totalTAProfitShare: 0,
      trainerName: "",
      trainingAcademy: "",
      noOfStudents: "",
      productsTrainedOn: "",
      noOfBoxes: "",
      minimumProfit: "1",
      overhead: "1",
      year1: 0,
      year2: 0,
      year3: 0,
    },
    crmDetails: [],
    instaCompletedOrders: [],
    shopifyCompletedOrders: [],
    pharmaCompletedOrders: [],
    storeCompletedOrders: [],
    commentsData: [],
    existingCustomerError: "",
    existingCustomerData: {
      firstname: "",
      lastname: "",
      instaname: "",
      mobileno: "",
      email: "",
      address: {
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        postcode: ""
      }
    },
    promotionalMessage: "",
    promocodeDiscount: null,
    promoCodeError: "",
    discountAmount: 0,
    promocode: "",
    cppList: [],
    updateCppMessage: "",
    cppStatus: "",
    cppCodeExist: "",
    reportsData: [],
    taAccountEnabled: false,
    tacredituser: [],
    updateSendCreditFormMessage: "",
    approvedCreditLimitMsg: "",
    savedTACreditMsg: "",
    taForm: {},
    gpReportsData: [],
    couponList: [],
    coupon: {}
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_MOBILE_VIEW:
      return {
        ...state,
        mobileView: action.payload,
      };
    case actionTypes.SET_SIGN_IN_DATA:
      return {
        ...state,
        config: {
          ...state.config,
          authToken: action.payload.authToken,
          email: action.email,
          userType: action.payload.userType,
          signInError: action.signInError,
        },
      };
    case actionTypes.SET_SIGN_UP_DATA:
      return {
        ...state,
        signUpStatus: action.payload,
        isLoading: false,
      };
    case actionTypes.SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
      };
    case actionTypes.SET_USER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
        orderDetails: action.orderDetails
          ? action.orderDetails
          : state.orderDetails,
        shippingAddressDetails: action.shippingAddressDetails,
        productList: action.productList,
        cartDetails: action.cartDetails,
        isLoading: false,
      };
    case actionTypes.UPDATE_USER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
        shippingAddressDetails: action.shippingAddressDetails,
        isLoading: false,
      };

    case actionTypes.DELETE_CUSTOMER:
      return {
        ...state,
        customerList: state.customerList.filter(c => c.email !== action.payload)
      };
    case actionTypes.SET_FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordError: action.payload,
        isLoading: false,
      };
    case actionTypes.EDIT_PRODUCT_QUANTITY:
      return {
        ...state,
        productList: action.payload,
        cartDetails: action.cartDetails ? action.cartDetails : [],
      };
    case actionTypes.SET_TILE_CLICKED:
      return {
        ...state,
        tileClicked: action.payload,
      };
    case actionTypes.PRODUCT_DETAIL_DATA:
      return {
        ...state,
        productDetailData: action.payload,
      };
    case actionTypes.IS_CART_EMPTY:
      return {
        ...state,
        isCartEmpty: action.payload,
      };
    case actionTypes.SET_CUSTOMER_BILLING_DETAILS:
      return {
        ...state,
        shippingAddressDetails: action.payload?.shippingAddressDetails,
        billingAddressDetails:
          action.payload?.billingAddressDetails || state.billingAddressDetails,
        isSameAddress: action.payload?.isSameAddress,
        cppCodeExist: action.payload?.cppCodeExist
      };
    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.SET_TOTAL_AMOUNT:
      return {
        ...state,
        subTotalAmount: action.payload.subTotalAmount,
        totalVatAmount: action.payload.totalVatAmount,
        totalAmount: action.payload.totalAmount,
        shippingCost: action.payload.shippingCost,
        warehouseAttr: action.payload.warehouseattr,
      };
    case actionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case actionTypes.UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: action.payload,
        isLoading: false,
      };
    case actionTypes.SET_USER_VERIFY_STATE:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          ...action.payload
        },
        isLoading: false,
      };
    case actionTypes.SET_KANBAN_DETAILS:
      return {
        ...state,
        crmDetails: action.payload,
        taProductRates: action.taProductRates,
        instaCompletedOrders: action.instaCompletedOrders,
        shopifyCompletedOrders: action.shopifyCompletedOrders,
        pharmaCompletedOrders: action.pharmaCompletedOrders,
        storeCompletedOrders: action.storeCompletedOrders,
        isLoading: false,
      };
    case actionTypes.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerList: action.customerList,
        pharmaCustList: action.pharmaCustList,
        wholesaleCustList: action.wholesaleCustList,
      };
    case actionTypes.ADMIN_ADD_ITEM_FOR_ORDER:
      return {
        ...state,
        adminPlaceOrder: action.payload.adminPlaceOrder,
        subTotalAmount: action.payload.subTotalAmount,
        totalVatAmount: action.payload.totalVatAmount,
        totalAmount: action.payload.totalAmount,
        shippingCost: action.payload.shippingCost,
        cartDetails: action.payload.adminPlaceOrder,
        isBulkDiscount: action.payload.isBulkDiscount
      };
    case actionTypes.CLEAR_ADD_ITEM_FOR_ORDER:
      return {
        ...state,
        adminPlaceOrder: [],
        subTotalAmount: 0,
        totalVatAmount: 0,
        totalAmount: 0,
        shippingCost: 0,
        cartDetails: [],
        isBulkDiscount: false
      };
    case actionTypes.SET_PROFILE_UPDATE_STATUS:
      return {
        ...state,
        profileUpdateStatus: action.payload,
      };
    case actionTypes.GET_CUSTOMER_ORDER_LIST:
      return {
        ...state,
        getCustomerOrderList: action.payload,
        isLoading: false,
      };
    case actionTypes.SET_CPP_DETAILS:
      return {
        ...state,
        cppFinalDetails: action.cppFinalDetails,
      };
    case actionTypes.SET_ADD_DELETE_NEW_PRODUCT:
      return {
        ...state,
        addDeleteStatus: action.payload,
        alertMessage: action.message,
      };
    case actionTypes.UPDATE_CUSTOMER_NOTIFICATION_STATUS:
      return {
        ...state,
        customerList: action.customerList,
      }
    case actionTypes.UPDATE_CUSTOMER_APPROVAL_STATUS:
      return {
        ...state,
        customerList: (state.customerList || [])
          .map(c => {
            if (c.email === action.payload.useremail) {
              return {
                ...c,
                userapproved: action.payload.userapprovalstatus
              }
            }

            return c;
          })
      }
    case actionTypes.SET_NOTIFICATION_SPINNER:
      return {
        ...state,
        isSpinnerEnabled: action.isSpinnerEnabled
      }
    case actionTypes.FETCH_COMMENTS_DATA:
      return {
        ...state,
        commentsData: action.payload.comments
      }
    case actionTypes.SET_ADD_DELETE_NEW_ORDER:
      return {
        ...state,
        orderDeleteStatus: action.payload,
        orderMessage: action.message,
      };
    case actionTypes.NO_DATA_FOUND:
      return {
        ...state,
        existingCustomerApiError: action.payload.error
      }
    case actionTypes.EXISTING_CUSTOMER_DETAILS:
      return {
        ...state,
        existingCustomerData: action.payload
      }
    case actionTypes.EXISTING_CUSTOMER_DETAILS_USING_ID:
      return {
        ...state,
        existingCustomerDataUsingId: action.payload
      }
    case actionTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      }
    case actionTypes.STOCK_ORDERS:
      return {
        ...state,
        stockOrders: action.payload
      }
    case actionTypes.CONTINUE_WITH_NEW_USER:
      return {
        ...state,
        existingCustomerData: action.payload
      }
    case actionTypes.PROMOTIONAL_EMAIL_MESSAGE:
      return {
        ...state,
        promotionalMessage: action.payload
      }
    case actionTypes.PROMOCODE_DISCOUNT:
      return {
        ...state,
        promocodeDiscount: action.payload,
        promoCodeError: action.promoCodeError,
        promocode: action.promocode
      }
    case actionTypes.DISCOUNT_AMOUNT:
      return {
        ...state,
        discountAmount: action.payload.discountAmount
      }
    case actionTypes.CPP_DISCOUNT:
      return {
        ...state,
        cppList: action.payload
      }
    case actionTypes.CPP_UPDATE:
      return {
        ...state,
        updateCppMessage: action.payload.message,
        cppStatus: action.payload.status
      }
    case actionTypes.FETCH_REPORTS_DATA:
      return {
        ...state,
        reportsData: action.payload
      }
    case actionTypes.TA_ACCOUNT_UPDATE:
      return {
        ...state,
        updateCppMessage: action.payload.message,
        taAccountEnabled: action.payload.status
      }
    case actionTypes.TA_CREDIT_USER_DETAILS:
      return {
        ...state,
        updateCppMessage: action.payload.message,
        tacredituser: action.payload
      }
      case actionTypes.ADD_USER_DETAILS:{
        return {
          ...state,
          addUser:action.payload
        }
      }
    case actionTypes.SEND_TA_CREDIT_SUCCESSED:
      return {
        ...state,
        updateSendCreditFormMessage: action.message
      }
    case actionTypes.TA_CREDIT_APPROVAL_SUCCESSED:
      return {
        ...state,
        approvedCreditLimitMsg: action.message
      }
    case actionTypes.SAVE_TA_CREDIT_SUCCESSED:
      return {
        ...state,
        savedTACreditMsg: action.message
      }
    case actionTypes.TA_CREDIT_DOC_UPLOAD_SUCCESSED:
      return {
        ...state,
        documentUpload: action.message
      }
    case actionTypes.TA_CREDIT_DOC_UPLOAD_FAILED:
      return {
        ...state,
        documentUpload: action.message
      }
    case actionTypes.TA_CREDIT_DOC_DELETE_SUCCESSED:
      return {
        ...state,
        documentDeleted: action.message
      }
    case actionTypes.TA_CREDIT_DOC_DELETE_FAILED:
      return {
        ...state,
        documentDeleted: action.message
      }
    case actionTypes.GET_TA_FORM_SUCCESS:
      return {
        ...state,
        taForm: action.payload
      }
    case actionTypes.GET_TA_FORM_FAILED:
      return {
        ...state,
        message: action.payload
      }
    case actionTypes.SUBMIT_TA_FORM_FAILED:
      return {
        ...state,
        taForm: action.payload
      }
    case actionTypes.UPDATE_ORDER_STATUS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.SUBMIT_TA_FORM_SUCCESS:
      return {
        ...state,
        message: action.payload
      }
    case actionTypes.SAVE_TA_CREDIT_SUCCESSED:
      return {
        ...state,
        message: action.payload
      }
    case actionTypes.FETCH_GP_REPORTS_DATA:
      return {
        ...state,
        gpReportsData: action.payload
      }
    case actionTypes.ADD_COUPON:
      return {
        ...state,
        coupon: action.payload,
      };
    case actionTypes.GET_ALL_COUPON:
      return {
        ...state,
        couponList: action.payload,
      };
    case actionTypes.DELETE_COUPON:
      return {
        ...state,
        couponList: state.couponList.filter(c => c.id !== action.payload)
      };
    case actionTypes.SET_ERROR:
    case actionTypes.RESET_ALL_DATA:
      return {
        "mobileView": false,
        "drawerOpen": false,
        "productList": [],
        "customerList": [],
        "pharmaCustList": [],
        "wholesaleCustList": [],
        "cartDetails": [],
        "customerDetails": {},
        "productDetailData": {},
        "shippingAddressDetails": {},
        "billingAddressDetails": {},
        "isSameAddress": true,
        "orderDetails": [],
        "config": {
          "signInError": false,
          "authToken": ""
        },
        "isCartEmpty": true,
        "isLoading": false,
        "forgotPasswordError": "",
        "totalAmount": 0,
        "totalVatAmount": 0,
        "subTotalAmount": 0,
        "isBulkDiscount": false,
        "shippingCost": 0,
        "paymentMethod": "",
        "adminPlaceOrder": [],
        "signUpStatus": {
          "signUpError": false
        },
        "addDeleteStatus": "",
        "alertMessage": "",
        "orderMessage": "",
        "orderDeleteStatus": "",
        "profileUpdateStatus": "",
        "getCustomerOrderList": {},
        "taProductRates": [],
        "cppFinalDetails": {
          "selectedProduct": [],
          "totalBoxesPurchasedWeekly": 0,
          "totalBoxesPurchasedMonthly": 0,
          "effectiveCost": 0,
          "totalTAProfitShare": 0,
          "trainerName": "",
          "trainingAcademy": "",
          "noOfStudents": "",
          "productsTrainedOn": "",
          "noOfBoxes": "",
          "minimumProfit": "1",
          "overhead": "1",
          "year1": 0,
          "year2": 0,
          "year3": 0
        },
        "crmDetails": [],
        "instaCompletedOrders": [],
        "shopifyCompletedOrders": [],
        "pharmaCompletedOrders": [],
        "storeCompletedOrders": [],
        "existingCustomerError": "",
        "existingCustomerData": {
          "firstname": "",
          "lastname": "",
          "instaname": "",
          "mobileno": "",
          "email": "",
          "address": {
               "addressLineOne": "",
               "addressLineTwo": "",
               "city": "",
               "postcode": ""
            }
          
        },
        "promotionalMessage": "",
        "promocodeDiscount": null,
        "promoCodeError": "",
        "discountAmount": 0,
        "promocode": "",
        "cppList": [],
        "updateCppMessage": "",
        "cppStatus": "",
        "cppCodeExist": "",
        "reportsData": [],
        "taAccountEnabled": false,
        "tacredituser": [],
        "updateSendCreditFormMessage": "",
        "approvedCreditLimitMsg": "",
        "savedTACreditMsg": "",
        "taForm": {},
        "gpReportsData": [],
        "couponList": [],
        "coupon": {}
      }
    case actionTypes.GET_SEARCH_EMAIL:
      return {
        ...state,
        getSearchEmail: action.payload
      }
    case actionTypes.GET_SEARCH_PRODUCT:
      return {
        ...state,
        getSearchProductValues: action.payload
      }
    default:
      return { state };
  }
};
