import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { updateCoupon } from "../../../serviceCalls/updateCouponApi";
import DatePicker from "react-datepicker";
import { Input, MenuItem, Select } from "@material-ui/core";

const AdminUpdateCouponModel = (props) => {
  const {
    config,
    dispatch,
    dataForUpdateModal,
    onClose,
    // history,
    showDeleteCouponModal,
    applicationState,
  } = props;

  const [newCouponDetails, setNewCouponDetails] = React.useState({});

  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");

  React.useEffect(() => {
    setNewCouponDetails({
      code: dataForUpdateModal?.code ?? "",
      couponType: dataForUpdateModal?.couponType ?? "",
      amount: Number(dataForUpdateModal?.amount).toFixed(2) ?? "",
      amountToApplyOn: Number(dataForUpdateModal?.amountToApplyOn) ?? "",
      description: dataForUpdateModal?.description ?? "",
      product: dataForUpdateModal?.product ?? [],
      usageCount: Number(dataForUpdateModal?.usageCount) ?? "",
      usageLimit: Number(dataForUpdateModal?.usageLimit) ?? "",
      expiryDate: dataForUpdateModal?.expiryDate ?? "",
    });
  }, [dataForUpdateModal]);

  const {
    code,
    couponType,
    amount,
    amountToApplyOn,
    description,
    product,
    usageCount,
    usageLimit,
    expiryDate,
  } = newCouponDetails;

  const onUpdate = () => {
    const requestBodyForEdit = {
      id: dataForUpdateModal.id,
      code,
      couponType,
      description,
      product,
      amount: Number(amount).toFixed(2),
      amountToApplyOn: Number(amountToApplyOn),
      usageCount: Number(usageCount),
      usageLimit: Number(usageLimit),
      expiryDate,
    };
    if (
      code === "" ||
      couponType === "" ||
      description === "" ||
      amountToApplyOn === 0 ||
      usageCount === 0 ||
      usageLimit === 0
    ) {
      setEmptyCredentialsError("All fields are required.");
    } else if (isNaN(amount)) {
      setEmptyCredentialsError("All fields are required.");
    } else {
      updateCoupon({
        dispatch: dispatch,
        requestBodyForEdit: requestBodyForEdit,
        config: config,
      });
      onClose();
    }
  };

  return (
    <>
      {props.dataForUpdateModal &&
        Object.keys(props.dataForUpdateModal).length > 0 && (
          <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
          >
            <div className="modal enter-done">
              <div
                className="modal-content"
                style={{ overflow: "auto", maxHeight: "600px" }}
              >
                <div className="modal-header">
                  <h4 className="modal-title">Edit Coupon Details</h4>
                </div>
                <div className="modal-body">
                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="code">
                      Code<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-data"
                      id="code"
                      placeholder="Code"
                      value={code}
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          code: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="couponType">
                      Coupon Type<span className="required">*</span>
                    </label>
                    <select
                      className="form-control form-data"
                      id="couponType"
                      name="couponType"
                      value={couponType}
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          couponType: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    >
                      <option value="">Please select</option>
                      <option value="Percentage Discount">
                        Percentage Discount
                      </option>
                      <option value="Fixed Cart Discount">
                        Fixed Cart Discount
                      </option>
                      <option value="Fixed Product Discount">
                        Fixed Product Discount
                      </option>
                    </select>
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="amount">
                      Coupon Amount<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control form-data"
                      id="amount"
                      placeholder="0.00"
                      step="0.01"
                      value={amount}
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          amount: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="amountToApplyOn">
                      Amount to be applied on<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-data"
                      id="amountToApplyOn"
                      placeholder="Coupon Amount Apply On"
                      value={amountToApplyOn}
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          amountToApplyOn: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="description">
                      Description<span className="required">*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control form-data"
                      id="description"
                      placeholder="description"
                      value={description}
                      maxLength={120}
                      rows="3"
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          description: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="product">
                      Select Product
                    </label>
                    <Select
                      className="form-control form-data"
                      multiple
                      value={product}
                      onChange={(event) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          product: event.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                      input={<Input label="Name" />}
                      disableUnderline
                      renderValue={(selected) => {
                        return (
                          <div>
                            {selected.map((productid, id) => {
                              const res = applicationState.productList.find(
                                (option) => option.productid === productid
                              );
                              return (
                                <span key={id}>
                                  {res.brand +
                                    " " +
                                    res.producttype +
                                    " " +
                                    res.productdesc}
                                  ,{" "}
                                </span>
                              );
                            })}
                          </div>
                        );
                      }}
                    >
                      {applicationState &&
                        applicationState.productList.map(
                          (
                            { brand, producttype, productdesc, productid },
                            id
                          ) => (
                            <MenuItem key={productid} value={productid}>
                              {`${brand} ${producttype} ${productdesc}`}
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="usageCount">
                      Usage<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-data"
                      id="usageCount"
                      placeholder="Usage"
                      value={usageCount}
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          usageCount: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="usageLimit">
                      Limit<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-data"
                      id="usageLimit"
                      placeholder="usageLimit"
                      value={usageLimit}
                      onChange={(e) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          usageLimit: e.target.value,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  <div className="form-group-coupon mb-3">
                    <label className="form-label" htmlFor="expiryDate">
                      Expiry Date<span className="required">*</span>
                    </label>
                    <DatePicker
                      name="expiryDate"
                      className="form-control form-data"
                      value={expiryDate}
                      // selected={expiryDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setNewCouponDetails({
                          ...newCouponDetails,
                          expiryDate: date,
                        });
                        setEmptyCredentialsError("");
                      }}
                    />
                  </div>

                  {emptyCredentialsError ? (
                    <span>
                      <div
                        className="error"
                        style={{ fontSize: "1.875em", marginBottom: "10px" }}
                      >
                        {emptyCredentialsError}
                      </div>
                    </span>
                  ) : (
                    <React.Fragment />
                  )}

                  <div className="modal-footer text-align-center">
                    <button
                      className="btn btn-main btn-update"
                      onClick={onUpdate}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-main btn-delete"
                      onClick={() => showDeleteCouponModal(true)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-main "
                      onClick={() => {
                        props.onClose();
                        setEmptyCredentialsError("");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        )}
    </>
  );
};
export default AdminUpdateCouponModel;