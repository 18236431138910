import * as React from "react";
import { fetchUpdateCommentsApiCall } from "../../../serviceCalls/fetchUpdateCommentsApiCall";

const CommentDetailsTab = ({comments, orderInfo, dispatch, authToken, history, email}) => {
    // console.log('comments :',comments);
    const addComment = document.getElementsByClassName("input-comment");
    if(addComment.length > 0 && addComment[0].value!== "")
        addComment[0].value = "";
    const addComments = () => {
        if(addComment.length > 0 && addComment[0]?.value!== "")
            fetchUpdateCommentsApiCall({dispatch: dispatch, orderId: orderInfo?.id, fetchComment: false, authToken: authToken, history: history, comment: addComment[0]?.value, commentaddedby: email})
    }
    return (<>
    <div className={`comment-component ${!!comments ? ``: `empty`}`}>
        {comments && comments.map((element, index) => {
            return <div className="comment-details">
                <div className={`comments-row ${index%2 ? `even` : `odd`}`}>
                    <div className="col-sm-12 col-xs-12">
                        <span>{element || "N/A"}</span>
                    </div>
                </div>
            </div>
        })}</div>
        <div className="add-comment pt-4 text-center">
            <input className="input-comment" />
            <button className="btn btn-main" onClick={addComments}>
                Add  Comment
            </button>
        </div>
    </>)
}

export default CommentDetailsTab