

import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_ERROR, SET_IS_LOADING, GET_CUSTOMER_ORDER_LIST } from "../constants/actionTypes";
import envConfig from "../envConfigurations";


export const getOrderListOfCustomerForAdmin = async ({
  dispatch,
  history,
  authToken,
  email
}) => {
    const apiRequestHeader = makeApiRequestHeader("GET", { "x-auth-token": authToken }, null);

  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/customer/list?custemail=${email}`;
  await axios
    .get(apiUrl, apiRequestHeader)
    .then((apiResponse) => {
      const customerorderlistarray = [];
      if(apiResponse.data.customerorderlist && apiResponse.data.customerorderlist.length > 0) {
        apiResponse.data.customerorderlist.forEach((customerOrder) => {
          customerorderlistarray.push({
            ...customerOrder,
            balance: Number(customerOrder.orderamount) - Number(customerOrder.orderamountpaid)
          })
        });
      }
      const customerOrderListData = {
        ...apiResponse.data,
        customerorderlist: customerorderlistarray
      }
      dispatch({ type: GET_CUSTOMER_ORDER_LIST, payload: customerOrderListData });
      history.push("/orders_by_customer")
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
};

export const getOrdersByEmail = async (email, authToken) => {
    try{
        const apiRequestHeader = makeApiRequestHeader("GET", { "x-auth-token": authToken }, null);
        const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/customer/list?custemail=${email}`;
        const apiResponse = await axios.get(apiUrl, apiRequestHeader);
        const ordersList = apiResponse?.data?.customerorderlist?.map(customerOrder => ({
            ...customerOrder,
            balance: Number(customerOrder?.orderamount) - Number(customerOrder?.orderamountpaid)
        }));
        const customerOrderListData = {
            ...apiResponse.data,
            customerorderlist: ordersList ? ordersList: []
        }
        return customerOrderListData;
    } catch(err) {
        console.error(`Error occurred while loading orders for email :${email}`);
        return [];
    }
}
