import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_SIGN_IN_DATA,
  SET_ERROR,
  SET_IS_LOADING,
} from "../constants/actionTypes";
import { getProductsApiCall } from "./getProductsApiCall";
import envConfig from "../envConfigurations";
import { USER_TYPE } from "../constants/common";

export const signInApiCall = async ({ dispatch, history, email, password }) => {
  const apiRequestHeader = makeApiRequestHeader("POST", null, null);
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/login`;
  await axios
    .post(
      apiUrl,
      {
        email,
        password,
      },
      apiRequestHeader
    )
    .then((apiResponse) => {
      window.sessionStorage.setItem(
        "sessionKey",
        apiResponse.data.body.authToken
      );
      window.sessionStorage.setItem("email", email);
      window.sessionStorage.setItem("password", password);
      window.sessionStorage.setItem("userType", apiResponse.data.body.userType);
      window.sessionStorage.setItem("counter", "1");
      // also using localstorage
      window.localStorage.setItem(
        "sessionKey",
        apiResponse.data.body.authToken
      );
      window.localStorage.setItem("email", email);
      window.localStorage.setItem("password", password);
      window.localStorage.setItem("userType", apiResponse.data.body.userType);
      window.localStorage.setItem("counter", "1");
      dispatch({
        type: SET_SIGN_IN_DATA,
        payload: apiResponse.data.body,
        signInError: false,
        email: email,
      });
      getProductsApiCall({
        dispatch,
        history,
        signInResponse: apiResponse.data.body,
        email,
        moveToNextPage: false
      });
      console.log("login");
      if (apiResponse.data.body.userType === USER_TYPE.PRESCRIBER) {
        history.push("/prescriber");
      } else if (apiResponse.data.body.userType === USER_TYPE.USER) {
        history.push("/producttypes");
      } else if (apiResponse.data.body.userType === USER_TYPE.ADMIN) {
        // history.push("/admin_product_list");
        history.push("/homepage");
      }
    })
    .catch((error) => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_SIGN_IN_DATA,
        payload: {},
        signInError: true,
      });
    });
};
