import * as React from "react";
import { useHistory } from "react-router-dom";
import { RESET_ALL_DATA } from "../../constants/actionTypes";

const Header = ({ cartCount, dispatch }) => {
    const history = useHistory();
    const logout = (e) => {
        const confirmLogout = window.confirm(
            "Are you sure you want to logout?"
        );
        if (confirmLogout) {
            window.sessionStorage.clear();
            window.localStorage.clear();
            window.location.replace("/");
            dispatch({
                type: RESET_ALL_DATA,
            });
            // history.push("/");
        } else {
            e.preventDefault();
        }
    };
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm navbar-fixed">
            <div className="container-fluid">
                <img src="./GD LOGOS-01.jpeg" alt="logo" className="logo" />
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_nav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                ></button>
                <button style={{color:"#172856",marginRight:20,fontWeight:"bold"}} onClick={(e) => logout(e)}>
                    Logout
                </button>
            </div>
        </nav>
    );
};
export default Header;

