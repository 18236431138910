import axios from "axios";
import fileSaver from "file-saver";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import envConfig from "../envConfigurations";

export const downloadDpdLabel = async (orderId, ordertype) => {

    let token = window.localStorage.getItem("sessionKey");

  let apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": token },
    null,
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/dpdLabel`;
  apiRequestHeader = {
    ...apiRequestHeader,
    responseType: 'blob'
  }

  await axios
    .post(apiUrl, {"orderid":orderId, "ordertype":ordertype},apiRequestHeader)
    .then((apiResponse) => {
     // const backendHtmlString = apiResponse.text()
      const { data } = apiResponse;
      const fileData = new Blob([data], {
        type: "application/html",
      });
      fileSaver.saveAs(fileData, "dpdLabel.html");
    })
    .catch((err) => {
      console.log(err)
    });
};
