import { useEffect, useState } from "react"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { logError } from "../../../utils/helper";

export const ScanProductButtonRenderer = (params) => {
    const [isScanning, setIsScanning] = useState(false);
    const [isProductScanned, setIsProductScanned] = useState(params?.data?.[params.tickKey] || false);

    const _setIsScanning = (status) => {
        if (window.__isScanning) return false;

        setIsScanning(status);
    }

    useEffect(() => {
        if (!isScanning || isProductScanned) return false;

        let barcode = "";
        const onProductScan = async (event) => {
            if (event.keyCode === 13) {
                try {
                    const resp = await params?.onScan(barcode);
                    if (resp?.data) {
                        setIsProductScanned(true);
                        params?.refetch();
                    }
                } catch(e) {
                    logError("failed to scan", e);
                }
                setIsScanning(false);
            }

            barcode += event.key
        };

        document.addEventListener('keydown', onProductScan);
        window.__isScanning = true;

        return () => {
            barcode = "";
            document.removeEventListener('keydown', onProductScan);
            window.__isScanning = false;
        };
    }, [isScanning]);

    if (isProductScanned) {
        return <CheckCircleIcon style={{ color: 'green' }} />
    }

    return (
        <button
            disabled={isScanning}
            onClick={() => _setIsScanning(true)}
            className="btn btn-tertiary scan-button"
        >Scan</button>
    )
}