import React, { useState } from "react";
import {
    Button
} from '@material-ui/core';
import { AgGridReact } from "ag-grid-react";
import { getAllUserProfileApiCall } from "../../../serviceCalls/getAllUserProfileApiCall"
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { DeleteUserColumn } from "./DeleteUserColumn";
import { UpdateUserColumn } from "./UpdateUserColumn";
import UpdateUserModal from "./UpdateUserModal";
import DeleteUserModal from "./DeleteUserModal";
import AddUserModal from "./AddUserModal";
import { useHistory } from "react-router-dom";

const UserProfile = ({ applicationState, dispatch, userDetails }) => {
    const {config} = applicationState
    const history = useHistory();
    React.useEffect(() => {
        getAllUserProfileApiCall({ dispatch: dispatch, config:config });
    }, [dispatch,config]);

    const [gridApi, setGridApi] = React.useState(null);
    const [gridColumnApi, setGridColumnApi] = React.useState(null);
    const [dataForUpdateModal, setDataForUpdateModal] = React.useState({});
    const [showUpdateModal, setUpdateUserModal] = React.useState(false);
    const [showDeleteModal, setDeleteUserModal] = React.useState(false);
    const [showAddModal, setAddUserModal] = React.useState(false);
    const [dataForDeleteModal, setDataForDeleteModal] = React.useState({});

    const columnDefs = () => [

        {
            field: "sno",
            headerName: "Sno.",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 150,
        },
        {
            field: "name",
            headerName: "Name",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 200,
        },
        {
            field: "email",
            headerName: "Email",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 195,
        },
        {
            field: "location",
            headerName: "Location",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
        },
        ,
        {
            field: "address",
            headerName: "Address",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
        },
        {
            field: "city",
            headerName: "City",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
        },
        {
            field: "postcode",
            headerName: "Post code",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
        },
        {
            field: "update",
            headerName: "Update",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
            cellRendererFramework: UpdateUserColumn,
        },
        {
            field: "delete",
            headerName: "Delete",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
            cellRendererFramework: DeleteUserColumn,
        },

    ];
    const rowData = () => {
        if (userDetails && userDetails?.length > 0) {
            userDetails.map((user, index) => {
                user.sno = index + 1
            })
            return userDetails;

        }
        else {
            return []
        }
    };
    const getRowStyle = (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return { background: "#F8F7FF" };
        }
    };
    const defaultColDef = React.useMemo(
        () => ({
            resizable: true,
            sortable: true,
        }),
        []
    );
    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };
    const showUpdateUserModal = (showModalValue) => {
        setUpdateUserModal(showModalValue);
    };

    const showDeleteUserModal = (showModalValue) => {
        setDeleteUserModal(showModalValue);
    };
    const showAddUserModal = (showModalValue) => {
        setAddUserModal(showModalValue);
    };
    const frameWorkComponentChange = ({ api, data, column, node, context }) => {
        setDataForUpdateModal(node.data);
        showUpdateUserModal(true);
    };

    const deleteComponentClick = ({ api, data, column, node, context }) => {
        setDataForDeleteModal(node.data);
        setDeleteUserModal(true);
    };
    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: `1140px`,
                width: "100%",
            }}
        >
            <div style={{ margin: "10px" }}>
                <Button variant="contained" color="primary"  onClick={() => showAddUserModal(true)}>
                    Add
                </Button>
            </div>

            <AgGridReact
                getRowStyle={getRowStyle}
                rowData={rowData()}
                columnDefs={columnDefs()}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                paginationPageSize={50}
                pagination={true}
                context={{
                    frameWorkComponentChange: frameWorkComponentChange,
                    deleteComponentClick: deleteComponentClick,
                    showUpdateUserModal: showUpdateUserModal,
                    showDeleteUserModal: showDeleteUserModal,
                }}
            ></AgGridReact>
            {userDetails && (
                <>
                    <UpdateUserModal
                        onClose={() => showUpdateUserModal(false)}
                        show={showUpdateModal}
                        dataForUpdateModal={dataForUpdateModal}
                        config={applicationState?.config}
                        history={history}
                        dispatch={dispatch}
                        showDeleteUserModal={showDeleteUserModal}
                    />
                    <DeleteUserModal
                        title="Delete User"
                        onClose={() => {
                            showDeleteUserModal(false);
                            showUpdateUserModal(false);
                        }}
                        show={showDeleteModal}
                        dataForDeleteModal={dataForDeleteModal}
                        config={applicationState?.config}
                        history={history}
                        dispatch={dispatch}
                    >
                        <p>
                            <strong>Are you sure you want to delete this User?</strong>
                        </p>
                    </DeleteUserModal>
                    <AddUserModal
                        onClose={() => showAddUserModal(false)}
                        show={showAddModal}
                        config={applicationState?.config}
                        history={history}
                        dispatch={dispatch}
                    /> 
                </>
            )}
        </div>
    )

}

export default UserProfile;