import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_SIGN_UP_DATA,
  SET_ERROR,
  SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const uploadUserDoc = (idFile, email) => {

  let token = window.localStorage.getItem("sessionKey");


  const formData = new FormData();
  formData.append('file', idFile);

  let apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": token, 'Content-Type': 'multipart/form-data' },
    null,
    null
  );

  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/uploadId?useremail=${email}`;
  
  apiRequestHeader = {
    ...apiRequestHeader,
    responseType: 'blob'
  }

  return axios
    .post(apiUrl, formData,apiRequestHeader)
    .then((apiResponse) => {
      console.log(apiResponse)
    })
    .catch((err) => {
      console.log(err)
    });
}

export const signUpApiCall = ({
  dispatch,
  history,
  firstName,
  lastName,
  email,
  password,
  phonenumber,
  idFile
}) => {
  const apiRequestHeader = makeApiRequestHeader("POST", null, null, null);
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/signup`;
  const requestBody = {
    address: {
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      postcode: ""
    },
    email,
    firstname: firstName,
    id: "abc",
    instaname: "sasbh",
    isuserloggedin: "assa",
    lastname: lastName,
    mobileno: phonenumber,
    password,
    profilepic: "na",
    roles: {
      id: "1234",
      role: "USER",
    },
    salt: "assa",
    tradeofbuisness: "Mobile",
    usercredebility: "as"
  };
  return axios
    .post(apiUrl, requestBody, apiRequestHeader)
    .then(apiResponse => {
      if (apiResponse.status === 200) {
        if (idFile) {
          return uploadUserDoc(idFile, email)
            .then(fileUploadResponse => {
              console.log('fileUploadResponse', fileUploadResponse);
              return Promise.resolve(apiResponse);
            })
            .catch(e => {
              console.log('Failed to Upload Medical Insurance document', e);
              return Promise.resolve(apiResponse);
            })
        }

        return Promise.resolve(apiResponse);
      }

      return Promise.reject(apiResponse);
    })
    .then((apiResponse) => {
      dispatch({
        type: SET_SIGN_UP_DATA,
        payload: {
          data: apiResponse.data,
        },
      });
      dispatch({ type: SET_IS_LOADING, payload: false });
      // history.push("/signin");

      return Promise.resolve(apiResponse);
    })
    .catch((e) => {
      console.log('signup failed', e);
      dispatch({
        type: SET_SIGN_UP_DATA,
        payload: { data: "", signUpError: true },
      });

      return Promise.reject(e);
    });
};
