import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_IS_LOADING,
  SET_ADD_DELETE_NEW_ORDER,
} from "../constants/actionTypes";
import { getProductsApiCall } from "./getProductsApiCall";
import envConfig from "../envConfigurations";

export const deleteOrderApiCall = async ({
  dispatch,
  authToken,
  orderid,
  history,
  config,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "DELETE",
    { "x-auth-token": authToken },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/deleteorder?orderid=${orderid}`;
  await axios
    .delete(apiUrl, apiRequestHeader)
    .then(() => {
      dispatch({
        type: SET_ADD_DELETE_NEW_ORDER,
        payload: true,
        message: "Order deleted successfully.",
      });
      getProductsApiCall({
        dispatch,
        history,
        signInResponse: config,
        email: null,
        moveToNextPage: false
      });
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ADD_DELETE_NEW_ORDER,
        payload: true,
        message: "There was some error while deleting the order."
      });
    });
};
