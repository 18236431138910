import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { SUPPORTED_WAREHOUSE } from "../../../constants/common";
import { StockOrdDropdownEditor } from "../StockManagement/StockOrdDropdownEditor";
import { ViewOrderColumn } from "./ViewOrderColumn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
    SET_IS_LOADING,
  } from "../../../constants/actionTypes";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { getAllStockOrders } from "../../../serviceCalls/getAllStockOrders";
import { AddStockOrder } from "../../../serviceCalls/AddStockOrder";
import OrderModal from "./OrderModal";
import OrderTabList from "./OrderTabList";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    FormControl,
    Card,
    CardContent,
    Button,
    Grid,
} from '@material-ui/core';

import {updateStockOrderStatusApi} from '../../../serviceCalls/updateStockOrderStatusApi';

import { makeStyles } from '@material-ui/core/styles';
import DeleteOrderModal from "./DeleteOrderModal";
import { DeleteUserColumn } from "./DeleteUserColumn";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const Stock = ({productList, applicationState, dispatch, stockOrders }) => {
    const classes = useStyles();
    const [gridApi, setGridApi] = React.useState(null);
    const [orderInfo, setOrderInfo] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [gridColumnApi, setGridColumnApi] = React.useState(null);
    const [formDataArray, setFormDataArray] = useState({ warehouse: '', shipping: '', products : [{product: '', quantity: ''}] });
    const [formValidations, setFormValidations] = useState(false);
    const [showDeleteModal, setDeleteOrderModal] = React.useState(false);
    const [dataForDeleteModal, setDataForDeleteModal] = React.useState({});


    const {config, isSpinnerEnabled, isLoading} = applicationState

    const fetchAllStockOrders = () => {
        getAllStockOrders({ dispatch, config });
    };

    React.useEffect(() => {
        fetchAllStockOrders();
    }, [dispatch, config]);

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newDataArray = [...formDataArray.products];
        newDataArray[index][name] = value;

        setFormDataArray((prev)=>({
            ...prev,
            warehouse:prev.warehouse,
            shipping:prev.shipping,
            products:newDataArray
        }));

    };

    const handleAddEntry = () => {
        setFormDataArray((prev)=>({
            ...prev,
            warehouse:prev.warehouse,
            shipping:prev.shipping,
            products:[...prev.products, { product: '', quantity: '' }]
        }));
    };

    const submitOrderStocks = () => {

        let validForm = true;

        if((formDataArray.warehouse === '' || formDataArray.warehouse === null)  || (formDataArray.shipping === '' || formDataArray.shipping === null)) {
            validForm = false;
        }

        const productiIdQuantitymap = {};

        for(const key of formDataArray.products) {
            productiIdQuantitymap[key.product] = key.quantity;
            if((key.product === '' || key.product === null)  || (key.quantity === '' || key.quantity === null)) {
                validForm = false;
            }
        }


        if(validForm) {
            let formdata = {
                location : formDataArray.warehouse,
                requestor : config.email,
                shippingcost : formDataArray.shipping,
                productiIdQuantitymap
            };

            let orderSuccess = AddStockOrder({
                dispatch: dispatch,
                authToken: config.authToken,
                requestBodyForAdd: formdata,
                config: config,
            });

            if(orderSuccess) {
                setFormDataArray({ warehouse: '', shipping: '', products : [{product: '', quantity: ''}] });
                toast("Stock order added successfully.");
            }
            setFormValidations(false);
        }
        else {
            setFormValidations(true);
        }
    }

    const handleRemoveEntry = (index) => {
        if (index !== 0) {
            const newDataArray = [...formDataArray.products];
            newDataArray.splice(index, 1);
            setFormDataArray((prev)=>({
                ...prev,
                warehouse:prev.warehouse,
                shipping:prev.shipping,
                products:newDataArray
            }));
        }
    };

    const handleWarehouseChange = (val) => {
        setFormDataArray((prev)=>({
            ...prev,
            warehouse:val,
        }));
    }

    const handleShippingChange = (val) => {
        setFormDataArray((prev)=>({
            ...prev,
            shipping:val,
        }));
    }

    const onCardClick = (cardId, laneId) => {
        setOrderInfo(stockOrders.find((crmElement) => crmElement.id === cardId));
        setShowModal(true);
    };

    const columnDefs = () => [
        {
            field: "location",
            headerName: "Location",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            sortable: false
        },
        {
            field: "name",
            headerName: "Admin Name",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            sortable: false
        },
        {
            field: "order",
            headerName: "View Order",
            cellRendererFramework: ViewOrderColumn,
            cellRendererParams: {
              onCardClick: onCardClick,
            },
            cellStyle: { color: "#31A191", fontSize: "14px" },
            sortable: false
        },
        {
            field: "status",
            headerName: "Order Status",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            cellRenderer: "customDropdownEditor",
            sortable: false
        },
        {
            field: "delete",
            headerName: "Delete",
            cellStyle: { color: "#31A191", fontSize: "14px" },
            width: 180,
            cellRendererFramework: DeleteUserColumn,
        },
    ];

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    };

    const rowData = () => {
        if (stockOrders && stockOrders?.length > 0) {
            stockOrders.map((order, index) => {
                order.sno = index + 1;
                order.location = order.userprofile ? order.userprofile.location : '';
                order.name = order.userprofile ? order.userprofile.name : '';
                order.order = order.stockOrderdetailList;
                order.delete = order.id;
                return order;
            })
            stockOrders = [...stockOrders].reverse();
            return stockOrders;

        }
        else {
            return []
        }
    };
    const getRowStyle = (params) => {
        if (params.node.rowIndex % 2 === 0) {
            return { background: "#F8F7FF" };
        }
    };
    const defaultColDef = React.useMemo(
        () => ({
            resizable: true,
            sortable: false,
        }),
        []
    );

    const onDataUpdated = (updatedData, index) => {
        console.log(updatedData, index);

        dispatch({ type: SET_IS_LOADING, payload: true });
        const requestBodyForAdd = {
            "stockorderid": index,
            "status": updatedData,
            "updatedby": config.email
        };

        updateStockOrderStatusApi({
            dispatch: dispatch,
            authToken: config.authToken,
            requestBodyForAdd: requestBodyForAdd,
            config: config
        });
    };

    const showDeleteOrderModal = (showModalValue) => {
        setDeleteOrderModal(showModalValue);
    };

    const deleteComponentClick = ({ api, data, column, node, context }) => {
        setDataForDeleteModal(node.data);
        setDeleteOrderModal(true);
    };

    return (
        <>
            <div style={{ borderRadius: '15px', backgroundColor: '#F1EDFF', padding: '10px', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card variant="outlined" style={{ borderRadius: '15px', boxShadow: '10px 10px 6px -4px rgba(204,198,204,1)', backgroundColor: '#fff' }}>
                    <CardContent>
                        <FormControl fullWidth>
                            <div className="form-group mb-3">
                                <label className="form-label" htmlFor="productStatus">
                                    Warehouse
                                </label>
                                <select
                                    className="form-control form-data"
                                    name="warehouse"
                                    value={formDataArray.warehouse}
                                    onChange={(e) => handleWarehouseChange(e.target.value)}
                                >
                                <option value="">Please select</option>
                                {SUPPORTED_WAREHOUSE.LIST.map((o) => (
                                    <option key={o} value={o}>
                                    {o}
                                    </option>
                                ))}
                                </select>
                            </div>

                            <div className="form-group mb-3">
                                <label className="form-label" htmlFor="productStatus">
                                    Shipping Cost
                                </label>
                                <select
                                    className="form-control form-data"
                                    id="shipping"
                                    name="shipping"
                                    onChange={(e) => handleShippingChange(e.target.value)}
                                    value={formDataArray.shipping}
                                >
                                    <option value="">Please select</option>
                                    <option data-warehouse='Click and Collect' value="0">Click and collect £ 0</option>
                                    <option data-warehouse='Next day delivery' value="9.95">Next day delivery £ 9.95</option>
                                    <option data-warehouse='Nextday by 12pm' value="15.95">Next day by 12pm £ 15.95</option>
                                    <option data-warehouse='Nextday by 10:30am' value="19.95">Next day by 10.30am £ 19.95</option>
                                    <option data-warehouse='Saturday Delivery' value="14">Saturday Delivery - £ 14
                                    (If delivery isn't completed we won't be responsible for any fridge lines)</option>
                                </select>
                            </div>
                        </FormControl>

                        <ToastContainer />

                        {formDataArray.products.map((formData, index) => (
                            <Grid container spacing={2} key={index} alignItems="flex-end">
                                <Grid item lg={6}>
                                    <div className="form-group mb-3">
                                        <label className="form-label" htmlFor="productStatus">
                                            Product
                                        </label>
                                        <select
                                            className="form-control form-data"
                                            name="product"
                                            value={formData.product}
                                            onChange={(e) => handleInputChange(index, e)}
                                        >
                                            <option value="">Please select</option>
                                            {productList?.sort((a, b) => a.brand.localeCompare(b.brand))?.map((product) => {
                                                const isProductInStock = product.warehouse && product.warehouse[formDataArray.warehouse] > 0;
                                                return (
                                                <option
                                                    value={product.productid}
                                                    disabled={!isProductInStock}
                                                >{`${product.brand} ${product.producttype} ${product.productdesc}`}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </Grid>
                                <Grid item lg={4}>
                                    <FormControl fullWidth>
                                        <div className="form-group form-margin mb-3">
                                            <label className="form-label" htmlFor="productPrice">
                                                Qty
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control form-data"
                                                id="productPrice"
                                                name="quantity"
                                                value={formData.quantity}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </div>
                                    </FormControl >
                                </Grid>
                                <Grid item lg={2}>
                                    {index !== 0 && (
                                        <div className="form-group ">

                                            <IconButton aria-label="delete"  onClick={() => handleRemoveEntry(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                        <div className={classes.root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="contained" color="primary" onClick={handleAddEntry}>
                                Add Product
                            </Button>
                            <Button variant="contained" color="primary" onClick={submitOrderStocks}>
                                Request Order
                            </Button>
                        </div>
                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} class="error">{formValidations ? 'Please fill in the empty fields.' : ''}<br /></span>

                    </CardContent>
                </Card>
            </div>
            <div
                    className="ag-theme-alpine custom-stock-order-grid"
                    id="custom-grid"
            >
                <AgGridReact
                    getRowStyle={getRowStyle}
                    rowData={rowData()}
                    columnDefs={columnDefs()}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    paginationPageSize={50}
                    pagination={true}
                    frameworkComponents={{
                        customDropdownEditor: StockOrdDropdownEditor,
                    }}
                    context={{
                        onDataUpdated,
                        deleteComponentClick: deleteComponentClick,
                    }}
                ></AgGridReact>
            </div>

            <DeleteOrderModal
                title="Delete Order"
                onClose={() => {
                    showDeleteOrderModal(false);
                }}
                show={showDeleteModal}
                dataForDeleteModal={dataForDeleteModal}
                config={applicationState?.config}
                dispatch={dispatch}
            >
                <p>
                    <strong>Are you sure you want to delete this Order?</strong>
                </p>
            </DeleteOrderModal>

            <OrderModal
                title={'View Order'}
                onClose={() => setShowModal(false)}
                show={showModal}
                orderInfo={orderInfo}
                >
                <OrderTabList
                    orderInfo={orderInfo}
                    dispatch={dispatch}
                    config={config}
                    onClose={() => setShowModal(false)}
                    isSpinnerEnabled={isSpinnerEnabled}
                    fetchAllStockOrders={fetchAllStockOrders}
                />
            </OrderModal>
        </>



    )

}

export default Stock;

