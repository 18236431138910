
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Modal from 'react-modal';

import { getTransactionReport, updateTransaction } from "../../../serviceCalls/transactionReport";
import { fetchUpdateCommentsApiCall } from "../../../serviceCalls/fetchUpdateCommentsApiCall";

const paymentOptions = {
  TACREDIT: "TACREDIT",
  BANK_TRANSFER: "BANK TRANSFER",
  CASH: "CASH",
  CARD: "CARD",
  PAYPAL: "PAYPAL",
  OTHER: "OTHER",
};

const customStyles = {
  content: {
    width: "350px",
    minHeight: "200px",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const BankingAndFinance = (props) => {
  const now = moment();
  const startDateM = now.clone().subtract(1, 'month');
  const endDateM = now.clone();
  const gridApi = useRef(null);
  const { dispatch, applicationState: { config, commentsData } } = props;
  const customerNameFieldInstance = useRef(null);
  const paymentFieldInstance = useRef(null);
  const dateFieldInstance = useRef(null);
  const [startDate, setStartDate] = useState(startDateM.toDate());
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [newComment, onCommentChange] = useState("");
  const [endDate, setEndDate] = useState(endDateM.toDate());
  const [transactiontype, setPaymentType] = useState(paymentOptions.CASH);
  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [transactionRecords, setTransactionRecords] = useState([]);
  const [fetchingTransactionRecords, setFetchingTransactionRecords] = useState(true);
  const fetchTransactionRecords = (payload) => {
    getTransactionReport(payload)
      .then(resp => {
        const { data } = resp;
        setTransactionRecords(data);
        setFetchingTransactionRecords(false);
      })
      .catch(e => {
        setFetchingTransactionRecords(false);
      })
  };

  useEffect(() => {
    fetchTransactionRecords({
      startdate: startDateM.format('DD-MM-YYYY'),
      enddate: endDateM.format('DD-MM-YYYY'),
      transactiontype
    });
  }, []);

  useEffect(() => {
    if (!startDate || !endDate || !transactiontype) return false;

    fetchTransactionRecords({
      startdate: moment(startDate).format('DD-MM-YYYY'),
      enddate: moment(endDate).format('DD-MM-YYYY'),
      transactiontype
    })
  }, [startDate, endDate, transactiontype]);

  const getRowStyle = (params) => {
    if (params.data.status) {
      return { background: '#bcf2bc' };
    }

    return params.node.rowIndex % 2 === 0
      ? { background: "#fff" }
      : { background: "#a8b4c6" };
  };

  const columnDefs = [
    { field: "orderid", headerName: "Order ID", },
    { field: "customername", headerName: "Customer Name" },
    { field: "paymentmethod", headerName: "Payment" },
    { field: "amount", headerName: "Amount" },
    { field: "comments", headerName: "Comment", cellRenderer: "commentsRenderer" },
    { field: "done", headerName: "Done", cellRenderer: "doneRenderer", cellRendererParams: { transactionRecords }},
    { field: "partialpayment", headerName: "Partial Payments" }
  ];

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true
    }),
    []
  );

  const onGridReady = (params) => {
    gridApi.current = params.api;
    customerNameFieldInstance.current = gridApi.current.getFilterInstance('customername');
    paymentFieldInstance.current = gridApi.current.getFilterInstance('paymentmethod');
    dateFieldInstance.current = gridApi.current.getFilterInstance('date');
    params.api.sizeColumnsToFit();
  };

  const changeTransactionStatus = (updatedRecord, records) => {
    updateTransaction(updatedRecord)
      .then(resp => {
        const { data } = resp;
        setTransactionRecords(
          records.map(t => t.orderid === updatedRecord.orderid ? data : t)
        )
      })
      .catch(e => {
        console.error('updateTransaction', e);
        toast('Failed to update record')
      })
  };

  const commentsRenderer = (props) => {
    const { data } = props;

    return <Button className="m-0 p-1" onClick={() => _showCommentModal(data.orderid)}>View</Button>;
  };

  const doneRenderer = (props) => {
    const { data } = props;
    return (
      <input
        type="checkbox"
        style={{ width: 20, height: 20 }}
        checked={!!data.status}
        onChange={(e) => changeTransactionStatus({
          ...data,
          status: e.target.checked
        }, props.transactionRecords)}
      />
    );
  };

  const onSearchByName = e => {
    const search = e.target.value;
    customerNameFieldInstance.current.setModel({
      filterType: 'text',
      type: 'contains',
      filter: search,
      filterParams: {
        caseSensitive: false
      }
    });
    gridApi.current.onFilterChanged();
  }

  const onSearchByPayment = e => {
    const search = e.target.value;
    setPaymentType(search);
  }

  const _showCommentModal = (orderId) => {
    fetchUpdateCommentsApiCall({
      dispatch,
      orderId,
      fetchComment: true,
      authToken: config?.authToken
    })
      .then(() => {
        setShowCommentModal(orderId);
      });
  }

  const addComment = () => {
    fetchUpdateCommentsApiCall({
      dispatch,
      orderId: showCommentModal,
      comment: newComment,
      commentaddedby: config.email,
      authToken: config?.authToken
    })
      .then(() => {
        onCommentChange("");
      })
  };

  return (
    <div>
      {fetchingTransactionRecords && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <Modal
        isOpen={showCommentModal}
        onRequestClose={() => setShowCommentModal(false)}
        contentLabel="Order Comments"
        style={customStyles}
      >
        <div>
          <ListGroup style={{ maxHeight: 200, overflowY: 'auto' }}>
            {
              (commentsData || []).map(c => (
                <ListGroup.Item key={c}>{c}</ListGroup.Item>
              ))
            }
          </ListGroup>
          {(commentsData || []).length ? <hr /> : null}
        </div>
        <div>
          <div>
            <label for="floatingInput">Comment</label>
            <input
              type="text"
              value={newComment}
              placeholder="new comment"
              onChange={e => onCommentChange(e.target.value)}
            />
          </div>
          <Button disabled={!newComment || !newComment.length} onClick={addComment}>Add Comment</Button>
        </div>
      </Modal>
      <div className="row">
        <div className="col-sm-3">
          <label for="floatingInput">Customer Name</label>
          <input
            placeholder="Customer Name"
            onChange={onSearchByName}
          />
        </div>
        <div className="col-sm-3">
          <label for="floatingInput">Purchase Type</label>
          <select value={transactiontype} className="form-select pl-2" onChange={onSearchByPayment}>
            {
              Object.values(paymentOptions).map(pType => (
                <option value={pType}>{pType}</option>
              ))
            }
          </select>
        </div>
        <div className="col-sm-6">
          <label for="floatingInput">Date Range</label>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onDateRangeChange}
            selectsRange
            isClearable={false}
          />
        </div>
      </div>
      <div className="ag-theme-alpine m-3" style={{ height: 'calc(100vh - 300px)'}}>
        <AgGridReact
          getRowStyle={getRowStyle}
          rowData={transactionRecords}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          paginationAutoPageSize={true}
          pagination={true}
          frameworkComponents={{
            doneRenderer,
            commentsRenderer
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}

export default BankingAndFinance;
