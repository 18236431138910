import React from 'react';

export const ViewProductColumn = (params) => {
// console.log(params);
    const {  data,  onCardClick} = params;
    // console.log(data);
    return (
        <div>
            <>
                <button
                    type="button"
                    className="btn-icon"
                    onClick={() =>{ onCardClick(data.id, data.currentstage)}}>

                    <i className="fa fa-search icon-blue"></i>

                </button>


            </>
        </div>

    );
}