import React from 'react';
import { CSSTransition } from "react-transition-group";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const checkFields = [
	"soleTrader", 
	"partnership", 
	"plcCompany", 
	"llp", 
	"post",
	 "phone", 
	 "whatsApp", 
	 "sms", 
	 "email",
	 "creditLimit1",
	 "creditLimit3",
	 "creditLimit5",
	 "creditLimit7"
]

const CreditFormModal = (props) => {
    const [emailId, setEmailId] = React.useState(props.showCreditFormModal);
	const [dateOfBirth1, setDateOfBirth1] = React.useState(new Date());
	const [dateOfBirth2, setDateOfBirth2] = React.useState(new Date());
	const [dateOfBirth3, setDateOfBirth3] = React.useState(new Date());
	const [dateOfBirth4, setDateOfBirth4] = React.useState(new Date());
	const [state, setState] = React.useState(props.taFormDetail);

	React.useEffect(() => {
		setState(props.taForm);
	}, [props.taForm])

	const handleTaCompanydetailsChange = e => {
		let { name, value } = e.target;
		if (checkFields.indexOf(name) > -1) {
			value = e.target.checked;
		}
        setState(prevState => ({
            ...prevState,
            tacompanydetails: {
				...prevState.tacompanydetails,
				[name]: value
			}
        }));
	}

	const handleTaimportantsectionChange = e => {
		let { name, value } = e.target;
		if (checkFields.indexOf(name) > -1) {
			value = e.target.checked;
		}
        setState(prevState => ({
            ...prevState,
            taimportantsection: {
				...prevState.taimportantsection,
				[name]: value
			}
        }));
	}

	const handleTadDrectorsChange = e => {
		let { name, value } = e.target;
		if (checkFields.indexOf(name) > -1) {
			value = e.target.checked;
		}
        setState(prevState => ({
            ...prevState,
            tadirectors: {
				...prevState.tadirectors,
				[name]: value
			}
        }));
	}

	const handleTaDataProtectionChange = e => {
		let { name, value } = e.target;
		if (checkFields.indexOf(name) > -1) {
			value = e.target.checked;
		}
        setState(prevState => ({
            ...prevState,
            tadataprotection: {
				...prevState.tadataprotection,
				[name]: value
			}
        }));
	}

	const handleTaConfirmationChange = e => {
		let { name, value } = e.target;
		if (checkFields.indexOf(name) > -1) {
			value = e.target.checked;
		}
        setState(prevState => ({
            ...prevState,
            taconfirmation: {
				...prevState.taconfirmation,
				[name]: value
			}
        }));
	}

	const handleTaCompanyRegistrationChange = e => {
		let { name, value } = e.target;
		if (checkFields.indexOf(name) > -1) {
			value = e.target.checked;
		}
        setState(prevState => ({
            ...prevState,
            tacompanyregistration: {
				...prevState.tacompanyregistration,
				[name]: value
			}
        }));
	}

    const [emailBody, setEmailBody] = React.useState("");
    return (
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
        <div className="modal creditFormModelContainer">
        <div className="modal-content creditFormModelContent" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
                <a href="#"><img src="./GD LOGOS-01.jpeg" width="140"/></a>
                <h4 className="modal-title">
                    {props.title}
                </h4>
            </div>
            <div className="modal-body" style={{overflow: 'auto'}}>
        <div class="tradeAccountAppn">
		<header>
				<div class="inner__div">
					<div class="col_div">
						
					</div>
				</div>
			</header>
		<div class="container">
			<div class="formtradeAcc">
				<div id="tradeApplication">
					<div class="form__custom company__details">
						<h3 class="title_name">1. COMPANY DETAILS - To be complete by ALL applicants.</h3>
						<div class="form-group setWidtht">
							<label>Full company name:</label>
							<input 
                                type="text"
                                name="fullCompanyName"
                                class="form-control"
                                value={state?.tacompanydetails && state?.tacompanydetails.fullCompanyName}
                                onChange={handleTaCompanydetailsChange}/>
						</div>
						<div class="form-group setWidtht">
							<label>Trading title (if applicable):</label>
							<input
                                type="text"
                                name="tradingTitle" 
                                class="form-control"
                                value={state?.tacompanydetails && state?.tacompanydetails.tradingTitle}
                                onChange={handleTaCompanydetailsChange}
                            />
						</div>
						<p><b>Please provide a letterhead with your full trading address.</b></p>
						<div class="form-group setWidtht">
							<label>Building name (if applicable):</label>
							<input type="text" name="buildingName" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.buildingName} onChange={handleTaCompanydetailsChange}/>
						</div>
						<div class="form-group setWidtht">
							<label>House/Office/Unit number:</label>
							<input type="text" name="houseNumber" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.houseNumber} onChange={handleTaCompanydetailsChange}/>
						</div>
						<div class="form-group setWidtht">
							<label>Street name:</label>
							<input type="text" name="streetName" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.streetName} onChange={handleTaCompanydetailsChange}/>
						</div>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Town:</label>
								<input type="text" name="town" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.town} onChange={handleTaCompanydetailsChange}/>
							</div>	
							<div class="form-group">
								<label>County:</label>
								<input type="text" name="county" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.county} onChange={handleTaCompanydetailsChange}/>
							</div>
							<div class="form-group">
								<label>Postcode:</label>
								<input type="text" name="postcode" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.postcode} onChange={handleTaCompanydetailsChange}/>
							</div>	
							<div class="form-group">
								<label>Country:</label>
								<input type="text" name="country" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.country} onChange={handleTaCompanydetailsChange}/>
							</div>
							<div class="form-group">
								<label>Telephone number:</label>
								<input type="text" name="telephoneNumber" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.telephoneNumber} onChange={handleTaCompanydetailsChange}/>
							</div>	
							<div class="form-group">
								<label>Email:</label>
								<input type="email" name="communicationEmail" class="form-control" value={state?.tacompanydetails && state?.tacompanydetails.communicationEmail} onChange={handleTaCompanydetailsChange}/>
							</div>
						</div>
					</div>
					<div class="form__custom principals__details">
						<h3 class="title_name">2. PRINCIPALS/DIRECTORS - To be complete by ALL applicants.</h3>
						<div class="fifthBlocksDiv">
							<div class="form-group">
								<label>Are you a:</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="soleTrader" checked={state?.tadirectors && state?.tadirectors.soleTrader} value={state?.tadirectors && state?.tadirectors.soleTrader} onChange={handleTadDrectorsChange}/>
									<span class="checkmark11"></span>
									Sole Trader 
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="partnership" checked={state?.tadirectors && state?.tadirectors.partnership} value={state?.tadirectors && state?.tadirectors.partnership} onChange={handleTadDrectorsChange}/>
									<span class="checkmark11"></span>
									Partnership 
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="plcCompany" checked={state?.tadirectors && state?.tadirectors.plcCompany} value={state?.tadirectors && state?.tadirectors.plcCompany} onChange={handleTadDrectorsChange}/>
									<span class="checkmark11"></span>
									Plc/Limted Company 
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="llp" checked={state?.tadirectors && state?.tadirectors.llp} value={state?.tadirectors && state?.tadirectors.llp} onChange={handleTadDrectorsChange}/>
									<span class="checkmark11"></span>
									LLP 
								</label>
							</div>
						</div>
						<p><b>Name and residential address of all principals/directors.</b><br/>
						This information is mandatory for all sole traders, partners and directors of Limited Companies that have not filed at least one years’ statutory accounts with Companies House. Private residential addresses are required – not service addresses.</p>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Name:</label>
								<input type="text" name="name1" class="form-control" value={state?.tadirectors && state?.tadirectors.name1} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Name:</label>
								<input type="text" name="name2" class="form-control" value={state?.tadirectors && state?.tadirectors.name2} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Address:</label>
								<input type="text" name="address1" class="form-control" value={state?.tadirectors && state?.tadirectors.address1} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Address</label>
								<input type="text" name="address2" class="form-control" value={state?.tadirectors && state?.tadirectors.address2} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Street name:</label>
								<input type="text" name="streetName1" class="form-control" value={state?.tadirectors && state?.tadirectors.streetName1} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Street name:</label>
								<input type="text" name="streetName2" class="form-control" value={state?.tadirectors && state?.tadirectors.streetName2} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Town:</label>
								<input type="text" name="town1" class="form-control" value={state?.tadirectors && state?.tadirectors.town1} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Town:</label>
								<input type="text" name="town2" class="form-control" value={state?.tadirectors && state?.tadirectors.town2} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Address:</label>
								<input type="text" name="postcode1" class="form-control" value={state?.tadirectors && state?.tadirectors.postcode1} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Address:</label>
								<input type="text" name="postcode2" class="form-control" value={state?.tadirectors && state?.tadirectors.postcode2} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Date of birth:</label>
								<DatePicker 
									selected={dateOfBirth1} 
									name="dateOfBirth1" class="form-control"
									value={dateOfBirth1}
									onChange={date => setDateOfBirth1(date)} 
									style={{paddingLeft: '20px !important'}}
								/>	
							</div>
							<div class="form-group">
								<label>Date of birth:</label>
								<DatePicker 
									selected={dateOfBirth2} 
									name="dateOfBirth2" class="form-control"
									value={dateOfBirth2}
									onChange={date => setDateOfBirth2(date)} 
									style={{paddingLeft: '20px !important'}}
								/>	
							</div>
							<div class="form-group">
								<label>Mobile No:</label>
								<input type="text" name="mobileNo1" class="form-control" value={state?.tadirectors && state?.tadirectors.mobileNo1} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Mobile No:</label>
								<input type="text" name="mobileNo2" class="form-control" value={state?.tadirectors && state?.tadirectors.mobileNo2} onChange={handleTadDrectorsChange}/>
							</div>
						</div>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Name:</label>
								<input type="text" name="name3" class="form-control" value={state?.tadirectors && state?.tadirectors.name3} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Name:</label>
								<input type="text" name="name4" class="form-control" value={state?.tadirectors && state?.tadirectors.name4} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Address:</label>
								<input type="text" name="address3" class="form-control" value={state?.tadirectors && state?.tadirectors.address3} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Address</label>
								<input type="text" name="address4" class="form-control" value={state?.tadirectors && state?.tadirectors.address4} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Street name:</label>
								<input type="text" name="streetName3" class="form-control" value={state?.tadirectors && state?.tadirectors.streetName3} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Street name:</label>
								<input type="text" name="streetName4" class="form-control" value={state?.tadirectors && state?.tadirectors.streetName4} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Town:</label>
								<input type="text" name="town3" class="form-control" value={state?.tadirectors && state?.tadirectors.town3} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<label>Town:</label>
								<input type="text" name="town4" class="form-control" value={state?.tadirectors && state?.tadirectors.town4} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Address:</label>
								<input type="text" name="postcode3" class="form-control" value={state?.tadirectors && state?.tadirectors.postcode3} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Address:</label>
								<input type="text" name="postcode4" class="form-control" value={state?.tadirectors && state?.tadirectors.Addpostcode4ress4} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Date of birth:</label>
								<DatePicker 
									selected={dateOfBirth3} 
									name="dateOfBirth2" class="form-control"
									value={dateOfBirth3}
									onChange={date => setDateOfBirth3(date)} 
									style={{paddingLeft: '20px !important'}}
								/>	
							</div>
							<div class="form-group">
								<label>Date of birth:</label>
								<DatePicker 
									selected={dateOfBirth4} 
									name="dateOfBirth4" class="form-control"
									value={dateOfBirth4}
									onChange={date => setDateOfBirth4(date)} 
									style={{paddingLeft: '20px !important'}}
								/>	
							</div>
							<div class="form-group">
								<label>Mobile No:</label>
								<input type="text" name="mobileNo3" class="form-control" value={state?.tadirectors && state?.tadirectors.mobileNo3} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<label>Mobile No:</label>
								<input type="text" name="mobileNo4" class="form-control" value={state?.tadirectors && state?.tadirectors.mobileNo4} onChange={handleTadDrectorsChange}/>
							</div>
						</div>
						<p><b>If less than three years at current address please provide previous address details.</b></p>
						<div class="twoBlocks setInputFull">
							<div class="form-group">
								<input type="text" name="addressLessThan1" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan1} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<input type="text" name="addressLessThan2" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan2} onChange={handleTadDrectorsChange}/>
							</div>??
							<div class="form-group">
								<input type="text" name="addressLessThan3" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan3} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<input type="text" name="addressLessThan4" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan4} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<input type="text" name="addressLessThan5" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan5} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<input type="text" name="addressLessThan6" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan6} onChange={handleTadDrectorsChange}/>
							</div>
							<div class="form-group">
								<input type="text" name="addressLessThan7" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan7} onChange={handleTadDrectorsChange}/>
							</div>	
							<div class="form-group">
								<input type="text" name="addressLessThan8" class="form-control" value={state?.tadirectors && state?.tadirectors.addressLessThan8} onChange={handleTadDrectorsChange}/>
							</div>
						</div>
					</div>
					<div class="form__custom companyRegistration__div">
						<h3 class="title_name">3. COMPANY AND REGISTRATION INCORPORATION - To be completed by Limited and Public companies only</h3>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Company Registration:</label>
								<input type="text" name="companyRegistration" class="form-control" value={state?.tacompanyregistration && state?.tacompanyregistration.companyRegistration} onChange={handleTaCompanyRegistrationChange}/>
							</div>	
							<div class="form-group">
								<label>Incorporation date:</label>
								<input type="text" name="incorporationdate" class="form-control" value={state?.tacompanyregistration && state?.tacompanyregistration.incorporationdate} onChange={handleTaCompanyRegistrationChange}/>
							</div>
						</div>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Number:</label>
								<input type="text" name="companyNumber1" class="form-control" value={state?.tacompanyregistration && state?.tacompanyregistration.companyNumber1} onChange={handleTaCompanyRegistrationChange}/>
							</div>
							<div class="form-group">
								<label>Number:</label>
								<input type="text" name="companyNumber2" class="form-control" value={state?.tacompanyregistration && state?.tacompanyregistration.companyNumber2} onChange={handleTaCompanyRegistrationChange}/>
							</div>
						</div>
					</div>
					<div class="form__custom dataProtection__div">
						<h3 class="title_name">4. DATA PROTECTION</h3>
						<p>Use of our information: We (Black Aesthetics Supplies Ltd) would like to send you details of relevant products and services, promotions, competitions, news and events which may be of interest to you.</p>
						<p><b>Please tick relevant boxes</b>:</p>
						<div class="sixBlocksDiv">
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="email" checked={state?.tadataprotection && state?.tadataprotection.email} value={state?.tadataprotection && state?.tadataprotection.email} onChange={handleTaDataProtectionChange}/>
									<span class="checkmark11"></span>
									Email
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="sms" checked={state?.tadataprotection && state?.tadataprotection.sms} value={state?.tadataprotection && state?.tadataprotection.sms} onChange={handleTaDataProtectionChange}/>
									<span class="checkmark11"></span>
									SMS
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="whatsApp" checked={state?.tadataprotection && state?.tadataprotection.whatsApp} value={state?.tadataprotection && state?.tadataprotection.whatsApp} onChange={handleTaDataProtectionChange}/>
									<span class="checkmark11"></span>
									WhatsApp
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="phone" checked={state?.tadataprotection && state?.tadataprotection.phone} value={state?.tadataprotection && state?.tadataprotection.phone} onChange={handleTaDataProtectionChange}/>
									<span class="checkmark11"></span>
									Phone
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="post" checked={state?.tadataprotection && state?.tadataprotection.post} value={state?.tadataprotection && state?.tadataprotection.post} onChange={handleTaDataProtectionChange}/>
									<span class="checkmark11"></span>
									Post
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="optOutEntirely" checked={state?.tadataprotection && state?.tadataprotection.optOutEntirely} required value={state?.tadataprotection && state?.tadataprotection.optOutEntirely} onChange={handleTaDataProtectionChange}/>
									<span class="checkmark11"></span>
									Opt out entirely*
								</label>
							</div>
						</div>
						<p>You can change your marketing preferences and can opt out at any time, by visiting www.blacksaesthetics.com/privacy-policy</p>
						<p>Your personal data will be handled in accordance with our privacy policy. By signing this application form you confirm you have read, understood and accept our Privacy Policy. We will use the information collected on this form to register your account, for our internal record keeping, to administer promotion scheme such as Extra points scheme and to assess your credit worthiness, which may involve passing the information to credit reference agencies, and receiving information from them about you.</p>
						<p>If we do use the information to make a search with a credit reference agency, that agency will keep a record of that search and will share that information with other businesses. We may also make enquiries about the principal directors with the credit reference agency. We will monitor and record information relating to your trade performance and such records will be made available to credit reference agencies, who will share that information with other businesses in assessing applications for credit and fraud prevention.</p>
						<p>*Opting out entirely does not control service messages which are required to trade with Us. Service messages can be sent by email, SMS, phone or post depending on the type of message. They are typically in relation to orders you have placed, the status of your account or your online access. They may also include updates to policies such as Privacy and Security.</p>
					</div>
					<div class="form__custom confirmation__div">
						<h3 class="title_name">5. CONFIRMATION - To be complete by ALL applicants</h3>
						<p>For Limited/LLP and PLC a Director or Company Secretary will need to sign the application. For sole traders the owner of the account needs to sign. For Partnerships all partners need to sign. For Clubs/Associations the Secretary/Treasurer needs to sign.</p>
						<p>In signing this application, I/We acknowledge and agree that I/We have read and understood the Conditions of Business enclosed with this pack and that these Conditions (as the same are updated from time to time and are available at www.gandyandblack.com) shall govern all transactions.</p>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Signature:</label>
								<input type="text" name="signature1" class="form-control" value={state?.taconfirmation && state?.taconfirmation.signature1} onChange={handleTaConfirmationChange}/>
							</div>	
							<div class="form-group">
								<label>Print name:</label>
								<input type="text" name="printName1" class="form-control" value={state?.taconfirmation && state?.taconfirmation.printName1} onChange={handleTaConfirmationChange}/>
							</div>
							<div class="form-group">
								<label>Position:</label>
								<input type="text" name="position1" class="form-control" value={state?.taconfirmation && state?.taconfirmation.position1} onChange={handleTaConfirmationChange}/>
							</div>	
							<div class="form-group">
								<label>Date signed:</label>
								<input type="text" name="dateSigned1" class="form-control" value={state?.taconfirmation && state?.taconfirmation.dateSigned1} onChange={handleTaConfirmationChange}/>
							</div>		
						</div>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Signature:</label>
								<input type="text" name="signature2" class="form-control" value={state?.taconfirmation && state?.taconfirmation.signature2} onChange={handleTaConfirmationChange}/>
							</div>	
							<div class="form-group">
								<label>Print name:</label>
								<input type="text" name="printName2" class="form-control" value={state?.taconfirmation && state?.taconfirmation.printName2} onChange={handleTaConfirmationChange}/>
							</div>
							<div class="form-group">
								<label>Position:</label>
								<input type="text" name="position2" class="form-control" value={state?.taconfirmation && state?.taconfirmation.position2} onChange={handleTaConfirmationChange}/>
							</div>	
							<div class="form-group">
								<label>Date signed:</label>
								<input type="text" name="dateSigned2" class="form-control" value={state?.taconfirmation && state?.taconfirmation.dateSigned2} onChange={handleTaConfirmationChange}/>
							</div>	
						</div>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Signature:</label>
								<input type="text" name="signature3" class="form-control" value={state?.taconfirmation && state?.taconfirmation.signature3} onChange={handleTaConfirmationChange}/>
							</div>	
							<div class="form-group">
								<label>Print name:</label>
								<input type="text" name="printName3" class="form-control" value={state?.taconfirmation && state?.taconfirmation.printName3} onChange={handleTaConfirmationChange}/>
							</div>
							<div class="form-group">
								<label>Position:</label>
								<input type="text" name="position3" class="form-control" value={state?.taconfirmation && state?.taconfirmation.position3} onChange={handleTaConfirmationChange}/>
							</div>	
							<div class="form-group">
								<label>Date signed:</label>
								<input type="text" name="dateSigned3" class="form-control" value={state?.taconfirmation && state?.taconfirmation.dateSigned3} onChange={handleTaConfirmationChange}/>
							</div>	
						</div>		
					</div>
					<div class="form__custom companyRegistration__div">
						<h3 class="title_name">IMPORTANT – PLEASE DO NOT COMPLETE THIS SECTION – For internal use only</h3>
						<div class="form-group">
							<label>Additional information:</label>
							<textarea name="additionalInformation" class="form-control" value={state?.taimportantsection && state?.taimportantsection.additionalInformation} onChange={handleTaimportantsectionChange}>
							</textarea>
						</div>	
						<p><b>Credit limit required: Please tick:</b></p>
						<div class="fifthBlocksDiv">
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="creditLimit1" checked={state?.taimportantsection && state?.taimportantsection.creditLimit1} value={state?.taimportantsection && state?.taimportantsection.creditLimit1} onChange={handleTaimportantsectionChange}/>
									<span class="checkmark11"></span>
									£1000
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="creditLimit3" checked={state?.taimportantsection && state?.taimportantsection.creditLimit3} value={state?.taimportantsection && state?.taimportantsection.creditLimit3} onChange={handleTaimportantsectionChange}/>
									<span class="checkmark11"></span>
									£3000
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="creditLimit5" checked={state?.taimportantsection && state?.taimportantsection.creditLimit5} value={state?.taimportantsection && state?.taimportantsection.creditLimit5} onChange={handleTaimportantsectionChange}/>
									<span class="checkmark11"></span>
									£5000
								</label>
							</div>
							<div class="form-group">
								<label class="container11">
									<input type="checkbox" name="creditLimit7" checked={state?.taimportantsection && state?.taimportantsection.creditLimit7}  value={state?.taimportantsection && state?.taimportantsection.creditLimit7} onChange={handleTaimportantsectionChange}/>
									<span class="checkmark11"></span>
									£7500
								</label>
							</div>
							<div class="form-group">
								<label>Other amount £:</label>
								<input type="text" name="otherAmount" checked={state?.taimportantsection && state?.taimportantsection.otherAmount}  class="form-control" value={state?.taimportantsection && state?.taimportantsection.otherAmount} onChange={handleTaimportantsectionChange}/>
							</div>
						</div>
						<div class="twoBlocks">
							<div class="form-group">
								<label>Office Location:</label>
								<input type="text" name="officeLocation" class="form-control" value={state?.taimportantsection && state?.taimportantsection.officeLocation} onChange={handleTaimportantsectionChange}/>
							</div>	
							<div class="form-group">
								<label>Account Manager:</label>
								<input type="text" name="accountManager" class="form-control" value={state?.taimportantsection && state?.taimportantsection.accountManager} onChange={handleTaimportantsectionChange}/>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
            <div className="modal-footer text-align-center">
                        <button className="btn btn-main" onClick={() => props.doCreditFormApiCall(state)}>
                            Submit
                        </button>
                        <button onClick={props.onClose} className="btn btn-secondary">
                            Close
                        </button>
                    </div>
            </div>
            </div>
            </div>
            </div>
             </CSSTransition>
    )
}

export default CreditFormModal;