import React, { useState } from "react";
import CRMPopupModal from "../CRMTab/CRMPopupModal";
import { ppsUpdateButtonApiCall } from "../../../serviceCalls/ppsUpdateButtonApiCall";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import envConfig from "../../../envConfigurations";

const PPS = (props) => {
    const { productList, profile } = props;
    const keyValueList = profile?.priceKeyValue;
    const [productKeyValue, setProductKeyValue] = React.useState([]);
    const [productItem, setProductItem] = React.useState(false);
    const [spin, setSpin] = useState(false);
    const [matchPrice, setMatchPrice] = React.useState("");
    const [ogPrice, setOgPrice] = React.useState("");
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [monthYear, setMonthYear] = useState("2022-01");
    const [tableArray, setTableArray] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredData, setFilterData] = useState([]);
    const [scriptTotal, setScriptTotal] = useState(0);
    const [psTotal, setPsTotal] = useState(0);
    const [allData, setAllData] = useState([]);
    let prescriberEmail = "";
    let token = "";
    let finalArray = [];
    let filteredDara = [];
    let scriptTotal1 = 0;
    let psTotal1 = 0;
    
    let clearData = [];

    React.useEffect(() => {
        if (keyValueList && keyValueList.length > 0)
            setProductKeyValue(keyValueList);
    }, [keyValueList]);

    // do api call
    React.useEffect(() => {
        const myFunc = async () => {
            const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
            let prescriberEmail = removeExtraSpace(props.profile.email);
            token = window.localStorage.getItem("sessionKey");
            let response = await axios.get(
                `${envConfig.apiUrl}:${envConfig.apiPort}/user/fetchppsdata?prescriberemail=
                ${prescriberEmail}`,
                {
                    headers: {
                        "x-auth-token": token,
                        Cookie: "JSESSIONID=D13B99007872825FED10DF9BAD8C1362",
                    },
                }
            );
            response.data.pharmaorders.map((row) => {
                // console.log("old format -->",row.orderdate)
                let date = new Date(row.orderdate);
                let finalDate =
                    date.getFullYear() +
                    "-" +
                    (date.getMonth() + 1) +
                    "-" +
                    date.getDate();
                row.orderdate = finalDate;
            });

            setProductKeyValue(response.data.pharmaorders.reverse());
            setAllData(response.data.pharmaorders);
        };
        myFunc();
    }, []);

  



    const onClearButton = (e, item) => {
        toast("Please Hit Update Data To Proceed");
        clearData.push(item);

    };

    const updatePrice = (e) => {
        if (e.target.value) setMatchPrice(e.target.value);
        else setMatchPrice("");
    };

    const email = props.profile.email;
    const saveProduct = async () => {
        await ppsUpdateButtonApiCall({ clearData , email });
    };

    const updateOGPrice = (e) => {
        if (e.target.type.includes("select") && e.target.value) {
            const product = JSON.parse(e.target.value);
            setOgPrice(product.salepriceperunit);
        }
    };


    const closePopup = (value) => {
        setDeleteModal(value);
    };

    const handleChange = () => {
        // set loading on
        setSpin(true);

        // calendar selected dates
        let selectedYear = monthYear.substring(0, 4);
        // console.log("selected year -->", selectedYear)
        let selectedMonth = monthYear.substring(5, 7);
        // console.log("selected month -->", selectedMonth)

        // filter data based on year
        let yearFilterDated = allData.map((row) => {
            let date = new Date(row.orderdate);
            let year = date.getFullYear();
            // console.log("year -->", year)
            if (year == selectedYear) {
                return row;
            } else {
                // console.log("not match ", year)
                return;
            }
        });

        const results = yearFilterDated.filter((element) => {
            return element !== undefined;
        });
        filteredDara = results;

        // filter based on month
        let monthFilter = results.map((i) => {
            if (
                parseInt(selectedMonth) ==
                parseInt(i.orderdate.substring(5, 7).replace("-", ""))
            ) {
                return i;
            }
        });
        const results2 = monthFilter.filter((element) => {
            return element !== undefined;
        });

        setFilterData(results2);
        setProductKeyValue(results2);
        setIsFiltered(true);

        scriptTotal1 = 0;
        psTotal1 = 0;

        // filtered data total
        results2.map((i) => {
            scriptTotal1 += i.scriptvalue;
            psTotal1 += i.ps
        })

        if (results2.length == 0) {
            setScriptTotal(0);
            setPsTotal(0);
        } else {
            setScriptTotal(scriptTotal1);
            setPsTotal(psTotal1);
        }

        setSpin(false);
    };

    finalArray = [...tableArray].reverse();

    // without filter data
    finalArray.map((i) => {
        scriptTotal1 += i.scriptvalue;
        psTotal1 += i.ps;
    });

    return (
        <>
            <div>
                {/* name ,sale year and month dropdown */}
                <div className="nametag">
                    {/* sale year */}
                    <div className="saleyear">
                        <span>Sales Year & Month : </span>
                    </div>
                    <div className="calender">
                        <input
                            type="month"
                            value={monthYear}
                            id="selectedDate"
                            name="selectedDate"
                            onChange={(e) => setMonthYear(e.target.value)}
                        />
                    </div>
                    <div className="submitButton">
                        {" "}
                        {spin ? (
                            <Spinner
                                style={{ marginLeft: 55 }}
                                animation="border"
                                role="status"
                            >
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </Spinner>
                        ) : (
                            <button
                                style={{
                                    padding: "6px 14px 6px 14px",
                                    marginLeft: 50,
                                    border: "2px solid #595959",
                                    borderRadius: 5,
                                    boxShadow: "4px 4px 7px 3px #BFBFBF",
                                    backgroundColor: "#E9ADAC",
                                    color: "black",
                                }}
                                // rgba(0, 0, 0, 0.16)
                                type="button"
                                onClick={handleChange}
                            >
                                Filter
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <br />
            <div className="be-spoken container-fluid">
                <div className="bespoken-container">
                    {/* table start */}
                    <table id="myTable" className="bespoken-table ">
                        <tr
                            style={{
                                height: 40,
                                width: "100%",
                                backgroundColor: "#7984a3",
                                color: "#fff",
                            }}
                        >
                            <th
                                id="product-list"
                                style={{ width: "10%", textAlign: "center" }}
                            >
                                Order ID
                            </th>
                            <th
                                id="og-price"
                                style={{ width: "15%", textAlign: "center" }}
                            >
                                Date
                            </th>
                            <th
                                id="match-price"
                                style={{ width: "15%", textAlign: "center" }}
                            >
                                Practitioner
                            </th>
                            <th
                                id="match-price"
                                style={{ width: "15%", textAlign: "center" }}
                            >
                                Script Value
                            </th>
                            <th
                                id="match-price"
                                style={{ width: "15%", textAlign: "center" }}
                            >
                                Profit Share
                            </th>
                            <th
                                id="delete"
                                style={{ width: "10%", textAlign: "center" }}
                            >
                                Payment Status
                            </th>
                        </tr>
                        {productKeyValue?.map((item, sIndex) => {
                            return (
                                <tr
                                    className={`table-${
                                        sIndex % 2 ? "even" : "odd"
                                    }`}
                                >
                                    <td
                                        headers="product-list"
                                        style={{
                                            padding: 3,
                                            textAlign: "center",
                                        }}
                                    >{`${item.id}`}</td>
                                    <td
                                        headers="og-price"
                                        style={{ textAlign: "center" }}
                                    >
                                        {item.orderdate}
                                    </td>
                                    <td
                                        headers="og-price"
                                        style={{ textAlign: "center" }}
                                    >
                                        {item.practioner}
                                    </td>
                                    <td
                                        headers="og-price"
                                        style={{ textAlign: "center" }}
                                    >
                                        {`£${Number(item.scriptvalue).toFixed(
                                            2
                                        )}`}
                                    </td>
                                    <td
                                        headers="og-price"
                                        style={{ textAlign: "center" }}
                                    >
                                        {`£${Number(item.ps).toFixed(2)}`}
                                    </td>

                                    <td style={{ textAlign: "center" }}>
                                        {item.paid ? (
                                            <>
                                                <i
                                                    class="fa fa-check "
                                                    aria-hidden="true"
                                                ></i>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    onClick={(e) =>
                                                        onClearButton(e, item)
                                                    }
                                                    // className="btn btn-main"
                                                    style={{
                                                        cursor: "pointer",
                                                        border: "1px solid grey",
                                                        "background-color":
                                                            "#E3ADAB",
                                                        "border-radius": "3px",
                                                        color: "#f0fcf3",
                                                    }}
                                                >
                                                    {" "}
                                                    CLEAR{" "}
                                                </button>
                                                <ToastContainer />
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        {productItem ? (
                            <tr onChange={(e) => updateOGPrice(e)}>
                                <td headers="product-list">
                                    <select
                                        name="all-products"
                                        id="all-products"
                                        className="all-products"
                                    >
                                        <option value="">Select Product</option>
                                        {productList.map((product) => {
                                            return (
                                                <option
                                                    value={JSON.stringify(
                                                        product
                                                    )}
                                                >{`${product.brand} ${product.producttype} ${product.productdesc}`}</option>
                                            );
                                        })}
                                    </select>
                                </td>
                                <td
                                    headers="og-price"
                                    style={{ textAlign: "center" }}
                                >
                                    {ogPrice}
                                </td>
                                <td
                                    headers="match-price"
                                    className="match-price"
                                    style={{ textAlign: "center" }}
                                >
                                    <input
                                        type="number"
                                        value={matchPrice ? matchPrice : ""}
                                        onChange={(e) => updatePrice(e)}
                                    />
                                </td>
                            </tr>
                        ) : null}
                    </table>
                    {/* <button onClick={addNewItem} className="btn btn-main"> Add Item </button> */}
                    <button onClick={saveProduct} className="btn btn-main">
                        {" "}
                        Update Data{" "}
                    </button>
                </div>
                <CRMPopupModal
                    title="Delete Product"
                    onClose={() => closePopup(false)}
                    show={deleteModal}
                >
                    <p>
                        <strong>Please update changes</strong>
                    </p>
                </CRMPopupModal>
            </div>
        </>
    );
};
export default PPS;
