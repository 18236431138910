import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import envConfig from "../envConfigurations";
import { ToastContainer, toast } from "react-toastify";

export const updateProductImage = async (prodId, image, imageUpdated) => {
  console.log(image);
  const data = new FormData()
  data.append('image', image);

    let token = window.localStorage.getItem("sessionKey");

  let apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": token, 'Content-Type': 'multipart/form-data' },
    null,
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/product/uploadS3productimage?productid=${prodId}`;
  apiRequestHeader = {
    ...apiRequestHeader,
    responseType: 'blob'
  }

  await axios
    .post(apiUrl, data,apiRequestHeader)
    .then((apiResponse) => {
      console.log(apiResponse)
      toast("Product image updated successfully");
      imageUpdated();
    })
    .catch((err) => {
      console.log(err)
      toast("Something went wrong");
    });
};
