import * as React from "react";
import { AgGridReact } from "ag-grid-react";

import "react-tabs/style/react-tabs.css";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "../../css/crm.css";
import CRMTabList from "./CRMTabList";
import CRMModal from "./CRMModal";
import EmailModal from "../NextStatusModal/EmailModal";
import Status1Modal from "../NextStatusModal/Status1Modal";
import Status2Modal from "../NextStatusModal/Status2Modal";
import Status3Modal from "../NextStatusModal/Status3Modal";

import AdminHeaderMenu from "../../common/AdminHeaderMenu";

import {
  SET_ADD_DELETE_NEW_ORDER,
  SET_ADD_DELETE_NEW_PRODUCT,
  SET_IS_LOADING,
} from "../../../constants/actionTypes";
import { moveToNextStatusApiCall } from "../../../serviceCalls/moveToNextStatusApiCall";
import { sendEmailApiCall } from "../../../serviceCalls/sendEmailApiCall";
import DeleteCRMOrder from "./DeleteCRMOrder";
import CRMPopupModal from "./CRMPopupModal";
import { ProductPriceColumn } from "./ProductPriceColumn.js";
import { ViewProductColumn } from "./ViewProductColumn.js";
import { DeleteProductColumn } from "./DeleteProductColumn.js";
import { updateOrderStatusApiCall } from "../../../serviceCalls/updateOrderStatusApiCall.js";
import {
  orderStatus,
  DefaultOrderStatus,
} from "../../../constants/orderStatusConstants.js";
import { CustomDropdownEditor } from "./CustomDropdownEditor.js";
import envConfig from "../../../envConfigurations.js";
import { makeApiRequestHeader } from "../../../utils/makeApiRequestHeader.js";
import axios from "axios";
import { formatDate } from "./CRMUtils.js";
import NavigationBar from "../../common/NavigationBar.js";

const CRM = (props) => {
  const { applicationState, dispatch } = props;
  const {
    isLoading,
    config,
    crmDetails,
    commentsData,
    isSpinnerEnabled,
    orderDeleteStatus,
    orderMessage,
  } = applicationState;
  const [showModal, setShowModal] = React.useState(false);
  const [orderInfo, setOrderInfo] = React.useState("");
  const [stageId, setStageId] = React.useState("");
  const [stageModal, setStageModal] = React.useState(false);
  const [emailIdForModal, setEmailIdForModal] = React.useState("");
  const [emailIdModal, setEmailIdModal] = React.useState(false);
  const [currentStage, setCurrentStage] = React.useState("");
  const [showDeleteModal, setDeleteProductModal] = React.useState(false);
  const [dataForDeleteModal, setDataForDeleteModal] = React.useState({});
  const [callback, setCallback] = React.useState({});
  const [popupValue, setpopupValue] = React.useState({});
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(props.value);
  const [searchSelectedValue, setSearchSelectedValue] = React.useState(
    props.value
  );
  const [currentStatus, setCurrentStatus] = React.useState(DefaultOrderStatus);
  const [orderData, setOrderData] = React.useState([]);
  const [date, setDate] = React.useState({
    startDate: formatDate(
      new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
      "YYYY-MM-DD"
    ),
    endDate: formatDate(new Date(), "YYYY-MM-DD"),
  });

  const history = useHistory();
  React.useEffect(() => {
    if (currentStage) {
      showStageModal(true);
    }
  }, [currentStage, crmDetails, emailIdModal]);

  React.useEffect(() => {
    let body = {};
    if (date.startDate) {
      body.startdate = formatDate(new Date(date.startDate), "DD-MM-YYYY");
    }
    if (date.endDate) {
      body.enddate = formatDate(new Date(date.endDate), "DD-MM-YYYY");
    }
    if (currentStatus) {
      body.status = currentStatus;
    }
    if (Object.keys(body).length) {
      fetchSearchResults(body);
    }
  }, [date, currentStatus, config]);

  const showPromocodeModal = (showModalValue) => {
    setShowModal(showModalValue);
  };

  const showStageModal = (showStageModalValue) => {
    setStageModal(showStageModalValue);
  };

  const showEmailModal = (showEmailModalValue) => {
    setEmailIdModal(showEmailModalValue);
  };

  const showDeleteProductModal = (showModalValue) => {
    setDeleteProductModal(showModalValue);
  };

  const onCardClick = (cardId, laneId) => {
    setOrderInfo(orderData.find((crmElement) => crmElement.id === cardId));
    showPromocodeModal(true);
  };
  const refetchOrderData = () => {
    fetchSearchResults({
      status: currentStatus,
      startdate: formatDate(new Date(date.startDate), "DD-MM-YYYY"),
      enddate: formatDate(new Date(date.endDate), "DD-MM-YYYY"),
    });
  }

  const onCardDelete = (cardId, laneId) => {
    setDataForDeleteModal({ cardId, laneId });
    setDeleteProductModal(true);
  };

  const closePopup = () => {
    dispatch({
      type: SET_ADD_DELETE_NEW_ORDER,
      payload: false,
      message: "",
    });
  };

  const onMoveToNextStage = (data) => {
    setCurrentStage(data.currentstage);
    showPromocodeModal(false);
  };

  const showEmailPopUp = (data) => {
    showEmailModal(true);
    setEmailIdForModal(data.customerEmail);
    showPromocodeModal(false);
  };

  const doEmailApiCall = ({ email, subject, emailbody }) => {
    const requestBodyForEmail = {
      email: email,
      subject: subject,
      emailbody: emailbody,
    };
    sendEmailApiCall({
      dispatch: dispatch,
      authToken: config.authToken,
      requestBodyForEmail: requestBodyForEmail,
    });
    showEmailModal(false);
    setEmailIdForModal("");
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const nextStageApiCall = ({ orderid, warehouse = null, email = null }) => {
    const statuses = {
      "status-1": "statustwo",
      "status-2": "statusthree",
      "status-3": "statusfour",
      "status-4": "statusfive",
      "status-5": "statussix",
    };
    const moveToStatus = statuses[currentStage];
    setOrderInfo("");
    setCurrentStage("");
    showStageModal(false);
    const requestBody = {
      orderid: orderid,
      orderstagewarehouse: warehouse,
      stagecompletedby: email,
    };
    if (!requestBody.orderstagewarehouse) {
      delete requestBody.orderstagewarehouse;
    }
    if (!requestBody.stagecompletedby) {
      delete requestBody.stagecompletedby;
    }
    dispatch({ type: SET_IS_LOADING, payload: true });
    moveToNextStatusApiCall({
      dispatch: dispatch,
      history: history,
      requestBody: requestBody,
      authToken: config.authToken,
      moveToStatus: moveToStatus,
      signInResponse: config,
      email: null,
      moveToNextPage: false,
    });
  };

  const statusModalToDisplay = () => {
    switch (currentStage) {
      case "status-1":
        return (
          <Status1Modal
            title="Move to Status 2"
            show={stageModal}
            onClose={() => {
              setCurrentStage("");
              showStageModal(false);
            }}
            nextStageApiCall={nextStageApiCall}
            orderid={orderInfo.id}
          />
        );
      case "status-2":
        return (
          <Status2Modal
            title="Move to Status 3"
            show={stageModal}
            onClose={() => {
              setCurrentStage("");
              showStageModal(false);
            }}
            nextStageApiCall={nextStageApiCall}
            orderid={orderInfo.id}
          />
        );
      case "status-3":
        return (
          <Status2Modal
            title="Move to Status 4"
            show={stageModal}
            onClose={() => {
              setCurrentStage("");
              showStageModal(false);
            }}
            nextStageApiCall={nextStageApiCall}
            orderid={orderInfo.id}
          />
        );
      case "status-4":
        return (
          <Status2Modal
            title="Move to Status 5"
            show={stageModal}
            onClose={() => {
              setCurrentStage("");
              showStageModal(false);
            }}
            nextStageApiCall={nextStageApiCall}
            orderid={orderInfo.id}
          />
        );
      case "status-5":
        return (
          <Status3Modal
            title="Move to Status 6"
            show={stageModal}
            onClose={() => {
              setCurrentStage("");
              showStageModal(false);
            }}
            nextStageApiCall={nextStageApiCall}
            orderid={orderInfo.id}
            config={config}
          />
        );
      default:
        return <div></div>;
    }
  };

  const emailModalToDisplay = () => {
    return (
      <>
        <EmailModal
          title="Send Email"
          show={emailIdModal}
          onClose={() => {
            setEmailIdForModal("");
            showEmailModal(false);
          }}
          doEmailApiCall={doEmailApiCall}
          emailIdForModal={emailIdForModal}
        />
      </>
    );
  };
  const getRowStyle = (params) => {
    // if (params.node.rowIndex % 2 === 0) {
    //   return { background: "#fff" };
    // } else {
    //   return { background: "#a8b4c6" };
    // }
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F8F7FF" };
      // E3ADAB 3141A1
    }
  };

  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const columnDefs = () => [
    {
      field: "id",
      headerName: "ORDER ID",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "customerName",
      headerName: "CUSTOMER NAME",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "customerEmail",
      headerName: "CUSTOMER EMAIL",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },

    {
      field: "orderDate",
      headerName: "ORDER DATE",
      cellStyle: { color: "#31A191", fontSize: "14px" },
      valueFormatter: function (params) {
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours().toString();
        const minutes = date.getMinutes().toString();
        const seconds = date.getSeconds().toString();
        const formattedDate = `${day.toString().padStart(2, "0")}-${month
          .toString()
          .padStart(2, "0")}-${year}  ${
          hours.length < 2 ? `0${hours}` : hours
        }:${minutes.length < 2 ? `0${minutes}` : minutes}:${
          seconds.length < 2 ? `0${seconds}` : seconds
        }`;

        return formattedDate;
      },
    },

    // {
    //   field: "orderDate",
    //   headerName: "ORDER TIME",
    //   valueFormatter: function (params) {
    //     const timeParts = params.value.split(":");
    //     const hours = parseInt(timeParts[0], 10);
    //     const minutes = parseInt(timeParts[1], 10);

    //     let formattedHours = hours % 12;
    //     formattedHours = formattedHours || 12;
    //     const ampm = hours >= 12 ? "PM" : "AM";

    //     const formattedTime = `${formattedHours}:${minutes
    //       .toString()
    //       .padStart(2, "0")} ${ampm}`;
    //     return formattedTime;
    //   },
    // },

    {
      field: "ordertype",
      headerName: "ORDER TYPE",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },

    {
      headerName: "ORDER STATUS",
      field: "currentstage",
      cellRenderer: "customDropdownEditor",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },

    {
      field: "amount",
      headerName: "ORDER TOTAL",
      cellRenderer: "salepricetotal",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "view",
      headerName: "VIEW",
      cellRendererFramework: ViewProductColumn,
      cellRendererParams: {
        onCardClick: onCardClick,
      },
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "delete",
      headerName: "DELETE",
      cellRendererFramework: DeleteProductColumn,
      cellRendererParams: {
        onCardDelete: onCardDelete,
      },
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const handleStartDateChange = (e) => {
    const startDate = e.target.value;
    setDate({ ...date, startDate });
  };

  const handleEndDateChange = (e) => {
    const endDate = e.target.value;
    setDate({ ...date, endDate });
  };

  const fetchSearchResults = async (body = {}) => {
    const apiPOSTRequestHeader = makeApiRequestHeader(
      "POST",
      { "x-auth-token": config?.authToken },
      null
    );
    let apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/searchorderbystatus`;

    if (config?.authToken) {
      await axios
        .post(apiUrl, body, apiPOSTRequestHeader)
        .then((apiResponse) => {
          setOrderData(
            apiResponse?.data.map((x, index) => {
              return { ...x, rowIndex: index };
            })
          );
        })
        .catch((error) => {
          console.error("error ==> ", error);
        });
    }
  };

  const onFilterTextBoxChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };
  const updateData = (rowIndex, field, value) => {
    const updatedRowData = [...orderData];
    updatedRowData[rowIndex][field] = value;
    return updatedRowData;
  };

  const onDataUpdated = (updatedData, index) => {
    const currentOrder = updatedData[index];
    dispatch({ type: SET_IS_LOADING, payload: true });
    const requestBodyForAdd = {
      orderid: currentOrder?.id,
      status: currentOrder?.currentstage,
      orderstagewarehouse: currentOrder?.warehouse,
      stagecompletedby: currentOrder?.customerEmail,
    };
    updateOrderStatusApiCall({
      dispatch: dispatch,
      authToken: config.authToken,
      requestBodyForAdd: requestBodyForAdd,
      history: history,
      config: config,
      callback: () => {
        refetchOrderData();
      },
    });
  };


  const resetData = () => {
    setDate({
      startDate: formatDate(
        new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
        "YYYY-MM-DD"
      ),
      endDate: formatDate(new Date(), "YYYY-MM-DD"),
    });
    setCurrentStatus(DefaultOrderStatus);
  };
  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="admin crm" id="crm">
        <div>
          <AdminHeaderMenu dispatch={dispatch} />
        </div>
        <div className="col-lg-12">
          <CRMPopupModal
            title="Delete Order"
            onClose={() => closePopup(false)}
            show={orderDeleteStatus}
          >
            <p>
              <strong>{orderMessage}</strong>
            </p>
          </CRMPopupModal>
          <div>
            {" "}
            <NavigationBar />
          </div>
          <div>
            {applicationState?.crmDetails && (
              <div className="container-fluid">
                <div className="card">
                  <div className="input-container">
                    <input
                      className="search-bottom-margin mt-4 input-element"
                      type="text"
                      id="crm-search-text-box"
                      placeholder="🔍Quick Search"
                      onChange={(event) => onFilterTextBoxChanged(event)}
                    />

                    <input
                      className="date-margin mt-3 input-element"
                      type="date"
                      value={date.startDate}
                      onChange={handleStartDateChange}
                    />
                    <input
                      className="date-margin mt-3 input-element"
                      type="date"
                      value={date.endDate}
                      onChange={handleEndDateChange}
                    />
                    <select
                      className="select-bottom mt-3 input-element"
                      value={currentStatus}
                      onChange={(e) => {
                        setCurrentStatus(e.target.value);
                      }}
                    >
                      {orderStatus.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <button
                      className="btn-main btn-element"
                      onClick={resetData}
                    >
                      Reset{" "}
                    </button>
                  </div>
                  <div
                    className="ag-theme-alpine"
                    id="custom-grid"
                    style={{ height: "600px", width: "100%", margin: "auto" }}
                  >
                    <AgGridReact
                      getRowStyle={getRowStyle}
                      rowData={orderData}
                      columnDefs={columnDefs()}
                      defaultColDef={defaultColDef}
                      onGridReady={onGridReady}
                      context={{
                        showDeleteProductModal: showDeleteProductModal,
                        updateData,
                        onDataUpdated,
                      }}
                      rowSelection={"multiple"}
                      paginationAutoPageSize={true}
                      pagination={true}
                      frameworkComponents={{
                        salepricetotal: ProductPriceColumn,
                        customDropdownEditor: CustomDropdownEditor,

                        deleteColumn: (params) => (
                          <DeleteOutlineIcon
                            style={{ color: "red" }}
                            onClick={(cardId, laneId) => {
                              onCardDelete(cardId, laneId);
                            }}
                          />
                        ),
                      }}
                    ></AgGridReact>
                  </div>
                </div>
              </div>
            )}
          </div>
          <DeleteCRMOrder
            title="Delete Product"
            onClose={() => showDeleteProductModal(false)}
            show={showDeleteModal}
            dataForDeleteModal={dataForDeleteModal}
            config={config}
            history={history}
            dispatch={dispatch}
            onCardDelete={callback}
          >
            <p>
              <strong>Are you sure you want to delete this order?</strong>
            </p>
          </DeleteCRMOrder>
        </div>
        <CRMModal
          title={currentStatus}
          onClose={() => showPromocodeModal(false)}
          show={showModal}
          onMoveToNextStage={onMoveToNextStage}
          showEmailPopUp={showEmailPopUp}
          orderInfo={orderInfo}
          selectedValue={selectedValue}
        >
          <CRMTabList
            orderInfo={orderInfo}
            showEmailPopUp={showEmailPopUp}
            dispatch={dispatch}
            history={history}
            config={config}
            onClose={() => showPromocodeModal(false)}
            comments={commentsData}
            isSpinnerEnabled={isSpinnerEnabled}
            refetchOrderData={refetchOrderData}
          />
        </CRMModal>
        {statusModalToDisplay()}
        {emailModalToDisplay()}
      </div>
    </>
  );
};

export default CRM;
