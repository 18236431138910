import React from "react";
import { useHistory } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { EDIT_PRODUCT_QUANTITY } from "../../constants/actionTypes";

import { SET_TILE_CLICKED, PRODUCT_DETAIL_DATA } from "../../constants/actionTypes";
import { updateCartDetails } from "../../serviceCalls/updateCartDetails";
import HeaderMenu from "../common/HeaderMenu";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import image_1 from '../../assert/customer-design/product_1.png';

import { NewColumnQuantity } from "./NewColumnQuantity";

const ProductList = (props) => {
  const { applicationState, dispatch } = props;
  const { cartDetails, isLoading } = applicationState;

  const [isLocalCartEmpty, setIsLocalCartEmpty] = React.useState(
    !(cartDetails && cartDetails.length > 0)
  );

  const history = useHistory();


  const handleQuantiyChanges = (val, prod) => {

    const { productList } = applicationState;
    const productlistArray = [...productList];

    // Find and update the product quantity
    const updatedProductList = productlistArray.map((product) => {
      if (product.productid === prod.productid) {
        return {
          ...product,
          quantity: val
        };
      } else {
        return product;
      }
    });

    const tempArray = [];

    updatedProductList.forEach((product) => {
      if (product.quantity && product.quantity !== 0) {
        tempArray.push(product);
      }
    });

    tempArray.length === 0
      ? setIsLocalCartEmpty(true)
      : setIsLocalCartEmpty(false);

    dispatch({
      type: EDIT_PRODUCT_QUANTITY,
      payload: updatedProductList,
      cartDetails: tempArray,
    });
    window.sessionStorage.setItem("cart", JSON.stringify(tempArray));
  };

  const rowData = () => {
    const { productList, tileClicked } = applicationState;
    const productlistArray = [];
    if (
      productList &&
      productList.length > 0 &&
      tileClicked &&
      tileClicked.length > 0
    ) {
      const filteredProductList = productList.filter((product) => {
        return product.producttype === tileClicked;
      });
      filteredProductList.forEach((rowdetail) => {
        productlistArray.push({
          ...rowdetail,
          quantity: rowdetail.quantity ? Math.abs(Number(rowdetail.quantity)) : 0,
        });
        return productlistArray;
      });
      // console.log(productlistArray);
      return productlistArray;
    } else {
      return productlistArray;
    }
  };

  const onProceed = (e) => {
    const {cartDetails, shippingCost, config} = applicationState;
    const itemList = cartDetails.map((item) => [
      item.productid,
      item.quantity,
    ]);
    let productidcartmap = Object.fromEntries(itemList);
    let subTotalValue = 0;
    let vatAmount = 0;
    const totalArray = cartDetails.map(
        (prod) => prod.salepriceperunit * prod.quantity
    );
    const vatArray = cartDetails.map((prod) => prod.vat);
    const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
    subTotalValue = totalArray.reduce(reducer);
    vatAmount = vatArray.reduce(reducer);

    const customerCartArray = {
      ordershippingcost: Number(shippingCost),
      productidcartmap,
      subtotal: Number(subTotalValue),
      totalvat: Number(vatAmount),
      userId: applicationState?.shippingAddressDetails?.email || "",
    };
    updateCartDetails({
      dispatch,
      customerCartArray,
      history,
      authToken: config.authToken,
    });

  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  return (
    <div id="productlist">
        <ToastContainer />
        {isLoading && (
          <div className="d-flex justify-content-center loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <div style={{marginBottom: '60px'}}>
          <HeaderMenu
            dispatch={dispatch}
            cartCount={cartDetails ? cartDetails.length : 0} />
        </div>


        {/* new design */}
        <div className="template_collection">
          <div className="container">
                <div className="c_productList flex flex-wrap">
                  {rowData().length ? rowData().map(item => {
                    return (
                    <div className={item?.warehouse?.["Online-Warehouse"] ? 'c_product_item' : 'c_product_item SoldOut'}>
                      <div className="card_product">
                          <div className="img_d">
                            {item?.productImageUrl ?
                              <img src={item?.productImageUrl} alt="img-1"/>
                             : <img src={image_1} alt="img-1"/>
                            }
                          </div>
                          <div className="card_content">
                            <a class="nav-link" href="javascript:void(0)"
                              onClick={() => {
                                dispatch({
                                  type: PRODUCT_DETAIL_DATA,
                                  payload: item,
                                });
                                history.push("/productdetail");
                              }}
                            >
                              <h3 className="pro_card_title">{truncateText(item.brand+' '+item.productdesc, 30)}</h3>
                            </a>
                              <div className="priceQty flex justify-content-between align-items-center">
                                  <div className="price_d">
                                      £ {item.salepriceperunit.toFixed(2)}
                                  </div>
                                  <div className="qty_stock">
                                  {
                                    item?.warehouse?.["Online-Warehouse"] ?
                                    <NewColumnQuantity params={item} handleQtyChanges={(val, prod) => handleQuantiyChanges(val, prod)} />
                                    : <span className="outOfStock">Out Of Stock</span>
                                  }
                                  </div>
                              </div>
                              {/* <div className="action_btn text-center">
                                  <a href="product.html" className="btn indigo-btn addToCart">Add to Cart</a>
                              </div> */}
                          </div>
                        </div>
                    </div>);
                  }) : <p className="text-center">No product were found. Please try a different category.</p> }
                </div>
            </div>
        </div>
        {/* new design */}

        <div
          className="container-fluid"
          style={{ width: "100%", height: "100%" }}
        >
          <div className="fixed-bottom text-center mrt-20">
            <button
              className="btn btn-secondary"
              type="submit"
              name="btn-checkout"
              id="btn-checkout"
              onClick={() => {
                dispatch({
                  type: SET_TILE_CLICKED,
                  payload: "",
                });
                history.push("/producttypes");
              } }
            >
              Back
            </button>
            <button
              className="btn btn-main"
              type="submit"
              name="btn-checkout"
              id="btn-checkout"
              onClick={(e) => onProceed(e)}
              disabled={isLocalCartEmpty}
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
  );
};
export default ProductList;
