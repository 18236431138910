import axios from "axios";
import { DELETE_CUSTOMER } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";

export const deleteCustomer = async (dispatch, email) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/deleteuser?email=${email}`;
    const response = await axios.post(apiUrl, { email }, apiRequestHeader);
    dispatch({
      type: DELETE_CUSTOMER,
      payload: email
    });
    return response;
  } catch(e) {
    return logError(e, 'deleteCustomer');
  }
}