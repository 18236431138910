import React from "react";
import HeaderMenu from "../common/HeaderMenu";
import { useHistory } from "react-router-dom";
import { SET_TILE_CLICKED } from "../../constants/actionTypes";
import extras from '../../assert/customer-design/EXTRAS.png';
import pdoThreads from '../../assert/customer-design/PDO_THREADS.png';
import skinBoosters from '../../assert/customer-design/SKIN_BOOSTERS.png';
import bodyFillers from '../../assert/customer-design/BODY_CONTOUR.png';
import consumables from '../../assert/customer-design/CONSUMABLES.png';
import dermalFillers from '../../assert/customer-design/DERMAL_FILLER.png';
import toxins from '../../assert/customer-design/TOXINS.png';
import img2 from '../../assert/customer-design/2.jpg';

const ProductListTiles = (props) => {
    const { applicationState, dispatch } = props;
    const { cartDetails } = applicationState;
    const history = useHistory();
    const onTileClick = (tileClicked) => {
        window.sessionStorage.setItem("tileClicked", tileClicked)
        dispatch({
            type: SET_TILE_CLICKED,
            payload: tileClicked
        })
        history.push("/productlist");
    }

    return (
        <div id="producttype">
            <div>
                <HeaderMenu dispatch={dispatch} cartCount={cartDetails ? cartDetails.length : 0} />
            </div>

            <div class="home_col_sec">
                <div class="container">
                    <div class="h_collectionList flex flex-wrap justify-content-center">
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Dermal Filler")}>
                                <div class="img_d">
                                    <img src={dermalFillers} alt="dermalFillers" />
                                </div>
                                <h3 class="DFColor">DERMAL FILLERS</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Body Filler")}>
                                <div class="img_d">
                                    <img src={bodyFillers} alt="bodyFillers" />
                                </div>
                                <h3 class="BFColor">BODY FILLERS</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Skin Booster")}>
                                <div class="img_d">
                                    <img src={skinBoosters} alt="skinBoosters" />
                                </div>
                                <h3 class="SBColor">SKIN BOOSTERS</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Fat Dissolver")}>
                                <div class="img_d">
                                    <img src={bodyFillers} alt="bodyFillers" />
                                </div>
                                <h3 class="FDColor">FAT DISSOLVERS</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Consumable")}>
                                <div class="img_d">
                                    <img src={consumables} alt="consumables" />
                                </div>
                                <h3 class="CColor">CONSUMABLES</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Extras")}>
                                <div class="img_d">
                                    <img src={extras} alt="extras" />
                                </div>
                                <h3 class="EColor">EXTRAS</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("PDO Threads")}>
                                <div class="img_d">
                                    <img src={pdoThreads} alt="pdoThreads" />
                                </div>
                                <h3 class="PHColor">PDO THREADS</h3>
                            </a>
                        </div>
                        <div class="item_d">
                            <a href="javascript:void(0)" onClick={() => onTileClick("Toxin")}>
                                <div class="img_d">
                                    <img src={toxins} alt="toxins" />
                                </div>
                                <h3 class="AWColor">ANTI WRINKLES</h3>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ProductListTiles;
