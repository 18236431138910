import React from "react";
import { useHistory } from "react-router-dom";

const NavigationBar = () => {
  const history = useHistory();

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm main-header" style={{marginTop:'82px'}}>
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown" id="myDropdown">
              <button
                className="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                Stock Management
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => history.push("/admin_product_list")}
                  >
                    Inventory
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => history.push("/stock_order")}
                  >
                    Stock Order
                  </button>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <button
                className="nav-link"
                onClick={() => history.push("/customer_list")}
              >
                Customers
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                // onClick={() => history.push("/walk_in_order")}
              >
                <a href="/walk_in_order">Walk In Order</a>
              </button>
            </li>
            <li className="nav-item dropdown" id="myDropdown">
              <button
                className="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                CRM
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => history.push("/crm")}
                  >
                    BA List
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => history.push("/crm")}
                  >
                    Black Aesthetics
                  </button>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                onClick={() => history.push("/admin_coupon_list")}
              >
                Coupons
              </button>
            </li>
            <li className="nav-item dropdown" id="myDropdown">
              <button
                className="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
              >
                Utility
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => history.push("/analytics_and_reports")}
                  >
                    Analytics & Reports
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
