import React from 'react';

export const ViewOrderColumn = (params) => {
    const {  data,  onCardClick} = params;
    return (
        <div>
        <>
            <button
                type="button"
                className="btn-icon"
                onClick={() =>{ onCardClick(data.id, data.currentstage)}}>
                <i className="fa fa-search icon-blue"></i>View
            </button>
        </>
        </div>

    );
}