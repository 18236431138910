import React from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { ProductQuantityEditor } from '../CRMTab/ProductQuantityEditor';
import { ScanProductButtonRenderer } from '../CRMTab/ScanProductButtonRenderer';
import { verifyStockOrderItem } from '../../../serviceCalls/scanApis';
import { downloadDpdLabel } from "../../../serviceCalls/downloadDpdLabel";

const StockOrderDetails = ({ orderInfo, fetchAllStockOrders, showEmailPopUp, dispatch, history, config, onClose, disableButtons }) => {

  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [productsOrderedArray, setProductsOrderedArray] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState([])

  React.useEffect(() => {
    const productOrderedListArray = [];
    orderInfo?.stockOrderdetailList.map((rowdetail) => {
      productOrderedListArray.push({
        ...rowdetail
      })
    });
    setProductsOrderedArray(productOrderedListArray);
  }, [orderInfo])

  const onGridReady = params => {
    setGridApi(params?.api);
    setGridColumnApi(params?.columnApi)
    params?.api?.sizeColumnsToFit()
  }

  const frameWorkComponentChange = ({ api, changedPrice }) => {
    const productOrderedArray = [];
    api.forEachNode((node) => {
      if (changedPrice && changedPrice?.data?.productid === node?.data?.productid && changedPrice?.data?.vat > 0) {
        node.data.vat = 0.20 * changedPrice?.data?.price;
      }
      let productOrderedObject = {
        ...node?.data,
        amount: Number((Number(node?.data?.quantity) * Number(node?.data?.price)) + (Number(node?.data?.quantity) * Number(node?.data?.vat)))
      };
      productOrderedArray.push(productOrderedObject);
    });
    setProductsOrderedArray(productOrderedArray);
  }

  const columnDefs = ({ frameWorkComponentChange }) => [
    { field: 'brand', headerName: "Brand" },
    { field: 'producttype', headerName: "Product Type" },
    { field: 'productdesc', headerName: "Description" },
    {
      field: 'quantity',
      headerName: "Quantity",
      editable: false
    },
    {
      field: 'scanProduct',
      headerName: "Scan Product",
      cellRenderer: "scanProductButtonRenderer",
      editable: false,
      cellRendererParams: {
        tickKey: "verified",
        onScan: (barcode) => {
          return verifyStockOrderItem(barcode, orderInfo?.id)
        },
        refetch: fetchAllStockOrders,
      }
    },
  ];

  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    sortable: true
  }), []);

  return (<>
    <div
      className="ag-theme-alpine product-detail-table"
      style={{ width: "100%" }}
    >
      <AgGridReact
        rowData={productsOrderedArray}
        columnDefs={columnDefs({
          frameWorkComponentChange: frameWorkComponentChange,
        })}
        gridOptions={{ rowHeight: 50 }}
        style={{ margin: 0 }}
        cellS
        rowSelection={'multiple'}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        context={{ frameWorkComponentChange: frameWorkComponentChange }}
        pagination={true}
        suppressRowClickSelection={true}
        frameworkComponents={{
          productQuantityEditor: ProductQuantityEditor,
          scanProductButtonRenderer: ScanProductButtonRenderer
        }}
        paginationAutoPageSize={true}
      ></AgGridReact>
      <button className='btn button-primary dpd button-danger' onClick={() => downloadDpdLabel(orderInfo.id,"stockorder")}>DPD Label</button>
    </div>
  </>)

}
export default StockOrderDetails