import axios from "axios";
import envConfig from "../envConfigurations";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";

export const ppsUpdateButtonApiCall = async ({ clearData , email}) => {
    let data = {
        prescriberemail: email,
        pharmaorders: clearData,
    };
    //! please uncomment the code to hit with update on live data

     let token = window.localStorage.getItem("sessionKey");
     const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": token }
    );
     let endpoint = `${envConfig.apiUrl}:${envConfig.apiPort}/user/updateprescriberpayment`

     let response = await axios.post(endpoint, {
        prescriberemail: email,
        pharmaorders: clearData,
    }, apiRequestHeader)
    // return response;

    window.location.reload();
};
