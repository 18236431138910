import axios from "axios";
import envConfig from "../envConfigurations";
import { downloadData, logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";

export const downloadUserId = async (email) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("GET",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/downloaduserid?email=${email}`;
    const response = await axios.get(apiUrl, apiRequestHeader);
    downloadData(response.data);
    return response;
  } catch(e) {
    return logError(e, 'downloadUserId');
  }
}