import * as React from "react";
import { useHistory } from "react-router-dom";
import { RESET_ALL_DATA } from "../../constants/actionTypes";
import profileSvg from "../../profile.svg";
import logo from "../../ion_home.svg";

const AdminHeaderMenu = ({ cartCount, dispatch }) => {
  const history = useHistory();
  const logout = (e) => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      window.sessionStorage.clear("cart");
      window.localStorage.clear();
      window.sessionStorage.clear();

      history.push("/signin");
      dispatch({
        type: RESET_ALL_DATA,
      });
    } else {
      e.preventDefault();
    }
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm navbar-fixed">
      <div className="container-fluid">
        <button onClick={() => history.push("/homepage")}>
          <h1 className="logo">
            <img src={logo} alt="logo" />
          </h1>
        </button>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main_nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown" id="myDropdown">
              <button className="nav-link" href="#" data-bs-toggle="dropdown">
                <img src={profileSvg} alt="Profile" />
              </button>
              <ul className="dropdown-menu dropdown-menu-logout">
                <li className="nav-item">
                  <button className="nav-link" onClick={(e) => logout(e)}>
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default AdminHeaderMenu;
