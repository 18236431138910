// npm run dev --> to start in development mode
//  npm run start --> to start in production mode

const devConfig = {
    apiUrl :  "https://stagegnbecslb-1287669108.eu-west-2.elb.amazonaws.com",
    apiPort: 8080,
    stripeApi: "https://stagegnbecslb-1287669108.eu-west-2.elb.amazonaws.com",
    stripeApiPort: 8000
}

const prodConfig = {
    apiUrl :  "https://balist.co.uk",
    apiPort: 8080,
    stripeApi: "https://balist.co.uk",
    stripeApiPort: 8000
}

export const addrApiConfig = {
    apiHost: 'https://api.getaddress.io/',
    apiKey: 'cjZ02cOjgUick_LrB8ZrHg34279'
}

const envConfig = process.env.REACT_APP_STAGE === 'production'
  ? prodConfig
  : devConfig;

export default {
  ...envConfig
};
