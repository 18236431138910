import React from "react";
import "./Group46.css";

function Group46(props) {
  const { className } = props;

  return (
    <div className={`group-46 border-1px-white ${className || ""}`}>
      <div className="next poppins-normal-white-30px">NEXT</div>
    </div>
  );
}

export default Group46;
