import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { addUserApiCall } from "../../../serviceCalls/addUserApiCall";
import { SUPPORTED_WAREHOUSE } from "../../../constants/common";

const AddUserModal = (props) => {
  const { config, dispatch, onClose, history, show } = props;
  const [newUserDetails, setNewUserDetails] = React.useState({
    address: "",
    city: "",
    postcode: "",
    name: "",
    location: SUPPORTED_WAREHOUSE.GLASGOW,
    email: "",
  });

  const { name, email, address, postcode, city, location } = newUserDetails;

  const onEdit = () => {
    const requestBodyForAdd = {
      name,
      email,
      address,
      postcode,
      city,
      location,
    };
    addUserApiCall({
      dispatch: dispatch,
      authToken: config.authToken,
      requestBodyForAdd: requestBodyForAdd,
      history: history,
      config: config,
    });
    onClose();
  };

  return (
    <>
      <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
        <div className="modal enter-done">
          <div
            className="modal-content"
            style={{ overflow: "auto", maxHeight: "600px" }}
          >
            <div className="modal-header">
              <h4 className="modal-title">Edit User Details</h4>
            </div>
            <div className="modal-body">
              <div style={{ display: "flex" }}>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="name">
                    Name<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) =>
                      setNewUserDetails({
                        ...newUserDetails,
                        name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group form-margin mb-3">
                  <label className="form-label" htmlFor="email">
                    Email<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="email"
                    placeholder="Example@abc.com"
                    value={email}
                    onChange={(e) =>
                      setNewUserDetails({
                        ...newUserDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
              <div className="form-group mb-3">
                        <label
                          className="form-label"
                          for="walkin-order-select"
                        >
                          Location<span class="required">*</span>
                        </label>
                        <select
                        style={{width:"100%"}}
                          className="form-select form-data"
                          id="walkin-order-select"
                          name="walkin-order-select"
                          value={location}
                          onChange={(e) => {
                            setNewUserDetails({
                              ...newUserDetails,
                              location: e.target.value,
                            });
                          }}
                        >
                          {SUPPORTED_WAREHOUSE.LIST.map((o) => (
                            <option key={o} value={o}>
                              {o}
                            </option>
                          ))}
                        </select>
                    </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" htmlFor="address">
                    Address<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="address"
                    placeholder="Address"
                    value={address}
                    onChange={(e) =>
                      setNewUserDetails({
                        ...newUserDetails,
                        address: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="city">
                    City<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={(e) =>
                      setNewUserDetails({
                        ...newUserDetails,
                        city: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" htmlFor="postcode">
                    Postcode<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="postcode"
                    placeholder="Postcode"
                    value={postcode}
                    onChange={(e) =>
                      setNewUserDetails({
                        ...newUserDetails,
                        postcode: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer text-align-center">
                <button
                  className="btn btn-main btn-update"
                  onClick={onEdit}
                >
                  Add
                </button>
                <button
                  className="btn btn-main "
                  onClick={() => onClose()}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default AddUserModal;
