import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_IS_LOADING, SET_ERROR } from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const updateTaCreditUserScore = async ({
  dispatch,
  authToken,
  payload,
}) => {
  dispatch({ type: SET_IS_LOADING, payload: true });

  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    {
      "x-auth-token": authToken,
      "Content-Type": "application/json",
      Cookie: "JSESSIONID=FA870331E3A5DC6DC1FBB6963BC9AFC0",
    },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/updatetacredituserScore`;
  await axios
    .post(apiUrl, payload, apiRequestHeader)
    .then(() => {
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_ERROR,
      });
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    });
};
