import React from "react";
import "./Group186.css";

function Group186(props) {
  const { x4Ab406B46B92, price1, price2, className } = props;

  return (
    <div className={`group-186 poppins-normal-mountain-mist-24px ${className || ""}`}>
      <div className="overlap-group-1">
        <div className="x4ab406b46b92">{x4Ab406B46B92}</div>
        <div className="name-2">Abbie Abhishek Kajare</div>
        <img className="line-7" src="/img/line-10-1@1x.png" />
      </div>
      <img className="line-8" src="/img/line-10-1@1x.png" />
      <div className="overlap-group-2">
        <div className="price-3 poppins-normal-mountain-mist-24px">{price1}</div>
        <img className="line-10" src="/img/line-10-1@1x.png" />
      </div>
      <div className="price-2">{price2}</div>
    </div>
  );
}

export default Group186;
