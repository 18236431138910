import * as React from "react";
import { useEffect, useState } from "react";
import envConfig from "../../../envConfigurations";
import axios from "axios";
import { makeApiRequestHeader } from "../../../utils/makeApiRequestHeader";
import DatePicker from "react-datepicker";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import "react-datepicker/dist/react-datepicker.css";
import { downloadUserId } from "../../../serviceCalls/downloadUserId";
import TextField from '@material-ui/core/TextField';
const CustomerProfileSection = (props) => {
  const {
    profileDetails,
    profileDetailsError,
    emptyCredentialsError,
    validateName,
    onNameChange,
    validateAddress,
    onAddressChange,
    validatePostcode,
    onPostcodeChange,
    validatePhone,
    onPhoneChange,
    setProfileDetails,
    validateSubmit,
    authToken
  } = props;
  const [commentValue, setCommentValue] = useState("");
  const [comments, setComments] = useState("");
  let userType = localStorage.getItem("userType");
  let userEmail = localStorage.getItem("email");
  const maxDate = new Date();
  const greenStyle = { style: { color: "green", top: '70%', right: '10px', transform: 'translateY(-50%)'} };
  const redStyle = { style: { color: "red",top: '70%', right: '10px', transform: 'translateY(-50%)' } };
  const insuranceexpirydate = profileDetails?.insuranceexpirydate ? new Date(profileDetails?.insuranceexpirydate) : null;
  const onIdFileChange = (e) => {
    setProfileDetails({
      ...profileDetails,
      idFile: e.target.files[0]
    })
  }

  useEffect(() => {
    fetchComments()

  }, [profileDetails?.email])

  const fetchComments = async () => {
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/admin/fetchusercomments?useremail=${profileDetails?.email}`;
    const apiRequestHeaderGET = makeApiRequestHeader(
      "GET",
      { "x-auth-token": authToken },
      null
    );
    await axios
      .get(apiUrl, apiRequestHeaderGET)
      .then((apiResponse) => {
        if (apiResponse?.status === 200) {
          setComments(apiResponse.data.userComments);
          setCommentValue("");
        }
      })
      .catch((error) => {
        console.log('Fetching comments Error ', error);
      });
  }

  const addComments = async () => {

    if (commentValue != '') {
      console.log('clicked', commentValue);

      const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/admin/addusercomment`;
      const apiRequestHeaderPOST = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
      );
      await axios
        .post(apiUrl, { useremail: profileDetails.email, usercomment: commentValue, commentaddedby: userEmail }, apiRequestHeaderPOST)
        .then((apiResponse) => {
          if (apiResponse?.status === 200) {
            setComments(apiResponse.data.userComments);
            setCommentValue("");
          }
        })
        .catch((error) => {
          console.log('Adding comments Error ', error);
        });
    }
  }


  return (
    <>
      <div className="bg-primary main-header">Personal Details</div>
      <div style={{ borderRadius: '15px', backgroundColor: '#F1EDFF', padding: '10px', height: 'auto', justifyContent: 'center', alignItems: 'center' }}>

        <div className="row form-card mt-4" >
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                First Name
              </label>
              <input
                type="text"
                className="form-control form-data"
                id="fname"
                placeholder="First Name"
                onChange={(e) => onNameChange(e)}
                onBlur={(e) => validateName(e)}
                value={profileDetails.firstname}
              />
            </div>

            {profileDetailsError.firstNameError ? (
              <span>{profileDetailsError.firstNameError}</span>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {/* <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="lname"
                placeholder="Last Name"
                onChange={(e) => onNameChange(e)}
                onBlur={(e) => validateName(e)}
                value={profileDetails.lastname}
              />
              <label htmlFor="floatingInput">Last Name</label>
            </div> */}
            <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Last Name
              </label>
              <input
                type="text"
                className="form-control form-data"
                id="lname"
                placeholder="Last Name"
                onChange={(e) => onNameChange(e)}
                onBlur={(e) => validateName(e)}
                value={profileDetails.lastname}
              />
            </div>
            {profileDetailsError.lastNameError ? (
              <span>{profileDetailsError.lastNameError}</span>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        <div className="row form-card mt-3">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {/* <div className="form-floating has-right-icon mb-3">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                value={profileDetails.email}
                disabled
              />
              <label htmlFor="floatingInput">Email Id</label>
              {profileDetails.emailverified ? <CheckIcon  {...greenStyle} /> : <ClearIcon {...redStyle} />}
            </div> */}
            <div className="form-group form-margin position-relative mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Email Id
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="email"
                  className="form-control form-data"
                  id="email"
                  placeholder="Email"
                  value={profileDetails.email}
                  disabled
                />
                {profileDetails.emailverified ? <CheckIcon className="position-absolute" {...greenStyle} /> :<ClearIcon className="position-absolute" {...redStyle} />}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {/* <div className="form-floating  mb-3">
              <input
                type="text"
                className="form-control"
                id="phoneNo"
                placeholder="Phone Number"
                onChange={(e) => onPhoneChange(e)}
                onBlur={(e) => validatePhone(e)}
                value={profileDetails.mobileno}
              />
              <label htmlFor="floatingInput">Phone Number</label>
              {profileDetails.smsverified ? <CheckIcon {...greenStyle} /> : <ClearIcon {...redStyle} />}
            </div> */}
            <div className="form-group form-margin position-relative mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Phone Number
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-data"
                  id="phoneNo"
                  placeholder="Phone Number"
                  onChange={(e) => onPhoneChange(e)}
                  onBlur={(e) => validatePhone(e)}
                  value={profileDetails.mobileno}
                />
                {profileDetails.smsverified ? <CheckIcon className="position-absolute" {...greenStyle} /> : <ClearIcon className="position-absolute" {...redStyle} />}
              </div>
            </div>
            {profileDetailsError.phoneNoError ? (
              <span>{profileDetailsError.phoneNoError}</span>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        <div className="row form-card mt-3">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {/* <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="instagramId"
                placeholder="Instagram Id"
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    instaname: e.target.value,
                  })
                }
                onBlur={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    instaname: e.target.value,
                  })
                }
                value={profileDetails.instaname}
              />
              <label htmlFor="floatingInput">Instagram Id</label>
            </div> */}
            {/* <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Instagram Id
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-data"
                  id="instagramId"
                  placeholder="Instagram Id"
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      instaname: e.target.value,
                    })
                  }
                  onBlur={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      instaname: e.target.value,
                    })
                  }
                  value={profileDetails.instaname}
                />
              </div>
            </div> */}
            <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Address Line 1
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-data"
                  id="addressLineOne"
                  placeholder="Address Line 1"
                  onChange={(e) => onAddressChange(e)}
                  value={
                    profileDetails.address
                      ? profileDetails.address?.addressLineOne
                      : ""
                  }
                />
              </div>
            </div>
            {profileDetailsError.addressLineOne ? (
              <span>{profileDetailsError.addressLineOne}</span>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {/* <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="Address"
                onChange={(e) => onAddressChange(e)}
                onBlur={(e) => validateAddress(e)}
                value={
                  profileDetails.address && profileDetails.address.length > 0
                    ? profileDetails.address[0].addressbody
                    : ""
                }
              />
              <label htmlFor="floatingInput">Address</label>
            </div> */}
            <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Address Line 2
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-data"
                  id="addressLineTwo"
                  placeholder="Address Line 2"
                  onChange={(e) => onAddressChange(e)}
                  value={
                    profileDetails.address
                      ? profileDetails.address?.addressLineTwo
                      : ""
                  }
                />
              </div>
            </div>
            {profileDetailsError.addressLineTwo ? (
              <span>{profileDetailsError.addressLineTwo}</span>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
        <div className="row form-card mt-3">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            {/* <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="postcode"
                placeholder="Postcode"
                onChange={(e) => onPostcodeChange(e)}
                onBlur={(e) => validatePostcode(e)}
                value={
                  profileDetails.address && profileDetails.address.length > 0
                    ? profileDetails.address[0].postcode
                    : ""
                }
              />
              <label htmlFor="floatingInput">Postcode</label>
            </div> */}
            <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                City
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-data"
                  id="city"
                  onChange={(e) => onAddressChange(e)}
                  placeholder="city"
                  value={
                    profileDetails.address
                      ? profileDetails.address?.city
                      : ""
                  }
                />
              </div>
            </div>
            {profileDetailsError.cityError ? (
              <span>{profileDetailsError.cityError}</span>
            ) : (
              <React.Fragment />
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="form-group form-margin mb-3">
              <label className="form-label" htmlFor="floatingInput">
                Postcode
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control form-data"
                  id="postcode"
                  placeholder="Postcode"
                  onChange={(e) => onPostcodeChange(e)}
                  onBlur={(e) => validatePostcode(e)}
                  value={
                    profileDetails.address
                      ? profileDetails.address?.postcode
                      : ""
                  }
                />
              </div>
            </div>
            {profileDetailsError.postCodeError ? (
              <span>{profileDetailsError.postCodeError}</span>
            ) : (
              <React.Fragment />
            )}


          </div>
        </div>
        <div className="row form-card mt-3">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="form-group form-margin mb-3 position-relative">
              <label htmlFor="trade_business" className="form-label">Trade of business</label>
              <div className="position-relative">
                <select
                  className="form-control form-data"
                  id="tradeofbuisness"
                  name="tradeofbuisness"
                  onChange={(e) =>
                    setProfileDetails({
                      ...profileDetails,
                      tradeofbuisness: e.target.value,
                    })
                  }
                  value={profileDetails.tradeofbuisness}
                >
                  <option value="Mobile Practitioners">Mobile Practitioners</option>
                  <option value="Prescriber">Prescriber</option>
                  <option value="Clinics">Clinics</option>
                  <option value="Training Academy">Training Academy</option>
                </select>
                <ArrowDropDownIcon className="position-absolute" style={{ top: '50%', right: '10px', transform: 'translateY(-50%)' }} />
              </div>
            </div>
            <div className="form-group form-margin mb-3">
                <label  className="form-label">Insurance Expiry Date:</label>
                <DatePicker
                  name="insuranceexpirydate"
                  className="form-control form-data"
                  value={insuranceexpirydate}
                  selected={insuranceexpirydate}
                  onChange={date => setProfileDetails({ ...profileDetails, insuranceexpirydate: date })}
                />

            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="form-group form-margin mb-3">
            <label htmlFor="floatingEmail" className="form-label">Upload Medical Insurance</label>

              <input
                type="file"
                id="floatingIdFile"
                className="form-control form-data"
                accept="image/*, application/pdf"
                onChange={(e) => onIdFileChange(e)}
                required
              />
              {
                profileDetails.insuranceDocUrl
                  ? <a target="_blank" href={profileDetails.insuranceDocUrl}><GetAppIcon /></a>
                  : null
              }
            </div>
          </div>
        </div>

        <div className="text-center mt-4">
          {emptyCredentialsError ? (
            <div className="mrb-20 error">{emptyCredentialsError}</div>
          ) : (
            <React.Fragment />
          )}
          <button type="text" className="btn btn-main" onClick={validateSubmit}>
            Update
          </button>
        </div>
        <div className="row form-card mt-3">
          <div className={`commentList comment-component ${!!comments ? `` : `empty`}`}>
            {
              userType == 'ADMIN' && comments.length != 0 ?
                comments && comments.map((element, index) => {
                  return <div className="comment-details">
                    <div className={`comments-row ${index % 2 ? `even` : `odd`}`}>
                      <div className="col-sm-12 col-xs-12">
                        <span>{element || "N/A"}</span>
                      </div>
                    </div>
                  </div>
                }) : <h6>No Comments Found</h6>}

          </div>
          <div className="add-comment text-center">
            <input className="input-comment" value={commentValue} onChange={(e) => setCommentValue(e.target.value)} />
            <button className="btn btn-main" onClick={() => addComments()}>
              Add  Comment
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfileSection;
