import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_IS_LOADING, SET_ERROR } from "../constants/actionTypes";


import envConfig from "../envConfigurations";

export const downloadTACredit = async ({
    dispatch,
    authToken,
    taemail,
    filetype,
    file
}) => {
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken},
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/downloadtadocs`;
    await axios
        .post(apiUrl, {
            taemail: taemail,
            filetype: filetype,
            file: file
            }, apiRequestHeader)
        .then((res) => {
            if (res.data) { 
                const linkSource = `data:${res.data.mediaType.type/res.data.mediaType.subType};base64,${res.data.context.entity}`;
                const downloadLink = document.createElement("a");
                const fileName = res.data && res.data.metadata['Content-Disposition'] ? res.data.metadata['Content-Disposition'][0].split(";")[1].split("=")[1] : `${filetype}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click()
            }
        })
        .catch(() => {
            dispatch({
                type: SET_ERROR,
            });
        });

        const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];
          
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);
          
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
          
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
          
            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
          }    
        };
