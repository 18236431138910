import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    SET_IS_LOADING,
    CPP_DISCOUNT
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const fetchCppDetails = async ({ authToken, dispatch, email}) => {

    let apiRequestHeader = makeApiRequestHeader(
        "GET",
        { "x-auth-token": authToken }
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcppdetails?email=${email}`;
  
    dispatch({ type: SET_IS_LOADING, payload: true });
    
    await axios
        .get(apiUrl, apiRequestHeader)
        .then((apiResponse) => {
            apiResponse.data.adminfee = Number(apiResponse.data.adminfee).toFixed(2);
            dispatch({
                type: CPP_DISCOUNT,
                payload: apiResponse.data
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        })
        .catch((error) => {
            dispatch({
                type: CPP_DISCOUNT,
                payload: {}
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
