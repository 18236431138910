import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_ERROR } from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const customUpdateCartDetails = async ({
  dispatch,
  customerCartArray,
  history,
  authToken,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": authToken },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/cart`;
  await axios
    .post(apiUrl, customerCartArray, apiRequestHeader)
    .then((apiResponse) => {
      history.push("/customercart_details");
    })
    .catch(() => {
      dispatch({
        type: SET_ERROR,
      });
    });
};
