import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_ERROR,
  SET_IS_LOADING,
  UPDATE_USER_DETAILS,
  SET_PROFILE_UPDATE_STATUS,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { getAllUserProfileApiCall } from "./getAllUserProfileApiCall"

export const updateUserProfile = async ({
  dispatch,
  requestBodyForEdit,
  config,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": config.authToken },
    null
  );
  const apiUrl =`${envConfig.apiUrl}:${envConfig.apiPort}/admin/edit_profile`;
  await axios
    .post(apiUrl, requestBodyForEdit, apiRequestHeader)
    .then((apiResponse) => {
      if (apiResponse?.status === 200) {
        dispatch({
          type: SET_PROFILE_UPDATE_STATUS,
          payload: apiResponse.data,
        });
    getAllUserProfileApiCall({ dispatch: dispatch, config: config })

      }
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
};
