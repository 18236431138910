import axios from "axios";
import { DELETE_ORDER_STOCK } from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { getAllStockOrders } from "./getAllStockOrders"

export const deleteStock = async ({dispatch, orderId, config}) => {
    try {
        const authToken = localStorage.getItem("sessionKey");
        const apiRequestHeader = makeApiRequestHeader("POST",
        {
          "x-auth-token": authToken,
          'Cookie': 'JSESSIONID=C5C78EDE67C2701EE652117A878F3FC6'
        }, null);
        const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/stockOrder/deletestockorder?stockid=${orderId}`;
        const response = await axios.post(apiUrl, { orderId }, apiRequestHeader);
        console.log('DELETE_ORDER_STOCK response ', response);
        dispatch({
          type: DELETE_ORDER_STOCK,
          payload: orderId
        });
        await getAllStockOrders({ dispatch: dispatch, config: config })
        return response;
      } catch(e) {
        return logError(e, 'deleteCustomer');
      }
}