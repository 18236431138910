import React, { useEffect, useRef, useState } from "react";
import { emailValidity } from "../../../utils/regexUtils";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import PlaceOrderAddItemModal from "./PlaceOrderAddItemModal";
import { RemoveItemColumn } from "./RemoveItemColumn";
import RemoveItemModal from "./RemoveItemModal";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { ToastContainer, toast } from "react-toastify";
import { fetchExistingCustomerDetails } from "../../../serviceCalls/fetchExistingCustomerDetails.js";
import { fetchExistingCustomerUsingId } from "../../../serviceCalls/fetchExistingCustomerUsingIdApiCall.js";

import {
  NO_DATA_FOUND,
  SET_TOTAL_AMOUNT,
  CONTINUE_WITH_NEW_USER,
} from "../../../constants/actionTypes";
import { STORAGE_KEYS, SUPPORTED_WAREHOUSE } from "../../../constants/common";
import "react-toastify/dist/ReactToastify.css";

import { ADMIN_ADD_ITEM_FOR_ORDER } from "../../../constants/actionTypes";
import NoDataFoundModal from "./NoDataFoundModal";
import CheckoutProgressBar from "../../CustomerCheckout/CheckoutProgressBar";
import { Spinner } from "react-bootstrap";
import NavigationBar from "../../common/NavigationBar.js";
import axios from "axios";
import { getSearchEmailId } from "../../../serviceCalls/getReportsApiCall.js";

const WalkInOrder = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const {
    cartDetails,
    subTotalAmount,
    totalVatAmount,
    totalAmount,
    shippingCost,
    config,
    existingCustomerData,
    existingCustomerDataUsingId,
    existingCustomerApiError,
    isLoading,
    isBulkDiscount,
    getSearchEmail
  } = applicationState;
  const cachedWarehouseValue = localStorage.getItem(
    STORAGE_KEYS.WALKING_SELECTED_WAREHOUSE
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    cachedWarehouseValue || SUPPORTED_WAREHOUSE.GLASGOW
  );
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [applyCoupon, setApplyCoupon] = React.useState(false);
  const [tempCart, setTempCart] = React.useState(cartDetails);
  const [email, setEmail] = useState(null);
  const [existingCustomerError, setExistingCustomerError] = React.useState({
    emailError: "",
  });
  const [isExist, setIsExist] = useState(null);
  const [showManualSelection, setShowManualSelection] = useState(false);
  const [showQrSelection, setShowQrSelection] = useState(false);
  const [barcodeScan, setBarcodeScan] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [getSearchEmailState, setGetSearchEmailState] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleQRClick = () => {
    setShowQrSelection(true);
    setShowManualSelection(false);
  };

  const handleManualClick = () => {
    setShowManualSelection(true);
    setShowQrSelection(false);
  };

  const persistWarehouseSelection = () => {
    localStorage.setItem(
      STORAGE_KEYS.WALKING_SELECTED_WAREHOUSE,
      selectedWarehouse
    );
  };

  const _setSelectedWarehouse = (warehouse) => {
    persistWarehouseSelection(warehouse);
    setSelectedWarehouse(warehouse);
  };

  React.useEffect(() => {
    const cartData = JSON.parse(window.sessionStorage.getItem("cart"));

    if (cartData) {
      setTempCart(cartData);
    }
    if (existingCustomerData?.firstname || existingCustomerData?.email) {
      setShippingAddressDetails({
        ...shippingAddressDetails,
        firstName: existingCustomerData.firstname ?? "",
        lastName: existingCustomerData.lastname ?? "",
        email: existingCustomerData.email ?? "",
        phoneNo: existingCustomerData.mobileno ?? "",
        address: existingCustomerData.address
        ? existingCustomerData.address ?.addressLineOne
          : "",
        postCode: existingCustomerData.address
        ? existingCustomerData.address ?.postcode
          : "",
        instagramId: existingCustomerData.instaname ?? "",
      });
    }
    if (existingCustomerApiError) {
      setExistingCustomerError({
        ...existingCustomerError,
        emailError: existingCustomerApiError,
      });
    }
    if (existingCustomerData?.email != "") {
      setIsExist(true);
    }
  }, [existingCustomerData?.email]);

  let bar = ""; // Initialize bar variable


  React.useEffect(() => {

    function handleKeyDown(e) {
      if (e.keyCode === 13 && bar.length > 3) {
        handleScan(bar);
        return;
      }

      if (e.keyCode === 16) {
        return;
      }

      bar += e.key;
      setTimeout(() => {
        bar = "";
      }, 100);
    }

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove event listener
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [bar]); // Include bar in the dependency array


  const handleScan = (data) => {

    setTimeout(() => {
      const scannedBarcode = data;
      console.log(scannedBarcode)

      findExistingCustomerUsingId(scannedBarcode)
      console.log(existingCustomerDataUsingId)
      setShowQrSelection(true);
      setShowManualSelection(false);
      if (scannedBarcode) {
        if (existingCustomerDataUsingId?.firstname || existingCustomerDataUsingId?.email) {
          setShippingAddressDetails({
            ...shippingAddressDetails,
            firstName: existingCustomerDataUsingId.firstname ?? "",
            lastName: existingCustomerDataUsingId.lastname ?? "",
            email: existingCustomerDataUsingId.email ?? "",
            phoneNo: existingCustomerDataUsingId.mobileno ?? "",
            address: existingCustomerDataUsingId?.address
               ? existingCustomerDataUsingId?.address ?.addressLineOne : existingCustomerDataUsingId?.address ? existingCustomerDataUsingId?.address :                
               "",
            postCode: existingCustomerData.address
               ? existingCustomerDataUsingId?.address?.postcode : existingCustomerDataUsingId?.address ? existingCustomerDataUsingId?.address.pincode :
               "",
            instagramId: existingCustomerDataUsingId?.instaname ?? "",
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        alert("Invalid barcode or product not found!");
      }
    }, 2000);
  }

  const handleBarcodeInput = () => {
    setLoading(true);

    const handleBarcodeScan = (event) => {
      setBarcodeScan(event.target.value);
    };
    document.addEventListener('input', handleBarcodeScan);
    return () => {
      document.removeEventListener('input', handleBarcodeScan);
    };
  };


  const settingAmountDetails = (shippingCost, discountedAmount = 0) => {
    dispatch({
      type: SET_TOTAL_AMOUNT,
      payload: {
        shippingCost,
        subTotalAmount: subTotalAmount,
        totalVatAmount: totalVatAmount,
        totalAmount:
          Number(subTotalAmount) +
          Number(totalVatAmount) +
          Number(shippingCost) -
          Number(discountedAmount).toFixed(2),
      },
    });
  };

  const [shippingAddressDetails, setShippingAddressDetails] = React.useState({
    firstName: applicationState?.shippingAddressDetails?.firstName ?? "",
    lastName: applicationState?.shippingAddressDetails?.lastName ?? "",
    email: applicationState?.shippingAddressDetails?.email ?? "",
    phoneNo: applicationState?.shippingAddressDetails?.phoneNo ?? "",
    address: applicationState?.shippingAddressDetails?.address ?? "",
    postCode: applicationState?.shippingAddressDetails?.postCode ?? "",
    instagramId: applicationState?.shippingAddressDetails?.instagramId ?? "",
    promoCode: "",
    tradeOfBusiness:
      applicationState?.shippingAddressDetails?.tradeOfBusiness ?? "",
  });

  React.useEffect(() => {
    setShippingAddressDetails(applicationState.shippingAddressDetails);
  }, [applicationState.shippingAddressDetails]);

  // Function to handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
    }
};

  useEffect(()=>{
      if(getSearchEmail?.length > 0){
        setIsDropdownOpen(true);
        setGetSearchEmailState(getSearchEmail)
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
      }
  },[getSearchEmail])



  const onClickHanlderEmail=(email)=>{
    setIsDropdownOpen(false)
    setEmail(email);
      setExistingCustomerError({
        ...existingCustomerError,
        emailError: emailValidity(email),
      });
  }

  const validateFindEmail = (e) => {
    e.preventDefault();
    const { value } = e?.target;
    if(value?.length >=3){
      setEmail(value);
      if (emailValidity(value)) {
        try {
          config?.authToken && getSearchEmailId({
            value,
            dispatch,
            signInResponse: {
              authToken: config.authToken,
              userType: config.userType,
            }
          })
          
        } catch (error) {
          console.log('error',error)
        }      
      } else {
        setExistingCustomerError({
          ...existingCustomerError,
          emailError:
            "Hmm. Can you double check your email address? There might be a typo in there somewhere.",
        });
      }
    }
    
  };

  const findExistingCustomer = (e) => {
    const isValid = emailValidity(email);
    if (isValid === true && email != "" && email != null) {
      setShippingAddressDetails({
        ...shippingAddressDetails,
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        address: "",
        postCode: "",
        instagramId: "",
      });
      setExistingCustomerError({
        ...existingCustomerError,
        emailError: "",
      });
      fetchExistingCustomerDetails({ dispatch, email, config });

      handleManualClick();
    } else {
      setExistingCustomerError({
        ...existingCustomerError,
        emailError:
          "Hmm. Can you double check your email address? There might be a typo in there somewhere.",
      });
    }
    e.preventDefault();
  };

  const findExistingCustomerUsingId = (userId) => {
    fetchExistingCustomerUsingId({ dispatch, userId, config });
  }

  const closePopup = (e) => {
    e.preventDefault();
    dispatch({
      type: NO_DATA_FOUND,
      payload: { error: "" },
    });
  };

  const placeOrder = () => {
    persistWarehouseSelection();
    history.push("place_order");
  };

  const continueExistingCustomer = (e) => {
    e.preventDefault();
    setIsExist(false);
    placeOrder();
  };

  const continueWithNewUser = (e) => {
    e.preventDefault();
    dispatch({
      type: CONTINUE_WITH_NEW_USER,
      payload: {
        firstname: "",
        lastname: "",
        instaname: "",
        mobileno: "",
        email: "",
        address: {
          addressLineOne: "",
          addressLineTwo: "",
          city: "",
          postcode: ""
          }
      },
    });

    placeOrder();
  };


  return (
    <div id="productlist" className="admin">
      {isLoading || loading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div className="container-fluid">
        <div style={{ marginTop: "90px" }}>
          {" "}
          <NavigationBar />
        </div>
      </div>

      {/* REMOVE THIS COMMENTED CODE AFTER API IMPLEMENTATION OF SCAN PRODUCT */}
      {/* <div className="walkInorder_div">
                <div className="card px-0 pb-0 mb-0">


                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-md-first order-last px-0 admin-shipaddress">
                            <div className="form-card walkInOrderInner_div">
                                <input
                                    type="text"
                                    name="existingCustomer"
                                    className="form-control"
                                    id="existingCustomer"
                                    value={email}
                                    placeholder="Existing Customer Email"
                                    onChange={(e) => { validateFindEmail(e)}}
                                />
                                {existingCustomerError.emailError ? (
                                    <span className="error">
                                        {existingCustomerError.emailError}
                                        <br />
                                    </span>
                                ) : (
                                    <React.Fragment />
                                )}
                                <select value={selectedWarehouse} className="form-select" id="walkin-order-select" onChange={e => _setSelectedWarehouse(e.target.value)}>
                                    {
                                        SUPPORTED_WAREHOUSE.LIST.map(o => (
                                            <option key={o} value={o}>{o}</option>
                                        ))
                                    }
                                </select>

                                <NoDataFoundModal
                                    title="No Data Found"
                                    onClose={(e) => closePopup(e)}
                                    show={existingCustomerApiError ? true : false}>
                                    <p>
                                        <strong>{existingCustomerApiError}</strong>
                                    </p>
                                </NoDataFoundModal>
                                { }
                                {
                                    isExist && email!=null ? <button
                                    className="next action-button btn-main walkInOrder_btn"
                                    type="submit"
                                    onClick={(e) => continueExistingCustomer(e)}
                                    style={{ fontSize: '14px' }}
                                >
                                    Continue Existing Customer
                                </button> : <button
                                    className="next action-button btn-main walkInOrder_btn"
                                    type="submit"
                                    onClick={(e) => findExistingCustomer(e)}
                                >
                                    Find Customer
                                </button>
                                }
                                &nbsp;
                                &nbsp;
                                <button
                                    className="next action-button btn-main walkInOrder_btn"
                                    type="submit"
                                    onClick={(e) => continueWithNewUser(e)}
                                    style={{ fontSize: '15px' }}

                                >
                                    Continue as new Customer
                                </button>
                            </div>

                        </div>
                    </div>

                </div>
            </div> */}
      {/* REMOVE THIS ABOVE COMMENTED CODE AFTER API IMPLEMENTATION OF SCAN PRODUCT */}
      <div className="container-fluid">
        <div className="manual-container">
          <div className="walkInorder_div">
            <div className="px-0 pb-0 mb-0">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-md-first order-last px-0 admin-shipaddress">
                  <div
                    className="form-card walkInOrderInner_div"
                    style={{
                      marginLeft:
                        showManualSelection && isExist && email != null
                          ? "0px"
                          : "400px",
                      marginRight:
                        showManualSelection && isExist && email != null
                          ? "20px"
                          : "10px",
                    }}
                  >
                    <div style={{ marginTop: "-50px" }}>
                      <div className="walkin-form-group">
                        <label
                          className="existingCustomer"
                          htmlFor="existingCustomer"
                        >
                          Email ID
                        </label>
                        <div>
                        <input
                          type="text"
                          name="existingCustomer"
                          className="form-control form-data walkin-form-data"
                          id="existingCustomer"
                          value={email}
                          onChange={(e) => {
                            validateFindEmail(e);
                          }}
                        />
                        {
                          isDropdownOpen ? <div ref={dropdownRef} className="d-flex flex-column gap-2" style={{background:"white", padding:"10px", position:"absolute", borderRadius:"10px", width:"300px", lineHeight:"30px", border:"1px solid"}}>
                          <>
                          {
                            getSearchEmailState?.map((el,i)=>{
                              return(
                                <a href="javascript:void(0)" className="pe-auto" onClick={()=> onClickHanlderEmail(el?.email)}>{el?.email}</a>
                              )
                            })
                          }</>

                          </div> : ""
                        }
                        
                        </div>
                      </div>
                     
                      {existingCustomerError.emailError ? (
                        <span className="error">
                          {existingCustomerError.emailError}
                          <br />
                        </span>
                      ) : (
                        <React.Fragment />
                      )}
                      <div className="walkin-form-group mb-3">
                        <label
                          className="walkin-order-select"
                          for="walkin-order-select"
                        >
                          Warehouse Location<span class="required">*</span>
                        </label>
                        <select
                          className="form-select form-data walkin-form-data"
                          id="walkin-order-select"
                          name="walkin-order-select"
                          value={selectedWarehouse}
                          onChange={(e) => _setSelectedWarehouse(e.target.value)}
                        >
                          {SUPPORTED_WAREHOUSE.LIST.map((o) => (
                            <option key={o} value={o}>
                              {o}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    { }
                    {/* {isExist && email != null ? (
                    <button
                      className="next action-button btn-main walkInOrder_btn"
                      type="submit"
                      onClick={(e) => continueExistingCustomer(e)}
                      style={{ fontSize: "14px" }}
                    >
                      Continue Existing Customer
                    </button>
                  ) :
                   (*/}
                    <button
                      className="next action-button btn-main walkInOrder_btn"
                      type="submit"
                      onClick={(e) => {
                        findExistingCustomer(e);
                      }}
                    >
                      Find Customer
                    </button>
                    {/* )} */}
                    &nbsp; &nbsp;
                    <button
                      className="next action-button btn-main walkInOrder_btn"
                      type="submit"
                      onClick={(e) => continueWithNewUser(e)}
                      style={{ fontSize: "15px",marginLeft:"50px"}}
                    >
                      Continue as new Customer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isExist && email != null ? (
  <span
    style={{
      transition: "margin-left 0.5s ease-in-out",
      marginLeft: showManualSelection ? "-50px" : "-100px", // Initial position off-screen
      opacity: showManualSelection ? 1 : 0
    }}
  >
    <div style={{ marginTop: "-35px" }}>
      <div className="walkin-manual-form-group">
        <label
          className="detailExistingCustomer mr-4"
          htmlFor="existingCustomer"
        >
          Name{" "}
        </label>
        <div className="input-wrapper">
          <input
            type="text"
            name="existingCustomer"
            className="form-control form-data walkin-form-data"
            id="existingCustomer"
            value={`${existingCustomerData?.firstname} ${existingCustomerData?.lastname}`}
            readOnly
          />
        </div>
      </div>
      <div className="walkin-manual-form-group">
        <label
          className="detailExistingCustomer mr-4"
          htmlFor="existingCustomer"
        >
          Email{" "}
        </label>
        <div className="input-wrapper">
          <input
            type="text"
            name="existingCustomer"
            className="form-control form-data walkin-form-data"
            id="existingCustomer"
            value={`${existingCustomerData?.email}`}
            readOnly
          />
        </div>
      </div>
      <div className="walkin-manual-form-group">
        <label
          className="detailExistingCustomer"
          htmlFor="existingCustomer"
        >
          Contact{" "}
        </label>
        <div className="input-wrapper">
          <input
            type="text"
            name="existingCustomer"
            className="form-control form-data walkin-form-data"
            id="existingCustomer"
            value={`${existingCustomerData?.mobileno}`}
            readOnly
          />
        </div>
      </div>
      <div className="walkin-manual-form-group">
        <label
          className="detailExistingCustomer"
          htmlFor="existingCustomer"
        >
          Address{" "}
        </label>
        <div className="input-wrapper">
          <textarea
            type="text"
            name="existingCustomer"
            className="form-control form-data walkin-form-data"
            id="existingCustomer"
            value={`${existingCustomerData?.address?.addressLineOne}`}
            readOnly
          ></textarea>
        </div>
      </div>
      </div>

      <button
        className="next btn-main action-button walkInOrder_NewSelection_btn"
        type="submit"
        onClick={(e) => continueExistingCustomer(e)}
        style={{ fontSize: "14px", marginTop: "13px" ,marginLeft:"65px" }} // Adjust margin-left to create space between buttons
      >
        Continue Above Selection
      </button>
  </span>
) : (
  <NoDataFoundModal
    title="No Data Found"
    onClose={(e) => closePopup(e)}
    show={existingCustomerApiError ? true : false}
  >
    <p>
      <strong>{existingCustomerApiError}</strong>
    </p>
  </NoDataFoundModal>
)}

        </div>

        <div>
          <br />
        </div>
        <div className="scan-qr-container">
          <div>
            <div className="form-card mb-4">
              <button
                className="next action-button btn-main walkInOrder_Scanbtn"
                type="submit"
                onClick={handleBarcodeInput}
                style={{
                  fontSize: "15px",
                  marginLeft: showQrSelection ? "0" : "350px",
                }}
              >
                Scan QR{" "}
              </button>
            </div>
          </div>
          <span
            style={{
              transition: "margin-left 0.5s ease-in-out",
              marginLeft: showQrSelection ? "50px" : "-100px", // Initial position off-screen
              opacity: showQrSelection ? 1 : 0, // Show text when visible
            }}
          >
            <div className="walkin-manual-form-group">
              <label
                className="detailExistingCustomer mr-4"
                htmlFor="existingCustomer"
              >
                Name{" "}
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="existingCustomer"
                  className="form-control form-data walkin-form-data"
                  id="existingCustomer"
                  value={`${existingCustomerDataUsingId?.firstname || ''} ${existingCustomerDataUsingId?.lastname || ''}`} // Make sure to use template literals to concatenate the first name and last name
                  readOnly // Add the readOnly attribute to make the input field read-only
                />
              </div>
            </div>
            <div className="walkin-manual-form-group">
              <label
                className="detailExistingCustomer mr-4"
                htmlFor="existingCustomer"
              >
                Email{" "}
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="existingCustomer"
                  className="form-control form-data walkin-form-data"
                  id="existingCustomer"
                  value={`${existingCustomerDataUsingId?.email || ''}`} // Make sure to use template literals to concatenate the first name and last name
                  readOnly // Add the readOnly attribute to make the input field read-only
                />
              </div>
            </div>
            <div className="walkin-manual-form-group">
              <label
                className="detailExistingCustomer"
                htmlFor="existingCustomer"
              >
                Contact{" "}
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="existingCustomer"
                  className="form-control form-data walkin-form-data"
                  id="existingCustomer"
                  value={`${existingCustomerDataUsingId?.mobileno || ''}`} // Make sure to use template literals to concatenate the first name and last name
                  readOnly // Add the readOnly attribute to make the input field read-only
                />
              </div>
            </div>
            <div className="walkin-manual-form-group">
              <label
                className="detailExistingCustomer"
                htmlFor="existingCustomer"
              >
                Address{" "}
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  name="existingCustomer"
                  className="form-control form-data walkin-form-data"
                  id="existingCustomer"
                  value={`${existingCustomerDataUsingId?.address?.addressLineOne}`} // Make sure to use template literals to concatenate the first name and last name
                  readOnly // Add the readOnly attribute to make the input field read-only
                />
              </div>
            </div>
            <button
              className="next btn-main action-button walkInOrder_NewSelection_btn"
              type="submit"
              onClick={(e) => continueExistingCustomer(e)}
              style={{ fontSize: "14px" }}
            >
              Continue Above Selection
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default WalkInOrder;
