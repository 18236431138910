import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    EXISTING_CUSTOMER_DETAILS,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const fetchExistingCustomerDetails = async ({
    dispatch,
    email,
    config
}) => {
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({ 
        type: NO_DATA_FOUND, 
        payload: { error: ""}
    });
    const apiRequestHeader = makeApiRequestHeader(
        "GET",
        { "x-auth-token": config.authToken },
        null
    );
    await axios
        .get(`${envConfig.apiUrl}:${envConfig.apiPort}/user/account/${email}`,
            apiRequestHeader)
        .then((apiResponse) => {
            dispatch({
                type: EXISTING_CUSTOMER_DETAILS,
                payload: apiResponse.data,
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        }).catch((error) => {
            dispatch({ 
                type: NO_DATA_FOUND, 
                payload: { error: error.response.data ?? "No profile found for user" }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
