import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_IS_LOADING,
  FETCH_REPORTS_DATA,
  FETCH_GP_REPORTS_DATA,
  GET_SEARCH_EMAIL,
  GET_SEARCH_PRODUCT,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const getAnalyticsAndReportsApiCall = async ({
  dispatch,
  signInResponse,
  orderType
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "GET",
    { "x-auth-token": signInResponse.authToken },
    null
  );

  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchreportdata?orderType=${orderType}`;
  const gpReportUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchGPreportdata`;

  await axios
    .get(apiUrl, apiRequestHeader)
    .then(({ data }) => {
      dispatch({
        type: FETCH_REPORTS_DATA,
        payload: data,
      });
    })
    .catch(() => {
      alert("There was some error while fetching data.")
      dispatch({
        type: FETCH_REPORTS_DATA,
        payload: [],
      });
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    });

  await axios
    .get(gpReportUrl, apiRequestHeader)
    .then(({ data }) => {

      dispatch({
        type: FETCH_GP_REPORTS_DATA,
        payload: data,
      });

    })
    .catch(() => {
      alert("There was some error while fetching data.")
      dispatch({
        type: FETCH_GP_REPORTS_DATA,
        payload: [],
      });
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    });
};

export const getAnalyticsAndReportsApiCallV2 = async ({
  dispatch,
  signInResponse,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "GET",
    { "x-auth-token": signInResponse.authToken },
    null
  );

  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/report/fetchreportdata`;
  
  await axios
    .get(apiUrl, apiRequestHeader)
    .then(({ data }) => {
      dispatch({
        type: FETCH_REPORTS_DATA,
        payload: data,
      });
    })
    .catch(() => {
      alert("There was some error while fetching data.")
      dispatch({
        type: FETCH_REPORTS_DATA,
        payload: [],
      });
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    });
};

export const getSearchEmailId = async ({
  value,
  dispatch,
  signInResponse,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "GET",
    { "x-auth-token": signInResponse.authToken },
    null
  );

  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/admin/search/emails/${value}`;
  
  await axios
    .get(apiUrl, apiRequestHeader)
    .then(({ data }) => {
     dispatch({
      type: GET_SEARCH_EMAIL,
      payload: data,
    });
    })
    .catch(() => {
      alert("There was some error while fetching data.")
      dispatch({
        type: GET_SEARCH_EMAIL,
        payload: [],
      });
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    });
};

export const getSearchProduct = async ({
  value,
  dispatch,
  signInResponse,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "GET",
    { "x-auth-token": signInResponse.authToken },
    null
  );

  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/product/search/brand/${value}`;
  
  await axios
    .get(apiUrl, apiRequestHeader)
    .then(({ data }) => {
     dispatch({
      type: GET_SEARCH_PRODUCT,
      payload: data,
    });
    })
    .catch(() => {
      alert("There was some error while fetching data.")
      dispatch({
        type: GET_SEARCH_PRODUCT,
        payload: [],
      });
      dispatch({
        type: SET_IS_LOADING,
        isLoading: false,
      });
    });
};

