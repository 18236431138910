import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { addProductApiCall } from "../../../serviceCalls/addProductApiCall";
import { SET_IS_LOADING } from "../../../constants/actionTypes";

const AddProductModal = (props) => {
  const { config, dispatch, onClose, history } = props;
  const [newProductDetails, setNewProductDetails] = React.useState({
    productType: "",
    productBrand: "",
    productDescription: "",
    productPrice: "",
    productWarehouse: "",
    productWareHouseStock: "",
    productVat: "",
    productShortCode: "",
    productStockYellow: "",
    productStockRed: "",
    productStatus: "",
  });

  const [newProductDetailsError, setNewProductDetailsError] = React.useState({
    productTypeError: "",
    productBrandError: "",
    productDescriptionError: "",
    productPriceError: "",
    productWarehouseError: "",
    productWareHouseStockError: "",
    productVatError: "",
    productShortCodeError: "",
    productStockYellowError: "",
    productStockRedError: "",
    productStatusError: "",
  });

  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);

  const validateValue = (value, fieldName) => {
    value === ""
      ? setNewProductDetailsError({
          ...newProductDetailsError,
          [`${fieldName}Error`]: "Please enter a proper value",
        })
      : setNewProductDetailsError({
          ...newProductDetailsError,
          [`${fieldName}Error`]: "",
        });
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const {
    productType,
    productBrand,
    productDescription,
    productPrice,
    productWarehouse,
    productWareHouseStock,
    productVat,
    productShortCode,
    productStockYellow,
    productStockRed,
    productStatus,
  } = newProductDetails;

  const {
    productTypeError,
    productBrandError,
    productDescriptionError,
    productPriceError,
    productWareHouseStockError,
    productVatError,
    productShortCodeError,
    productStockYellowError,
    productStockRedError,
    productStatusError,
  } = newProductDetailsError;

  const onAdd = (e) => {
    e.preventDefault();
    if (
      productTypeError ||
      productBrandError ||
      productDescriptionError ||
      productPriceError ||
      productWareHouseStockError ||
      // productVatError ||
      productStockYellowError ||
      productStockRedError ||
      productStatusError
    ) {
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try?"
      );
    } else if (
      productType === "" ||
      productBrand === "" ||
      productDescription === "" ||
      productPrice === "" ||
      // productWarehouse === "" ||
      productWareHouseStock === "" ||
      // productVat === "" ||
      productStockYellow === "" ||
      productStockRed === "" ||
      productStatus === ""
    ) {
      setEmptyCredentialsError(
        "Looks like you're missing something! Do you want to give it another try?"
      );
    } else {
      const nProductPrice = Number(productPrice);
      dispatch({ type: SET_IS_LOADING, payload: true });
      const requestBodyForAdd = {
        brand: productBrand,
        producttype: productType,
        productdesc: productDescription,
        salepriceperunit: productPrice,
        numberofstock: 181,
        createDate: "2023/01/01 12:51:23",
        vat: isChecked ? Number((nProductPrice * 0.20).toFixed(2)) : 0,
        threshold: productStockYellow,
        breakpoint: productStockRed,
        warehouse: {
          ["Online-Warehouse"]: productWareHouseStock,
        },
        shortcode: productShortCode ? productShortCode : "N/A",
        fromwarehouse: "",
        productstatus: productStatus,
      };
      addProductApiCall({
        dispatch: dispatch,
        authToken: config.authToken,
        requestBodyForAdd: requestBodyForAdd,
        history: history,
        config: config,
      });
      onClose();
    }
  };

  return (
    <>
      <CSSTransition
        in={props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
      >
        <div className="modal enter-done">
          <div
            className="modal-content"
            style={{ overflow: "auto", maxHeight: "600px" }}
          >
            <div className="modal-header">
              <h4 className="modal-title">Add New Product</h4>
            </div>
            <div className="modal-body">
              <div style={{ display: "flex" }}>
                <div className="form-group mb-3">
                  <label className="form-label" for="productType">
                    Product Type<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productType"
                    value={productType}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productTypeError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productType: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) => validateValue(e.target.value, "productType")}
                  />
                  {/* <label for="floatingInput">
                    Product Type<span class="required">*</span>
                  </label> */}
                  {productTypeError ? (
                    <span className="error">{productTypeError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" htmlFor="productBrand">
                    Product Brand<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productBrand"
                    value={productBrand}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productBrandError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productBrand: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) =>
                      validateValue(e.target.value, "productBrand")
                    }
                  />
                  {/* <label for="floatingInput">
                    Product Brand<span class="required">*</span>
                  </label> */}
                  {productBrandError ? (
                    <span className="error">{productBrandError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="form-group mb-3">
                  <label className="form-label" for="productDescription">
                    Product Description<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productDescription"
                    value={productDescription}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productDescriptionError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productDescription: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) =>
                      validateValue(e.target.value, "productDescription")
                    }
                  />
                  {productDescriptionError ? (
                    <span className="error">{productDescriptionError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" for="productPrice">
                    Product Price<span class="required">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control form-data"
                    id="productPrice"
                    value={productPrice}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productPriceError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productPrice: e.target.value
                          ? Number(e.target.value)
                          : 0,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) =>
                      validateValue(e.target.value, "productPrice")
                    }
                  />
                  {productPriceError ? (
                    <span className="error">{productPriceError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {/* <div className="form-group mb-3">
                  <select
                    className="form-select"
                    id="productWarehouse"
                    name="productWarehouse"
                    value={productWarehouse}
                    onChange={(e) => {
                      setNewProductDetails({
                        ...newProductDetails,
                        productWarehouse: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                  >
                    <option value="">Please select</option>
                    <option value="Online-Warehouse">Online-Warehouse</option>
                    <option value="Glasgow">Glasgow</option>
                    <option value="London">London</option>
                    <option value="Hale">Hale</option>
                  </select>
                  <label className="form-label" for="productWarehouse">
                    Product Warehouse<span class="required">*</span>
                  </label>
                </div> */}
                <div className="form-group mb-3">
                  <label className="form-label" for="productStatus">
                    Status<span class="required">*</span>
                  </label>
                  <select
                    className="form-select form-data"
                    id="productStatus"
                    name="productStatus"
                    value={productStatus}
                    onChange={(e) => {
                      setNewProductDetails({
                        ...newProductDetails,
                        productStatus: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                  >
                    <option value="">Please select</option>
                    <option value="live">Live</option>
                    <option value="draft">Draft</option>
                  </select>
                </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" for="productWareHouseStock">
                    Product Warehouse Stock<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productWareHouseStock"
                    value={productWareHouseStock}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productWareHouseStockError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productWareHouseStock: e.target.value
                          ? Number(e.target.value)
                          : 0,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) =>
                      validateValue(e.target.value, "productWareHouseStock")
                    }
                  />
                  {productWareHouseStockError ? (
                    <span className="error">{productWareHouseStockError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="form-group mb-3">
                  <label className="form-label" for="productVat">
                    Product Vat<span class="required">*</span>
                  </label>
                  {/* <input
                    type="text"
                    className="form-control form-data"
                    id="productVat"
                    value={productVat}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productVatError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productVat: e.target.value ? Number(e.target.value) : 0,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) => validateValue(e.target.value, "productVat")}
                  />
                  {productVatError ? (
                    <span className="error">{productVatError}</span>
                  ) : (
                    <React.Fragment />
                  )} */}
                  <input
                    type="checkbox"
                    className="form-data"
                    id="productVat"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" for="productShortCode">
                    Product Short Code
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productShortCode"
                    value={productShortCode}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productShortCodeError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productShortCode: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                  />
                  {productShortCodeError ? (
                    <span className="error">{productShortCodeError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div className="form-group mb-3">
                  <label className="form-label" for="productStockYellow">
                    Product Stock Yellow<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productStockYellow"
                    value={productStockYellow}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productStockYellowError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productStockYellow: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) =>
                      validateValue(e.target.value, "productStockYellow")
                    }
                  />
                  {productStockYellowError ? (
                    <span className="error">{productStockYellowError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="form-group form-margin mb-3">
                  <label className="form-label" for="productStockRed">
                    Product Stock Red<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-data"
                    id="productStockRed"
                    value={productStockRed}
                    onChange={(e) => {
                      setNewProductDetailsError({
                        ...newProductDetailsError,
                        productStockRedError: "",
                      });
                      setNewProductDetails({
                        ...newProductDetails,
                        productStockRed: e.target.value,
                      });
                      setEmptyCredentialsError("");
                    }}
                    onBlur={(e) =>
                      validateValue(e.target.value, "productStockRed")
                    }
                  />
                  {productStockRedError ? (
                    <span className="error">{productStockRedError}</span>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
              <div className="modal-footer text-align-center">
                {emptyCredentialsError ? (
                  <div className="mrb-20 error">{emptyCredentialsError}</div>
                ) : (
                  <React.Fragment />
                )}
                <button className="btn btn-main" onClick={(e) => onAdd(e)}>
                  Add
                </button>
                <button
                  className="btn floating-modal-btn btn-secondary"
                  onClick={() => onClose()}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
export default AddProductModal;
