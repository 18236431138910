import * as React from 'react';


const Footer = () => {
    return (
      <footer>
      <p>
        <small>&copy; 2021 Copyright. BA LIST</small>
      </p>
    </footer>
    );
  }
export default Footer