import React from "react";
import { Button } from "react-bootstrap";


export const CustomerDownloadButton = (param, downloadButtonClicked) => {
    return (
        <div className="" >
            <span >
                {/* <ToggleButton
                    value={param.data.notification}
                    onToggle={(value)=> {
                        downloadButtonClicked(!value, param.data)
                    }}
                    thumbStyle={{ borderRadius: 2 }}
                    trackStyle={{ borderRadius: 2 }}
                /> */}
            
                <Button size="sm" onClick={()=>downloadButtonClicked(param?.data|| "N/A")}>Download</Button>
            </span>
        </div >
    )
};

const changeValue = (value) =>
{
    return !value;
}
