import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    SET_IS_LOADING,
    CPP_UPDATE,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const updateCPPDetails = async ({authToken, dispatch, cppUpdateRequest}) => {

    let apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken }
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/updatecppdetails`;
  
    dispatch({ type: SET_IS_LOADING, payload: true });
    
    await axios
        .post(apiUrl, cppUpdateRequest, apiRequestHeader)
        .then((apiResponse) => {
            dispatch({
                type: CPP_UPDATE,
                payload: { message: apiResponse.data, status: 'success' }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        })
        .catch((error) => {
            dispatch({
                type: CPP_UPDATE,
                payload: { message: error?.response?.data ?? "Please try again", status: 'error'}
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
