import axios from "axios";
import envConfig from "../envConfigurations";

export const prescriberApiCall = async () => {

 

    let prescriberEmail = window.localStorage.getItem("email");
    let token = window.localStorage.getItem("sessionKey");

    const apiurl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/fetchppsdata?prescriberemail=${prescriberEmail}`;
    let response = await axios.get(apiurl, {
        headers: {
            "x-auth-token": token,
            Cookie: "JSESSIONID=D13B99007872825FED10DF9BAD8C1362",
        },
    });

    return response;

  
};
