import axios from "axios";
import { toast } from "react-toastify";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_ERROR,
  SET_IS_LOADING,
  UPDATE_USER_DETAILS,
  SET_PROFILE_UPDATE_STATUS,
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_NOTIFICATION_STATUS,
  SET_NOTIFICATION_SPINNER,
  UPDATE_CUSTOMER_APPROVAL_STATUS
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const updateCustomerApprovalStatus = async ({
  dispatch,
  payload,
  authToken,
  history
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": authToken },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/approveuser`;
  dispatch({
    type: SET_NOTIFICATION_SPINNER,
    isSpinnerEnabled: true,
  });
  await axios
    .post(apiUrl, payload, apiRequestHeader)
    .then((apiResponse) => {
      if (apiResponse?.status === 200) {
        dispatch({
          type: UPDATE_CUSTOMER_APPROVAL_STATUS,
          payload,
        });
      }

      dispatch({
        type: SET_NOTIFICATION_SPINNER,
        isSpinnerEnabled: false,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_NOTIFICATION_SPINNER,
        isSpinnerEnabled: false,
      });
      toast("Failed to change the status");
    });
};
