import axios from "axios";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";

export const getTransactionReport = async (data) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/transactionreport`;
    const response = await axios.post(apiUrl, data, apiRequestHeader);
    return response;
  } catch(e) {
    return logError(e, 'getTransactionReport');
  }
}

export const updateTransaction = async (data) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/updatetransaction`;
    const response = await axios.post(apiUrl, data, apiRequestHeader);
    return response;
  } catch(e) {
    return logError(e, 'getTransactionReport');
  }
}
