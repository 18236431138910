import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';


import { Row, Col, Card, Button } from 'react-bootstrap'
import { getAnalyticsAndReportsApiCall } from '../../../serviceCalls/getReportsApiCall';
import { AgGridReact } from "ag-grid-react";
import { exportLastOrders, exportMultiRangeOrders } from "../../../serviceCalls/exportLastOrders";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import moment from 'moment'
import {
  SET_IS_LOADING,
} from "../../../constants/actionTypes";

const AnalyticsAndReports = (props) => {
  const gridRef = useRef();
  const { applicationState, dispatch } = props;
  const { config, reportsData, gpReportsData } = applicationState;
  const [orderType, setOrderType] = useState('ALL')

  var curr = new Date();
  var startOfWeek = moment().startOf('week').toDate();
  var endOfWeek = moment().endOf('week').toDate();
  var startOfMonth = new Date(curr.getFullYear(), curr.getMonth(), 1);
  var endOfMonth = new Date(curr.getFullYear(), curr.getMonth() + 1, 0);

  const [multiDate, setMultiDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const exportLastOrderDocument = (e) => {
    e.preventDefault();
    dispatch({ type: SET_IS_LOADING, payload: true });
    exportLastOrders({
      dispatch,
      authToken: config.authToken,
    });
  };
  useEffect(() => {

    config?.authToken && getAnalyticsAndReportsApiCall({
      dispatch: dispatch,
      signInResponse: {
        authToken: config.authToken,
        userType: config.userType,

      },
      orderType: orderType

    })

    if (gpReportsData?.gpreports) {

      gridRef.current.api.setPinnedBottomRowData([{
        productname: 'Total',
        weeklyunitssold: gpReportsData.weeklyunitssold,
        weeklyunitssoldpercent: '100',
        "producttotalsaleweekly": gpReportsData.weeklytotalsale,
        "producttotalcostweekly": gpReportsData.weeklytotalcost,
        "producttotalgrossprofitweekly": gpReportsData.weeklytotalgrossprofit,
        "producttotalgrossprofitpercentweekly": gpReportsData.weeklytotalgrossprofitpercent,
        monthlyunitssold: gpReportsData.monthlyunitssold,
        monthlyunitssoldpercent: '100',
        "producttotalsalemonthly": gpReportsData.monthlytotalsale,
        "producttotalcostmonthly": gpReportsData.monthlytotalcost,
        "producttotalgrossprofitmonthly": gpReportsData.monthlytotalgrossprofit,
        "producttotalgrossprofitpercentmonthly": gpReportsData.monthlytotalgrossprofitpercent,

      }])
    }

  }, [config?.authToken, orderType, gpReportsData?.length]);
  const exportExcelWithMultiRange =(type) => {
    let startDate = multiDate[0].startDate;
    // console.log('moment(startDate)v:',moment(startDate).format('DD-MM-YYYY'));
    startDate = moment(startDate).format('DD-MM-YYYY');
    let endDate = multiDate[0].endDate;
    endDate = moment(endDate).format('DD-MM-YYYY')
    dispatch({ type: SET_IS_LOADING, payload: true });
    exportMultiRangeOrders({
      dispatch,
      authToken: config.authToken,
      startdate: startDate,
      enddate: endDate,
      type: type
    })
  };

  const onSelectChange = (e) => {
    setOrderType(e.target.value)
  }

  const colDefs = [
    { field: 'productname', headerName: 'Product' },
    { field: 'productbrand', headerName: 'Product Type' },

    { field: 'productdesc', headerName: 'Description' },

    {
      headerName: 'STOCK',
      marryChildren: true,

      children: [
        {
          headerName: 'Units Cost', field: 'productcostprice', width: 100,
        },
        {
          headerName: 'Units Sale', field: 'productsaleprice', width: 100,
        },
        {
          headerName: 'Units Gp', field: 'productgrossprofit', width: 100,
        },
      ],
    },
    {
      headerName: 'weekly ' + moment(startOfWeek).format("DD/MM") + '-' + moment(endOfWeek).format("DD/MM"),
      marryChildren: true,
      children: [
        {
          headerName: 'Units Sold', field: 'weeklyunitssold', headerClass: 'analytics-color-blue', width: 100,
          cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {

              return { backgroundColor: '#b4c6e7' };
            }
            return null;
          }
        },
        {
          headerName: 'Units % Sold', field: 'weeklyunitssoldpercent', width: 100, headerClass: 'analytics-color-blue', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {

              return { backgroundColor: '#b4c6e7' };
            }
            return null;
          },
          cellRenderer: params => params.value ? Number(params.value).toFixed(2) + ' %' : '0.00 %'
        },
        {
          headerName: 'Total Cost', field: 'producttotalcostweekly', width: 100, headerClass: 'analytics-color-pink', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {

              return { backgroundColor: '#e3adab' };
            }
            return null;
          },
          cellRenderer: params => params.value ? '£ ' + Number(params.value).toFixed(2) : '£ 0.00'
        },
        {
          headerName: 'Total Sale', field: 'producttotalsaleweekly', width: 100, headerClass: 'analytics-color-pink', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {
              return { backgroundColor: '#e3adab' };
            }
            return null;
          },
          cellRenderer: params => params.value ? '£ ' + Number(params.value).toFixed(2) : '£ 0.00'
        },
        {
          headerName: 'Total Gp', field: 'producttotalgrossprofitweekly', width: 100, headerClass: 'analytics-color-green', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {
              //mark police cells as red
              return { backgroundColor: '#c6e0b4' };
            }
            return null;
          },
          cellRenderer: params => params.value ? '£ ' + Number(params.value).toFixed(2) : '£ 0.00'
        },
        {
          headerName: 'Gp % Sold', field: 'producttotalgrossprofitpercentweekly', width: 100, headerClass: 'analytics-color-green', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {
              //mark police cells as red
              return { backgroundColor: '#c6e0b4' };
            }
            return null;
          },
          cellRenderer: params => params.value ? Number(params.value).toFixed(2) + ' %' : '0.00 %'
        },
      ],
    },
    {
      headerName: 'monthly ' + moment(startOfMonth).format("DD/MM") + '-' + moment(endOfMonth).format("DD/MM"),
      marryChildren: true,
      children: [
        {
          headerName: 'Units Sold', field: 'monthlyunitssold', width: 100, headerClass: 'analytics-color-blue',
          cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {

              return { backgroundColor: '#b4c6e7' };
            }
            return null;
          }
        },
        {
          headerName: 'Units % Sold', field: 'monthlyunitssoldpercent', width: 100, headerClass: 'analytics-color-blue', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {

              return { backgroundColor: '#b4c6e7' };
            }
            return null;
          },
          cellRenderer: params => params.value ? Number(params.value).toFixed(2) + ' %' : '0.00 %'
        },
        {
          headerName: 'Total Cost', field: 'producttotalcostmonthly', width: 100, headerClass: 'analytics-color-pink', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {

              return { backgroundColor: '#e3adab' };
            }
            return null;
          },
          cellRenderer: params => params.value ? '£ ' + Number(params.value).toFixed(2) : '£ 0.00'
        },
        {
          headerName: 'Total Sale', field: 'producttotalsalemonthly', width: 100, headerClass: 'analytics-color-pink', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {
              return { backgroundColor: '#e3adab' };
            }
            return null;
          },
          cellRenderer: params => params.value ? '£ ' + Number(params.value).toFixed(2) : '£ 0.00'
        },
        {
          headerName: 'Total Gp', field: 'producttotalgrossprofitmonthly', width: 100, headerClass: 'analytics-color-green', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {
              //mark police cells as red
              return { backgroundColor: '#c6e0b4' };
            }
            return null;
          },
          cellRenderer: params => params.value ? '£ ' + Number(params.value).toFixed(2) : '£ 0.00'
        },
        {
          headerName: 'Gp % Sold', field: 'producttotalgrossprofitpercentmonthly', width: 100, headerClass: 'analytics-color-green', cellStyle: params => {
            if (params.value !== '' && !params.node.rowPinned) {
              //mark police cells as red
              return { backgroundColor: '#c6e0b4' };
            }
            return null;
          },
          cellRenderer: params => params.value ? Number(params.value).toFixed(2) + ' %' : '0 %'
        },
      ],
    },
  ];


  const columnTypes = {
    nonEditableColumn: { editable: false },
    dateColumn: {
      filter: 'agDateColumnFilter',

      suppressMenu: true,
    },
  };

  const pinnedBottomRowData = useMemo(() => {

    if (gpReportsData?.gpreports) {
      return [{
        productname: 'Total',
        weeklyunitssold: gpReportsData.weeklyunitssold,
        weeklyunitssoldpercent: '100%',
        "producttotalsaleweekly": '£ ' + Number(gpReportsData.weeklytotalsale).toFixed(2),
        "producttotalcostweekly": '£ ' + Number(gpReportsData.weeklytotalcost).toFixed(2),
        "producttotalgrossprofitweekly": '£ ' + Number(gpReportsData.weeklytotalgrossprofit).toFixed(2),
        "producttotalgrossprofitpercentweekly": gpReportsData.weeklytotalgrossprofitpercent,
        monthlyunitssold: gpReportsData.monthlyunitssold,
        monthlyunitssoldpercent: '100%',
        "producttotalsalemonthly": '£ ' + Number(gpReportsData.monthlytotalsale).toFixed(2),
        "producttotalcostmonthly": '£ ' + Number(gpReportsData.monthlytotalcost).toFixed(2),
        "producttotalgrossprofitmonthly": '£ ' + Number(gpReportsData.monthlytotalgrossprofit).toFixed(2),
        "producttotalgrossprofitpercentmonthly": Number(gpReportsData.monthlytotalgrossprofitpercent).toFixed(2),

      }]
    }

  }, []);

  const getRowStyle = useCallback(function (params) {
    if (params.node.rowPinned) {
      return { 'background': '#7984a3' };
    }
  }, []);



  return (
    <div>
      <div id='content-area' className='h-100 col px-5 py-5'>

        <div className="page-title-container">
          <Row>
            {/* Title Start */}
            <Col md="7">
              {/* <h1 className="mb-0 pb-0 display-4 ">Analytics and Reports</h1> */}
            </Col>
            {/* Title End */}
          </Row>
        </div>

        {/* Analytics Start */}
        <Row className=''>
          <Col lg="12" className="mb-5">
            <h2 className="small-title">Analytics</h2>
            <Row>
              <Col lg='4' xs='12' className='mb-4'>
                <select
                  className="form-control"
                  id=""
                  name=""
                  placeholder='select filter'
                  onChange={onSelectChange}

                >
                  <option value="ALL">All</option>
                  <option value="STORE">Store</option>
                  <option value="INSTA_SITE">Insta</option>
                  <option value="SHOPIFY">Wholesale</option>
                </select>
              </Col>
            </Row>
            <Row className="g-2 analytics-container-row">

              <Col xs="12" xl="2" className="sh-19">
                <Card className="h-100 hover-scale-up tile-one" >
                  <Card.Body className="text-center">
                    <img src='./sales.png' alt='sales' className='analytics-images' />

                    <p className="heading mt-3">Total Sales</p>
                    <div className="text-extra-small fw-medium ">{reportsData ? '£ ' + Number(reportsData.totalSales).toFixed(2) : ''}</div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" xl="2" className="sh-19">
                <Card className="h-100 hover-scale-up tile-two">
                  <Card.Body className="text-center">
                    <img src='./shopping-bag.png' alt='sales' className='analytics-images' />


                    <p className="heading mt-3 ">Total Orders</p>
                    <div className="text-extra-small fw-medium ">{reportsData ? reportsData.totalOrders : 0}</div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" xl="2" className="sh-19">
                <Card className="h-100 hover-scale-up tile-three">
                  <Card.Body className="text-center">
                    <img src='./trade.png' alt='sales' className='analytics-images' />


                    <p className="heading mt-3 ">Total Products Sold</p>
                    <div className="text-extra-small fw-medium ">{reportsData ? reportsData.totalProductsSold : 0}</div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" xl="2" className="sh-19">
                <Card className="h-100 hover-scale-up analytics-tile">
                  <Card.Body className="text-center">
                    <img src='./shipped.png' alt='sales' className='analytics-images' />


                    <p className="heading mt-3 ">Total Shipping</p>
                    <div className="text-extra-small fw-medium ">{reportsData ? '£ ' + Number(reportsData.totalShipping).toFixed(2) : ''}</div>

                  </Card.Body>
                </Card>
              </Col>
              <Col xs="12" xl="2" className="sh-19">
                <Card className="h-100 hover-scale-up tile-four">
                  <Card.Body className="text-center">
                    <img src='./customer-feedback.png' alt='sales' className='analytics-images' />


                    <p className="heading mt-3 ">Total Customers</p>
                    <div className="text-extra-small fw-medium ">{reportsData ? reportsData.totalCustomers : 0}</div>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* Analytics End */}


          <Col lg='12' className='mb-5'>


            <h2 className="small-title">Reports</h2>

            <Button variant="success" className="mb-1"
              type="submit"
              onClick={(e) => exportLastOrderDocument(e)}
              disabled={false}>
              Export Last Order
            </Button>{' '}

          </Col>

          <Col lg='12' className='mb-5'>


            <h2 className="small-title">Download Excel (With date range)</h2>
            <div className='row'>
              <div className='col-8'>
                <DateRangePicker
                  onChange={item => setMultiDate([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={multiDate}
                  direction="horizontal"
                />
              </div>
              <div className='col-4'>
              <Button variant="primary" className="mb-1" onClick={() => exportExcelWithMultiRange('STOCK')}>
                  Stock Report
                </Button>
                <Button variant="success" className="mb-1" onClick={() => exportExcelWithMultiRange('SALES')}>
                  Sales Report
                </Button>{' '}
                <Button variant="success" className="mb-1"
                  onClick={() => exportExcelWithMultiRange('PROMO')}
                >
                  Promotional Code Report
                </Button>{' '}
                <Button variant="primary" className="mb-1"
                  onClick={() => exportExcelWithMultiRange('OUT')}
                >
                  Outstanding Balance Report
                </Button>{' '}
              </div>
            </div>

          </Col>

        </Row>
        <Row className='page-title-container'>
          {/* Title Start */}
          <Col md="7">
            <h1 className="mb-0 pb-0 display-4  ">GP Reports</h1>
          </Col>
          {/* Title End */}
        </Row>

        <div
          className="ag-theme-alpine"
          // style={{ height: "calc(100vh - 309px)", width: "100%" }}
          style={{ height: "520px", width: "100%", marginBottom: "100px" }}
        >
          <AgGridReact ref={gridRef} rowData={gpReportsData?.gpreports} columnDefs={colDefs} columnTypes={columnTypes} pinnedBottomRowData={pinnedBottomRowData} getRowStyle={getRowStyle} />
        </div>

      </div>
    </div>
  );

}


export default AnalyticsAndReports;