import { useState, useRef } from "react";
import React from "react";
import Tippy from "@tippyjs/react";
import { printBarcode } from "../../../serviceCalls/printBarcodeApiCall";

const PopupCellRenderer = (props) => {
  const { data } = props;
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const [quantity, setQuantity] = useState('');
  const productid = data?.productid;

  const printBarcodePopup = () => {
    if (productid) {
    printBarcode(productid, quantity);
    setVisible(false)}
  };

  const dropDownContent = (
    <div className="menu-container">
      <div style={{ display: "flex" }}>
        <label className="form-label" for="qty">
          Quantity<span class="required">*</span>
        </label>
        <input
          type="text"
          style={{ width: "75px", marginLeft: "10px" }}
          className="form-control form-data"
          id="qty"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
        />
      </div>
      <button className="btn-print" onClick={() => printBarcodePopup()}>
        Print
      </button>
    </div>
  );

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="bottom"
    >
      <div
        style={{ color: "#31A191", fontSize: "14px" }}
        onClick={visible ? hide : show}
      >
        {data?.barCode?.code}
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;


