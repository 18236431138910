import { useState } from "react";
import { Alert } from "react-bootstrap";
import Countdown, { formatTimeDelta } from 'react-countdown';

import { getEmailOtp, verifyEmail } from "../serviceCalls/verifyEmail";
import { isValidEmail } from "../utils/regexUtils";

const VerifyEmail = (props) => {
  const [serverError, setServerError] = useState("");
  const [fetchingOtp, setFetchingOtp] = useState(false);
  const [validatingOtp, setValidatingOtp] = useState(false);
  const [email, setEmailAddress] = useState(props.email || "");
  const [emailError, setEmailError] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isEmailOtpSent, setIsEmailOtpSent] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const { dispatch } = props;
  const twoMin = Date.now() + 120000;

  const onEmailChange = (e) => {
    setEmailAddress(e.target.value);
    setEmailError("");
  };

  const validateEmail = (e) => {
    const { value } = e?.target;
    setEmailError(isValidEmail(value));
  };

  const onOtpChange = (e) => {
    setOtp(e.target.value);
    setEmailError("");
  };

  const validateOtp = (e) => {
    const { value } = e?.target;
    const error = !value?.trim() ? 'Please enter an OTP' : '';
    setOtpError(error);
    return error;
  };

  const getOtp = async (e) => {
    e.preventDefault();
    setServerError(null);
    if (emailError) return false;

    try {
      setFetchingOtp(true);
      setOtp("");
      setShowResendOtp(false);
      await getEmailOtp({ email });
      setIsEmailOtpSent(true);
      setFetchingOtp(false);
    } catch(e) {
      setServerError(e);
      setFetchingOtp(false);
    }
  }

  const verify = async (e) => {
    e.preventDefault();
    setServerError(null);
    if (validateOtp({ target: { value: otp }})) return false;

    try {
      setValidatingOtp(true);
      await verifyEmail({ dispatch, email, otp })
      setValidatingOtp(false);
    } catch(e) {
      setServerError(e);
      setValidatingOtp(false);
    }
  }

  return (
    <div className="row">
      <div className="page-center" role="main">
        {serverError && (
          <Alert className="text-error" severity="error">
            {serverError || 'Something\'s gone wrong! Please try again.'}
          </Alert>
        )}
        {
          !isEmailOtpSent
          ?
            <form method="post">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  onChange={(e) => onEmailChange(e)}
                  onBlur={(e) => validateEmail(e)}
                  value={email}
                  disabled={!!props.email}
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              {emailError ? (
                <span>
                  <div className="text-error">{emailError}</div>
                </span>
              ) : null}
              <div className="form">
                <button
                  className="btn btn-lg btn-main"
                  type="submit"
                  onClick={(e) => getOtp(e)}
                  disabled={fetchingOtp}
                >
                  Verify Email
                </button>
              </div>
            </form>
          :
            <form method="post">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="floatingInput"
                  placeholder="Enter an OTP"
                  onChange={(e) => onOtpChange(e)}
                  onBlur={(e) => validateOtp(e)}
                  value={otp}
                />
                <label htmlFor="floatingInput">Email OTP</label>
              </div>
              {otpError ? (
                <span>
                  <div className="text-error">{otpError}</div>
                </span>
              ) : null}
              <div className="form verify-action">
                <button
                  className="btn btn-lg btn-main"
                  type="submit"
                  onClick={(e) => verify(e)}
                  disabled={validatingOtp}
                >
                  Submit Code {" "}
                </button>
                {
                  showResendOtp ?
                  <button
                    className="btn btn-lg btn-main"
                    type="submit"
                    onClick={(e) => getOtp(e)}
                  >
                    Resend Verify Email
                  </button> : null
                }
              </div>
              <div className="text-center mt-4">
                {
                  !showResendOtp ?
                    <Countdown
                      date={twoMin}
                      onComplete={() => setShowResendOtp(true)}
                      renderer={(t) => {
                        const { minutes, seconds } = formatTimeDelta(t);
                        return <span className="countdown-text">{`${minutes}:${seconds}`}</span>
                      }}
                    /> : null
                }
              </div>
            </form>

        }
      </div>
    </div>
  )
}

export default VerifyEmail;
