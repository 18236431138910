import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import envConfig from "../envConfigurations";
import { logError } from "../utils/helper";

export const verifyOrderItem = async (barcode, orderId) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);

    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/verifyorderItem/${orderId}/${barcode}`;
    const response = await axios.get(apiUrl, apiRequestHeader);
    return response;
  } catch(e) {
    return logError(e, 'getEmailOtp');
  }
}

export const verifyStockOrderItem = async (barcode, orderId) => {
  try {
    const authToken = localStorage.getItem("sessionKey");
    const apiRequestHeader = makeApiRequestHeader("POST",
    {
      "x-auth-token": authToken
    }, null);

    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/verifystockorderItem/${orderId}/${barcode}`;
    const response = await axios.get(apiUrl, apiRequestHeader);
    return response;
  } catch(e) {
    return logError(e, 'getEmailOtp');
  }
}
