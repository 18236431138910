import React from "react";
import { CSSTransition } from "react-transition-group";

import { deleteCustomer } from "../../../serviceCalls/DeleteUserApiCAll";
import { SET_IS_LOADING } from "../../../constants/actionTypes";

const DeleteUserModal = (props) => {
  const { config, dispatch, dataForDeleteModal, onClose, history } = props;

  const deleteUser = async () => {
    try {
      dispatch({ type: SET_IS_LOADING, payload: true });
      await deleteCustomer({ dispatch, userId: dataForDeleteModal.id,config });
    onClose();

    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  };

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h4 className="modal-title">{props.title}</h4>
          </div>
          <div className="modal-body">{props.children}</div>
          <div className="modal-footer text-align-center">
            <button className="btn btn-main" onClick={() => deleteUser()}>
              Delete
            </button>
            <button
              onClick={props.onClose}
              className="btn floating-modal-btn btn-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default DeleteUserModal;
