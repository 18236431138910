import React, { useState } from "react";
import HeaderMenu2 from "./common/HeaderMenu2.js";
import { useHistory } from "react-router-dom";

const HomePage = (props) => {
  const history = useHistory();
  let isLogedIn = window.localStorage.getItem('sessionKey');
  let userType = window.localStorage.getItem('userType');
  const { applicationState, dispatch } = props;
  const { config, cartDetails } = applicationState;

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white header-shadow navbar-fixed">
        <div className="container-fluid">
          {(userType == 'USER' || userType == 'ADMIN') ? (<>
            <HeaderMenu2 dispatch={dispatch} cartCount={cartDetails ? cartDetails.length : 0} />)
          </>) : (<>
            <button onClick={() => history.push("/")} className="navbar-brand">
              <img src="./GD LOGOS-01.jpeg" alt="logo" className="logo" />
            </button>
          </>)}
          {/* <button onClick={() => history.push("/")} className="navbar-brand">
        <img src="./GD LOGOS-01.jpeg" alt="logo" className="logo" />
      </button> */}
        </div>
      </nav>
      {/* main content */}
      <div className="container-center-horizontal">
        <div className="g-b-insta-landing-page screen">
          {/* Bg image section */}
          <img className="mask-group-1-uEBdwN" src="mask-group-1@1x.png" />
          <div className="rectangle-201-uEBdwN"></div>
          <h1 className="supplier-s-of-p-hetics-products-uEBdwN">
            SUPPLIERS OF PREMIUM AESTHETICS PRODUCTS
          </h1>

          {/* View product button */}
          <div className="viewproduct-main-container">
            <div className="rectangle-205-uEBdwN"
              onClick={() => {
                if (userType == "ADMIN") {
                  history.push("/admin_product_list")
                } else if (userType == "USER") {
                  history.push("/producttypes")
                }else {
                  history.push("/producttypes")
                }

              }}
            >

              <div
                className="poppins-normal-white-24px"

              >
                VIEW PRODUCTS
              </div>
            </div>
          </div>
          {/* col 3 */}
          {/* col 3 */}
          {(userType == 'USER' || userType == 'ADMIN') ? (<>
          </>) : (<>
            <div className="row">
              <div
                className="col-lg-4 rectangle-202-uEBdwN"
                onClick={() => history.push("/signin")}
              >
                <div className="poppins-normal-white-24px">LOGIN</div>
              </div>
              <div
                className="col-lg-4 rectangle-203-uEBdwN"
                onClick={() => history.push("/signup")}
              >
                <div className="poppins-normal-white-24px">REGISTER</div>
              </div>
            </div>

          </>)}

        </div>
      </div>
    </>
  );
};

export default HomePage;
