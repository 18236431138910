import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React from "react";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import VerifyEmailAndPhonenumber from "../components/VerifyEmail";
import ForgotPassword from "../components/ForgotPassword";
import ProductList from "../components/ProductList/ProductList";
import ProductDetails from "../components/ProductList/ProductDetails.js";
import CustomerCart from "../components/CustomerCheckout/CustomerCart";
import CustomerShippingInformation from "../components/CustomerCheckout/CustomerShippingInformation";
import CustomerPayment from "../components/CustomerCheckout/CustomerPayment";
import CustomerPaymentSuccess from "../components/CustomerCheckout/CustomerPaymentSuccess";
import MyProfile from "../components/Customer/UserProfile/MyProfile";
import PayPal from "../components/CustomerCheckout/PayPal";
import PayWithCard from "../components/CustomerCheckout/PayWithCard";
import MyOrders from "../components/Customer/MyOrders/MyOrders";
import AdminCustomersList from "../components/Admin/AdminCustomerTab/AdminCustomersList";
import CRM from "../components/Admin/CRMTab/CRM";
//import Barcode from "../components/Admin/BarcodeTab/Barcode.js";
import HomePage from "../components/HomePage";
import AdminProductList from "../components/Admin/AdminProductListTab/AdminProductList";
import AdminPlaceOrder from "../components/Admin/AdminPlaceOrderTab/AdminPlaceOrder";
import WalkInOrder from "../components/Admin/AdminPlaceOrderTab/WalkInOrder";
import AdminCoupon from "../components/Admin/AdminCouponTab/AdminCoupon";
import ProductListTiles from "../components/ProductList/ProductListTiles";
import CustomerPaymentFail from "../components/CustomerCheckout/CustomerPaymentFail";
import RequireAuth from "../components/RequireAuth";
import AdminCustomerOrderList from "../components/Admin/AdminCustomerTab/AdminCustomerOrderList";
import CppPage1 from "../components/Admin/CPP/CppPage1";
import CppPage2 from "../components/Admin/CPP/CppPage2";
import CppPage3 from "../components/Admin/CPP/CppPage3";
import CustomerProfile from "../components/Admin/AdminCustomerProfile/CustomerProfile";
import CPPList from "../components/Admin/CppFutureWork/Cpp";
import CompletedOrdersALL from "../components/Admin/CompletedOrders/CompletedOrdersAll";
import CompletedOrdersInsta from "../components/Admin/CompletedOrders/CompletedOrdersInsta";
import CompletedOrdersShopify from "../components/Admin/CompletedOrders/CompletedOrdersShopify";
import CompletedOrdersPharma from "../components/Admin/CompletedOrders/CompletedOrdersPharma";
import CompletedOrdersStore from "../components/Admin/CompletedOrders/CompletedOrdersStore";
import CustomerShippingInformationAdmin from "../components/Admin/AdminPlaceOrderTab/CustomerShippingInformationAdmin";
import CustomerPaymentWalkin from "../components/Admin/AdminPlaceOrderTab/CustomerPaymentWalkin";
import CustomerWalkinPaymentSuccess from "../components/Admin/AdminPlaceOrderTab/CustomerWalkinPaymentSuccess";
import AnalyticsAndReports from "../components/Admin/AnalyticsAndReports";
// 404 page component
import LostPage from "../components/LostPage";
import AdminAuth from "../components/AdminAuth";
import PrescriberAuth from "../components/PrescriberAuth";
import Prescriber from "../components/Prescriber/Prescriber";
import CustomerProfilePharma from "../components/Admin/AdminCustomerProfile/CustomerProfilePharma";
import CustomerProfileWholesale from "../components/Admin/AdminCustomerProfile/CustomerProfileWholesale";
import AdminCustomerOrderPharmaList from "../components/Admin/AdminCustomerTab/AdminCustomerOrderPharmaList";
import AdminCustomerOrderWholesaleList from "../components/Admin/AdminCustomerTab/AdminCustomerOrderWholesaleList.js";
// cpp info page route
import CppInfo1 from "../components/Admin/CPP/CppInfo1";
import CppInfo2 from "../components/Admin/CPP/CppInfo2";
import CppInfo3 from "../components/Admin/CPP/CppInfo3";
import EsaiInfo4 from "../components/Admin/CPP/EsaiInfo4";
import ApprovalPending from "../components/ApprovalPending";
// stock order page route
import StockOrder from "../components/Admin/StockManagement/StockOrder";
import HomepageList from "../components/Admin/homepage.js";

export const AppRouter = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/">
          <SignIn {...props} />
        </Route>
        <Route exact={true} path="/signin">
          <SignIn {...props} />
        </Route>
        <Route exact={true} path="/forgot_password">
          <ForgotPassword {...props} />
        </Route>
        <Route exact={true} path="/signup">
          <SignUp {...props} />
        </Route>
        <Route exact={true} path="/approvalPending">
          {RequireAuth(ApprovalPending, { ...props })}
        </Route>
        <Route exact={true} path="/productlist">
          {RequireAuth(ProductList, { ...props })}
        </Route>
        <Route exact={true} path="/productdetail">
          {RequireAuth(ProductDetails, { ...props })}
        </Route>
        <Route exact={true} path="/customercart_details">
          {RequireAuth(CustomerCart, { ...props })}
        </Route>
        <Route exact={true} path="/customershipping_info">
          {RequireAuth(CustomerShippingInformation, { ...props })}
        </Route>
        <Route exact={true} path="/customerpayment_info">
          {RequireAuth(CustomerPayment, { ...props })}
        </Route>
        <Route exact={true} path="/customerpayment_success">
          {RequireAuth(CustomerPaymentSuccess, { ...props })}
        </Route>
        <Route exact={true} path="/customer_list">
          {RequireAuth(AdminCustomersList, { ...props })}
        </Route>
        <Route exact={true} path="/my_profile">
          {RequireAuth(MyProfile, { ...props })}
        </Route>
        <Route exact={true} path="/paypal">
          {RequireAuth(PayPal, { ...props })}
        </Route>
        <Route exact={true} path="/pay-with-card">
          {RequireAuth(PayWithCard, { ...props })}
        </Route>
        <Route exact={true} path="/crm">
          {AdminAuth(CRM, { ...props })}
        </Route>

        <Route exact={true} path="/my_orders">
          {RequireAuth(MyOrders, { ...props })}
        </Route>
        <Route exact={true} path="/admin_product_list">
          {AdminAuth(AdminProductList, { ...props })}
        </Route>
        <Route exact={true} path="/homepage">
          {AdminAuth(HomepageList, { ...props })}
        </Route>
        <Route exact={true} path="/walk_in_order">
          {AdminAuth(WalkInOrder, { ...props })}
        </Route>
        <Route exact={true} path="/admin_coupon_list">
          {AdminAuth(AdminCoupon, { ...props })}
        </Route>
        <Route exact={true} path="/place_order">
          {AdminAuth(AdminPlaceOrder, { ...props })}
        </Route>
        <Route exact={true} path="/producttypes">
          {RequireAuth(ProductListTiles, { ...props })}
        </Route>
        <Route exact={true} path="/customerfail">
          {RequireAuth(CustomerPaymentFail, { ...props })}
        </Route>
        <Route exact={true} path="/orders_by_customer/:id">
          {AdminAuth(AdminCustomerOrderList, { ...props })}
        </Route>
        <Route exact={true} path="/cppPage1">
          {RequireAuth(CppPage1, { ...props })}
        </Route>
        <Route exact={true} path="/cppPage2">
          {RequireAuth(CppPage2, { ...props })}
        </Route>
        <Route exact={true} path="/cppPage3">
          {RequireAuth(CppPage3, { ...props })}
        </Route>
        <Route exact={true} path="/cppinfo1">
          {RequireAuth(CppInfo1, { ...props, ...gBEASIHOMEPRESENTATIONData })}
        </Route>
        <Route exact={true} path="/cppinfo2">
          {RequireAuth(CppInfo2, { ...props, ...gBEASIHOMEPRESENTATION2Data })}
        </Route>
        <Route exact={true} path="/cppinfo3">
          {RequireAuth(CppInfo3, { ...props, ...gBEASIHOMEPRESENTATION3Data })}
        </Route>
        <Route exact={true} path="/esaiInfo">
          {RequireAuth(EsaiInfo4, { ...props, ...gBEASIPROFITPAGEData })}
        </Route>

        <Route exact={true} path="/customer_profile/:id">
          {RequireAuth(CustomerProfile, { ...props })}
        </Route>
        <Route exact={true} path="/completed_orders_insta">
          {AdminAuth(CompletedOrdersInsta, { ...props })}
        </Route>
        <Route exact={true} path="/completed_orders_all">
          {AdminAuth(CompletedOrdersALL, { ...props })}
        </Route>
        <Route exact={true} path="/completed_orders_shopify">
          {RequireAuth(CompletedOrdersShopify, { ...props })}
        </Route>
        <Route exact={true} path="/completed_orders_pharma">
          {AdminAuth(CompletedOrdersPharma, { ...props })}
        </Route>
        <Route exact={true} path="/completed_orders_store">
          {AdminAuth(CompletedOrdersStore, { ...props })}
        </Route>
        <Route exact={true} path="/customershipping_info_admin">
          {AdminAuth(CustomerShippingInformationAdmin, { ...props })}
        </Route>
        <Route exact={true} path="/customerpayment_info_walkin">
          {AdminAuth(CustomerPaymentWalkin, { ...props })}
        </Route>
        <Route exact={true} path="/customerpayment_walkin_success">
          {AdminAuth(CustomerWalkinPaymentSuccess, { ...props })}
        </Route>
        <Route exact={true} path="/analytics_and_reports">
          {AdminAuth(AnalyticsAndReports, { ...props })}
        </Route>
        <Route exact={true} path="/prescriber">
          {PrescriberAuth(Prescriber, { ...props })}
        </Route>
        <Route exact={true} path="/customer_profile/pharma/:id">
          {RequireAuth(CustomerProfilePharma, { ...props })}
        </Route>
        <Route exact={true} path="/customer_profile/wholesale/:id">
          {RequireAuth(CustomerProfileWholesale, { ...props })}
        </Route>
        <Route exact={true} path="/orders_by_customer_pharma/:id">
          {AdminAuth(AdminCustomerOrderPharmaList, { ...props })}
        </Route>
        <Route exact={true} path="/orders_by_customer_wholesale/:id">
          {AdminAuth(AdminCustomerOrderWholesaleList, { ...props })}
        </Route>
        <Route exact={true} path="/stock_order">
          {AdminAuth(StockOrder, { ...props })}
        </Route>
        {/* adding 404 page route here */}
        <Route exact={true} path="*">
          <LostPage />
        </Route>
      </Switch>
    </Router>
  );
};

const gBEASIHOMEPRESENTATIONData = {
  easiImagesLast: "./img/G&B_EASI_PROFIT_PAGE.jpg",
  easiImages: "./img/easi-images@1x.png",
  introducingTheTrainingAcademy: "INTRODUCING THE TRAINING ACADEMY",
  spanText1: "EARN AND SAVE INCENTIVE ",
  spanText2: "(EASI)",
  saveMoneyWithDi: "- Save money with discounted products",
  earnMoneyWithDi: "- Earn money from ongoing student purchases",
  monitorSavingsWithDi:
    "- Monitor savings and projected earnings with on-line portal",
  group127: "./img/group-127@1x.png",
  group132: "./img/group-132@1x.png",
  group142: "./img/group-142@1x.png",
  group145: "./img/group-145@1x.png",
  group154: "./img/group-154@1x.png",
  group178: "./img/group-178@1x.png",
  group41: "./img/path-47@1x.png",
  group42: "./img/group-42@1x.png",
  group40: "./img/group-40@1x.png",
  group43: "./img/path-70@1x.png",
  group58: "./img/group-58@1x.png",
  group68: "./img/group-68@1x.png",
  group69: "./img/path-91@1x.png",
  group70: "./img/path-92@1x.png",
  group71: "./img/path-93@1x.png",
  group72: "./img/path-94@1x.png",
  group73: "./img/path-95@1x.png",
  group74: "./img/path-91@1x.png",
  group75: "./img/path-97@1x.png",
  group76: "./img/path-98@1x.png",
  group77: "./img/path-99@1x.png",
  group78: "./img/path-100@1x.png",
  group79: "./img/path-101@1x.png",
  group80: "./img/path-102@1x.png",
  group81: "./img/path-103@1x.png",
  group82: "./img/path-104@1x.png",
  group83: "./img/path-105@1x.png",
  group84: "./img/path-106@1x.png",
  group85: "./img/path-102@1x.png",
  group86: "./img/path-104@1x.png",
  group87: "./img/path-100@1x.png",
  group88: "./img/path-110@1x.png",
  group89: "./img/path-101@1x.png",
  group90: "./img/path-105@1x.png",
  group91: "./img/path-113@1x.png",
  group93: "./img/group3.png",
};

const group462Data = {
  className: "group-46-1",
};

const gBEASIHOMEPRESENTATION2Data = {
  easiImages2: "./img/easi-images2@1x.png",
  spanText1: "WHO ARE BLACK'S AESTHETICS?",
  spanText2:
    "BLACK'S AESTHETICS are a international Wholesale and Pharmacy supplier with offices in London and Glasgow. Our efficient distribution network enables us to offer a first class service supplying premium aesthetic products across the globe.",
  spanText3: "WHY CHOOSE US?",
  spanText4: "",
  spanText5:
    "We are a dedicated team committed to delivering you the very best service levels. We go the extra mile for our customers, always available and we recognise that our customers want a connection and relationship with their suppliers not just simply a web page to buy from. We are here to offer the best advice we can about our product and constantly looking for ways we can continue to develop and help our customers thrive.",
  spanText6: "OFFICIAL DISTRIBUTORS OF:",
  spanText7: "",
  spanText8: "WHAT PRODUCTS DO WE SUPPLY?",
  spanText9:
    "Our product range guarantees a strong foothold within the beauty and cosmetic industry.We want to find the right products and solutions that meet your needs, suit your clientele and support your business to grow. There are products for everyone, we will help you find the right one for you and your clients.",
  maskGroup4: "./img/mask-group-4@1x.png",
  group69: "./img/path-91-1@1x.png",
  group70: "./img/path-92-1@1x.png",
  group71: "./img/path-93-1@1x.png",
  group72: "./img/path-94-1@1x.png",
  group73: "./img/path-95-1@1x.png",
  group74: "./img/path-96-1@1x.png",
  group75: "./img/path-97-1@1x.png",
  group76: "./img/path-98-1@1x.png",
  group77: "./img/path-99-1@1x.png",
  group78: "./img/path-100-1@1x.png",
  group79: "./img/path-101-1@1x.png",
  group80: "./img/path-102-1@1x.png",
  group81: "./img/path-103-1@1x.png",
  group82: "./img/path-104-1@1x.png",
  group83: "./img/path-105-1@1x.png",
  group84: "./img/path-106-1@1x.png",
  group85: "./img/path-102-1@1x.png",
  group86: "./img/path-104-1@1x.png",
  group87: "./img/path-100-1@1x.png",
  group88: "./img/path-105-1@1x.png",
  group89: "./img/path-101-1@1x.png",
  group90: "./img/path-105-1@1x.png",
  group91: "./img/path-113-1@1x.png",
  group58: "./img/group-58-1@1x.png",
  group68: "./img/group-68-1@1x.png",
  group3: "./img/group3.png",
  group46Props: group462Data,
};

const gBEASIHOMEPRESENTATION3Data = {
  easiImages3: "./img/easi-images3@1x.png",
  spanText1: "WE CAN GROW & THRIVE TOGETHER",
  spanText2:
    "Our customers are at the heart of everything we do, not just todays customers but the future practitioners who are looking to grow into the industry. We created the Earn And Save Incentive (EASI) to help all parties thrive and grow their careers and businesses together.",
  spanText3: "WHAT ARE THE BENEFITS OF EASI?",
  spanText4:
    "We want all our customers to succeed so we developed a system that creates financial rewards for your business. By joining our Earn And Save Incentive (EASI) you receive the following benefits:",
  spanText41: "- Save money with discounted products",
  spanText42: "- Earn money from ongoing student purchases",
  spanText43: "- On-line portal showing current savings and projected earnings",
  spanText44: "- Ongoing product and marketing support from BLACK'S AESTHETICS",
  spanText5: "",
  maskGroup7: "./img/mask-group-7@1x.png",
  maskGroup8: "./img/mask-group-8@1x.png",
  maskGroup5: "./img/mask-group-5@1x.png",
};

const group1861Data = {
  x4Ab406B46B92: "4ab406b46b92",
  price1: "£336.00",
  price2: "£1.70",
};

const group1862Data = {
  x4Ab406B46B92: "D31dc8e818d2",
  price1: "£6708.00",
  price2: "£45.98",
  className: "group-187",
};

const gBEASIPROFITPAGEData = {
  title: "DETAILS",
  spanText1: "EASI Code",
  spanText2: "EASI123",
  spanText3: (
    <React.Fragment>
      Profit Percentage
      <br />
    </React.Fragment>
  ),
  spanText4: "4%",
  path198: "./img/path-198@1x.png",
  spanText5: (
    <React.Fragment>
      Max Profit
      <br />
    </React.Fragment>
  ),
  spanText6: "£5.00",
  path199: "./img/path-198@1x.png",
  line1: "./img/line-1@1x.png",
  line2: "./img/line-2@1x.png",
  line3: "./img/line-3@1x.png",
  spanText7: "Admin Fee",
  spanText8: "£1.00",
  spanText9: (
    <React.Fragment>
      Approver Email
      <br />
    </React.Fragment>
  ),
  spanText10: "Abhisheck@blacksaesthetics.com",
  line4: "./img/line-4@1x.png",
  line5: "./img/line-5@1x.png",
  update: "UPDATE",
  ordersReceived: "ORDERS RECEIVED",
  orderId: "ORDER ID",
  easiProfitShare: "EASI PROFIT SHARE",
  customerName: "CUSTOMER NAME",
  totalAmount: "TOTAL AMOUNT",
  line6: "./img/line-10-1@1x.png",
  line9: "./img/line-10-1@1x.png",
  line11: "./img/line-10-1@1x.png",
  monthlyEasiProfit: "MONTHLY EASI PROFIT",
  month: "MONTH",
  amount1: "AMOUNT",
  line12: "./img/line-10-1@1x.png",
  line13: "./img/line-10-1@1x.png",
  line16: "./img/line-10-1@1x.png",
  march2022: "March 2022",
  name: "August 2022",
  price1: "£2.80",
  price2: "£47.67",
  previous: "PROJECTIONS",
  yearlyEasiProfit: "YEARLY EASI PROFIT",
  yearly: "YEARLY",
  amount2: "AMOUNT",
  line14: "./img/line-10-1@1x.png",
  line15: "./img/line-10-1@1x.png",
  number: "2022",
  price3: "£50.47",
  line17: "./img/line-10-1@1x.png",
  next: "CPP HOME",
  group1861Props: group1861Data,
  group1862Props: group1862Data,
};
