import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_IS_LOADING, SET_ERROR, SEND_TA_CREDIT_SUCCESSED} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const sendCreditFormApiCall = async ({
    dispatch,
    authToken,
    email
}) => {
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/notification/email/sendapprovalform?email=${email}`;
    await axios
        .post(apiUrl, email, apiRequestHeader)
        .then(() => {
            dispatch({
                type: SEND_TA_CREDIT_SUCCESSED, message: "Trade credit form send successfully"
            })
            dispatch({
                type: SET_IS_LOADING, isLoading: false
            })
        })
        .catch(() => {
            dispatch({
                type: SET_ERROR,
            });
        });
};
