import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import AnalyticsAndReports from './AnalyticsAndReports';
import BankingAndFinance from './BankingAndFinance';
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { ToastContainer } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react';
import { getAnalyticsAndReportsApiCallV2, getDownloadReportsApiCall } from '../../../serviceCalls/getReportsApiCall';
import envConfigurations from '../../../envConfigurations';
import { makeApiRequestHeader } from '../../../utils/makeApiRequestHeader';
import axios from 'axios';
import { SET_IS_LOADING } from '../../../constants/actionTypes';
import fileSaver from "file-saver";




const AnalyticsAndReportsTabs = (props) => {
  const {  applicationState, dispatch, authToken } = props;
  const history = useHistory();
  const { config,reportsData } = applicationState;
  console.log("authToken",reportsData)

  const [salesReportStartDate, setSalesReportStartDate] = useState();
  const [salesReportEndDate, setSalesReportEndDate] = useState();

  const [productSalesReportStartDate, setProductSalasReportStartDate] = useState();
  const [productSalesReportEndDate, setProductSalasReportEndDate] = useState();

  const [productSoldStartDate, setProductSoldStartDate] = useState();
  const [productSoldEndDate, setProductSoldEndDate] = useState();

  const [customerReportStartDate, setCustomerReportStartDate] = useState();
  const [customerReportEndDate, setCustomerReportEndDate] = useState();

  const [stockReportStartDate, setStockReportStartDate] = useState();
  const [stockReportEndDate, setStockReportEndDate] = useState();


  useEffect(async()=>{
    try {
      config?.authToken && getAnalyticsAndReportsApiCallV2({
        dispatch: dispatch,
        signInResponse: {
          authToken: config.authToken,
          userType: config.userType,
  
        }
      })
    } catch (error) {
      console.log('error',error)
    }
    
  },[config?.authToken])

  const StartDateComponent = ({ value, onClick }) => {
    return (
      <div className='p-2'>
        <button className="date-picker-input" style={{ width: "100%" }} onClick={onClick}>
          <div className='d-flex justify-content-between'>
            <div className='d-flex gap-4'>
              <span>Start Date: </span>
              <span>{value || "dd/mm/yyyy"}</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
              </svg>
            </div>
          </div>
        </button>
      </div>

    )
  }

  const EndDateComponent = ({ value, onClick }) => {
    return (
      <div className='p-2'>
        <button className="date-picker-input" style={{ width: "100%" }} onClick={onClick}>
          <div className='d-flex justify-content-between'>
            <div className='d-flex gap-4'>
              <span>End Date: </span>
              <span>{value || "dd/mm/yyyy"}</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
              </svg>
            </div>
          </div>
        </button>
      </div>

    )
  }

  const convertToDDMMYYYY=(date)=>{
    const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
  }

  const downloadFile=async(type, startdate, enddate)=>{
    try {
      const convertStartDate = convertToDDMMYYYY(startdate);
      const convertEndDate = convertToDDMMYYYY(enddate);

      const apiUrl = `${envConfigurations.apiUrl}:${envConfigurations.apiPort}/order/report/Reports`;
      let apiRequestHeaderPOST = makeApiRequestHeader(
        "POST",
        { "x-auth-token": config.authToken,  },
        null,
        null
      );
      let data = {
        "startdate": convertStartDate,
        "enddate": convertEndDate,
        "type": type
      };
      apiRequestHeaderPOST = {
        ...apiRequestHeaderPOST,
        responseType: 'blob'
      }
      await axios
      .post(apiUrl, data, apiRequestHeaderPOST)
      .then((apiResponse) => {
        const { data, headers } = apiResponse;
        const fileData = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
        });
        fileSaver.saveAs(fileData, type + '.xlsx');
        dispatch({ type: SET_IS_LOADING, payload: false });
      })
      .catch(() => {      
        alert('Not able to generate report please try again later.');
        dispatch({ type: SET_IS_LOADING, payload: false });
      });
    } catch (error) {
      console.log('error',error)
      alert('Something Went Wrong.');
    }
  }

  return (
    <>
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "120px" }}>
        <Col><h2 className="text-uppercase" style={{ color: "#00aaff" }}>Analytics and reports</h2></Col>
        <Row className="g-2 analytics-container-row">
          <Col xs="12" xl="3" className="sh-19">
            <div className="d-flex tile-one" style={{ height: "200px" }} >
              <Card.Body className="">
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column text-white gap-3'>
                      <span>Cash: £{reportsData?.cashlondon?.toFixed(2)}</span>
                      <span>Bank Transfer: £{reportsData?.bankTransferlondon?.toFixed(2)}</span>
                      <span>Link: £{reportsData?.linklondon?.toFixed(2)}</span>
                      <span>Card: £{reportsData?.cardlondon?.toFixed(2)}</span>
                      <span>Paypal: £{reportsData?.paypallondon?.toFixed(2)}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-between'>
                    <div className="heading text-white fs-3 fw-bold">London</div>
                    <div className="text-extra-small mt-4 fs-1 fw-bolder text-white fw-medium ">£{(reportsData?.cashlondon + reportsData?.bankTransferlondon + reportsData?.linklondon + reportsData?.cardlondon + reportsData?.paypallondon).toFixed(2)}</div>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
          <Col xs="12" xl="3" className="sh-19">
            <div className="d-flex tile-two" style={{ height: "200px" }}>
              <Card.Body className="">
              <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column text-white gap-3'>
                      <span>Cash: £{reportsData?.cashhale?.toFixed(2)}</span>
                      <span>Bank Transfer: £{reportsData?.bankTransferhale?.toFixed(2)}</span>
                      <span>Link: £{reportsData?.linkhale?.toFixed(2)}</span>
                      <span>Card: £{reportsData?.cardhale?.toFixed(2)}</span>
                      <span>Paypal: £{reportsData?.paypalhale?.toFixed(2)}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-between'>
                    <div className="heading text-white fs-3 fw-bold">Hale</div>
                    <div className="text-extra-small mt-4 fs-1 fw-bolder text-white fw-medium ">£{(reportsData?.cashhale + reportsData?.bankTransferhale + reportsData?.linkhale + reportsData?.cardhale + reportsData?.paypalhale).toFixed(2)}</div>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
          <Col xs="12" xl="3" className="sh-19">
            <div className="d-flex tile-three" style={{ height: "200px" }}>
              <Card.Body className="">
              <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column text-white gap-3'>
                      <span>Cash: £{reportsData?.cashglasgow?.toFixed(2)}</span>
                      <span>Bank Transfer: £{reportsData?.bankTransferglasgow?.toFixed(2)}</span>
                      <span>Link: £{reportsData?.linkglasgow?.toFixed(2)}</span>
                      <span>Card: £{reportsData?.cardglasgow?.toFixed(2)}</span>
                      <span>Paypal: £{reportsData?.paypalglasgow?.toFixed(2)}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-between'>
                    <div className="heading text-white fs-3 fw-bold">Glasgow</div>
                    <div className="text-extra-small mt-4 fs-1 fw-bolder text-white fw-medium ">£{(reportsData?.cashglasgow + reportsData?.bankTransferglasgow + reportsData?.linkglasgow + reportsData?.cardglasgow + reportsData?.paypalglasgow).toFixed(2)}</div>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
          <Col xs="12" xl="3" className="sh-19">
            <div className="d-flex tile-two" style={{ height: "200px" }}>
              <Card.Body className="">
              <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column text-white gap-3'>
                      <span>Cash: £{reportsData?.cashessex?.toFixed(2)}</span>
                      <span>Bank Transfer: £{reportsData?.bankTransferessex?.toFixed(2)}</span>
                      <span>Link: £{reportsData?.linkessex?.toFixed(2)}</span>
                      <span>Card: £{reportsData?.cardessex?.toFixed(2)}</span>
                      <span>Paypal: £{reportsData?.paypalessex?.toFixed(2)}</span>
                    </div>
                    <div className='d-flex flex-column justify-content-between'>
                    <div className="heading text-white fs-3 fw-bold">Essex</div>
                    <div className="text-extra-small mt-4 fs-1 fw-bolder text-white fw-medium ">£{(reportsData?.cashessex + reportsData?.bankTransferessex + reportsData?.linkessex + reportsData?.cardessex + reportsData?.paypalessex)?.toFixed(2)}</div>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
        </Row>
        <Col className='mt-4'><h4 style={{ color: "gray" }}>Report Type:</h4></Col>
        <div className='d-flex gap-3 flex-column'>
          <Row className="g-2 analytics-container-row">
            <Col xs="12" xl="3" className="sh-19">
              <div className="text-white text-uppercase text-center tile-one" style={{padding: "10px 0px" }}>
                sales report
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={salesReportStartDate} onChange={(date) => setSalesReportStartDate(date)} customInput={<StartDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={salesReportEndDate} onChange={(date) => setSalesReportEndDate(date)} customInput={<EndDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19" onClick={()=>downloadFile("SALES", salesReportStartDate, salesReportEndDate)}>
              <div className="text-white  text-uppercase text-center tile-one" style={{ padding: "10px 0px", borderRadius: "20px", width: "170px", cursor:"pointer" }}>
                Download
              </div>
            </Col>
          </Row>
          <Row className="g-2 analytics-container-row">
            <Col xs="12" xl="3" className="sh-19">
              <div className="text-white text-uppercase text-center tile-two" style={{ padding: "10px 0px" }}>
                product sales report
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={productSalesReportStartDate} onChange={(date) => setProductSalasReportStartDate(date)} customInput={<StartDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={productSalesReportEndDate} onChange={(date) => setProductSalasReportEndDate(date)} customInput={<EndDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19" onClick={()=>downloadFile("PRODUCT_SALES_REPORT", productSalesReportStartDate, productSalesReportEndDate)}>
              <div className="text-white  text-uppercase text-center tile-two" style={{  padding: "10px 0px", borderRadius: "20px", width: "170px", cursor:"pointer" }}>
                Download
              </div>
            </Col>
          </Row>
          <Row className="g-2 analytics-container-row">
            <Col xs="12" xl="3" className="sh-19">
              <div className="text-white text-uppercase text-center tile-three" style={{ padding: "10px 0px" }}>
                product sold by location
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={productSoldStartDate} onChange={(date) => setProductSoldStartDate(date)} customInput={<StartDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={productSoldEndDate} onChange={(date) => setProductSoldEndDate(date)} customInput={<EndDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19" onClick={()=>downloadFile("PRODUCT_SOLD_BY_LOCATION", productSoldStartDate, productSoldEndDate)}>
              <div className="text-white  text-uppercase text-center tile-three" style={{padding: "10px 0px", borderRadius: "20px", width: "170px", cursor:"pointer" }}>
                Download
              </div>
            </Col>
          </Row>
          <Row className="g-2 analytics-container-row">
            <Col xs="12" xl="3" className="sh-19">
              <div className="text-white text-uppercase text-center tile-one" style={{padding: "10px 0px" }}>
                customer report
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={customerReportStartDate} onChange={(date) => setCustomerReportStartDate(date)} customInput={<StartDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={customerReportEndDate} onChange={(date) => setCustomerReportEndDate(date)} customInput={<EndDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19" onClick={()=>downloadFile("CUSTOMER_REPORT", customerReportStartDate, customerReportEndDate)}>
              <div className="text-white  text-uppercase text-center tile-one" style={{ padding: "10px 0px", borderRadius: "20px", width: "170px",cursor:"pointer" }}>
                Download
              </div>
            </Col>
          </Row>
          <Row className="g-2 analytics-container-row">
            <Col xs="12" xl="3" className="sh-19">
              <div className="text-white text-uppercase text-center tile-one" style={{padding: "10px 0px" }}>
                stock report
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={stockReportStartDate} onChange={(date) => setStockReportStartDate(date)} customInput={<StartDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19">
              <div className="hover-scale-up border d-flex align-items-center h-100">
                <DatePicker dateFormat="dd/MM/yyyy" selected={stockReportEndDate} onChange={(date) => setStockReportEndDate(date)} customInput={<EndDateComponent />} />
              </div>
            </Col>
            <Col xs="12" xl="3" className="sh-19" onClick={()=>downloadFile("STOCK", stockReportStartDate, stockReportEndDate)}>
              <div className="text-white text-uppercase text-center tile-one" style={{ padding: "10px 0px", borderRadius: "20px", width: "170px", cursor:"pointer" }}>
                Download
              </div>
            </Col>
          </Row>
        </div>




        {/* <Tabs>
          <TabList>
            <Tab>Analytics and Reports</Tab>
            <Tab>Banking and Finance</Tab>
          </TabList>

          <TabPanel>
            <AnalyticsAndReports {...props} />
          </TabPanel>

          <TabPanel>
            <BankingAndFinance {...props} />
          </TabPanel>
        </Tabs> */}
        <ToastContainer />
      </div>
    </>
  );

}

export default AnalyticsAndReportsTabs;
