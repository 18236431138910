import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";
import { Spinner} from "react-bootstrap";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import PlaceOrderAddItemModal from "./PlaceOrderAddItemModal";
import { RemoveItemColumn } from "./RemoveItemColumn";
import RemoveItemModal from "./RemoveItemModal";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ADMIN_ADD_ITEM_FOR_ORDER } from "../../../constants/actionTypes";
import NavigationBar from "../../common/NavigationBar";

const AdminPlaceOrder = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const { adminPlaceOrder, productList, existingCustomerData } =
    applicationState;
  let existingbeespokeData = existingCustomerData?.priceKeyValue;
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);

  const [placeOrderData, setplaceOrderData] = React.useState(adminPlaceOrder);
  const [loading, setLoading] = React.useState(false);
  const [showRemoveItem, setRemoveItemModal] = React.useState(false);
  const [showPlaceOrder, setPlaceOrderItemModal] = React.useState(false);
  const [barcodeScan, setBarcodeScan] = useState('');


  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const frameWorkComponentChange = ({ api }) => { };

  const columnDefs = () => [
    { field: "product", headerName: "Product" },
    { field: "quantity", headerName: "Quantity", editable: true },
    {
      field: "totalprice",
      headerName: "Product Price",
      cellRenderer: "totalprice",
    },
    { field: "totalvat", headerName: "Total Vat", cellRenderer: "totalvat" },
    { field: "totalcost", headerName: "Total Cost", cellRenderer: "totalcost" },
    {
      field: "delete",
      headerName: "Delete",
      cellRendererFramework: RemoveItemColumn,
    },
    {
      field: "bulkDiscount",
      headerName: "Bulk Discount",
    },
  ];

  const totalPrice = (params) => {
    return (
      <div className="totalprice">{`£${Number(params.data.totalprice).toFixed(
        2
      )}`}</div>
    );
  };

  const totalVat = (params) => {
    return (
      <div className="totalvat">{`£${Number(params.data.quantity * params.data.vat).toFixed(
        2
      )}`}</div>
    );
  };

  const totalCost = (params) => {
    return (
      <div className="totalcost">{`£${Number(
        (params.data.quantity * params.data.totalprice) + 
        (params.data.quantity * params.data.vat)
      ).toFixed(2)}`}</div>
    );
  };

  const rowData = () => {
    return placeOrderData || [];
  };
  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
      // minWidth: 256,
    }),
    []
  );

  const onFilterTextBoxChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };

  const showRemoveItemModal = (showModalValue) => {
    setRemoveItemModal(showModalValue);
  };

  const showPlaceOrderItemModal = (showModalValue) => {
    setPlaceOrderItemModal(showModalValue);
  };

  const updateOrders = (placeOrderData) => {
    setplaceOrderData(placeOrderData);
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#fff" };
    } else {
      return { background: "#a8b4c6" };
    }
  };

  const placeOrder = () => {
    const orderDataInTable = [];
    let subTotalAmount = 0;
    let totalVatAmount = 0;
    let totalAmount = 0;
    let shippingCost = 9.95;
    let isBulkDiscount = 0;

    gridApi.forEachNode((node) => {
      console.log(node.data);
      orderDataInTable.push(node.data);
      subTotalAmount += node.data.salepriceperunit*node.data.quantity;
      totalVatAmount += node.data.vat*node.data.quantity;
      totalAmount += node.data.salepriceperunit*node.data.quantity;
      isBulkDiscount += node.data.bulkDiscount != "N/A" ? 1 : 0;
    });

    dispatch({
      type: ADMIN_ADD_ITEM_FOR_ORDER,
      payload: {
        adminPlaceOrder: orderDataInTable,
        subTotalAmount: Number(subTotalAmount)?.toFixed(2),
        totalVatAmount: Number(totalVatAmount)?.toFixed(2),
        totalAmount: Number(totalAmount + Number(totalVatAmount || 0) + 9.95)?.toFixed(2),
        isBulkDiscount: isBulkDiscount > 0,
        shippingCost: 9.95,
      },
    });
    history.push("/customershipping_info_admin");
  };

  const onCellValueChanged = (params) => {
    const newValue = params.newValue;
    const data = params.data;
    const newTotalCost = newValue * data.totalprice;
    const newTotalVat = newValue * data.vat;
    const newSubTotalAmount = newValue * data.totalprice;
    const calBulkAmount = (finalProduct, isBulkDiscount) => {
      if (finalProduct.vat === 0 && isBulkDiscount != 'N/A') {
        return {
          ...finalProduct,
          product: `${finalProduct?.brand} ${finalProduct?.producttype} ${finalProduct?.productdesc}`,
          quantity: Number(params.newValue),
          totalprice: Number(finalProduct?.salepriceperunit),
          totalvat: Number(params.newValue * finalProduct?.vat),
          subTotalAmount: Number(
            (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * params.newValue),
          totalcost: Number(
            (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * params.newValue),
          bulkDiscount: isBulkDiscount
        };
      }
      else if (finalProduct.vat > 0 && isBulkDiscount != 'N/A') {
        const discountedvat = (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * 0.2;
        return {
          ...finalProduct,
          product: `${finalProduct?.brand} ${finalProduct?.producttype} ${finalProduct?.productdesc}`,
          quantity: Number(params.newValue),
          totalprice: Number(finalProduct?.salepriceperunit),
          totalvat: Number(params.newValue * discountedvat),
          subTotalAmount: Number(
            (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * params.newValue),
          totalcost: Number(
            (finalProduct?.salepriceperunit + discountedvat - finalProduct[isBulkDiscount]) * params.newValue
          ),
          bulkDiscount: isBulkDiscount
        };
      }
      else {
        return {
          ...finalProduct,
          product: `${finalProduct?.brand} ${finalProduct?.producttype} ${finalProduct?.productdesc}`,
          quantity: Number(params.newValue),
          totalprice: Number(finalProduct?.salepriceperunit),
          totalvat: Number(params.newValue * finalProduct?.vat),
          subTotalAmount: Number(params.newValue * finalProduct?.salepriceperunit),
          totalcost: Number(
            (finalProduct?.salepriceperunit + finalProduct?.vat) *
            params.newValue
          ),
          bulkDiscount: isBulkDiscount
        };
      }
    }
    const bulkDiscountCal = (item) => {
      if (item.morethan50discount != undefined && item.morethan50discount && newValue > 50)
        return "morethan50discount";
      else if (item.morethan20discount != undefined && item.morethan20discount && newValue > 20)
        return "morethan20discount";
      else
        return "N/A";
    };
    const isBulkDiscount = bulkDiscountCal(data);
    let product = calBulkAmount(data, isBulkDiscount);
    data.bulkDiscount = product.bulkDiscount;
    const updatedData = placeOrderData.map(item => {
      if (item.productid === data.productid) {
        return { ...item, quantity: newValue, totalcost: newTotalCost, totalvat: newTotalVat, subTotalAmount: newSubTotalAmount };
      }
      return item;
    });
    console.log(placeOrderData)
    console.log(updatedData)
    setplaceOrderData(updatedData);
    gridApi.refreshCells({ force: true });
  };

  let bar = "";
  useEffect(() => {

    function handleKeyDown(e) {
      if (e.keyCode === 13 && bar.length > 3) {
        handleScan(bar)
        return
      }

      if (e.keyCode === 16) {
        return
      }

      bar += e.key
      setTimeout(() => {
        bar = ""
      }, 100)

    }

    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  const handleScan = (data) => {

    setTimeout(() => {
      const scannedBarcode = data;

      const scannedProduct = productList.find(
        (product) => product?.barCode?.code == scannedBarcode || null
      );
      if (scannedProduct) {
        const bulkDiscount = (item) => {
          if (item.morethan50discount != undefined && item.morethan50discount && 1 > 50)
            return "morethan50discount";
          else if (item.morethan20discount != undefined && item.morethan20discount && 1 > 20)
            return "morethan20discount";
          else
            return "N/A";
        };
      const isBulkDiscount = bulkDiscount(scannedProduct);

        scannedProduct.product= `${scannedProduct?.brand} ${scannedProduct?.producttype} ${scannedProduct?.productdesc}`;
        scannedProduct.quantity= Number(1);
        scannedProduct.totalprice= Number(scannedProduct?.salepriceperunit);
        scannedProduct.totalvat= Number(1 * scannedProduct?.vat);
        scannedProduct.subTotalAmount= Number(1 * scannedProduct?.salepriceperunit);
        scannedProduct.totalcost= Number(
          (scannedProduct?.salepriceperunit + scannedProduct?.vat) *
          1
        );
        scannedProduct.bulkDiscount= isBulkDiscount;
        setplaceOrderData([...placeOrderData, scannedProduct]);
        setLoading(false);
      } else {
        setLoading(false);
        alert("Invalid barcode or product not found!");
      }
    }, 2000);
  }

  const handleBarcodeInput = () => {
    setLoading(true);

    const handleBarcodeScan = (event) => {
      setBarcodeScan(event.target.value);
    };
    document.addEventListener('input', handleBarcodeScan);
    return () => {
      document.removeEventListener('input', handleBarcodeScan);
    };
  };
  const stopLoading = () => {
    setLoading(false);
  };

  return (
    <div id="productlist" className="admin">
       {loading && (
        <div className="text-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <button
            className="btn btn-main"
            style={{ marginTop: "40%" }}
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={stopLoading}
          >
            Cancel
          </button>
        </div>
      )}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>

      <div
        className="container-fluid"
        style={{ width: "100%", height: "100%" }}
      >
        <div style={{ marginTop: "90px" }}>
          {" "}
          <NavigationBar />
        </div>
        <input
          className="search-bottom-margin"
          type="text"
          id="filter-text-box"
          placeholder="Filter..."
          onChange={(event) => onFilterTextBoxChanged(event)}
        />

        <div
          className="ag-theme-alpine"
          style={{ height: "calc(100vh - 315px)", width: "100%" }}
        >
          <AgGridReact
            getRowStyle={getRowStyle}
            rowData={rowData()}
            columnDefs={columnDefs()}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            context={{
              frameWorkComponentChange: frameWorkComponentChange,
              showRemoveItemModal: showRemoveItemModal,
            }}
            frameworkComponents={{
              totalprice: totalPrice,
              totalvat: totalVat,
              totalcost: totalCost,
            }}
            rowSelection={"multiple"}
            onCellValueChanged={onCellValueChanged}
            stopEditingWhenCellsLoseFocus={true}
          ></AgGridReact>
        </div>
        <div className="text-center mrt-20">
          <button
            className="btn btn-main"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={() => showPlaceOrderItemModal(true)}
          >
            Add Item
          </button>
          <button
            className="btn btn-main"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={handleBarcodeInput}
          >
            Scan Product
          </button>
          <button
            className="btn btn-secondary"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={gridApi && gridApi.getDisplayedRowCount() === 0}
            onClick={() => placeOrder()}
          >
            Place Order
          </button>
        </div>
        <PlaceOrderAddItemModal
          adminPlaceOrder={placeOrderData}
          productList={productList}
          onClose={() => showPlaceOrderItemModal(false)}
          gridApi={gridApi}
          show={showPlaceOrder}
          beespokeData={existingbeespokeData}
          applicationState={applicationState}
          dispatch={dispatch}
        />
        <RemoveItemModal
          title="Remove Item"
          onClose={() => showRemoveItemModal(false)}
          show={showRemoveItem}
          gridApi={gridApi}
        >
          <p>
            <strong>
              Are you sure you want to remove this item from order list?
            </strong>
          </p>
        </RemoveItemModal>
      </div>
    </div>
  );
};

export default AdminPlaceOrder;
