export const logError = (e, key) => {
  const error = e?.response?.data || e;
  console.error(key, error);

  return Promise.reject(error);
}

export const downloadData = (fileResp) => {
  if (!fileResp) return null;

  const linkSource = `data:${fileResp.mediaType.type/fileResp.mediaType.subType};base64,${fileResp.context.entity}`;
  const downloadLink = document.createElement("a");
  const fileData = fileResp &&
    fileResp.metadata['Content-Disposition'] &&
    fileResp.metadata['Content-Disposition'][0].split(";")[1].split("=")[1];
  if (fileData) {
    downloadLink.href = linkSource;
    downloadLink.download = fileData;
    downloadLink.click();
    return true;
  }

  return null;
}