export  const orderStatus = [
    { value: "status-1", label: "Order Placed" },
    { value: "status-2", label: "Ready To Collect" },
    { value: "status-3", label: "On Hold" },
    { value: "status-4", label: "Awaiting Payment" },
    { value: "status-5", label: "Cancelled" },
    { value: "status-6", label: "Order Complete" },

  ];

  export const DefaultOrderStatus="status-1"