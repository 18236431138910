import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { STORAGE_KEYS } from "../../../constants/common";
import { getSearchProduct } from "../../../serviceCalls/getReportsApiCall";
import { useRef, useState } from "react";
const PlaceOrderAddItemModal = (props) => {
  const { productList, beespokeData,applicationState, dispatch } = props;
  const {
    config,
    getSearchProductValues,
  } = applicationState;
  const [productToOrder, setProductToOrder] = React.useState("");
  const [quantityToOrder, setQuantityToOrder] = React.useState("");
  const [clearProductState, setClearProductState] = React.useState(0);
  const selectedWarehouse = localStorage.getItem(STORAGE_KEYS.WALKING_SELECTED_WAREHOUSE);

  const [getSearchProductState, setGetSearchProductState] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [productName, setProductName] = useState();
  const dropdownRef = useRef(null);

  const calBulkAmount = (finalProduct, isBulkDiscount) => {
    if (finalProduct.vat === 0 && isBulkDiscount != 'N/A') {
      return {
        ...finalProduct,
        product: `${finalProduct?.brand} ${finalProduct?.producttype} ${finalProduct?.productdesc}`,
        quantity: Number(quantityToOrder),
        totalprice: Number(finalProduct?.salepriceperunit),
        totalvat: Number(quantityToOrder * finalProduct?.vat),
        subTotalAmount: Number(
          (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * quantityToOrder),
        totalcost: Number(
          (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * quantityToOrder),
        bulkDiscount: isBulkDiscount
      };
    }
    else if (finalProduct.vat > 0 && isBulkDiscount != 'N/A') {
      const discountedvat = (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * 0.2;
      return {
        ...finalProduct,
        product: `${finalProduct?.brand} ${finalProduct?.producttype} ${finalProduct?.productdesc}`,
        quantity: Number(quantityToOrder),
        totalprice: Number(finalProduct?.salepriceperunit),
        totalvat: Number(quantityToOrder * discountedvat),
        subTotalAmount: Number(
          (finalProduct?.salepriceperunit - finalProduct[isBulkDiscount]) * quantityToOrder),
        totalcost: Number(
          (finalProduct?.salepriceperunit + discountedvat - finalProduct[isBulkDiscount]) * quantityToOrder
        ),
        bulkDiscount: isBulkDiscount
      };
    }
    else {
      return {
        ...finalProduct,
        product: `${finalProduct?.brand} ${finalProduct?.producttype} ${finalProduct?.productdesc}`,
        quantity: Number(quantityToOrder),
        totalprice: Number(finalProduct?.salepriceperunit),
        totalvat: Number(quantityToOrder * finalProduct?.vat),
        subTotalAmount: Number(quantityToOrder * finalProduct?.salepriceperunit),
        totalcost: Number(
          (finalProduct?.salepriceperunit + finalProduct?.vat) *
          quantityToOrder
        ),
        bulkDiscount: isBulkDiscount
      };
    }
  }
  const addItemToOrder = () => {
    // check if product is not selected or quantity is not provided
    if (productToOrder === "") {
      toast("Please select product !!!");
    } else if (!quantityToOrder || parseInt(quantityToOrder) <= 0) {
      toast("Please select quantity !!!");
    } else if (!quantityToOrder.toString().match(/^[0-9]+$/g)) {
      toast("Please enter a valid quantity");
    } else {
      let finalProduct;
      const beespokemap = beespokeData?.find((beespoke) => beespoke.productId === productToOrder)

      if (beespokemap?.productId != '' && beespokemap?.productId != undefined) {
        finalProduct = productList?.find(
          (prod) => {
            if (prod.productid === beespokemap.productId) {
              prod['salepriceperunit'] = Number(beespokemap.matchedPrice);
              prod['vat'] = Number(beespokemap.productVat);
              return true;
            }
            return false;
          }
        );
      } else{
        finalProduct = productList?.find(
          (prod) => prod.productid === productToOrder)
      }

      if(finalProduct != undefined){
        const maxQuantity = finalProduct.warehouse[selectedWarehouse];
        const totalCountSelectedProduct = props.gridApi.getRenderedNodes().reduce((total, node) => {
          if (node?.data?.productid !== productToOrder) return total;

          return total + (node?.data?.quantity || 0)
        }, parseInt(quantityToOrder));

        if (totalCountSelectedProduct > maxQuantity) {
          toast(`You can add upto ${maxQuantity} quantity for this product`);
          return false;
        }

      const bulkDiscount = (item) => {
        if (item.morethan50discount != undefined && item.morethan50discount && quantityToOrder > 50)
          return "morethan50discount";
        else if (item.morethan20discount != undefined && item.morethan20discount && quantityToOrder > 20)
          return "morethan20discount";
        else
          return "N/A";
      };

      const isBulkDiscount = bulkDiscount(finalProduct);
      let product = calBulkAmount(finalProduct, isBulkDiscount);
      if((beespokeData == '' || beespokeData == undefined) && (clearProductState == 0 && product == '')){
        product = ''
        setClearProductState(1)
      }

      props.gridApi.applyTransaction({
        add: [
          product
        ]
      });
      setProductToOrder("");
      setQuantityToOrder("");
      props.onClose();
    }
  }
  };
  // fix here
  const updateQuantity = (value) => {
    if (value < 0) {
      setQuantityToOrder(value * -1)
    }
    else {
      setQuantityToOrder(value)
    }
  }

  // Function to handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
    }
};

  React.useEffect(()=>{
      if(getSearchProductValues?.length > 0){
        setIsDropdownOpen(true);
        setGetSearchProductState(getSearchProductValues)
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
      }
  },[getSearchProductValues])


  const onSearchHandler=(value)=>{
    setProductName(value);
    if(value?.length >=3){
        try {
          config?.authToken && getSearchProduct({
            value,
            dispatch,
            signInResponse: {
              authToken: config.authToken,
              userType: config.userType,
            }
          })
          
        } catch (error) {
          console.log('error',error)
        }      
      
    }
  }

  const onChangHadlerProduct =(product)=>{
    setProductToOrder(product?.productid)
    setProductName(product.brand +" "+ product.producttype +" "+ product.productdesc)
    setIsDropdownOpen(false)
  }

  return (
    <>
      <CSSTransition
        in={props.show}
        unmountOnExit
        timeout={{ enter: 0, exit: 300 }}
      >
        <div className="modal enter-done">
          <ToastContainer />
          <div
            className="modal-content"
            style={{ overflow: "auto", maxHeight: "600px" }}
          >
            <div className="modal-header">
              <h4 className="modal-title">Add Items</h4>
            </div>
            <div className="modal-body">
            

              <div className="form-floating m-auto">
                <input
                  type="text"
                  className="form-control"
                  value={productName} onChange={(e)=>onSearchHandler(e.target.value)}
                />
                {/* <label for="floatingInput">Product</label> */}
              
                
                {
                  isDropdownOpen ? <div ref={dropdownRef} className="d-flex flex-column gap-2" style={{background:"white", padding:"10px", position:"absolute", maxHeight:"150px", borderRadius:"10px", width:"400px", border:"1px solid",zIndex:1, overflow:"auto"}}>
                  <>
                  {getSearchProductState?.sort((a, b) => a.brand.localeCompare(b.brand))?.map((product) => {
                    const isProductInStock = product.warehouse && product.warehouse[selectedWarehouse] > 0;
                    return (
                      <a href="javascript:void(0)" className="pe-auto"
                        // value={product?.productid}
                        disabled={!isProductInStock}
                        onClick={() => onChangHadlerProduct(product)}
                      >{`${product.brand} ${product.producttype} ${product.productdesc}`}</a>
                    )
                  })}
                  </>

                  
                  </div> : ""
                }
                
                <label for="productWarehouse">Product</label>
              </div>
              <h3 className="text-center mt-2 mb-2">OR</h3>

              <div className="form-floating m-auto">
                <select
                  className="form-select"
                  id="productList"
                  name="productList"
                  value={productToOrder}
                  onChange={(e) => setProductToOrder(e.target.value)}
                >
                  <option value="">Please select</option>
                  {productList?.sort((a, b) => a.brand.localeCompare(b.brand))?.map((product) => {
                    const isProductInStock = product.warehouse && product.warehouse[selectedWarehouse] > 0;
                    return (
                      <option
                        value={product.productid}
                        disabled={!isProductInStock}
                      >{`${product.brand} ${product.producttype} ${product.productdesc}`}</option>
                    )
                  })}
                </select>
                <label for="productWarehouse">Product</label>
              </div>
              <div className="form-floating mb-3 m-auto">
                <input
                  type="number"
                  className="form-control"
                  id="quantity"
                  placeholder="quantity"
                  value={quantityToOrder}
                  onChange={(e) => updateQuantity(e.target.value)}
                  min="1"
                />
                <label for="floatingInput">Quantity</label>
              </div>
            </div>
            <div className="modal-footer text-align-center">
              <button className="btn btn-main" onClick={() => addItemToOrder()}>
                Add Item
              </button>
              <button
                onClick={() => props.onClose()}
                className="btn floating-modal-btn btn-secondary"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
export default PlaceOrderAddItemModal;