import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { SET_IS_LOADING, UPDATE_CUSTOMER_DETAILS } from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const updateMatchedPrice = async ({
  dispatch,
  email,
  priceKeyValueList,
  authToken,
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": authToken },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/user/updatePricekeyValue`;

  dispatch({ type: SET_IS_LOADING, payload: true });
  await axios
    .post(apiUrl, { email: email, priceKeyValueList: priceKeyValueList }, apiRequestHeader)
    .then((apiResponse) => {
      dispatch({
        type: UPDATE_CUSTOMER_DETAILS,
        payload: apiResponse.data,
      });
      dispatch({ type: SET_IS_LOADING, payload: false });
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
    });
};
