import React from "react";
import "./css/GBEASIHOMEPRESENTATION.css";
import { useHistory, Redirect } from "react-router-dom";
import HeaderMenu from '../../common/AdminHeaderMenu';
function CppInfo1(props) {
    const history = useHistory();
    const {
        easiImages,
        introducingTheTrainingAcademy,
        spanText1,
        spanText2,
        saveMoneyWithDi,
        earnMoneyWithDi,
        monitorSavingsWithDi,
        group127,
        group132,
        group142,
        group145,
        group154,
        group178,
        group41,
        group42,
        group40,
        group43,
        group58,
        group68,
        group69,
        group70,
        group71,
        group72,
        group73,
        group74,
        group75,
        group76,
        group77,
        group78,
        group79,
        group80,
        group81,
        group82,
        group83,
        group84,
        group85,
        group86,
        group87,
        group88,
        group89,
        group90,
        group91,
        group93
    } = props;
    return (
        <>
            <HeaderMenu />
            <div className="container-fluid cpp1mainDiv p-0 bgimg">
                {/* <div className="cpp1firstrectangle"></div> */}
                {/* <div className="bgimgdiv">
                <img className="bgimg"></img>
            </div> */}
                <div className="content">
                    <div className="col-sm-3 group44div" style={{ margin: '50px 0px' }}>
                        <div className="cpp1group-44">
                            <div className="cpp1group-41" style={{ backgroundImage: `url(${group41})` }}></div>
                            <div className="cpp1group-container mt-3">
                                <img className="cpp1group-40" src={group40} />
                            </div>
                        </div>
                        <div className="cpp1line-1"></div>
                    </div>
                    <div className="col-sm-10 training-academy" style={{ margin: '50px 0px' }}>
                        <div className="cpp1introducing-the-training-academy">{introducingTheTrainingAcademy}</div>
                        <div className="cpp1title">
                            <span style={{ margin: 'font-weight:bold' }}>{spanText1}</span>
                            <span className="cpp1span1" style={{ margin: 'font-weight:bold' }}>{spanText2}</span>
                        </div>
                        <div className="cpp1line-2"></div>
                    </div>
                    <div className="col-md-10 col-sm-8 cpp1save-money" style={{ margin: '50px 0px' }}>
                        <div className="cpp1save-money-with-di">{saveMoneyWithDi}</div>
                        <div className="cpp1save-money-with-di">{earnMoneyWithDi}</div>
                        <div className="cpp1save-money-with-di">{monitorSavingsWithDi}</div>
                        <div className="cpp1line-3"></div>
                    </div>
                    <div className="col-md-12 col-sm-8 d-flex partnerimg" style={{ margin: '50px 0px', flexWrap: 'wrap' }}>
                        <img className="cpp1group-58" src={group58} />
                        <img className="cpp1group-68" src={group68} />
                        <img className="cpp1group-93" src={group93}></img>
                        <img className="cpp1group-127" src={group127} />
                        <img className="cpp1group-132" src={group132} />
                        <img className="cpp1group-142" src={group142} />
                        <img className="cpp1group-145" src={group145} />
                        <img className="cpp1group-154" src={group154} />
                        <img className="cpp1group-178" src={group178} />
                    </div>
                    <a href="cppinfo2">
                        <div className="container-fluid nextdiv">
                            <span>Next</span>
                        </div>
                    </a>
                </div>
                <div className="cpp1lastrectangle"></div>
            </div>
        </>
    );
}
export default CppInfo1;