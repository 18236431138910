import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import {QuantityColumn} from './QuantityColumn';
import {BalanceColumn} from './BalanceColumn';
import { Spinner } from "react-bootstrap";
import moment from 'moment';
import AdminOrderDetailsModal from "../AdminCustomerTab/AdminOrderDetailsModal";
import CRMTabList from "../CRMTab/CRMTabList";

const CompletedOrdersInsta = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const { getCustomerOrderList, instaCompletedOrders, isLoading, config, crmDetails, commentsData, isSpinnerEnabled } = applicationState;

  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);

  const [showModal, setShowModal] = React.useState(false);
  const [orderInfo, setOrderInfo] = React.useState("");
  const [completedInstaOrders, setCompletedInstaOrders] = React.useState(instaCompletedOrders);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const onRowClicked = (params) => {
    setOrderInfo(crmDetails.find((crmElement) => crmElement.id === params.data.id));
    setShowModal(true);
  };

  const frameWorkComponentChange = ({ api }) => {};


  const columnDefs = () => [
    { field: "customerEmail", headerName: "Email" },
    { field: "id", headerName: "Order Id" },
    { field: "orderdetailList", headerName: "Quantity", cellRenderer: "quantityColumnComponent" },
    { field: "orderDate", headerName: "Order placed on",cellRenderer: "orderdate" },
    { field: "amount", headerName: "Total Amount", cellRenderer: "amountCol" },
    { field: "amountpaid", headerName: "Amount Paid", cellRenderer: "amountpaid" },
    { field: "balance", headerName: "Balance", cellRenderer: "balanceColumnComponent" },
  ];

  const amountColumn = (params) => {
    return (
      <div className="amountColumn">{`£${params.data.amount}`}</div>
    )
  };

  const amountPaid = (params) => {
    return (
      <div className="amountPaid">{`£${params.data.amountpaid}`}</div>
    )
  };

  const orderDate = (params) => {
    return (
      <div className="orderdate">{moment(params.data.orderDate).format("DD-MM-YYYY  h:mm:ss a")}</div>
    )
  };

  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    [instaCompletedOrders]
  );

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#fff" };
    }
    else
    {
      return { background: "#a8b4c6" };
    }
  };

  const onFilterTextBoxChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };

  return (
    <div className="admin crm" id="admincustorder">
    {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div className="container-fluid">
        <div className="card mt-4">
          <input
            className="search-bottom-margin mt-4"
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={(event) => onFilterTextBoxChanged(event)}
          />
          <div
            id="myGrid"
            className="ag-theme-alpine product-detail-table"
            style={{ height: "calc(100vh - 335px)",width: '100%'}}
          >
            <AgGridReact
              getRowStyle={getRowStyle}
              rowData={instaCompletedOrders}
              columnDefs={columnDefs()}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowSelection={"multiple"}
              paginationAutoPageSize={true}
              pagination={true}
              frameworkComponents={{
                quantityColumnComponent: QuantityColumn,
                balanceColumnComponent: BalanceColumn,
                amountCol: amountColumn,
                amountpaid: amountPaid,
                orderdate: orderDate
              }}
              onRowClicked={onRowClicked}
            ></AgGridReact>
          </div>
        </div>
      </div>
      <AdminOrderDetailsModal
        title={`Order Id`}
        onClose={() => setShowModal(false)}
        show={showModal}
        orderInfo={orderInfo}
      >
        <CRMTabList
          orderInfo={orderInfo}
          dispatch={dispatch}
          history={history}
          config={config}
          comments={commentsData}
          isSpinnerEnabled={isSpinnerEnabled}
          onClose={() => setShowModal(false)}
          disableButtons
           />
      </AdminOrderDetailsModal>
    </div>
  );
};

export default CompletedOrdersInsta;
