import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { saveAs } from "file-saver";
import envConfig from "../envConfigurations";

export const printBarcode = async (productId, quantity) => {
    try {
        let token = window.localStorage.getItem("sessionKey");

        const apiRequestHeader = makeApiRequestHeader(
            "GET",
            {
                "x-auth-token": token,
                "Cookie": "JSESSIONID=68F7DAFDE2BF1034C6FF674DCA1CCDB2"
            },
            null
        );
        const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/product/print/${productId}/${quantity}`;
        
        const apiResponse = await axios.get(apiUrl, apiRequestHeader);
        const printWindow = window.open("", "_blank");
        printWindow.document.write(apiResponse.data);
        printWindow.document.close();
        printWindow.onload = function() {
            printWindow.print();
        };
    } catch (error) {
        console.log("Error occurred while fetching or saving barcode data:", error);
    }
};