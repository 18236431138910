import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    GET_TA_FORM_SUCCESS,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const getTAFormApiCall = async ({
    dispatch,
    email,
    authToken
}) => {
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({ 
        type: NO_DATA_FOUND, 
        payload: { error: ""}
    });
    const apiRequestHeader = makeApiRequestHeader(
        "GET",
        { "x-auth-token": authToken },
        null
    );
    await axios
        .get(`${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchtaform?taemail=${email}`,
            apiRequestHeader)
        .then((apiResponse) => {
            console.log(apiResponse)
            dispatch({
                type: GET_TA_FORM_SUCCESS,
                payload: apiResponse.data,
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        }).catch((error) => {
            dispatch({ 
                type: NO_DATA_FOUND, 
                payload: { error: error.response.data ?? "Error while loading TA Form" }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
