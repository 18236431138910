import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { TA_CREDIT_DOC_DELETE_FAILED, TA_CREDIT_DOC_DELETE_SUCCESSED, SET_IS_LOADING } from "../constants/actionTypes";
import { fetchCreditUserApiCall } from "../serviceCalls/fetchCreditUserApiCall";

import envConfig from "../envConfigurations";

export const deleteTACreditDocsApiCall = async ({
  dispatch,
  authToken,
  taemail,
  fileType
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "DELETE",
    { "x-auth-token": authToken },
    null
  );
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/deletetadocs`;
  await axios
    .delete(apiUrl, {data: {taemail: taemail, filetype: fileType, file: null}, headers: {"x-auth-token": authToken }})
    .then(() => {
      dispatch({
        type: TA_CREDIT_DOC_DELETE_SUCCESSED,
        payload: true,
        message: "Document deleted successfully.",
      });
      fetchCreditUserApiCall({
          dispatch: dispatch,
          authToken: authToken,
          email: taemail
      })
    })
    .catch(() => {
        dispatch({
            type: TA_CREDIT_DOC_DELETE_FAILED,
            payload: true,
            message: "Document deleted failed.",
            });
        dispatch({ type: SET_IS_LOADING, payload: false });
    });
};
