import * as React from "react";
import QrModal from "./QrModal";

const CustomerProfileHeaderSection = ({
  profileDetails,
  showUpdatePassowrdModal,
}) => {
  const qrcode = `${`data:image/jpeg;base64,${profileDetails.qrcode}`}`;
  const [showQRModal, setShowQRModal] = React.useState(false);

  const toggleQRModal = () => {
    setShowQRModal(!showQRModal);
  };
  return (
    <>
      <div className="my-profile-banner">
        {/* <img src="./GB-PN-INSERT-CLIENT.jpg" alt="login background image" /> */}
      </div>
      <div className="row mrlr-40">
        <div className="container-fluid pt-0 pb-0">
          <div className="row align-items-end">
            <div className="col-sm d-flex ">
              <h5 className="font-size-16"  style={{ marginTop: "20px" }} >
                Hello, <br />{profileDetails?.firstname}{" "}
                {profileDetails?.lastname}{" "}
              </h5>
              <img src={qrcode} className="qr-code" alt="QR Code" onClick={toggleQRModal} />
            </div>
            <div className="col-sm-auto px-0">
              <div className="d-flex align-items-start gap-2 mb-2 mt-4">
                <div>
                  <button
                    type="button"
                    className="btn btn-main"
                    onClick={() => showUpdatePassowrdModal(true)}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QrModal
        isOpen={showQRModal}
        qrcode={qrcode}
        onRequestClose={toggleQRModal}
      />
    </>
  );
};

export default CustomerProfileHeaderSection;
