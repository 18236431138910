import React from "react";

import "./css/lostpage.css";

const LostPage = () => {
    let userType = localStorage.getItem("userType");

    return (
        <div>
            {/* back to home button */}
            {/* <div classNameName='backtohomebtn'>
                <Button variant="contained" color="primary" onClick={backToHome}>
                    Back To Home
                </Button>
            </div>
            <div style={{ "backgroundImage": { LostImage } }}>
                <img src={LostImage} alt="404 page" width="100%" height="80%" />
            </div> */}

            {/* awesome style */}
            <a to="/">
                <header className="top-header"></header>

                {/* <!--dust particel--> */}
                <div>
                    <div className="starsec"></div>
                    <div className="starthird"></div>
                    <div className="starfourth"></div>
                    <div className="starfifth"></div>
                </div>
                {/* <!--Dust particle end---> */}

                <div className="lamp__wrap">
                    <div className="lamp">
                        <div className="cable"></div>
                        <div className="cover"></div>
                        <div className="in-cover">
                            <div className="bulb"></div>
                        </div>
                        <div className="light"></div>
                    </div>
                </div>
                {/* <!-- END Lamp --> */}
                <section className="error_notfound">
                    {/* <!-- Content --> */}
                    <div className="error__content">
                        <div className="error__message message">
                            <h1 className="message__title">Page Not Found</h1>
                            <p className="message__text">
                                We're sorry, the page you were looking for isn't
                                found here. The link you followed may either be
                                broken or no longer exists. Please try again, or
                                take a look at our.
                            </p>
                        </div>
                        <div className="error__nav e-nav">
                            {userType === "ADMIN" ? (
                                <>
                                    <a
                                        href="/homepage"
                                        className="e-nav__link"
                                    ></a>
                                </>
                            ) : (
                                <></>
                            )}
                            {userType === "USER" ? (
                                <>
                                    {" "}
                                    <a
                                        href="/producttypes"
                                        className="e-nav__link"
                                    ></a>
                                </>
                            ) : (
                                <></>
                            )}
                            {userType === "PRESCRIBER" ? (
                                <>
                                    {" "}
                                    <a
                                        href="/prescriber"
                                        className="e-nav__link"
                                    ></a>
                                </>
                            ) : (
                                <></>
                            )}
                            {userType === "undefined" ? (
                                <>
                                    <a
                                        href="/signin"
                                        className="e-nav__link"
                                    ></a>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    {/* <!-- END Content --> */}
                </section>
            </a>
        </div>
    );
};

export default LostPage;
