import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    SET_IS_LOADING,
    CPP_DISCOUNT,
    UPDATE_CUSTOMER_NOTIFICATION_STATUS,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const toggleCppCall = async ({ profile, authToken, dispatch, toggleValue, adminEmail }) => {

    let apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken }
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/cppstatus`;
    const cppRequest = {
        email: profile.email,
        status: toggleValue,
        approveremail: adminEmail
    }
    dispatch({ type: SET_IS_LOADING, payload: true });

    await axios
        .post(apiUrl, cppRequest, apiRequestHeader)
        .then((apiResponse) => {
          apiResponse.data.adminfee = Number(apiResponse.data.adminfee).toFixed(2);
            dispatch({
                type: CPP_DISCOUNT,
                payload: apiResponse.data
            });
            axios.get(
                `${envConfig.apiUrl}:${envConfig.apiPort}/admin/user/management/GANDYS_USER`,
                apiRequestHeader
              ).then((userDetails) => {
                const allCustomersData = [];
                userDetails &&
                  userDetails.data &&
                  userDetails.data.map((user) => {
                    allCustomersData.push({
                      ...user,
                      username: `${user.firstname ? user.firstname : ""} ${user.lastname ? user.lastname : ""
                        }`,
                      postcode:
                        user.address && user.address.length > 0
                          ? user.address[0].postcode
                          : "",
                    });
                  });
                dispatch({
                  type: UPDATE_CUSTOMER_NOTIFICATION_STATUS,
                  customerList: allCustomersData,
                });
              })
            dispatch({ type: SET_IS_LOADING, payload: false });
        })
        .catch(() => {
            dispatch({
                type: CPP_DISCOUNT,
                payload: {}
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
