import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { addCouponApiCall } from "../../../serviceCalls/addCouponApiCall";
import { Input, MenuItem, Select } from "@material-ui/core";
import DatePicker from "react-datepicker";
import moment from "moment";

const AdminCouponModal = (props) => {
  const { config, dispatch, onClose, history, show, applicationState } = props;
  const [newCouponDetails, setNewCouponDetails] = React.useState({
    code: "",
    couponType: "",
    amount: "",
    amountToApplyOn: "",
    description: "",
    product: [],
    usageCount: "",
    usageLimit: "",
    expiryDate: new Date(),
  });

  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");

  const {
    code,
    couponType,
    amount,
    amountToApplyOn,
    description,
    product,
    usageCount,
    usageLimit,
    expiryDate,
  } = newCouponDetails;

  function cleanState() {
    setNewCouponDetails({
      code: "",
      couponType: "",
      amount: "",
      amountToApplyOn: 0,
      description: "",
      product: [],
      usageCount: 0,
      usageLimit: 0,
      expiryDate: new Date(),
    });
  }

  const onSave = () => {
    let data = {
      ...newCouponDetails,
      expiryDate: moment(expiryDate).format("DD/MM/YYYY"),
      amount: Number(amount).toFixed(2),
      amountToApplyOn: Number(amountToApplyOn),
      usageCount: Number(usageCount),
      usageLimit: Number(usageLimit),
    };
    if (
      code === "" ||
      couponType === "" ||
      description === "" ||
      amountToApplyOn == 0 ||
      usageCount == 0 ||
      usageLimit == 0
    ) {
      setEmptyCredentialsError("All fields are required.");
    } else if (isNaN(amount)) {
      setEmptyCredentialsError("All fields are required.");
    } else {
      addCouponApiCall({
        dispatch: dispatch,
        requestBodyForAdd: data,
        config: config,
      });
      cleanState();
      onClose();
    }
  };

  return (
    <>
      <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
        <div className="modal enter-done">
          <div
            className="modal-content"
            style={{ overflow: "auto", maxHeight: "600px" }}
          >
            <div className="modal-header">
              <h4 className="modal-title">Add Coupon Details</h4>
            </div>
            <div className="modal-body">
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="code">
                  Code<span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control form-data"
                  id="code"
                  placeholder="Code"
                  value={code}
                  onChange={(e) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      code: e.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="couponType">
                  Coupon Type<span className="required">*</span>
                </label>
                <select
                  className="form-control form-data"
                  id="shipping"
                  name="shipping"
                  onChange={(e) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      couponType: e.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                  value={couponType}
                >
                  <option value="">Please select</option>
                  <option value="Percentage Discount">
                    Percentage Discount
                  </option>
                  <option value="Fixed Cart Discount">
                    Fixed Cart Discount
                  </option>
                  <option value="Fixed Product Discount">
                    Fixed Product Discount
                  </option>
                </select>
              </div>

              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="amount">
                  Coupon Amount<span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-data"
                  id="amount"
                  placeholder="0.00"
                  step="0.01"
                  value={amount}
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);
                    setNewCouponDetails({
                      ...newCouponDetails,
                      amount: value,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="amountToApplyOn">
                  Amount to be applied on<span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-data"
                  id="amountToApplyOn"
                  placeholder="0"
                  value={amountToApplyOn}
                  onChange={(e) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      amountToApplyOn: e.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="description">
                  Description<span className="required">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control form-data"
                  id="description"
                  placeholder="description"
                  value={description}
                  maxLength={120}
                  rows="3"
                  onChange={(e) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      description: e.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="product">
                  Select Product
                </label>
                <Select
                  className="form-control form-data"
                  multiple
                  value={product}
                  onChange={(event) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      product: event.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                  input={<Input label="Name" />}
                  disableUnderline
                >
                  {applicationState &&
                    applicationState.productList.map(
                      ({ brand, producttype, productdesc, productid }, id) => (
                        <MenuItem key={productid} value={productid}>
                          {`${brand} ${producttype} ${productdesc}`}
                        </MenuItem>
                      )
                    )}
                </Select>
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="usageCount">
                  Usage<span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-data"
                  id="usageCount"
                  placeholder="0"
                  value={usageCount}
                  onChange={(e) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      usageCount: e.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="usageLimit">
                  Limit<span className="required">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-data"
                  id="usageLimit"
                  placeholder="0"
                  value={usageLimit}
                  onChange={(e) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      usageLimit: e.target.value,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              <div className="form-group-coupon mb-3">
                <label className="form-label" htmlFor="expiryDate">
                  Expiry Date<span className="required">*</span>
                </label>
                <DatePicker
                  name="expiryDate"
                  className="form-control form-data"
                  value={expiryDate}
                  selected={expiryDate}
                  minDate={expiryDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    setNewCouponDetails({
                      ...newCouponDetails,
                      expiryDate: date,
                    });
                    setEmptyCredentialsError("");
                  }}
                />
              </div>
              {emptyCredentialsError ? (
                <span>
                  <div
                    className="error"
                    style={{ fontSize: "1.875em", marginBottom: "10px" }}
                  >
                    {emptyCredentialsError}
                  </div>
                </span>
              ) : (
                <React.Fragment />
              )}
              <div className="modal-footer text-align-center">
                <button className="btn btn-main btn-update" onClick={onSave}>
                  Add
                </button>
                <button
                  className="btn btn-main "
                  onClick={() => {
                    onClose();
                    setEmptyCredentialsError("");
                    cleanState();
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default AdminCouponModal;