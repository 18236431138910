// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root { 
  --white: #ffffff;
 
  --font-size-l: 30px;
  --font-size-m: 24px;
  --font-size-xl: 70px;
 
  --font-family-poppins: "Poppins", Helvetica;
}
.poppins-normal-white-30px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/styles/styleguide.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;EAEhB,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;;EAEpB,2CAA2C;AAC7C;AACA;EACE,mBAAmB;EACnB,uCAAuC;EACvC,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,uCAAuC;EACvC,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[":root { \n  --white: #ffffff;\n \n  --font-size-l: 30px;\n  --font-size-m: 24px;\n  --font-size-xl: 70px;\n \n  --font-family-poppins: \"Poppins\", Helvetica;\n}\n.poppins-normal-white-30px {\n  color: var(--white);\n  font-family: var(--font-family-poppins);\n  font-size: var(--font-size-l);\n  font-style: normal;\n  font-weight: 400;\n}\n\n.poppins-normal-white-24px {\n  color: var(--white);\n  font-family: var(--font-family-poppins);\n  font-size: var(--font-size-m);\n  font-style: normal;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
