import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Spinner } from "react-bootstrap";
import "../../../src/components/css/homepage.css";
import NavigationBar from "../common/NavigationBar";
import AdminHeaderMenu from "../common/AdminHeaderMenu";
import { RESET_ALL_DATA } from "../../constants/actionTypes";

const HomepageList = (props) => {
  const { applicationState, dispatch } = props;
  const { isLoading } = applicationState;
  const history = useHistory();

  const logoutHanlder = (e) => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");
    if (confirmLogout) {
      window.sessionStorage.clear("cart");
      window.localStorage.clear();
      window.sessionStorage.clear();

      history.push("/signin");
      dispatch({
        type: RESET_ALL_DATA,
      });
    } else {
      e.preventDefault();
    }
  };

  return (
    <div id="adminproductlist" className="admin">
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      <div className="container-fluid">
        <div>
          {" "}
          <NavigationBar />
        </div>
        <div
          class="d-flex justify-content-around flex-wrap"
          style={{ paddingLeft: "7%", paddingRight: "7%" }}
        >
          <div
            style={{
              height: "290px",
              width: "407px",
              borderRadius: "10px",
              background: "#3141A1",
              marginBottom: "15px",
            }}
          >
            <div className="content">
              <h1 className="navBarHeading">Stock Management</h1>
              <a
                onClick={() => history.push("/admin_product_list")}
                className="navBarHeadingMenu"
              >
                Inventory
              </a>

              <a
                onClick={() => history.push("/stock_order")}
                className="navBarHeadingMenu"
              >
                Stock Order
              </a>
            </div>
          </div>
          <div
            style={{
              height: "290px",
              width: "407px",
              borderRadius: "10px",
              background: "#ACA9BB",
              marginBottom: "15px",
            }}
          >
            <div className="content">
              <a
                onClick={() => history.push("/customer_list")}
                className="navBarHeading"
              >
                Customers
              </a>
            </div>
          </div>
          <div
            style={{
              height: "290px",
              width: "407px",
              borderRadius: "10px",
              background: "#777586",
              marginBottom: "15px",
            }}
          >
            <div className="content">
              <a
                onClick={() => history.push("/walk_in_order")}
                className="navBarHeading"
              >
                Walk In Order
              </a>
            </div>
          </div>
          <div
            style={{
              height: "290px",
              width: "407px",
              borderRadius: "10px",
              background: "#980925",
              marginBottom: "15px",
            }}
          >
            <div className="content">
              <h1 className="navBarHeading">CRM</h1>
              <a
                onClick={() => history.push("/crm")}
                className="navBarHeadingMenu"
              >
                BA List
              </a>
              <a
                onClick={() => history.push("/crm")}
                className="navBarHeadingMenu"
              >
                Black Aesthetics
              </a>
            </div>
          </div>
          <div
            style={{
              height: "290px",
              width: "407px",
              borderRadius: "10px",
              background: "#D54C51",
              marginBottom: "15px",
            }}
          >
            <div className="content">
              <h1 className="navBarHeading">Utility</h1>
  
              <a
                onClick={() => history.push("/analytics_and_reports")}
                className="navBarHeadingMenu"
              >
                Analytics and Reports
              </a>
            </div>
          </div>
          <div
            style={{
              height: "290px",
              width: "407px",
              borderRadius: "10px",
              background: "#FFBD58",
              marginBottom: "15px",
            }}
          >
            <div className="content">
              <a onClick={() => logoutHanlder()} className="navBarHeading">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageList;
