import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StockOrderDetails from './StockOrderDetails';
import CloseIcon from '@material-ui/icons/Close';
import { Spinner } from "react-bootstrap";

const OrderTabList = ({ orderInfo, fetchAllStockOrders, showEmailPopUp, dispatch, history, config, onClose, commentsData, isSpinnerEnabled, showStatus, disableButtons}) => {
    return (
    <>
      <Tabs className="tab-container position-relative pt-4">
        <button className="close-button" onClick={onClose}>Close <CloseIcon style={{ width: 20 }} /></button>
        <TabList className="m-0">
          <Tab>View Order</Tab>
        </TabList>
        {/* Order Details */}
        <TabPanel className="tab-content">
          <StockOrderDetails fetchAllStockOrders={fetchAllStockOrders} orderInfo={orderInfo} showEmailPopUp={showEmailPopUp} showStatus={showStatus} disableButtons={disableButtons}/>
        </TabPanel>
        {/* Customer Details */}
      </Tabs>
      {isSpinnerEnabled && <div className="d-flex justify-content-center loader">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>}
    </>
  );
}
export default OrderTabList