export const getStatusTitle = ( title) => {
  switch (title) {
    case "status-1":
      return title.replace("status-1", "Stage- ORDER PLACED");
    case "status-2":
      return title.replace("status-2", "Stage- READY TO COLLECT");
    case "status-3":
      return title.replace("status-3", "Stage- ON HOLD");
    case "status-4":
      return title.replace("status-4", "Stage- AWAITING PAYMENT");
    case "status-5":
      return title.replace("status-5", "Stage- CANCELLED");
      case "status-6":
      return title.replace("status-6", "Stage- ORDER COMPLETE");
    default:
      return title;
  }
};

export const getStatus = (stage) => {
  switch (stage?.currentstage) {
    case "status-1":
      return "ORDER PLACED";
    case "status-2":
      return "READY TO COLLECT";
    case "status-3":
      return "ON HOLD";
    case "status-4":
      return "AWAITING PAYMENT";
    case "status-5":
      return "CANCELLED";
    default:
      return "N/A";
  }
};

export function formatDate(date, format) {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  // Pad month and day with leading zeros if needed
  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;

  // Replace format placeholders with actual date values
  return format.replace("YYYY", year).replace("MM", month).replace("DD", day);
}
