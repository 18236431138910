import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    ADD_USER_DETAILS,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";
import { getAllStockOrders } from "./getAllStockOrders"

export const AddStockOrder = async ({
    dispatch,
    requestBodyForAdd,
    config
}) => {
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({
        type: NO_DATA_FOUND,
        payload: { error: ""}
    });
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": config.authToken },
        null
    );
    await axios
        .post(`${envConfig.apiUrl}:${envConfig.apiPort}/order/placestockorder`,
        requestBodyForAdd,apiRequestHeader)
        .then((apiResponse) => {
            getAllStockOrders({ dispatch: dispatch, config: config })
            dispatch({ type: SET_IS_LOADING, payload: false });
        }).catch((error) => {
            dispatch({
                type: NO_DATA_FOUND,
                payload: { error: error.response.data ?? "No User found for user" }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
