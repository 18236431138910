import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    SET_IS_LOADING,
    PROMOCODE_DISCOUNT,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const promoCodeApiCall = async ({ dispatch, authToken, promoCodeRequest }) => {
    let apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken }
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/applypromocode`;
    
    await axios
        .post(apiUrl, promoCodeRequest, apiRequestHeader)
        .then((apiResponse) => {
            console.log(apiResponse.data);
            dispatch({
                type: PROMOCODE_DISCOUNT,
                payload: apiResponse.data,
                promocode: promoCodeRequest.promoCode
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        })
        .catch((error) => {
            dispatch({
                type: PROMOCODE_DISCOUNT,
                payload: null,
                promoCodeError: error.response.data,
                promocode: ""
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
