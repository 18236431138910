import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
    GET_ALL_COUPON,
    NO_DATA_FOUND,
    SET_IS_LOADING,
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const getCouponApiCall = async ({
    dispatch,
    config
}) => {
    dispatch({ type: SET_IS_LOADING, payload: true });
    dispatch({ 
        type: NO_DATA_FOUND, 
        payload: { error: ""}
    });
    const apiRequestHeader = makeApiRequestHeader(
        "GET",
        { 
            "x-auth-token": config && config.authToken,
            'Content-Type': 'application/json',
            'Cookie': 'JSESSIONID=0219AB5B01F985FD5218404C0C1CBE0F'
        },
        null
    );
    await axios
        .get(`${envConfig.apiUrl}:${envConfig.apiPort}/order/couponlines/fetchall`,
            apiRequestHeader)
        .then((apiResponse) => {
            dispatch({
                type: GET_ALL_COUPON,
                payload: apiResponse?.data,
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        }).catch((error) => {
            dispatch({ 
                type: NO_DATA_FOUND, 
                payload: { error: error.response.data ?? "No User found for user" }
            });
            dispatch({ type: SET_IS_LOADING, payload: false });
        });
};
