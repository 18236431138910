import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { CLEAR_ADD_ITEM_FOR_ORDER, DISCOUNT_AMOUNT, PROMOCODE_DISCOUNT, SET_ERROR, SET_IS_LOADING } from "../constants/actionTypes";
import { saveAs } from "file-saver";
import envConfig from "../envConfigurations";

export const placeOrderApiCall = async ({
  dispatch,
  history,
  placeOrderRequest,
  authToken,
  userType
}) => {
  const apiRequestHeader = makeApiRequestHeader(
    "POST",
    { "x-auth-token": authToken },
    null
  );
  apiRequestHeader['responseType'] = 'blob'
  const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/${userType === "ADMIN" ? "walkin" : "placeorder"}`;

  await axios
    .post(apiUrl, placeOrderRequest, apiRequestHeader)
    .then((apiResponse) => {
      if (userType === "ADMIN") {
        const fileData = new Blob([apiResponse.data]);
        saveAs(fileData, "invoice.pdf");
        dispatch({
          type: CLEAR_ADD_ITEM_FOR_ORDER
        })
        history.push("/customerpayment_walkin_success")
      }
      else {
        dispatch({ type: SET_IS_LOADING, payload: false });
        dispatch({
          type: PROMOCODE_DISCOUNT,
          payload: null,
          promoCodeError: "",
          promocode: ""
        });
        dispatch({
          type: DISCOUNT_AMOUNT,
          payload: {
            discountAmount: 0
          }
        });
        history.push("/customerpayment_success");
      }
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
};
