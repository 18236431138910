import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { useHistory } from "react-router-dom";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

// import { PromoCodeColumn } from './PromoCodeColumn';
// import PromoCodeModal from './PromoCodeModal';

import { CustomerUserNameColumn } from "./CustomerUserNameColumn";
import { CustomerEmailColumn } from "./CustomerEmailColumn";
import { CustomerNotificationColumn } from "./CustomerNotificationColumn";
import { CustomerApprovalStatus } from "./CustomerApprovalStatus";
import { CustomerDownloadButton } from "./CustomerDownloadButton";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import {
  CPP_UPDATE,
  PROMOTIONAL_EMAIL_MESSAGE,
  SET_IS_LOADING,
  UPDATE_CUSTOMER_DETAILS,
} from "../../../constants/actionTypes";
import { getOrderListOfCustomerForAdmin } from "../../../serviceCalls/getOrderListOfCustomerForAdmin";
import { Spinner } from "react-bootstrap";
import { updateCustomerNotificationApiCall } from "../../../serviceCalls/updateCustomerNotificationApiCall";
import { updateCustomerApprovalStatus } from "../../../serviceCalls/updateCustomerApprovalStatus";
import PromotionalEmailModal from "../AdminProductListTab/PromotionalEmailModal";
import { toggleCppCall } from "../../../serviceCalls/toggleCppCall";
import { downloadOrderInovice } from "../../../serviceCalls/downloadOrderInvoice";
import { deleteCustomer } from "../../../serviceCalls/deleteCustomer";
import { ToastContainer } from "react-toastify";
import { fetchCustomerDetails } from "../../../serviceCalls/getProductsApiCall";
import NavigationBar from "../../common/NavigationBar";

const AdminCustomersList = (props) => {
  const { applicationState, dispatch } = props;
  const { isLoading, config, promotionalMessage } = applicationState;
  const history = useHistory();

  const [gridApi, setGridApi] = React.useState(null);
  const [fetchingCustomerInfo, setFetchingCustomerInfo] = React.useState(true);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [rerender, setRerender] = React.useState(false);
  const [showPromotionalModal, setshowPromotionalModal] = React.useState(false);
  const [userType, setUserType] = React.useState("ALL");
  const authToken = localStorage.getItem("sessionKey");
  let notificationValue = {};
  const _fetchCustomerInfo = () => {
    try {
      fetchCustomerDetails(dispatch);
      setFetchingCustomerInfo(false);
    } catch (e) {
      setFetchingCustomerInfo(false);
    }
  };

  useEffect(() => {
    _fetchCustomerInfo();
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const frameWorkComponentChange = ({ api }) => {};

  const onEmailClicked = (params) => {
    // check usertype and based on that render the element
    if (userType === "ALL" && params?.data?.usertype === "GANDYS_USER") {
      history.push(`/orders_by_customer/${params?.data?.id}`);
    } else if (
      userType === "ALL" &&
      params?.data?.usertype === "WHOLESALE_USER"
    ) {
      history.push(`/orders_by_customer_wholesale/${params?.data?.id}`);
    } else if (userType === "ALL" && params?.data?.usertype === "PHARMA_USER") {
      history.push(`/orders_by_customer_pharma/${params?.data?.id}`);
    } else if (userType === "GANDYS_USER") {
      history.push(`/orders_by_customer/${params?.data?.id}`);
    } else if (userType === "WHOLESALE_USER") {
      history.push(`/orders_by_customer_wholesale/${params?.data?.id}`);
    } else {
      history.push(`/orders_by_customer_pharma/${params?.data?.id}`);
    }
  };

  const onUserNameClicked = (customerData) => {
    if (
      customerData?.iscppenabled &&
      customerData?.tradeofbuisness === "Training Academy"
    ) {
      dispatch({
        type: CPP_UPDATE,
        payload: { message: "", status: "" },
      });
      toggleCppCall({
        authToken: config?.authToken,
        profile: customerData,
        dispatch,
        toggleValue: customerData.iscppenabled,
        adminEmail: config?.email,
      });
    }
    // check usertype and based on that render the element
    if (userType === "ALL" && customerData?.usertype === "GANDYS_USER") {
      history.push(`/customer_profile/${customerData?.id}`);
    } else if (
      userType === "ALL" &&
      customerData?.usertype === "WHOLESALE_USER"
    ) {
      history.push(`/customer_profile/wholesale/${customerData?.id}`);
    } else if (userType === "ALL" && customerData?.usertype === "PHARMA_USER") {
      history.push(`/customer_profile/pharma/${customerData?.id}`);
    } else if (userType === "GANDYS_USER") {
      history.push(`/customer_profile/${customerData?.id}`);
    } else if (userType === "WHOLESALE_USER") {
      history.push(`/customer_profile/wholesale/${customerData?.id}`);
    } else {
      history.push(`/customer_profile/pharma/${customerData?.id}`);
    }
  };

  const showPromotionalEmailModal = (showModalValue) => {
    setshowPromotionalModal(showModalValue);
    dispatch({
      type: PROMOTIONAL_EMAIL_MESSAGE,
      payload: "",
    });
  };

  const promotionalEmail = (e) => {
    e.preventDefault();
    setshowPromotionalModal(true);
  };

  const columnDefs = [
    {
      field: "username",
      headerName: "Username",
      cellRenderer: "usernamecolumn",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "email",
      headerName: "Email",
      cellRenderer: "emailcolumn",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    // { field: "instaname", headerName: "Instagram Name" },
    {
      field: "userapproved",
      headerName: "Approval Status",
      cellRenderer: "customerApprovalStatusRenderer",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "mobileno",
      headerName: "Mobile Number",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "address.city",
      headerName: "City",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    {
      field: "address.postcode",
      headerName: "Postal Code",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    // {
    //   field: "notification",
    //   headerName: "Notification",
    //   cellRenderer: "notificationcolumn",
    //   cellStyle: { color: "#31A191", fontSize: "14px" },
    // },
    {
      field: "delete",
      headerName: "Delete",
      cellRenderer: "deleteColumn",
      cellStyle: { color: "#31A191", fontSize: "14px" },
    },
    // { field: "download", headerName: "Download", cellRenderer: "downloadcolumn" }
  ];

  const userTypeList = () => {
    if (userType === "ALL") {
      var a = applicationState.customerList || [];
      var b = applicationState.wholesaleCustList || [];
      var c = applicationState.pharmaCustList || [];
      var allusers = [...a, ...b, ...c];
      return allusers;
    } else if (userType === "GANDYS_USER") return applicationState.customerList;
    else if (userType === "WHOLESALE_USER")
      return applicationState.wholesaleCustList;
    else return applicationState.pharmaCustList;
  };

  const rowData = () => {
    const customerlistArray = [];
    const userList = userTypeList();
    userList &&
      userList.forEach((rowdetail) => {
        if (notificationValue.email === rowdetail.email)
          rowdetail.notification = notificationValue.value;
        customerlistArray.push({ ...rowdetail });
      });
    return customerlistArray;
  };

  const defaultColDef = React.useMemo(
    () => ({
      resizable: true,
      sortable: true,
    }),
    []
  );

  const onFilterTextBoxChanged = (event) => {
    gridApi.setQuickFilter(event.target.value);
  };
  const showPromocodeModal = (showModalValue) => {
    setShowModal(showModalValue);
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F8F7FF" };
    }
  };

  const changeNotification = (value, data, isNotification) => {
    notificationValue = {
      email: data.email,
      value: value,
    };
    const userList = userTypeList();
    userList.map((rowdetail) => {
      if (rowdetail.email === notificationValue.email)
        updateCustomerNotificationApiCall({
          dispatch,
          email: notificationValue.email,
          isNotificationEnabled: notificationValue.value,
          authToken,
          history,
        });
    });
  };

  const changeApprovalStatus = (userapprovalstatus, data) => {
    console.log(
      data,
      userapprovalstatus,
      applicationState,
      config,
      applicationState
    );
    updateCustomerApprovalStatus({
      dispatch,
      payload: {
        useremail: data.email,
        approveremail: localStorage.getItem("email"),
        userapprovalstatus,
      },
      authToken,
    });
  };
  const calculateGridHeight = () => {
    const rowHeight = 50; // Assuming each row is 50px
    const paginationHeight = 40; // Height of pagination control
    const rowCount = rowData().length;
    console.log(rowCount);
    return rowCount * rowHeight + paginationHeight + 110; // 110px is for the other fixed height elements
  };
  const onUserChange = (e) => {
    setUserType(e.target.value);
  };

  const SpinnerEl = () => (
    <div className="d-flex justify-content-center loader">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );

  if (fetchingCustomerInfo) return <SpinnerEl />;

  return (
    <div id="admincustlist" className="admin">
      {isLoading && <SpinnerEl />}
      <div>
        <AdminHeaderMenu dispatch={dispatch} />
      </div>
      {applicationState.isSpinnerEnabled && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div className="container-fluid">
        <div>
          {" "}
          <NavigationBar />
        </div>
        <div className="list-actions">
          <select
            name="user-type"
            id="user-type"
            onChange={(e) => onUserChange(e)}
            className="form-select"
          >
            <option value="ALL">ALL</option>
            <option value="GANDYS_USER">Instagram</option>
            <option value="WHOLESALE_USER">Wholesale</option>
            <option value="PHARMA_USER">Pharma</option>
          </select>
          <input
            className="search-bottom-margin mt-4"
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onChange={(event) => onFilterTextBoxChanged(event)}
          />
        </div>
        <div
          className="ag-theme-alpine"
          style={{ height: "1200px", width: "100%" }}
        >
          <AgGridReact
            rowData={rowData()}
            getRowStyle={getRowStyle}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            frameworkComponents={{
              usernamecolumn: CustomerUserNameColumn,
              emailcolumn: CustomerEmailColumn,
              notificationcolumn: (params) =>
                CustomerNotificationColumn(params, changeNotification),
              customerApprovalStatusRenderer: (params) =>
                CustomerApprovalStatus(params, changeApprovalStatus),
              deleteColumn: (params) => (
                <DeleteOutlineIcon
                  style={{ color: "red" }}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this customer?"
                      )
                    ) {
                      deleteCustomer(dispatch, params.data.email);
                    }
                  }}
                />
              ),
              // downloadcolumn: (params) => CustomerDownloadButton(params, downloadButtonClicked)
            }}
            context={{
              frameWorkComponentChange: frameWorkComponentChange,
              showPromocodeModal: showPromocodeModal,
              onEmailClicked: onEmailClicked,
              onUserNameClicked: onUserNameClicked,
            }}
            // paginationAutoPageSize={true}
            paginationPageSize={50}
            pagination={true}
          ></AgGridReact>
        </div>
        {/* <div className="text-center mrt-20">
          <button
            className="btn btn-secondary"
            type="submit"
            name="btn-checkout"
            id="btn-checkout"
            disabled={false}
            onClick={(e) => promotionalEmail(e)}
          >
            Promotional Email
          </button>
        </div>
        <PromotionalEmailModal
          title={"Promotional Email"}
          onClose={() => showPromotionalEmailModal(false)}
          show={showPromotionalModal}
          dispatch={dispatch}
          authToken={config.authToken}
          userEmail={config.email}
          promotionalMessage={promotionalMessage}
        /> */}
        {/* <PromoCodeModal title="Add Promocode" onClose={() => showPromocodeModal(false)} show={showModal}>
                        <p><strong>Please enter promotional code to be applied:</strong></p>
                        <input
                            type="text"
                            name="fname"
                            className=""
                            placeholder="Enter code"
                        />
                    </PromoCodeModal> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminCustomersList;
