import React from "react";
import "./css/GBEASIHOMEPRESENTATION2.css";
import { useHistory, Redirect } from "react-router-dom";
import HeaderMenu from '../../common/AdminHeaderMenu';
function CppInfo2(props) {
  const history = useHistory();
  const {
    spanText1,
    spanText2,
    spanText3,
    spanText5,
    spanText6,
    spanText8,
    spanText9,
    maskGroup4,
    group58,
    group68,
    group3
  } = props;
  return (
    <>
      <HeaderMenu />
      <div className="container-fuild cpp2maindiv p-0 cpp2bgimg">
        {/* <div className="cpp2firstrectangle"></div> */}
        {/* <div className="bgimgdiv">
        <img className="cpp2bgimg"></img>
      </div> */}
        <div className="content">
          <div className="col-md-10 col-sm-6 cpp2group44div" style={{ margin: '50px 0px' }}>
            <div className="cpp2group-44">
              <span className="cpp2group-41">{spanText1}</span><br />
              <span className="cpp2group-42">{spanText2}</span>
            </div>
          </div>
          <div className="col-md-12 col-sm-8 cpp2imgtext" style={{ margin: '50px 0px' }}>
            <div className="row">
              <div className="col-md-4 p-0 mr-3">
                <img className="mask-group-4" src={maskGroup4} />
              </div>
              <div className="col-md-8 col-sm-12 p-0 imgtext">
                <span className="cpp2group-41">{spanText8}</span><br />
                <span className="cpp2group-42">{spanText9}</span>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-sm-6 cpp2onlytext" style={{ margin: '50px 0px' }}>
            <div className="cpp2group-44">
              <span className="cpp2group-41">{spanText3}</span><br />
              <span className="cpp2group-42">{spanText5}</span>
            </div>
          </div>
          <div className="col-md-12 cpp2distributors" style={{ margin: '50px 0px' }}>
            <div className="cpp2group-44">
              <span className="cpp2group-41">{spanText6}</span>
              <div className="container-fuild mt-5 d-flex cpp2partnerimg" style={{ flexWrap: 'wrap' }}>
                <img className="cpp2group-58-1" src={group58} />
                <img className="cpp2group-68-1" src={group68} />
                <img className="cpp2group-68-2" src={group3} />
              </div>
            </div>
          </div>
          <a href="cppinfo3">
            <div className="container-fluid nextdiv">
              <span>Next</span>
            </div>
          </a>
        </div>
        <div className="cpp2lastrectangle"></div>
      </div>
    </>
  );
}
export default CppInfo2;