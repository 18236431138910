export const SUPPORTED_WAREHOUSE = {
  GLASGOW: "Glasgow",
  LONDON: "London",
  HALE: "Hale",
  Online_Warehouse: "Online-Warehouse",
  LIST: ["Online-Warehouse", "Glasgow", "London", "Hale"]
};

export const STORAGE_KEYS = {
  WALKING_SELECTED_WAREHOUSE: "walking-selected-warehouse"
};

export const USER_TYPE = {
  USER: 'USER',
  PRESCRIBER: 'PRESCRIBER',
  ADMIN: 'ADMIN'
}