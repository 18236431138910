import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import { SET_SIGN_IN_DATA, SET_IS_LOADING } from "../constants/actionTypes";
import { USER_TYPE } from "../constants/common";
import { getProductsApiCall } from "../serviceCalls/getProductsApiCall";
import ApprovalPending from "./ApprovalPending";
import VerifyEmail from "./VerifyEmail";
import VerifyPhonenumber from "./VerifyPhonenumber";

const RequireAuth = (WrappedComponent, props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const { config, customerDetails, isLoading, productList } = applicationState;
  const [isAuthenticated, setAuthentication] = useState(true);

  const activeSession = window.localStorage.getItem("sessionKey");
  const userType = window.localStorage.getItem("userType");
  const email = window.localStorage.getItem("email");
  const counter = window.localStorage.getItem("counter");

  const payload = {
    authToken: activeSession,
    userType: userType
  }

  React.useEffect(()=>{
    if (activeSession) {
      setAuthentication(true)
    } else {
      setAuthentication(false)
    }
  },[config])

  const loginErrorMessage = (
    <div>
      Please login in order to view this part of the application.
      <br />
      <a href="/signin">Click here!</a>
    </div>
  );

  if (userType === USER_TYPE.USER) {
    if (!customerDetails || !customerDetails.id) return null;

    if (!customerDetails.emailverified) {
      return <VerifyEmail email={customerDetails?.email} {...props} />
    }

    if (!customerDetails.smsverified) {
      return <VerifyPhonenumber mobileno={customerDetails?.mobileno} {...props} />
    }

    if (isAuthenticated && !customerDetails.userapproved) {
      return <ApprovalPending />
    }
  }

  return (
    <div>
      {isAuthenticated === true ? (
        <WrappedComponent {...props} />
      ) : (
          loginErrorMessage
        )}
    </div>
  );
};

export default RequireAuth;