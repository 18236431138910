
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import ImageUploading from 'react-images-uploading';
import { sendPromotionalEmail } from "../../../serviceCalls/sendPromotionalEmail";
import { PROMOTIONAL_EMAIL_MESSAGE } from "../../../constants/actionTypes";

const PromotionalEmailModal = (props) => {
    const { show, title, children, onClose, userEmail, authToken, dispatch, promotionalMessage } = props;
    const [images, setImages] = React.useState([]);
    const [subjectValue, setSubjectValue] = React.useState("");
    const [errorDetails, setErrorDetails] = React.useState("");
    const [showUpload, setShowUpload] = React.useState(true)
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        setShowUpload(imageList.length > 0 ? false : true);
    };

    const onSubjectChange = (e) => {
        setSubjectValue(e.target.value);
    };

    const sendMail = (mode, e) => {
        e.preventDefault();
        dispatch({
            type: PROMOTIONAL_EMAIL_MESSAGE,
            payload: ""
        })
        const imageToBase64 = images[0].data_url.split(',');
        sendPromotionalEmail({
            dispatch,
            authToken,
            requestBodyForEmail: {
                subject: subjectValue,
                userLoggedInEmail: userEmail,
                emailLiveMode: mode,
                imageSrc: imageToBase64[0],
                image: imageToBase64[1]
            }
        })
        console.log(images);
    }

    return (
        <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
            <div className="modal">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                    </div>
                    <div className="modal-body">
                        <div className="subject-details">
                            <label><strong>Subject: </strong></label>
                            <input
                                type="text"
                                className=""
                                id="subjectInput"
                                placeholder="Subject"
                                onChange={(e) => onSubjectChange(e)}
                                value={subjectValue}
                            />
                        </div>
                        <div className="body-details">
                            <label><strong>Body: </strong></label>
                            <div className="App">
                                <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={maxNumber}
                                    dataURLKey="data_url"
                                >
                                    {({
                                        imageList,
                                        onImageUpload,
                                        onImageRemoveAll,
                                        onImageUpdate,
                                        onImageRemove,
                                        isDragging,
                                        dragProps,
                                    }) => (
                                        <div className="upload-image-container" >
                                            <div className="upload-image">
                                                {showUpload && <button
                                                    style={isDragging ? { color: 'red' } : undefined}
                                                    onClick={onImageUpload}
                                                    {...dragProps}
                                                    className="upload-button"
                                                >
                                                    Click or Drop here
                                                </button>}
                                            </div>
                                            {imageList.map((image, index) => (
                                                <div key={index} className="image-item">
                                                    <div className="image-item__btn-wrapper">
                                                        <button onClick={() => onImageRemove(index)}>X</button>
                                                    </div>
                                                    <img src={image['data_url']} alt="" max-height="150px" />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </ImageUploading>
                            </div>
                        </div>
                        <div className="text-align-center promotional-email">
                            <button className="btn btn-main"
                                onClick={(e) => sendMail(false, e)}
                                disabled={images.length > 0 && subjectValue !== "" ? false : true}
                            >
                                Test Mail
                            </button>
                            <button
                                onClick={(e) => sendMail(true, e)}
                                className="btn floating-modal-btn btn-secondary"
                                disabled={images.length > 0 && subjectValue !== "" ? false : true}
                            >
                                Send Mail
                            </button>
                            <button
                                onClick={onClose}
                                className="btn floating-modal-btn btn-secondary"
                            >
                                Close
                            </button>
                        </div>
                        <div className="promotional-message">
                            <label>{promotionalMessage}</label>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default PromotionalEmailModal;
