export const SET_MOBILE_VIEW = "SET_MOBILE_VIEW";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const SET_ERROR = "SET_ERROR";
export const SET_SIGN_IN_DATA = "SET_SIGN_IN_DATA";
export const SET_SIGN_UP_DATA = "SET_SIGN_UP_DATA";
export const EDIT_PRODUCT_QUANTITY = "EDIT_PRODUCT_QUANTITY";
export const IS_CART_EMPTY = "IS_CART_EMPTY";
export const SET_CUSTOMER_BILLING_DETAILS = "SET_CUSTOMER_BILLING_DETAILS";
export const UPDATE_CUSTOMER_DETAILS = "UPDATE_CUSTOMER_DETAILS";
export const SET_USER_VERIFY_STATE = "SET_USER_VERIFY_STATE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_FORGOT_PASSWORD_ERROR = "SET_FORGOT_PASSWORD_ERROR";
export const SET_TOTAL_AMOUNT = "SET_TOTAL_AMOUNT";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_KANBAN_DETAILS = "SET_KANABAN_DETAILS";
export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS";
export const ADMIN_ADD_ITEM_FOR_ORDER = "ADMIN_ADD_ITEM_FOR_ORDER";
export const RESET_ALL_DATA = "RESET_ALL_DATA";
export const SET_TILE_CLICKED = "SET_TILE_CLICKED";
export const PRODUCT_DETAIL_DATA = "PRODUCT_DETAIL_DATA";
export const SET_PROFILE_UPDATE_STATUS = "SET_PROFILE_UPDATE_STATUS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const GET_CUSTOMER_ORDER_LIST = "GET_CUSTOMER_ORDER_LIST";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PLACE_ORDER_DATA = "PLACE_ORDER_DATA";
export const SET_CPP_DETAILS = "SET_CPP_DETAILS";
export const SET_ADD_DELETE_NEW_PRODUCT = "SET_ADD_DELETE_NEW_PRODUCT";
export const UPDATE_CUSTOMER_NOTIFICATION_STATUS = "UPDATE_CUSTOMER_NOTIFICATION_STATUS";
export const UPDATE_CUSTOMER_APPROVAL_STATUS = "UPDATE_CUSTOMER_APPROVAL_STATUS";
export const SET_NOTIFICATION_SPINNER = "SET_NOTIFICATION_SPINNER";
export const CLEAR_ADD_ITEM_FOR_ORDER = "CLEAR_ADD_ITEM_FOR_ORDER";
export const FETCH_COMMENTS_DATA = "FETCH_COMMENTS_DATA";
export const USER_DETAILS="USER_DETAILS"
export const STOCK_ORDERS="STOCK_ORDERS"
export const SET_ADD_DELETE_NEW_ORDER = "SET_ADD_DELETE_NEW_ORDER";
export const NO_DATA_FOUND = "NO_DATA_FOUND";
export const EXISTING_CUSTOMER_DETAILS = "EXISTING_CUSTOMER_DETAILS";
export const EXISTING_CUSTOMER_DETAILS_USING_ID = "EXISTING_CUSTOMER_DETAILS_BY_ID";
export const PROMOTIONAL_EMAIL_MESSAGE = "PROMOTIONAL_EMAIL_MESSAGE";
export const PROMOCODE_DISCOUNT = "PROMOCODE_DISCOUNT";
export const DISCOUNT_AMOUNT = "DISCOUNT_AMOUNT";
export const CPP_DISCOUNT = "CPP_DISCOUNT";
export const CPP_UPDATE = "CPP_UPDATE";
export const FETCH_REPORTS_DATA = "FETCH_REPORTS_DATA"
export const TA_ACCOUNT_UPDATE = "TA_ACCOUNT_UPDATE";
export const TA_CREDIT_USER_DETAILS = "TA_CREDIT_USER_DETAILS";
export const SEND_TA_CREDIT_SUCCESSED = "SEND_TA_CREDIT_SUCCESSED";
export const TA_CREDIT_APPROVAL_SUCCESSED = "TA_CREDIT_APPROVAL_SUCCESSED";
export const SAVE_TA_CREDIT_SUCCESSED = "SAVE_TA_CREDIT_SUCCESSED";
export const TA_CREDIT_DOC_UPLOAD_SUCCESSED = "TA_CREDIT_DOC_UPLOAD_SUCCESSED";
export const TA_CREDIT_DOC_UPLOAD_FAILED = "TA_CREDIT_DOC_UPLOAD_FAILED";
export const TA_CREDIT_DOC_DELETE_SUCCESSED = "TA_CREDIT_DOC_DELETE_SUCCESSED";
export const TA_CREDIT_DOC_DELETE_FAILED = "TA_CREDIT_DOC_DELETE_FAILED";
export const SUBMIT_TA_FORM_SUCCESS = "SUBMIT_TA_FORM_SUCCESS";
export const SUBMIT_TA_FORM_FAILED = "SUBMIT_TA_FORM_FAILED";
export const GET_TA_FORM_SUCCESS = "GET_TA_FORM_SUCCESS";
export const GET_TA_FORM_FAILED = "GET_TA_FORM_FAILED";
export const FETCH_GP_REPORTS_DATA = "FETCH_GP_REPORTS_DATA";
export const CONTINUE_WITH_NEW_USER = "CONTINUE_WITH_NEW_USER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const UPDATE_ORDER_STATUS='UPDATE_ORDER_STATUS';
export const ADD_USER_DETAILS="ADD_USER_DETAILS";
export const DELETE_ORDER_STOCK="DELETE_ORDER_STOCK";
export const ADD_COUPON="ADD_COUPON";
export const GET_ALL_COUPON="GET_ALL_COUPON";
export const DELETE_COUPON="DELETE_COUPON";
export const GET_SEARCH_EMAIL="GET_SEARCH_EMAIL";
export const GET_SEARCH_PRODUCT="GET_SEARCH_PRODUCT";
