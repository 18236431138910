import React from "react";
import Group46 from "./Group46";
import "./css/GBEASIHOMEPRESENTATION3.css";
import { useHistory, Redirect } from "react-router-dom";
import HeaderMenu from '../../common/AdminHeaderMenu';
function CppInfo3(props) {
  const history = useHistory();
  const {
    easiImages3,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText41,
    spanText42,
    spanText43,
    spanText44,
    spanText5,
    maskGroup7,
    maskGroup8,
    maskGroup5,
  } = props;
  return (
    <>
      <HeaderMenu />
      <div className="contianer-fuild cpp3maindiv p-0 cpp3bgimg">
        {/* <div className="cpp3firstrectangle"></div> */}
        {/* <div className="bgimgdiv">
        <img className="cpp3bgimg"></img>
      </div> */}
        <div className="content">
          <div className="col-md-10 col-sm-6 cpp3together" style={{ margin: '50px 0px' }}>
            <div className="cpp3group-44">
              <span className="cpp3group-41">{spanText1}</span><br />
              <span className="cpp3group-42">{spanText2}</span>
            </div>
          </div>
          <div className="col-md-10 col-sm-6 cpp3benefits" style={{ margin: '50px 0px' }}>
            <div className="cpp3group-44">
              <span className="cpp3group-41">{spanText3}</span><br />
              <span className="cpp3group-42">{spanText4}</span><br />
              <span className="cpp3group-42">{spanText41}</span><br />
              <span className="cpp3group-42">{spanText42}</span><br />
              <span className="cpp3group-42">{spanText43}</span><br />
              <span className="cpp3group-42">{spanText44}</span>
            </div>
          </div>
          <div className="col-md-10 img">
            <div className="row">
              <div className="col-md-5 p-0 mr-3 bigimg">
                <img className="cpp3mask-group-5" src={maskGroup5} />
              </div>
              <div className="col-md-5 p-0">
                <img className="cpp3mask-group-7" src={maskGroup7} />
                <img className="cpp3mask-group-8" src={maskGroup8} />
              </div>
            </div>

          </div>
          <a href="cpppage1">
            <div className="container-fluid nextdiv">
              <span>Next</span>
            </div>
          </a>
        </div>
        <div className="cpp3lastrectangle"></div>
      </div>
    </>
  );
}
export default CppInfo3;