import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import {
  SET_ERROR,
  SET_IS_LOADING,
  SET_NOTIFICATION_SPINNER,
  FETCH_COMMENTS_DATA
} from "../constants/actionTypes";
import envConfig from "../envConfigurations";

export const fetchUpdateCommentsApiCall = async ({
  dispatch,
  orderId,
  fetchComment,
  authToken,
  history,
  comment,
  commentaddedby
}) => {
  const apiUrl = fetchComment
    ? `${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcomments?orderid=${orderId}`
    : `${envConfig.apiUrl}:${envConfig.apiPort}/order/addcomment`;
    dispatch({
      type: SET_NOTIFICATION_SPINNER,
      isSpinnerEnabled: true,
    });
  if(fetchComment){
    const apiRequestHeaderGET = makeApiRequestHeader(
        "GET",
        { "x-auth-token": authToken },
        null
      );
    await axios
    .get(apiUrl, apiRequestHeaderGET)
    .then((apiResponse) => {
      if (apiResponse?.status === 200) {
        console.log('apiResponse', apiResponse);
        dispatch({
          type: FETCH_COMMENTS_DATA,
          payload: apiResponse.data
        });
          dispatch({
            type: SET_NOTIFICATION_SPINNER,
            isSpinnerEnabled: false,
          });
      }
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
  }
  else{
    const apiRequestHeaderPOST = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
      );
    await axios
    .post(apiUrl, { orderid: orderId,comment: comment, commentaddedby: commentaddedby }, apiRequestHeaderPOST)
    .then((apiResponse) => {
      if (apiResponse?.status === 200) {
          const requestHeader = makeApiRequestHeader(
            "GET",
            { "x-auth-token": authToken },
            null
          );
        axios
        .get(`${envConfig.apiUrl}:${envConfig.apiPort}/order/fetchcomments?orderid=${orderId}`, requestHeader)
        .then((apiResponse) => {
          if (apiResponse?.status === 200) {
            dispatch({
              type: FETCH_COMMENTS_DATA,
              payload: apiResponse.data,
              commentsData: apiResponse.data.comments
            });
          }
          dispatch({
            type: SET_NOTIFICATION_SPINNER,
            isSpinnerEnabled: false,
          });
        })
        .catch(() => {
          dispatch({ type: SET_IS_LOADING, payload: false });
          dispatch({
            type: SET_ERROR,
          });
        });
      }
    })
    .catch(() => {
      dispatch({ type: SET_IS_LOADING, payload: false });
      dispatch({
        type: SET_ERROR,
      });
    });
  }
  
};
