import axios from "axios";
import { makeApiRequestHeader } from "../utils/makeApiRequestHeader";
import { TA_CREDIT_DOC_UPLOAD_SUCCESSED,  TA_CREDIT_DOC_UPLOAD_FAILED} from "../constants/actionTypes";
import { fetchCreditUserApiCall } from "../serviceCalls/fetchCreditUserApiCall";

import envConfig from "../envConfigurations";

export const uploadTACreditDocs = async ({
    dispatch,
    authToken,
    file,
    taemail
}) => {
    const apiRequestHeader = makeApiRequestHeader(
        "POST",
        { "x-auth-token": authToken },
        null
    );
    const apiUrl = `${envConfig.apiUrl}:${envConfig.apiPort}/order/uploadtadocs`;
    await axios
        .post(apiUrl, file, apiRequestHeader)
        .then((response) => {
            dispatch({
                type: TA_CREDIT_DOC_UPLOAD_SUCCESSED,
                payload: response.data
            })
            fetchCreditUserApiCall({
                dispatch: dispatch,
                authToken: authToken,
                email: taemail
            })
        })
        .catch(() => {
            dispatch({
                type: TA_CREDIT_DOC_UPLOAD_FAILED,
                payload: "An unexpected error has occurred. Please try again later"
            })
        });
};
