import React from "react";
import { toast } from "react-toastify";
import minus from '../../assert/customer-design/minus.svg';
import plus from '../../assert/customer-design/plus.svg';

export const NewColumnQuantity = ({params, handleQtyChanges}) => {
  const { api, column, node, context } = params;
  const data = params;
  let quantityValue = data.quantity ? Number(data.quantity) : 0;


  const setDataValue = (newVal) => {
    if (parseInt(newVal) > 0) {
      return toast(`Product quantity can not be more than ${data.warehouse["Online-Warehouse"]}`)
    }
  }

  const onIncrement = () => {
    const incrementQuantity = Number(quantityValue) + 1;

    if (parseInt(incrementQuantity) > data.warehouse["Online-Warehouse"]) {
        return toast(`Product quantity can not be more than ${data.warehouse["Online-Warehouse"]}`)
    }

    handleQtyChanges(incrementQuantity, params);
  };

  const onDecrement = () => {
    const decrementQuantity = Number(quantityValue) <= 0 ? 0 : Number(quantityValue) - 1;

    if (parseInt(decrementQuantity) < 0) {
        return toast(`Product quantity can not be less than 0`)
    }

    handleQtyChanges(decrementQuantity, params);
  };

  const onInputChange = (event) => {
    if (event.target.value &&
        !isNaN(event.target.value) &&
        (Number(event.target.value) <= Number(data?.warehouse?.["Online-Warehouse"]))
      ) {
        quantityValue = Number(event.target.value);

      } else if (event.target.value &&
        !isNaN(event.target.value) &&
        (Number(event.target.value) > Number(data?.warehouse?.["Online-Warehouse"]))) {
        quantityValue = quantityValue;
      }
      else {
        quantityValue = 0;
      }
      document.getElementById(`quantity-input-${data.productid}`).innerHTML = quantityValue;
    handleQtyChanges(quantityValue, params);

  };

  const onInputBlur = (event) => {
    if (event.target.value && !isNaN(event.target.value)) {
        const qty = event.target.value;
        if (parseInt(qty) > data.warehouse["Online-Warehouse"]) {
            quantityValue = 0;
            return toast(`Product quantity can not be more than ${data.warehouse["Online-Warehouse"]}`)
        }
        if (parseInt(qty) < 0) {
            quantityValue = 0;
            return toast(`Product quantity can not be less than 0`)
        }

        handleQtyChanges(qty, params);
    }
  };

  const componentToDisplay = () => {
    return Number(data?.warehouse?.["Online-Warehouse"]) <= 0 ? (<span className="out_of_stock"> Out of stock </span>) : (<span className="my-renderer quantity__div">
      <>
        <div className="pro__quantity quantity">
            <button className="pro__quantity-minus qty_minus"
                onClick={(event) => onDecrement(event)}
                disabled={quantityValue <= 0}
            >
                <img src={minus} alt="minus" />
            </button>
            <input
                id={`quantity-input-${data.productid}`}
                type="number"
                value={quantityValue}
                className="pro__quantity-field qty_input"
                onChange={(event) => onInputChange(event)}
                onBlur={(event) => onInputBlur(event)}
                disabled={data?.warehouse?.["Online-Warehouse"] === 0}
            />
            <button className="pro__quantity-plus qty_plus"
                onClick={(event) => onIncrement(event)}
                disabled={quantityValue === Number(data?.warehouse?.["Online-Warehouse"])}
            >
                <img src={plus} alt="plus" />
            </button>
        </div>
      </>

    </span>)
  }

  return (
    <>{componentToDisplay()}</>
  );
};

// export const ColumnQuantity = React.forwardRef((props, ref) => {
//   const { api, node, column, context } = props;
//   const [value, setValue] = React.useState(parseInt(props.value));
//   const refInput = React.useRef(null);

//   React.useEffect(() => {
//     setTimeout(() => refInput.current.focus());
//   }, []);

//   React.useImperativeHandle(ref, () => {
//     return {
//       getValue() {
//         node.setDataValue(column.colId, Number(value));
//         context.frameWorkComponentChange({ api: api });
//         return Number(value);
//       },
//       isCancelBeforeStart() {
//         return false;
//       },
//       isCancelAfterEnd() {
//         return Number(value) > Number(props.data?.warehouse?.["Online-Warehouse"]);
//       }
//     };
//   });

//   return (
// <>
//     <span className="my-renderer">
//         <button
//           className="btn-quantity"
//           onClick={() => setValue(value ? Number(value) + 1 : 1)}
//           disabled={value === Number(props.data?.warehouse?.["Online-Warehouse"])}
//         >+</button>
//         <input type="number"
//           className="quantity-inputbtn"
//           ref={refInput}
//           value={value}
//           onChange={event => setValue(event.target.value ? Number(event.target.value) : event.target.value)}
//         />

//         <button
//           className="btn-quantity"
//           onClick={() =>
//             setValue(value ? Number(value) - 1 : 0)
//           }
//           disabled={value <= 0}
//         >-</button>
//     </span>
//     </>
//   );
// });