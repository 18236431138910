import React from "react";
import { updateMatchedPrice } from "../../../serviceCalls/updateMatchedPrice";
import EmailModal from "../NextStatusModal/EmailModal";
import CreditFormModal from "../NextStatusModal/CreditForm";
import { ToastContainer, toast } from "react-toastify";

import ToggleButton from "react-toggle-button";

import { toggleTAApiCall } from "../../../serviceCalls/toggleTAAccountApiCall";
import {
  SET_IS_LOADING,
  SEND_TA_CREDIT_SUCCESSED,
  TA_CREDIT_APPROVAL_SUCCESSED,
} from "../../../constants/actionTypes";
import { sendEmailApiCall } from "../../../serviceCalls/sendEmailApiCall";
import { updateCreditLimitApiCall } from "../../../serviceCalls/updateCreditLimitApiCall";
import { actionOnEmiFormApiCall } from "../../../serviceCalls/actionOnEmiform";
import { fetchCreditUserApiCall } from "../../../serviceCalls/fetchCreditUserApiCall";
import { approveCreditLimitApiCall } from "../../../serviceCalls/approveCreditLimitApiCall";
import { sendCreditFormApiCall } from "../../../serviceCalls/sendCreditFormApiCall";
import { saveTaCreditFormApiCall } from "../../../serviceCalls/saveTaCreditFormApiCall";
import { uploadTACreditDocs } from "../../../serviceCalls/uploadTACreditDocs";
import { deleteTACreditDocsApiCall } from "../../../serviceCalls/deleteTACreditDocs";
import { submitTAFormApi } from "../../../serviceCalls/submitTAForm";
import { getTAFormApiCall } from "../../../serviceCalls/getTAForm";
import { downloadTACredit } from "../../../serviceCalls/downloadTaCredit";
import { updateTaCreditUserScore } from "../../../serviceCalls/updateTaCreditUserScore";

import Alert from "@material-ui/lab/Alert";

const TraderAccountSection = (props) => {
  const {
    authToken,
    dispatch,
    profile,
    isTAenabled,
    isAdmin,
    adminEmail,
    tacredituser,
    updateSendCreditFormMessage,
    approvedCreditLimitMsg,
    savedTACreditMsg,
    taForm,
  } = props;

  const [emailIdForModal, setEmailIdForModal] = React.useState("");
  const [emailIdModal, setEmailIdModal] = React.useState(false);
  const [tacredit, setTaCreditLimit] = React.useState(
    tacredituser && tacredituser.tacredit
  );
  const [pendingAmount, setPendingAmount] = React.useState(
    tacredituser && tacredituser.pendingAmount
  );
  const [taCredituser, setTaCreditUser] = React.useState(
    tacredituser && tacredituser.creditorders
  );
  const [fileToUpload, setFile] = React.useState([]);

  const [taFormDetail, setTaFormDetail] = React.useState({
    tacompanydetails: {
      fullCompanyName: null,
      tradingTitle: null,
      buildingName: null,
      houseNumber: null,
      streetName: null,
      town: null,
      country: null,
      postcode: null,
      telephoneNumber: null,
      county: null,
      communicationEmail: null,
    },
    taimportantsection: {
      accountManager: null,
      officeLocation: null,
      otherAmount: null,
      creditLimit1: false,
      creditLimit3: false,
      creditLimit5: false,
      creditLimit7: false,
      additionalInformation: null,
    },
    tadirectors: {
      soleTrader: false,
      partnership: false,
      plcCompany: false,
      llp: false,
      addressLessThan1: null,
      addressLessThan2: null,
      addressLessThan3: null,
      addressLessThan5: null,
      addressLessThan6: null,
      addressLessThan7: null,
      addressLessThan8: null,
      name1: null,
      address1: null,
      streetName1: null,
      town1: null,
      postcode1: null,
      dateOfBirth1: new Date(),
      mobileNo1: null,
      name2: null,
      Address2: null,
      streetName2: null,
      town2: null,
      postcode2: null,
      dateOfBirth2: null,
      mobileNo2: null,
      name3: null,
      address3: null,
      streetName3: null,
      town3: null,
      postcode3: null,
      dateOfBirth3: null,
      mobileNo3: null,
      name4: null,
      address4: null,
      streetName4: null,
      town4: null,
      postcode4: null,
      dateOfBirth4: null,
      mobileNo4: null,
    },
    tacompanyregistration: {
      companyNumber1: null,
      companyNumber2: null,
      companyRegistration: null,
      incorporationdate: null,
    },
    tadataprotection: {
      optOutEntirely: false,
      post: false,
      phone: false,
      whatsApp: false,
      sms: false,
      email: false,
    },
    taconfirmation: {
      dateSigned1: null,
      position1: null,
      printName1: null,
      signature1: null,
      dateSigned2: null,
      position2: null,
      printName2: null,
      signature2: null,
      dateSigned3: null,
      position3: null,
      printName3: null,
      signature3: null,
    },
  });

  const [creditFormModal, setCreditFormModal] = React.useState(false);

  const { applicationState } = props;

  React.useEffect(() => {
    fetchCreditUser();
    setTaCreditLimit(tacredituser && tacredituser.tacredit);
    setPendingAmount(tacredituser && tacredituser.pendingAmount);
    getTAFormApiCall({
      dispatch: dispatch,
      authToken: authToken,
      email: profile && profile.email,
    });
  }, [tacredituser && tacredituser.tacredit]);

  const onTaCreditChange = (value) => {
    setTaCreditLimit(value);
  };

  const saveInput = (e, item) => {
    const amountPaid = Number(e.target.value);
    if (amountPaid > item.orderamount) {
      toast("Amount paid cannot exceed Order amount.");
      return;
    }
    const order = tacredituser.creditorders.map((order) => {
      if (item.orderid === order.orderid) {
        order.amountpaid = amountPaid;
        order.outstandingcredit = Number(
          order.orderamount - amountPaid
        ).toFixed(2);
      }
      return order;
    });
    setTaCreditUser(order);
  };

  const saveTaCreditAccounts = () => {
    if (tacredit) {
      tacredituser["tacredit"] = Number(tacredit);
    }
    saveTaCreditFormApiCall({
      dispatch: dispatch,
      authToken: authToken,
      taCreditObject: tacredituser,
      email: profile && profile.email,
    });
  };

  const saveTaCreditUserScore = () => {
    if (tacredit) {
      let payload = JSON.stringify({
        useremail: tacredituser.useremail,
        approversEmail: tacredituser.approversEmail,
        pendingAmount: pendingAmount,
        tacredit: tacredit,
      });
      updateTaCreditUserScore({
        dispatch: dispatch,
        authToken: authToken,
        payload: payload,
      });
    }
  };

  const doEmailApiCall = ({ email, subject, emailbody }) => {
    const requestBodyForEmail = {
      email: email,
      subject: subject,
      emailbody: emailbody,
    };
    sendEmailApiCall({
      dispatch: dispatch,
      authToken: authToken,
      requestBodyForEmail: requestBodyForEmail,
    });
    showEmailModal(false);
    setEmailIdForModal("");
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const showEmailModal = (showEmailModalValue) => {
    setEmailIdModal(showEmailModalValue);
  };

  const showEmailPopUp = (data) => {
    showEmailModal(true);
    setEmailIdForModal(data.customerEmail);
  };

  const emailModalToDisplay = () => {
    return (
      <>
        <EmailModal
          title="Send Email"
          show={emailIdModal}
          onClose={() => {
            setEmailIdForModal("");
            showEmailModal(false);
          }}
          doEmailApiCall={doEmailApiCall}
          emailIdForModal={emailIdForModal}
        />
      </>
    );
  };

  const doCreditFormApiCall = (formObj) => {
    formObj["tauseremail"] = profile && profile.email;
    submitTAFormApi({
      dispatch: dispatch,
      authToken: authToken,
      data: formObj,
      taemail: profile && profile.email,
    });
    showCreditFormModal(false);
    setCreditFormModal("");
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const showCreditFormModal = (showCreditFormModalValue) => {
    setCreditFormModal(showCreditFormModalValue);
  };

  const creditFormModalToDisplay = () => {
    return (
      <>
        <CreditFormModal
          title="TRADE ACCOUNT APPLICATION FORM"
          show={creditFormModal}
          onClose={() => {
            setCreditFormModal("");
            showEmailModal(false);
          }}
          doCreditFormApiCall={doCreditFormApiCall}
          showCreditFormModal={creditFormModal}
          taForm={taForm}
          taFormDetail={taFormDetail}
        />
      </>
    );
  };

  const showCreditFormPopUp = (data) => {
    showCreditFormModal(true);
    // setCreditFormModal(data.customerEmail);
  };

  const updateCreditLimit = ({ creditLimit }) => {
    updateCreditLimitApiCall({
      dispatch: dispatch,
      authToken: authToken,
      creditLimit: creditLimit,
    });
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const actionOnEmiForm = ({ action }) => {
    actionOnEmiFormApiCall({
      dispatch: dispatch,
      authToken: authToken,
      action: action,
    });
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const approveTraderAccount = () => {
    approveCreditLimitApiCall({
      dispatch: dispatch,
      authToken: authToken,
      email: profile && profile.email,
      status: true,
      approveremail: adminEmail,
    });
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const sendCreditForm = () => {
    sendCreditFormApiCall({
      dispatch: dispatch,
      authToken: authToken,
      email: profile && profile.email,
    });
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const fetchCreditUser = () => {
    fetchCreditUserApiCall({
      dispatch: dispatch,
      authToken: authToken,
      email: profile && profile.email,
    });
    dispatch({ type: SET_IS_LOADING, payload: true });
  };

  const onClearSendFormMessage = () => {
    dispatch({
      type: SEND_TA_CREDIT_SUCCESSED,
      payload: { message: "", status: "" },
    });
  };

  const onClearApproveFormMessage = () => {
    dispatch({
      type: TA_CREDIT_APPROVAL_SUCCESSED,
      payload: { message: "", status: "" },
    });
  };

  const deleteItem = (type) => {
    console.log(type);
    deleteTACreditDocsApiCall({
      dispatch: dispatch,
      authToken: authToken,
      taemail: profile.email,
      fileType: type,
    });
  };

  const viewItem = (type) => {
    downloadTACredit({
      dispatch: dispatch,
      authToken: authToken,
      taemail: profile.email,
      filetype: type,
      file: null,
    });
  };

  const calculateAmountUnpaid = (item) => {
    const outstandingcredit = item.orderamount - item.amountpaid;
    return Number(outstandingcredit).toFixed(2);
  };

  return (
    <div className="be-spoken container-fluid">
      <ToastContainer />
      {isTAenabled ? (
        <div>
          <div>
            <button
              onClick={() => showCreditFormPopUp()}
              className="btn pink-btn"
            >
              Open Form
            </button>
            {isAdmin ? (
              <>
                <button
                  onClick={() => approveTraderAccount()}
                  className="btn btn-main"
                >
                  {" "}
                  Approve{" "}
                </button>
                <button
                  onClick={() =>
                    showEmailPopUp({ customerEmail: profile.email })
                  }
                  className="btn btn-main"
                >
                  {" "}
                  Reject{" "}
                </button>
              </>
            ) : (
              ""
            )}
            {updateSendCreditFormMessage && (
              <Alert
                className="mb-4"
                severity={"success"}
                onClose={onClearSendFormMessage}
              >
                {updateSendCreditFormMessage}
              </Alert>
            )}
            {approvedCreditLimitMsg && (
              <Alert
                className="mb-4"
                severity={"success"}
                onClose={onClearApproveFormMessage}
              >
                {approvedCreditLimitMsg}
              </Alert>
            )}
            {savedTACreditMsg && (
              <Alert
                className="mb-4"
                severity={"success"}
                onClose={onClearApproveFormMessage}
              >
                {savedTACreditMsg}
              </Alert>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {isAdmin ? (
        <div className="toggle mrt-20 pdb-30">
          <span>
            <span className="float-left mrl-15">
              Trade Account Active: &nbsp;&nbsp;
            </span>
            <ToggleButton
              value={isTAenabled}
              inactiveLabel={"NO"}
              activeLabel={"YES"}
              onToggle={() => {
                toggleTAApiCall({
                  authToken,
                  profile,
                  dispatch,
                  toggleValue: !isTAenabled,
                  adminEmail,
                });
              }}
              thumbStyle={{ borderRadius: 2 }}
              trackStyle={{ borderRadius: 2 }}
            />
          </span>
        </div>
      ) : (
        ""
      )}
      {isTAenabled ? (
        <div>
          <div>
            <div className="row form-card mt-12">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label htmlFor="floatingInput">Approver Email</label>
                <input
                  type="text"
                  disabled
                  className=""
                  id="ApproverEmail"
                  placeholder="Approver Email"
                  value={tacredituser && tacredituser.approversEmail}
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="form-floating mb-3">
                  <div style={{ position: "relative" }}>
                    <span
                      style={{ position: "absolute", top: "9px", left: "10px" }}
                    >
                      £
                    </span>
                    <input
                      type="number"
                      className=""
                      style={{ paddingLeft: "25px" }}
                      id="pendingAmountText"
                      placeholder="Pending Amount"
                      value={Number(pendingAmount).toFixed(2)}
                    />
                    <label htmlFor="floatingInput">Pending Amount</label>
                  </div>
                </div>
                <div className="form-floating mb-3">
                  <div style={{ position: "relative" }}>
                    <span
                      style={{ position: "absolute", top: "9px", left: "10px" }}
                    >
                      £
                    </span>
                    <input
                      type="number"
                      className=""
                      id="creditLimitText"
                      disabled={!isAdmin}
                      style={{ paddingLeft: "25px" }}
                      placeholder="Credit Limit"
                      onChange={(e) => onTaCreditChange(e.target.value)}
                      value={Number(tacredit).toFixed(2)}
                    />
                    <label htmlFor="floatingInput">Credit Limit</label>
                  </div>
                </div>
                {isAdmin ? (
                  <button
                    onClick={saveTaCreditUserScore}
                    className="btn btn-main"
                    disabled={!tacredituser.approved}
                  >
                    Save
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="bespoken-container">
            <table id="myTable" className="bespoken-table ">
            <tr style={{ height: 40, width: '100%', background: 'linear-gradient(90deg, #3141A1 0%, rgba(49, 65, 161, 0.90) 26%, rgba(49, 65, 161, 0.70) 50%, rgba(227, 173, 171, 0.70) 76%, #E3ADAB 100%)', color: '#fff' }}>

                <th
                  id="product-list"
                  style={{ width: "20%", textAlign: "center" }}
                >
                  Order ID
                </th>
                <th
                  id="product-list"
                  style={{ width: "20%", textAlign: "center" }}
                >
                  Order Date
                </th>
                <th id="og-price" style={{ width: "20%", textAlign: "center" }}>
                  Order Amount
                </th>
                <th
                  id="match-price"
                  style={{ width: "10%", textAlign: "center" }}
                >
                  Amount Paid
                </th>
                <th id="delete" style={{ width: "20%", textAlign: "center" }}>
                  Amount unpaid
                </th>
                <th id="delete" style={{ width: "30%", textAlign: "center" }}>
                  Status
                </th>
              </tr>
              {tacredituser &&
                tacredituser.creditorders &&
                tacredituser.creditorders.map((item, sIndex) => {
                  return (
                    <tr className={`table-${sIndex % 2 ? "even" : "odd"}`}>
                      <td
                        headers="product-list"
                        style={{ padding: 3, bgcolor: "#E8E7E7" }}
                      >{`${item.orderid}`}</td>
                      <td
                        headers="product-list"
                        style={{ padding: 3, bgcolor: "#E8E7E7" }}
                      >{`${item.orderdate}`}</td>
                      <td
                        headers="og-price"
                        style={{ textAlign: "center", bgcolor: "#E8E7E7" }}
                      >
                        £ {Number(item.orderamount).toFixed(2)}
                      </td>
                      <td
                        headers="og-price"
                        className="match-price"
                        style={{ textAlign: "center" }}
                      >
                        <div style={{ position: "relative" }}>
                          <span
                            style={{ position: "absolute", top: 10, left: 5 }}
                          >
                            £
                          </span>
                          <input
                            type="number"
                            disabled={!isAdmin}
                            value={Number(item.amountpaid).toFixed(2)}
                            onChange={(e) => saveInput(e, item)}
                            onKeyDown={(e) => saveInput(e, item)}
                          />
                        </div>
                      </td>
                      <td
                        headers="match-price"
                        style={{ textAlign: "center", bgcolor: "#E8E7E7" }}
                      >
                        £ {Number(item.outstandingcredit).toFixed(2)}
                      </td>
                      <td headers="match-price" className="match-price">
                        <input
                          type="text"
                          disabled
                          value={
                            item.orderamount == item.amountpaid
                              ? "PAID"
                              : "UNPAID"
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </table>
            {isAdmin ? (
              <button
                onClick={() => saveTaCreditAccounts()}
                className="btn btn-main"
                disabled={!tacredituser.approved}
              >
                Save
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {emailModalToDisplay()}
      {creditFormModalToDisplay()}
    </div>
  );
};
export default TraderAccountSection;