import * as React from "react";
import HeaderMenu from "../../common/HeaderMenu.js";
import { useHistory } from "react-router-dom";
import CheckoutProgressBar from "./CheckoutProgressBar";
import CustomerAmountDetails from "./CustomerAmountDetails";
import {
  isValidName,
  isValidEmail,
  isValidAddress,
  isValidPostcode,
  isValidPhone,
} from "../../../utils/regexUtils";
import {
  DISCOUNT_AMOUNT,
  PROMOCODE_DISCOUNT,
  SET_CUSTOMER_BILLING_DETAILS,
  SET_TOTAL_AMOUNT,
} from "../../../constants/actionTypes";
import { STORAGE_KEYS } from "../../../constants/common.js";
import ToggleButton from "react-toggle-button";
import AdminHeaderMenu from "../../common/AdminHeaderMenu";
import { promoCodeApiCall } from "../../../serviceCalls/promoCodeApiCall.js";
import { Button, Spinner } from "react-bootstrap";
import NoDataFoundModal from "./NoDataFoundModal.js";
import { addrApiConfig } from '../../../envConfigurations';
import axios from "axios";


const CustomerShippingInformationAdmin = (props) => {
  const history = useHistory();
  const { applicationState, dispatch } = props;
  const {
    cartDetails,
    subTotalAmount,
    totalVatAmount,
    totalAmount,
    shippingCost,
    config,
    existingCustomerData,
    existingCustomerApiError,
    isLoading,
    isBulkDiscount,
    promocodeDiscount,
    promoCodeError,
    warehouseAttr
  } = applicationState;
  console.log('applicationState :',applicationState);

  const [applyCoupon, setApplyCoupon] = React.useState(false);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [shippingAddressDetails, setShippingAddressDetails] = React.useState({
    firstName: applicationState?.existingCustomerData?.firstname ?? "",
    lastName: applicationState?.existingCustomerData?.lastname ?? "",
    email: applicationState?.existingCustomerData?.email ?? "",
    phoneNo: applicationState?.existingCustomerData.mobileno ?? "",
    addressLineOne: applicationState?.existingCustomerData.address ? existingCustomerData.address?.addressLineOne : "",
    addressLineTwo: applicationState?.existingCustomerData.address ? existingCustomerData.address?.addressLineTwo : "",
    city: applicationState?.existingCustomerData.address ? existingCustomerData.address?.city : "",
    postCode: applicationState?.existingCustomerData.address ? existingCustomerData.address?.postcode : "",
    promoCode: "",
    tradeOfBusiness:
      applicationState?.existingCustomerData?.tradeOfBusiness ?? "",
  });
  React.useEffect(() => {
    const cartData = JSON.parse(window.sessionStorage.getItem("cart"));

    if (promocodeDiscount || promoCodeError) {
      if(!promoCodeError && promocodeDiscount === 619 && shippingCost === 9.95){
        settingAmountDetails(shippingCost, Number(Number(subTotalAmount) +
          Number(totalVatAmount) +
          Number(shippingCost)) * Number(promocodeDiscount === 619 ? 0 : promocodeDiscount / 100).toFixed(2),applicationState.warehouseAttr);
        setDiscountAmount(Number(Number(subTotalAmount) +
        Number(totalVatAmount) +
        Number(shippingCost)) * Number((promocodeDiscount === 619 ? 0 : promocodeDiscount / 100)).toFixed(2));
        }else if (!promoCodeError && promocodeDiscount != 619 ) {
        settingAmountDetails(shippingCost, Number(Number(subTotalAmount) +
        Number(totalVatAmount) +
        Number(shippingCost)) * Number(promocodeDiscount === 619 ? 0 : promocodeDiscount / 100).toFixed(2),applicationState.warehouseAttr);
        setDiscountAmount(Number(Number(subTotalAmount) +
          Number(totalVatAmount) +
          Number(shippingCost)) * Number((promocodeDiscount === 619 ? 0 : promocodeDiscount / 100)).toFixed(2));
        }
      else {
        settingAmountDetails(shippingCost, 0,applicationState.warehouseAttr);
        setDiscountAmount(0);
        }
    }

    if (cartData) {
      setTempCart(cartData);
    }
    if (existingCustomerApiError) {
      setExistingCustomerError({
        ...existingCustomerError,
        emailError: existingCustomerApiError,
      });
    }
  }, [existingCustomerData?.email, promocodeDiscount, promoCodeError]);

  const [tempCart, setTempCart] = React.useState(cartDetails);


  // React.useEffect(() => {
  //   setShippingAddressDetails(applicationState.shippingAddressDetails)
  // }, [applicationState.shippingAddressDetails]);

  const [shippingAddressDetailsError, setShippingAddressDetailsError] =
    React.useState({
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneNoError: "",
      addressLineOneError: "",
      addressLineTwoError: "",
      cityError: "",
      postCodeError: "",
      instagramIdError: "",
      tradeOfBusinessError: "",
    });

  const [existingCustomerError, setExistingCustomerError] = React.useState({
    emailError: ""
  })

  const [billingAddressDetails, setBillingAddressDetails] = React.useState({
    firstNameBilling: applicationState?.shippingAddressDetails?.firstName ?? "",
    lastNameBilling: applicationState?.shippingAddressDetails?.lastName ?? "",
    emailBilling: applicationState?.shippingAddressDetails?.email ?? "",
    phoneNoBilling: applicationState?.shippingAddressDetails?.phoneNo ?? "",
    billingAddressLineOne: applicationState?.shippingAddressDetails?.billingAddressLineOne ?? "",
    billingAddressLineTwo: applicationState?.shippingAddressDetails?.billingAddressLineTwo ?? "",
    billingCity: applicationState?.shippingAddressDetails?.billingCity ?? "",
    billingPostCode: applicationState?.shippingAddressDetails?.postCode ?? "",
  });

  const [billingAddressDetailsError, setBillingAddressDetailsError] =
    React.useState({
      firstNameBillingError: "",
      lastNameBillingError: "",
      emailBillingError: "",
      phoneNoBillingError: "",
      billingAddressLineOneError: "",
      billingAddressLineTwoError: "",
      billingCityError: "",
      billingPostCodeError: "",
    });

  const [emptyCredentialsError, setEmptyCredentialsError] = React.useState("");
  const [isSameAddress, setIsSameAddress] = React.useState(
    applicationState?.isSameAddress ?? true
  );

  const [lookedUpAddrs, setLookedUpAddrs] = React.useState([]);
  const [lookupPerformed, setLookupPerformed] = React.useState();

  const lookupAddress = async () => {
    setLookupPerformed(true);
    const postcode = shippingAddressDetails.postCode;
    const url = `${addrApiConfig.apiHost}/find/${postcode}?api-key=${addrApiConfig.apiKey}`;
    let resp = null;
    try {
      resp = await axios.get(url);
      const addressList = resp?.data?.addresses.map(addressItem => {
        return addressItem?.split(',').map(item => item.trim()).filter(addressPart => addressPart).join(', ')
      })
      setLookedUpAddrs(addressList);
    } catch (error) {
      console.error('Error occurred while fetching addresses from ', url);
    }
  };

  const selectAddress = chosenAddress => {
    const customEvent = { target: { id: 'address', value: chosenAddress } };
    onShippingFieldChange(customEvent);
    setLookupPerformed(false);
  };

  const onNameChange = (e) => {
    let regex = /^[A-Za-z]+$/

    // check first char
    let first = e.target.value[0];

    if (first == undefined) {
      setShippingAddressDetails({
        ...shippingAddressDetails,
        [e.target.id]: "",
      });
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        [`${e.target.id}Error`]: "",
      });

    }

    if (regex.test(e.target.value)) {
      setShippingAddressDetails({
        ...shippingAddressDetails,
        [e.target.id]: e.target.value.replace(/\s+/g, ''),
      });
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        [`${e.target.id}Error`]: "",
      });

    }

  }

  const onEmailChange = (e) => {


    setShippingAddressDetails({
      ...shippingAddressDetails,
      [e.target.id]: e.target.value.replace(/\s+/g, ''),
    });
    setShippingAddressDetailsError({
      ...shippingAddressDetailsError,
      [`${e.target.id}Error`]: "",
    });


  }
  const onShippingFieldChange = (e) => {
    setShippingAddressDetails({
      ...shippingAddressDetails,
      [e.target.id]: e.target.value,
    });
    setShippingAddressDetailsError({
      ...shippingAddressDetailsError,
      [`${e.target.id}Error`]: "",
    });
  };

  const onBillingFieldChange = (e) => {
    setBillingAddressDetails({
      ...billingAddressDetails,
      [e.target.id]: e.target.value,
    });
    setBillingAddressDetailsError({
      ...billingAddressDetailsError,
      [`${e.target.id}Error`]: "",
    });
  };

  const toggleIsSameAddress = () => {
    setBillingAddressDetails({
      firstNameBilling: shippingAddressDetails.firstName,
      lastNameBilling: shippingAddressDetails.lastName,
      emailBilling: shippingAddressDetails.email,
      phoneNoBilling: shippingAddressDetails.phoneNo,
      billingAddressLineOne: shippingAddressDetails.addressLineOne,
      billingAddressLineTwo: shippingAddressDetails.addressLineTwo,
      billingCity: shippingAddressDetails.city,
      billingPostCode: shippingAddressDetails.postCode,
    });
    setIsSameAddress(!isSameAddress);
  };

  const validateName = (e) => {
    const { id, value } = e?.target;
    switch (id) {
      case "firstName":
        setShippingAddressDetailsError({
          ...shippingAddressDetailsError,
          firstNameError: isValidName(value),
        });
        break;
      case "lastName":
        setShippingAddressDetailsError({
          ...shippingAddressDetailsError,
          lastNameError: isValidName(value),
        });
      case "firstNameBilling":
        setBillingAddressDetailsError({
          ...billingAddressDetailsError,
          firstNameBillingError: isValidName(value),
        });
      case "lastNameBilling":
        setBillingAddressDetailsError({
          ...billingAddressDetailsError,
          lastNameBillingError: isValidName(value),
        });
    }
  };

  const validateEmail = (e) => {
    const { id, value } = e?.target;
    if (id === "email") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        emailError: isValidEmail(value),
      });
    } else {
      setBillingAddressDetailsError({
        ...billingAddressDetailsError,
        emailBillingError: isValidEmail(value),
      });
    }
  };



  const validateAddress = (e) => {
    const { id, value } = e?.target;

    if (id === "addressLineOne") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        addressLineOneError: isValidAddress(value),
      });
    }
    else if(id === "addressLineTwoError") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        addressLineTwoError: isValidAddress(value),
      });
    }
    else if(id === "billingAddressLineOne") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        billingAddressLineOne: isValidAddress(value),
      });
    }
    else if(id === "billingAddressLineTwo") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        billingAddressLineTwo: isValidAddress(value),
      });
    }
  };

  const validatePostcode = (e) => {
    const { id, value } = e?.target;
    if (id === "postCode") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        postCodeError: isValidPostcode(value),
      });
    } else {
      setBillingAddressDetailsError({
        ...billingAddressDetailsError,
        billingPostCodeError: isValidPostcode(value),
      });
    }
  };

  const validatePhone = (e) => {
    const { id, value } = e?.target;
    if (id === "phoneNo") {
      setShippingAddressDetailsError({
        ...shippingAddressDetailsError,
        phoneNoError: isValidPhone(value),
      });
    } else {
      setBillingAddressDetailsError({
        ...billingAddressDetailsError,
        phoneNoBillingError: isValidPhone(value),
      });
    }
  };

  const validateSubmit = (e) => {
    if (
      shippingAddressDetailsError.firstNameError ||
      shippingAddressDetailsError.lastNameError ||
      shippingAddressDetailsError.emailError ||
      shippingAddressDetailsError.addressLineOneError ||
      shippingAddressDetailsError.phoneNoError
    ) {
      e.preventDefault();
    } else if (
      shippingAddressDetails.firstName === "" ||
      shippingAddressDetails.lastName === "" ||
      shippingAddressDetails.email === "" ||
      shippingAddressDetails.addressLineOne === "" ||
      shippingAddressDetails.city === "" ||
      shippingAddressDetails.phoneNo === "" ||
      (!isSameAddress &&
        (billingAddressDetails.firstNameBilling === "" ||
          billingAddressDetails.lastNameBilling === "" ||
          billingAddressDetails.emailBilling === "" ||
          billingAddressDetails.phoneNoBilling === "" ||
          billingAddressDetails.billingAddressLineOne === "" ||
          billingAddressDetails.billingCity === "" ||
          billingAddressDetails.billingPostCode === ""))
    ) {
      e.preventDefault();
      let erroMsg = '';
        if(shippingAddressDetails.firstName === "") {
          erroMsg = 'First name is required.';
        }
        else if(shippingAddressDetails.lastName === "") {
          erroMsg = 'Last name is required.';
        }
        else if(shippingAddressDetails.email === "") {
          erroMsg = 'Email is required.';
        }
        else if(shippingAddressDetails.addressLineOne === "") {
          erroMsg = 'Shipping address line 1 is required.';
        }
        else if(shippingAddressDetails.phoneNo === "") {
          erroMsg = 'Phone No. is required.';
        }
        else if(shippingAddressDetails.city === "") {
          erroMsg = 'City is required.';
        }

        if(!isSameAddress) {
          if(billingAddressDetails.firstNameBilling === "") {
            erroMsg = 'Billing First name is required.';
          }
          else if(billingAddressDetails.lastNameBilling === "") {
            erroMsg = 'Billing Last name is required.';
          }
          else if(billingAddressDetails.emailBilling === "") {
            erroMsg = 'Billing Email is required.';
          }
          else if(billingAddressDetails.phoneNoBilling === "") {
            erroMsg = 'Billing Phone No. is required.';
          }
          else if(billingAddressDetails.billingAddressLineOne === "") {
            erroMsg = 'Billing Address Line 1 is required.';
          }
          else if(billingAddressDetails.billingCity === "") {
            erroMsg = 'Billing City is required.';
          }
          else if(billingAddressDetails.billingPostCode === "") {
            erroMsg = 'Billing Postcode is required.';
          }
        }
      setEmptyCredentialsError(
        erroMsg
      );
    } else {
      dispatch({
        type: DISCOUNT_AMOUNT,
        payload: {
          discountAmount
        }
      });
      dispatch({
        type: SET_CUSTOMER_BILLING_DETAILS,
        payload: {
          shippingAddressDetails,
          billingAddressDetails,
          isSameAddress,
          cppCodeExist: document.getElementById('ccpCode')?.value ? document.getElementById('ccpCode').value : "",
          warehouse:warehouseAttr
        },
      });
      history.push("/customerpayment_info_walkin");
    }
  };

  const settingAmountDetails = (shippingCost, discountedAmount = 0, option) => {

    var altshipcost = shippingCost;
    var updatedsubtotal = subTotalAmount;
    if(!promoCodeError && promocodeDiscount == 619 && shippingCost == 9.95){
      updatedsubtotal = subTotalAmount - 9.95;
    }
    console.log("subtotal " + subTotalAmount - 9.95);
    dispatch({
      type: SET_TOTAL_AMOUNT,
      payload: {
        altshipcost,
        subTotalAmount: updatedsubtotal,
        shippingCost: altshipcost,
        totalVatAmount: totalVatAmount,
        totalAmount: Number(updatedsubtotal) +
          Number(totalVatAmount) +
          Number(altshipcost) -
          Number(discountedAmount)
            .toFixed(2),
        warehouseattr: localStorage.getItem(STORAGE_KEYS.WALKING_SELECTED_WAREHOUSE) || option
      },
    });
  };

  const validatePromocode = (e) => {
    e.preventDefault();
    dispatch({
      type: PROMOCODE_DISCOUNT,
      payload: null,
      promoCodeError: ""
    });
    setApplyCoupon(true);
    if (shippingAddressDetails.promoCode != "") {
      let productQuantity = 0;
      const totalCost = Number(Number(subTotalAmount) + Number(totalVatAmount) + Number(shippingCost)).toFixed(2);
      cartDetails.forEach((cart) => {
        productQuantity = productQuantity + cart.quantity;
      });

      if (shippingAddressDetails.email != "") {

        const promoCodeRequest = {
          promoCode: shippingAddressDetails.promoCode,
          email: shippingAddressDetails.email,
          totalCost: Number(totalCost).toFixed(2),
          productQuantity: productQuantity
        }
        promoCodeApiCall({ dispatch, authToken: config.authToken, promoCodeRequest: promoCodeRequest })
      }
    }

    setApplyCoupon(false);


  }



  return (
    <div>
      <div>
        {config?.userType === "ADMIN" ? (
          <AdminHeaderMenu dispatch={dispatch} />
        ) : (
          <HeaderMenu dispatch={dispatch} cartCount={tempCart.length} />
        )}
      </div>
      {isLoading && (
        <div className="d-flex justify-content-center loader">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div
        id="checkout"
        style={{marginTop: '58px'}}
        className={` ${config?.userType === "ADMIN" ? "admin" : ""}`}
      >
        <div className="container-fluid">
          <div className="card px-0 pb-0 mb-3">
            <div className="row">
              <div className="col-md-12 mx-0 px-0">
                <form id="msform">
                  <CheckoutProgressBar
                    progressItem="Billing"
                    userType={config?.userType}
                  />
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-md-first order-last px-0 admin-shipaddress">
                      <fieldset>
                        <h2 className="fs-title">Shipping Address</h2>
                        <div className="form-card">
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="firstName"
                            placeholder="First Name*"
                            onChange={(e) => onNameChange(e)}
                            // onBlur={(e) => validateName(e)}
                            value={shippingAddressDetails.firstName}
                            maxLength="20"
                          />
                          {shippingAddressDetailsError.firstNameError ? (
                            <span className="error">
                              {shippingAddressDetailsError.firstNameError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="lastName"
                            placeholder="Last Name*"
                            onChange={(e) => onNameChange(e)}
                            // onBlur={(e) => validateName(e)}
                            value={shippingAddressDetails.lastName}
                            maxLength="20"
                          />
                          {shippingAddressDetailsError.lastNameError ? (
                            <span className="error">
                              {shippingAddressDetailsError.lastNameError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email*"
                            onChange={(e) => onEmailChange(e)}
                            // onBlur={(e) => validateEmail(e)}
                            value={shippingAddressDetails.email}
                            readOnly={config?.userType !== "ADMIN"}
                          />
                          {shippingAddressDetailsError.emailError ? (
                            <span className="error">
                              {shippingAddressDetailsError.emailError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="number"
                            name="phoneNo"
                            id="phoneNo"
                            className="form-control"
                            placeholder="Contact No.*"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validatePhone(e)}
                            value={shippingAddressDetails.phoneNo}
                            maxLength="10"
                          />
                          {shippingAddressDetailsError.phoneNoError ? (
                            <span className="error">
                              {shippingAddressDetailsError.phoneNoError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="addressLineOne"
                            id="addressLineOne"
                            placeholder="Shipping Address Line 1*"
                            className="form-control"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateAddress(e)}
                            value={shippingAddressDetails?.addressLineOne}
                          />
                          {shippingAddressDetailsError.addressLineOneError ? (
                            <span className="error">
                              {shippingAddressDetailsError.addressLineOneError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="addressLineTwo"
                            id="addressLineTwo"
                            placeholder="Shipping Address Line 2"
                            className="form-control"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateAddress(e)}
                            value={shippingAddressDetails?.addressLineTwo}
                          />
                          {shippingAddressDetailsError.addressLineTwoError ? (
                            <span className="error">
                              {shippingAddressDetailsError.addressLineTwoError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="city"
                            id="city"
                            placeholder="City*"
                            className="form-control"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validateAddress(e)}
                            value={shippingAddressDetails?.city}
                          />
                          {shippingAddressDetailsError.cityError ? (
                            <span className="error">
                              {shippingAddressDetailsError.cityError}
                            </span>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="postCode"
                            id="postCode"
                            className="form-control col-sm-6 col-md-4"
                            placeholder="Post Code*"
                            onChange={(e) => onShippingFieldChange(e)}
                            onBlur={(e) => validatePostcode(e)}
                            value={shippingAddressDetails.postCode}
                          />
                          {lookupPerformed ?
                            <select className="form-control col-sm-12 col-md-5 mb-3 ml-0" onChange={e => selectAddress(e.target.value)}>
                              <option selected={true}>{lookedUpAddrs?.length ? 'Choose Address' : 'No address found'}</option>
                              {lookedUpAddrs?.map(item => <option key={item} >{item}</option>)}
                            </select>
                            : null}
                          {shippingAddressDetailsError.postCodeError ? (
                            <span className="error">
                              {shippingAddressDetailsError.postCodeError}<br />
                            </span>
                          ) : (
                            <React.Fragment />
                          )} <br></br><br></br>
                          <input
                              type="text"
                              name="promoCode"
                              id="promoCode"
                              className="promoCode"
                              placeholder="Coupon code"
                              onChange={(e) => onShippingFieldChange(e)}
                              onBlur={(e) => onShippingFieldChange(e)}
                              value={shippingAddressDetails.promoCode}
                            />
                            {!applyCoupon &&
                              <button
                              className="next action-button btn-promocode"
                              type="submit"
                              onClick={(e) => validatePromocode(e)}>
                              APPLY
                            </button>}
                              {applyCoupon && <Button
                                className="button-spinner"
                                disabled
                                variant="secondary"
                                type="submit">
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                {`    Please Wait`}
                              </Button>}
                              <br></br><br></br>
							  {promoCodeError ? (
                            <>
                              <span className="error">
                                {promoCodeError}
                              </span>
                              <br />
                            </>
                          ) : (
                            <React.Fragment />
                          )}
                          <input
                            type="text"
                            name="cppCode"
                            id="ccpCode"
                            className="ccpCode"
                            placeholder="EASI code (optional)"
                          />
                          <label>Trade of business</label>
                          <select
                            id="tradeOfBusiness"
                            className="form-control"
                            name="tradeOfBusiness"
                            onChange={(e) => onShippingFieldChange(e)}
                            value={shippingAddressDetails.tradeOfBusiness}
                          >

                            <option value="Mobile Practitioners">
                              Mobile Practitioners
                            </option>
                            <option value="Prescriber">Prescriber</option>
                            <option value="Clinics">Clinics</option>
                            <option value="Training Academy">
                              Training Academy
                            </option>
                          </select>
                          {/* toggle */}
                          <div className="toggle mrt-20 pdb-30">
                            <span>
                              <ToggleButton
                                value={isSameAddress}
                                inactiveLabel={'NO'}
                                activeLabel={'YES'}
                                onToggle={() =>
                                  toggleIsSameAddress(!isSameAddress)
                                }
                                thumbStyle={{ borderRadius: 2 }}
                                trackStyle={{ borderRadius: 2 }}
                              />
                            </span>
                            <span className="float-left mrl-15">
                              Is billing address same as shipping address?
                            </span>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mobile-padding">
                      <CustomerAmountDetails
                        subTotalAmount={subTotalAmount}
                        finalVatAmount={totalVatAmount}
                        totalAmount={totalAmount}
                        shippingCost={promocodeDiscount && promocodeDiscount === 619 && shippingCost === 9.95 ? 9.95 : shippingCost}
                        isBulkDiscount={isBulkDiscount}
                        changeShippingCost={(newShippingCost,option) =>
                          settingAmountDetails(newShippingCost,'',option)
                        }
                        dispatch={dispatch}
                        discountAmount={discountAmount}
                        promocodeDiscount={promocodeDiscount}
                        shippingDisabled={promocodeDiscount && promocodeDiscount === 619 &&
                          shippingCost === 9.95 ? true : false}
                      />
                    </div>
                  </div>
                  {!isSameAddress && (
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-md-first order-last">
                        <fieldset>
                          <h2 className="fs-title">Billing Address</h2>
                          <div className="form-card">
                            <input
                              type="text"
                              name="firstNameBilling"
                              className="form-control"
                              id="firstNameBilling"
                              placeholder="First Name*"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateName(e)}
                              value={billingAddressDetails.firstNameBilling}
                            />
                            {billingAddressDetailsError.firstNameBillingError ? (
                              <span className="error">
                                {
                                  billingAddressDetailsError.firstNameBillingError
                                }
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="lastNameBilling"
                              className="form-control"
                              id="lastNameBilling"
                              placeholder="Last Name*"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateName(e)}
                              value={billingAddressDetails.lastNameBilling}
                            />
                            {billingAddressDetailsError.lastNameBillingError ? (
                              <span className="error">
                                {
                                  billingAddressDetailsError.lastNameBillingError
                                }
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="emailBilling"
                              id="emailBilling"
                              className="form-control"
                              placeholder="Email*"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateEmail(e)}
                              value={billingAddressDetails.emailBilling}
                            />
                            {billingAddressDetailsError.emailBillingError ? (
                              <span className="error">
                                {billingAddressDetailsError.emailBillingError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="phoneNoBilling"
                              id="phoneNoBilling"
                              className="form-control"
                              placeholder="Billing Contact No."
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validatePhone(e)}
                              value={billingAddressDetails.phoneNoBilling}
                            />
                            {billingAddressDetailsError.phoneNoBillingError ? (
                              <span className="error">
                                {billingAddressDetailsError.phoneNoBillingError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingAddressLineOne"
                              id="billingAddressLineOne"
                              placeholder="Billing Address Line 1*"
                              className="form-control"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateAddress(e)}
                              value={billingAddressDetails.billingAddressLineOne}
                            />
                            {billingAddressDetailsError.billingAddressLineOneError ? (
                              <span className="error">
                                {billingAddressDetailsError.billingAddressLineOneError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingAddressLineTwo"
                              id="billingAddressLineTwo"
                              placeholder="Billing Address Line 2"
                              className="form-control"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateAddress(e)}
                              value={billingAddressDetails.billingAddressLineTwo}
                            />
                            {billingAddressDetailsError.billingAddressLineTwoError ? (
                              <span className="error">
                                {billingAddressDetailsError.billingAddressLineTwoError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingCity"
                              id="billingCity"
                              placeholder="Billing City*"
                              className="form-control"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validateAddress(e)}
                              value={billingAddressDetails.billingCity}
                            />
                            {billingAddressDetailsError.billingAddressLineTwoError ? (
                              <span className="error">
                                {billingAddressDetailsError.billingAddressLineTwoError}
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                            <input
                              type="text"
                              name="billingPostCode"
                              id="billingPostCode"
                              className="form-control"
                              placeholder="Billing Post Code*"
                              onChange={(e) => onBillingFieldChange(e)}
                              onBlur={(e) => validatePostcode(e)}
                              value={billingAddressDetails.billingPostCode}
                            />
                            {billingAddressDetailsError.billingPostCodeError ? (
                              <span className="error">
                                {
                                  billingAddressDetailsError.billingPostCodeError
                                }
                              </span>
                            ) : (
                              <React.Fragment />
                            )}
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 order-md-first order-last admin-mb">
                    {emptyCredentialsError ? (
                      <div className="mb-4 error">{emptyCredentialsError}</div>
                    ) : (
                      <React.Fragment />
                    )}
                    <button
                      className="previous action-button-previous btn-secondary"
                      type="submit"
                      onClick={() => {
                        config?.userType === "ADMIN"
                          ? history.push("/place_order")
                          : history.push("/customercart_details");
                      }}
                    >
                      Back
                    </button>
                    <button
                      className="next action-button btn-main"
                      type="submit"
                      onClick={validateSubmit}
                    >
                      Proceed to Pay
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerShippingInformationAdmin;